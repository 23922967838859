import {
  ATTENDANCE_FREQ,
  SCORING_METHOD,
} from "../../../../../../../constants/criterion";

export const CUSTOMIZED_ATTENDANCE_FREQ = {
  no_check: { ...ATTENDANCE_FREQ.ITEM.NONE, cons_label: "無し", active: false },
  sometime: {
    ...ATTENDANCE_FREQ.ITEM.SOMETIME,
    cons_label: "時々",
    active: false,
  },
  always: {
    ...ATTENDANCE_FREQ.ITEM.ALWAYS,
    cons_label: "いつも",
    active: false,
  },
  report: {
    ...ATTENDANCE_FREQ.ITEM.REPORT,
    cons_label: "レポート提出",
    active: false,
  },
};

export const CUSTOMIZED_SCORING_METHOD = {
  only_exam: {
    ...SCORING_METHOD.ITEM.ONLY_EXAM,
    cons_label: "テスト",
    active: false,
  },
  only_report: {
    ...SCORING_METHOD.ITEM.ONLY_REPORT,
    cons_label: "レポート",
    active: false,
  },
  exam_report: {
    ...SCORING_METHOD.ITEM.EXAM_REPORT,
    cons_label: "テスト&レポート",
    active: false,
  },
};

export const SORT_METHODS = [
  { key: "student_count", text: "履修者数順", name: "users" },
  { key: "credit_easiness", text: "楽単順", name: "heart" },
  { key: "content_quality", text: "内容良さ順", name: "thumbs up" },
];
