import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Authenticator } from "@aws-amplify/ui-react";

import { Container } from "semantic-ui-react";

import Navbar from "../../components/Navbar/index";
import Footer from "../../components/Footer/index";

import * as ROUTES from "../../constants/routes";

class AuthPageTemplate extends React.Component {
  pushHistory = (uri) => {
    if (this.props.history) {
      this.props.history.push(uri);
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>楽単らくだ</title>
          <meta
            name="description"
            content={
              "「楽単らくだ」は、履修選択に悩める大学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
            }
          />
          <meta name="robots" content="index" />
        </Helmet>
        <Navbar
          style={{ marginBottom: "24px" }}
          subtitle={"授業評価"}
          show_pusher={false}
          show_control={false}
          show_action={false}
          pushHistory={this.pushHistory}
        />
        <Container>
          <Authenticator
            signUpAttributes={["email"]}
            formFields={{
              signIn: {
                username: {
                  label: "ユーザIDまたはメールアドレス",
                  placeholder: "ユーザID または メールアドレスを入力",
                },
                password: {
                  label: "パスワード",
                  placeholder: "パスワードを入力",
                },
              },
              signUp: {
                username: {
                  label: "ユーザーID",
                  placeholder: "ユーザーIDを入力 例: raku_taro55",
                  order: 1,
                },
                email: {
                  label: "メールアドレス",
                  placeholder: "メールアドレスを入力",
                  order: 2,
                },
                password: {
                  label: "パスワード",
                  placeholder: "パスワードを入力 ※8文字以上大文字含む",
                  order: 3,
                },
                confirm_password: {
                  label: "パスワードの確認",
                  placeholder: "パスワードを再入力",
                  order: 4,
                },
              },
            }}
          >
            {/* ログインまたはサインアップが完了していたらトップページへリダイレクト */}
            <RedirectToLandingPage />
          </Authenticator>
        </Container>
        <Footer pushHistory={this.pushHistory} />
      </>
    );
  }
}
export default AuthPageTemplate;

const RedirectToLandingPage = () => {
  useEffect(() => {
    window.location.href = ROUTES.LANDING;
  }, []);
  return null;
};
