import { createOption } from "./option";

export const onSelectChange = (
  e,
  { name, value },
  state,
  updateInput,
  setState
) => {
  switch (name) {
    case "course":
      value = value
        .trim()
        .replace(/\s+/g, " ")
        .replace("（", "(")
        .replace("）", ")")
        .replace("Ⅰ", "I")
        .replace("Ⅱ", "II")
        .replace("Ⅲ", "III");
      break;
    case "teacher":
      // valueは配列で渡される
      // 教員検索により追加したoptionはvalueが配列になっているため、渡ってくるvalueが[str, array]の形になっている
      // 本来は[str]のため、Warningが出る可能性がある
      if (value.length > 0 && typeof value[value.length - 1] === "object") {
        // valueが文字列ではなく配列になっているoptionを選択したとき
        value = value[value.length - 1];
      }
      value = value.map((item) => {
        return item.trim().replace(/\s+/g, " ");
      });
      break;
    default:
      break;
  }

  updateInput({ [name]: value });

  switch (name) {
    case "course":
      setState({
        is_course_selected: true,
        options: {
          ...state.options,
          selected: {
            ...state.options.selected,
            [name]: value !== "" ? [createOption(value)] : [],
          },
          results: {
            ...state.options.results,
            [name]: [], // resultsをクリア
          },
        },
        teachers_of_course: value === "" ? [] : state.teachers_of_course,
      });
      break;
    case "teacher":
      setState({
        is_teacher_selected: true,
        options: {
          ...state.options,
          selected: {
            ...state.options.selected,
            [name]: value.map((elm) => createOption(elm)),
          },
          results: {
            ...state.options.results,
            [name]: [], // resultsをクリア
          },
        },
      });
      break;
    default:
      break;
  }
};
