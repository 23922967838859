import React from "react";
import { Helmet } from "react-helmet";
import { Form } from "semantic-ui-react";
import request from "superagent";
import { Auth } from "aws-amplify";

import ReviewTemplateView from "./ReviewTemplateView";
import Searchbox from "../../components/Searchbox";

import UNIV from "../../constants/univ";
import * as API from "../../api/ApiConstant";

import { gaEventSearch, gaEventReview } from "../../api/GA";
import ReviewApi from "../../api/v2/ReviewApi";
import { convertReviewSchemaToReview } from "../../api/v2/ApiUtils";

export default class ReviewTemplate extends React.Component {
  static View = ReviewTemplateView;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      review_list: [],
      params: this.getParams(),
      title: "",
      user_id: "abcdef",
      currentUser: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match) {
      if (prevProps.match.params !== this.props.match.params) {
        this.setState({
          params: this.getParams(),
        });
        this.getReview();
      }
    }
  }

  componentDidMount() {
    this.setState({
      user_id: localStorage.getItem("user_session_id"),
    });
    this.getReview();
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({ currentUser: user });
      })
      .catch(() => null);
  }

  getParams() {
    const params = new URLSearchParams(this.props.location.search);
    return {
      keyword: params.get("keyword")?.split(",") || [],
      teacher: params.get("teacher")?.split(",") || [],
      course: params.get("course")?.split(",") || [],
      course_group: params.get("course_group")?.split(",") || [],
      semester: params.get("semester") || "",
    };
  }

  getReview = () => {
    // var api_str = "";
    // switch (page) {
    //   case "teacher":
    //   case "course":
    //     api_str = API.REVIEW.LIST.QUERY.GET.replace(
    //       "<univ_id>",
    //       univ_id
    //     ).replace("<query>", query);
    //     break;
    //   case "search":
    //     api_str = API.REVIEW.LIST.SEARCH.GET.replace(
    //       "<univ_id>",
    //       univ_id
    //     ).replace("<query>", query);
    //     break;
    //   default:
    //     api_str = API.REVIEW.LIST.LATEST.GET.replace("<univ_id>", univ_id);
    //     break;
    // }
    // request
    //   .get(api_str)
    //   .then((res) => {
    //     var title = "";
    //     if (res.body.length > 0) {
    //       switch (page) {
    //         case "teacher":
    //           title = res.body[0].teacher.data.name + "先生の授業評価";
    //           break;
    //         case "course":
    //           title = "「" + res.body[0].course.data.name + "」の授業評価";
    //           break;
    //         case "search":
    //           title = "「" + query + "」の検索結果";
    //           break;
    //         default:
    //           title = "";
    //           break;
    //       }
    //     }

    //     this.setState(
    //       {
    //         review_list: res.body,
    //         node_teacher: this.uniqNodeTeacher(res.body),
    //         node_course: this.uniqNodeCourse(res.body),
    //         title: title,
    //         loading: false,
    //       },
    //       () => {
    //         gaPageView("/app/" + univ_id + "/" + page + "/" + query);
    //         callback();
    //       }
    //     );
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // TODO: title設定
    const { univ_id } = this.props.match.params;
    const { keyword, teacher, course, course_group, semester } =
      this.getParams();
    this.setState({ loading: true });

    // GAイベント送信
    const labels = [];
    labels[0] = keyword.join(" ");
    labels[1] = teacher.join(" ");
    labels[2] = course.join(" ");
    labels[3] = course_group.join(" ");
    labels[4] = semester;
    gaEventSearch(labels.join(","));

    // 条件に一致するレビューを取得
    new ReviewApi()
      .getUnivReviews(univ_id, {
        keywords: keyword,
        teachers: teacher,
        course: course,
        course_group: course_group,
        semester: semester,
      })
      .then((reviews) => {
        this.setState({
          review_list: reviews.map((review) =>
            convertReviewSchemaToReview(review)
          ),
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  // レビューを削除
  deletePost = (nid) => {
    var api_str = API.REVIEW.DELETE.replace("<nid>", nid);
    var delete_key = prompt("投稿時に設定した削除キーを入力してください");
    request
      .delete(api_str)
      .send({
        delete_key: delete_key,
        user_id: localStorage.getItem("user_session_id"),
      })
      .then((res) => {
        alert("授業評価を削除しました。");
        gaEventReview("delete", localStorage.getItem("user_session_id"));
        this.getReview();
      })
      .catch((err) => {
        alert("授業評価を削除できませんでした。");
        console.log(err);
      });
  };

  pushHistory = (uri) => {
    if (this.props.history) {
      this.props.history.push(uri);
    }
  };

  render() {
    const { univ_id = "nagoya_u" } = this.props.match.params;
    const {
      is_valid_univ_id = Object.keys(UNIV).includes(univ_id),
      ...otherProps
    } = this.props;
    if (is_valid_univ_id) {
      return (
        <div {...otherProps}>
          <Helmet>
            <title>楽単らくだ for {UNIV[univ_id].name}</title>
            <meta
              name="description"
              content="「楽単らくだ」は、履修選択に悩める大学生のための情報共有プラットフォームです"
            />
            <meta name="robots" content="noindex" />
          </Helmet>
          <ReviewTemplateView
            univ_id={univ_id}
            subtitle={"for " + UNIV[univ_id].name}
            control={
              <Form>
                <Form.Field>
                  <Searchbox
                    univ_id={univ_id}
                    location={this.props.location}
                    pushHistory={this.pushHistory}
                    showDetail
                  />
                </Form.Field>
              </Form>
            }
            title={this.state.title}
            review_list={this.state.review_list}
            user_id={
              this.state.currentUser
                ? this.state.currentUser.attributes.sub
                : "abcdef"
            }
            deletePost={this.deletePost}
            pushHistory={this.pushHistory}
            loading={this.state.loading}
            limited={this.state.currentUser ? false : true}
          />
        </div>
      );
    } else {
      return <div>Not Found</div>;
    }
  }
}
