const config = {
  Auth: {
    region: process.env.REACT_APP_AWS_AUTH_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDPOOL_ID,
    userPoolId: process.env.REACT_APP_AWS_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_AUTH_CLIENT_ID,
  },
};

export default config;
