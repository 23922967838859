// export const API_HOSTNAME = process.env.REACT_APP_BACKEND_API_URL
//   ? process.env.REACT_APP_BACKEND_API_URL + "/api/v2"
//   : "/api/v2";
export const API_HOSTNAME = process.env.REACT_APP_BACKEND_API_URL + "/api/v2";

export const REVIEWS = {
  POST: API_HOSTNAME + "/reviews",
  COUNT: {
    GET: API_HOSTNAME + "/reviews/count",
    EACH: {
      GET: API_HOSTNAME + "/reviews/count/each",
    },
  },
  SINGLE: {
    GET: API_HOSTNAME + "/reviews/<review_id>",
    DELETE: API_HOSTNAME + "/reviews/<review_id>",
    LGTM: {
      PUT: API_HOSTNAME + "/reviews/<review_id>/lgtm",
      DELETE: API_HOSTNAME + "/reviews/<review_id>/lgtm",
    },
  },
  UNIV: {
    GET: API_HOSTNAME + "/reviews/univ/<univ_id>",
    COUNT: {
      GET: API_HOSTNAME + "/reviews/univ/<univ_id>/count",
    },
  },
  USER: {
    GET: API_HOSTNAME + "/reviews/user/<user_id>",
  },
};

export const USERS = {
  POST: API_HOSTNAME + "/users",
  UNIV: {
    GET: API_HOSTNAME + "/users/univ/<univ_id>",
  },
  SINGLE: {
    PUBLIC: {
      GET: API_HOSTNAME + "/users/<user_id>",
    },
    PRIVATE: {
      GET: API_HOSTNAME + "/users/<user_id>/private",
    },
    PUT: API_HOSTNAME + "/users/<user_id>",
    DELETE: API_HOSTNAME + "/users/<user_id>",
  },
};

export const TEACHERS = {
  UNIV: {
    GET: API_HOSTNAME + "/teachers/<univ_id>",
    GROUPS: {
      GET: API_HOSTNAME + "/teachers/groups/<univ_id>",
    },
  },
};

export const COURSES = {
  UNIV: {
    GET: API_HOSTNAME + "/courses/<univ_id>",
  },
};

export const APPLICANTS = {
  COUNT: {
    EACH: {
      GET: API_HOSTNAME + "/applicants/count/each",
    },
    UNIV: {
      GET: API_HOSTNAME + "/applicants/count/<univ_id>",
    },
  },
};

export const COMMUNITIES = {
  UNIV: {
    GET: API_HOSTNAME + "/communities/<univ_id>",
  },
};

export const SURVEYS = {
  GET: API_HOSTNAME + "/surveys",
  SINGLE: {
    ANSWERS: {
      POST: API_HOSTNAME + "/surveys/<survey_id>/answers",
    },
  },
};

export const TIMETABLES = {
  GET: API_HOSTNAME + "/timetables",
  POST: API_HOSTNAME + "/timetables",
  SINGLE: {
    GET: API_HOSTNAME + "/timetables/<table_id>",
    PUT: API_HOSTNAME + "/timetables/<table_id>",
    DELETE: API_HOSTNAME + "/timetables/<table_id>",
  },
  AVERAGE: {
    GET: API_HOSTNAME + "/timetables/average",
  },
  SHARES: {
    GET: API_HOSTNAME + "/timetables/shares",
    POST: API_HOSTNAME + "/timetables/shares",
    SINGLE: {
      DELETE: API_HOSTNAME + "/timetables/shares/<table_id>",
      COMMENTS: {
        POST: API_HOSTNAME + "/timetables/shares/<table_id>/comments",
      },
      IMPORT_COUNTS: {
        POST: API_HOSTNAME + "/timetables/shares/<table_id>/import_counts",
      },
    },
  },
  CELLS: {
    GET: API_HOSTNAME + "/timetables/cells",
    POST: API_HOSTNAME + "/timetables/cells",
    SINGLE: {
      GET: API_HOSTNAME + "/timetables/cells/<cell_id>",
    },
  },
};
