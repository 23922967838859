import React from "react";

import TimeTableChart from "../TimeTableChart";

const CaptureTemplate = (props) => {
  const {
    loading,
    grade,
    semester,
    timetable,
    placeholderTimeTable,
    captureRef,
  } = props;
  return (
    <div
      ref={captureRef}
      style={{
        display: "none",
        height: window.parent.screen.height,
        width: window.parent.screen.width,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          padding: "12px",
          backgroundColor: "#f4f4f4",
        }}
      >
        <div style={{ textAlign: "center", maxWidth: "768px" }}>
          <div
            style={{
              marginBottom: 16,
              padding: "4px 16px",
              backgroundColor: "white",
              display: "inline-block",
              borderRadius: 999,
              fontFamily: "sans-serif",
              fontWeight: "bold",
              color: "#4A94A1",
            }}
          >
            {grade}年{semester}
          </div>
          <TimeTableChart
            timetable={timetable || placeholderTimeTable}
            loading={loading}
            pictureMode={false}
          />
          <div style={{ marginTop: 24 }}>
            <img
              src="/img/rakuda/rakuda_transparent.png"
              alt="logo"
              style={{ width: "50%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CaptureTemplate;
