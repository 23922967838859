import React, { useState } from "react";
import { Progress } from "semantic-ui-react";

import styles from "./index.module.sass";
import SurveyApi from "../../../api/v2/SurveyApi";

const ThreeOptions = (props) => {
  const { ...otherProps } = props;
  const [selected, setSelected] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [survey, setSurvey] = useState(props.survey);
  const handleBarClick = (idx) => {
    setSelected(true);
    setSelectedIndex(idx);
    incrementAnsCount(idx);
    postAnswer(idx);
  };
  const incrementAnsCount = (idx) => {
    setSurvey((prevValue) => {
      const newSurvey = { ...prevValue };
      newSurvey.total_ans_count++;
      newSurvey.options[idx].ans_count++;
      return newSurvey;
    });
  };
  const postAnswer = (idx) => {
    const surveyApi = new SurveyApi();
    const answer = {
      answer: survey.options[idx].option,
    };
    surveyApi.postAnswer(survey.survey_id, answer);
  };
  if (!survey) return null;
  const { question, options, total_ans_count } = survey;
  return (
    <div {...otherProps}>
      <p className={styles.question}>{question}</p>
      <div>
        {options.map((option, idx) => {
          const percent = Math.round(
            (option.ans_count * 100) / total_ans_count
          );
          return (
            <div
              className={styles.option}
              style={{
                cursor: selected ? "default" : "pointer",
              }}
              onClick={selected ? null : () => handleBarClick(idx)}
              {...otherProps}
            >
              <Progress
                className={styles.progress}
                percent={selected ? percent : 0}
                color={selectedIndex === idx ? "green" : "gray"}
              />
              <p
                className={styles.description}
                style={{
                  fontWeight: selected ? "bold" : "normal",
                  color: selected ? "white" : "#222",
                  textShadow: selected ? "0.8px 1px 1.5px #333" : "none",
                }}
              >
                {option.option}
                {selected && ` ${percent}%`}
              </p>
            </div>
          );
        })}
      </div>
      <p className={styles.ans_count}>{total_ans_count} 票</p>
    </div>
  );
};
export default ThreeOptions;
