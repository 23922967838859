import React from "react";
import { Segment } from "semantic-ui-react";

export const SymbolButton = (props) => {
  const { icon, label, disabled = false, onClick } = props;
  return (
    <Segment
      style={{
        textAlign: "center",
        padding: "12px",
        cursor: disabled ? "auto" : "pointer",
      }}
      onClick={disabled ? null : onClick}
      disabled={disabled}
    >
      <div style={{ margin: "4px auto", maxWidth: "50%" }}>{icon}</div>
      <p style={{ color: "gray" }}>{label}</p>
    </Segment>
  );
};
