export const updateInput = (state, setState, obj) => {
    var input_select = { ...state.input_select };
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        input_select[key]["value"] = obj[key];
        input_select[key]["is_invalid"] = false;
      }
    }
    setState({
      input_select: input_select,
      is_form_changed: true,
    });
  };

  export const verifyInput = (state, setState, obj) => {
    var input_select = { ...state.input_select };
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        input_select[key]["is_invalid"] = obj[key];
      }
    }
    setState({
      input_select: input_select,
    });
  };