import React from "react";
import { Input, Label, Icon, LabelGroup } from "semantic-ui-react";

import { SORT_METHODS } from "../CellSelectContent/constants";

const CellSelectControl = (props) => {
  const {
    searchQuery,
    attendanceFreq,
    scoringMethod,
    setSearchQuery,
    sortMethod,
    setAttendanceFreq,
    setScoringMethod,
    setSortMethod,
    style,
  } = props;
  return (
    <div style={style}>
      <Input
        value={searchQuery}
        icon="search"
        placeholder="講義名を入力"
        fluid
        size="small"
        onChange={(_, { value }) => setSearchQuery(value)}
      />
      <p
        style={{
          margin: "10px 0 0",
          fontSize: "14px",
          fontWeight: "bold",
          color: "#333",
          fontFamily: "sans-serif",
        }}
      >
        絞り込み条件
      </p>
      <div style={{ marginTop: "6px" }}>
        <div>
          <span style={{ color: "gray" }}>
            <Icon name="clipboard check" />
            <span style={{ fontSize: "0.9em" }}>出欠確認: </span>
          </span>
          <LabelGroup size="small" style={{ display: "inline" }}>
            {Object.keys(attendanceFreq).map((key, index) => {
              const freq = attendanceFreq[key];
              return (
                <Label
                  key={index}
                  color="grey"
                  as="a"
                  content={freq.cons_label}
                  basic={!freq.active}
                  onClick={() => {
                    setAttendanceFreq((prev) => ({
                      ...prev,
                      [key]: { ...prev[key], active: !prev[key].active },
                    }));
                  }}
                  style={{ margin: "0 3px 3px 0", fontFamily: "sans-serif" }}
                />
              );
            })}
          </LabelGroup>
        </div>
        <div>
          <span style={{ color: "gray" }}>
            <Icon name="pencil" />
            <span style={{ fontSize: "0.9em" }}>採点方法: </span>
          </span>
          <LabelGroup size="small" style={{ display: "inline" }}>
            {Object.keys(scoringMethod).map((key, index) => {
              const method = scoringMethod[key];
              return (
                <Label
                  key={index}
                  color="grey"
                  as="a"
                  content={method.cons_label}
                  basic={!method.active}
                  onClick={() => {
                    setScoringMethod((prev) => ({
                      ...prev,
                      [key]: { ...prev[key], active: !prev[key].active },
                    }));
                  }}
                  style={{ margin: "0 3px 3px 0", fontFamily: "sans-serif" }}
                />
              );
            })}
          </LabelGroup>
        </div>
        <div style={{ margin: "8px 0 2px" }}>
          <span style={{ color: "gray" }}>
            <Icon name="sort amount down" />
            <span style={{ fontSize: "0.9em" }}>並べ替え: </span>
          </span>
          <LabelGroup size="small" style={{ display: "inline" }}>
            {SORT_METHODS.map((method, index) => (
              <Label
                key={index}
                color="grey"
                as="a"
                content={
                  <span>
                    <Icon name={method.name} />
                    {method.text}
                  </span>
                }
                basic={method.key !== sortMethod}
                onClick={() => setSortMethod(method.key)}
                style={{ margin: "0 3px 3px 0", fontFamily: "sans-serif" }}
              />
            ))}
          </LabelGroup>
        </div>
      </div>
    </div>
  );
};
export default CellSelectControl;
