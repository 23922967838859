export const ICON_TYPES = {
  red: {
    name: "サンレッド",
    color: "#df212c",
    scoreLimit: 0,
  },
  orange: {
    name: "キャメルオレンジ",
    color: "#FFA500",
    scoreLimit: 0,
  },
  green: {
    name: "オアシスグリーン",
    color: "#9dc04c",
    scoreLimit: 0,
  },
  cyan: {
    name: "スカイブルー",
    color: "#80c8ef",
    scoreLimit: 0,
  },
  blue: {
    name: "ナイトブルー",
    color: "#0075c2",
    scoreLimit: 0,
  },
  purple: {
    name: "ミスティックパープル",
    color: "#8e4593",
    scoreLimit: 0,
  },
};

// 選択可能なアイコンのoptionsを取得する
export const getLimitedIconTypeOptions = (score) => {
  return Object.keys(ICON_TYPES)
    .filter((key) => {
      return ICON_TYPES[key].scoreLimit <= score;
    })
    .map((key) => {
      return {
        key: key,
        value: key,
        text: ICON_TYPES[key].name,
      };
    });
};
