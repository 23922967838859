import CourseApi from "@/api/v2/CourseApi";
import TeacherApi from "@/api/v2/TeacherApi";
import { teacherFullnameSort } from "./teacher";
import { createOption } from "./option";

let timer = null;

// 講義名が変化した際の処理: 講義名のオートコンプリート, 教員名のレコメンド
export const courseChangeAutoComplete = (
  state,
  setState,
  univId,
  name,
  searchQuery
) => {
  clearTimeout(timer);
  setState({ loading: { ...state.loading, [name]: true } });

  timer = setTimeout(() => {
    const courseApi = new CourseApi();
    const teacherApi = new TeacherApi();

    courseApi
      .getUnivCourses(univId, searchQuery)
      .then((res) => {
        const currentValue = state.input_select.course.value;
        const courses = Array.from(new Set(res.map((course) => course.name))); // 重複を削除
        const courseOptions = courses
          .map((course) => createOption(course))
          .filter((option) => option.value !== currentValue); // 現在の値と同じものはoptionが重複するので除外

        setState({
          options: {
            ...state.options,
            results: {
              ...state.options.results,
              [name]: courseOptions,
            },
          },
        });
      })
      .finally(() => {
        setState({
          loading: { ...state.loading, [name]: false },
        });
      });

    teacherApi
      .getUnivCourseTeachers(univId, searchQuery)
      .then((res) => {
        let teachers = res.map((teacher) => teacher.name);
        teachers
          .map((teacher) => createOption(teacher))
          .filter((option) => !state.teachers_of_course.includes(option.value)); // 現在の値と同じものはoptionが重複するので除外
        teachers = teacherFullnameSort(teachers);
        setState({
          teachers_of_course: teachers,
        });
      })
      .finally(() => {
        setState({
          loading: { ...state.loading, course: false, teacher: false },
        });
      });
  }, 1000); // 1秒間入力がなければAPIを叩く
};

// 教員名のオートコンプリート
export const teacherAutoComplete = (
  state,
  setState,
  univId,
  name,
  searchQuery
) => {
  clearTimeout(timer);
  setState({ loading: { ...state.loading, [name]: true } });
  timer = setTimeout(() => {
    const teacherApi = new TeacherApi();
    const currentValue = [...state.input_select.teacher.value, searchQuery];
    // 教員名を検索してオートコンプリート
    teacherApi
      .getUnivTeachers(univId, searchQuery)
      .then((res) => {
        const teachers = res.map((teacher) => teacher.name);
        const teacherOptions = teachers
          .map((teacher) => createOption(teacher))
          .filter((option) => !currentValue.includes(option.value)); // 現在の値と同じものはoptionが重複するので除外
        setState({
          options: {
            ...state.options,
            results: {
              ...state.options.results,
              [name]: teacherOptions,
            },
          },
        });
      })
      .finally(() => {
        setState({
          loading: { ...state.loading, [name]: false },
        });
      });
  }, 1000); // 1秒間入力がなければAPIを叩く
};
