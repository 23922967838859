import React from "react";
import { Loader } from "semantic-ui-react";

export const LoadingContent = () => {
  return (
    <div style={{ height: "30vh", paddingTop: "30px" }}>
      <Loader active inline="centered" size="big" content="読み込み中" />
    </div>
  );
};
