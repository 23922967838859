import React from "react";
import { Link } from "react-router-dom";

import { Segment, Button, Icon } from "semantic-ui-react";

export const IntroPanel = (props) => {
  const { onGenerateClick, onCustomClick } = props;
  return (
    <Segment
      raised
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        width: "70%",
        margin: "0px",
        padding: "16px 24px",
        backgroundColor: "white",
      }}
    >
      <Button
        onClick={onGenerateClick}
        color="instagram"
        size="massive"
        style={{
          marginRight: "10px",
          display: "block",
          margin: "12px auto 8px",
          height: "96px",
          fontFamily: "sans-serif",
        }}
      >
        <Icon name="magic" color="white" fitted />
        <p style={{ fontSize: "16px", marginTop: "12px" }}>時間割を自動作成</p>
      </Button>
      <div style={{ margin: "12px auto 4px" }}>
        <Link style={{ textShadow: "0 0 8px white" }} onClick={onCustomClick}>
          手動で作成
          <Icon name="angle right" />
        </Link>
      </div>
    </Segment>
  );
};
