import React from "react";
import {
  Button,
  Checkbox,
  Form,
  FormField,
  Select,
  Grid,
  GridColumn,
  LabelGroup,
  Dropdown,
  Icon,
} from "semantic-ui-react";

import styles from "./styles.module.scss";
import { useAddCellForm } from "../../../../../hooks/timetable/useAddCellForm";
import Counter from "./Counter";

import CourseApi from "../../../../../../../api/v2/CourseApi";
import TeacherApi from "../../../../../../../api/v2/TeacherApi";
import UNIV from "../../../../../../../constants/univ";
import { useLoadings } from "../../../../../../../hooks/utils";

const createOption = (value) => ({ key: value, text: value, value: value });
let timer = null;

const CellAddContent = (props) => {
  const { univ_id, semester, onCancelClick, onAddClick } = props;
  const {
    values,
    options,
    errors,
    register,
    setValues,
    setOptions,
    setErrors,
  } = useAddCellForm({
    defaultValues: {
      course_name: null,
      teachers: null,
      course_group: null,
      semester: UNIV[univ_id].periods.includes(semester)
        ? semester
        : UNIV[univ_id].periods[0],
      credit: 2,
    },
    defaultOptions: {
      selected: {
        course_name: [],
        teachers: [],
      },
      searched: {
        course_name: [],
        teachers: [],
      },
    },
  });
  const { loadings, setLoading } = useLoadings({
    course_name: false,
    teachers: false,
    submit: false,
  });

  const handleFormChange = (_, { name, value, checked }) => {
    switch (name) {
      case "course_name":
        value = value
          .trim()
          .replace(/\s+/g, " ")
          .replace("（", "(")
          .replace("）", ")")
          .replace("Ⅰ", "I")
          .replace("Ⅱ", "II")
          .replace("Ⅲ", "III");
        setValues((values) => ({
          ...values,
          [name]: value,
        }));
        setOptions((options) => ({
          searched: {
            ...options.searched,
            [name]: [],
          },
          selected: {
            ...options.selected,
            [name]: value ? [createOption(value)] : [],
          },
        }));
        break;
      case "teachers":
        value = value.map((item) => {
          return item.trim().replace(/\s+/g, " ");
        });
        setValues((values) => ({
          ...values,
          [name]: value,
        }));
        setOptions((options) => ({
          searched: {
            ...options.searched,
            [name]: [],
          },
          selected: {
            ...options.selected,
            [name]: value ? value.map((elm) => createOption(elm)) : [],
          },
        }));
        break;
      case "credit":
        setValues((values) => ({
          ...values,
          [name]: value < 0 ? 0 : value,
        }));
        break;
      default:
        break;
    }
  };

  const handleSearchChange = (_, { name, searchQuery }) => {
    switch (name) {
      case "course_name":
        setOptions((options) => ({
          ...options,
          searched: {
            ...options.searched,
            [name]: [],
          },
        }));
        if (searchQuery.length === 0) return;
        clearTimeout(timer);
        setLoading("course_name", true);
        timer = setTimeout(async () => {
          const courseApi = new CourseApi();
          try {
            const res = await courseApi.getUnivCourses(univ_id, searchQuery);
            const currentValue = values.course_name;
            const courses = Array.from(
              new Set(res.map((course) => course.name))
            ); // 重複を削除
            const courseOptions = courses
              .map((course) => createOption(course))
              .filter((option) => option.value !== currentValue); // 現在の値と同じものはoptionが重複するので除外
            setOptions((options) => ({
              ...options,
              searched: {
                ...options.searched,
                [name]: courseOptions,
              },
            }));
          } catch (err) {
            console.error(err);
          } finally {
            setLoading("course_name", false);
          }
        }, 1000); // 1秒間入力がなければAPIを叩く
        break;
      case "teachers":
        setOptions((options) => ({
          ...options,
          searched: {
            ...options.searched,
            [name]: [],
          },
        }));
        if (searchQuery.length === 0) return;
        clearTimeout(timer);
        setLoading("teachers", true);
        timer = setTimeout(async () => {
          const teacherApi = new TeacherApi();
          try {
            const res = await teacherApi.getUnivTeachers(univ_id, searchQuery);
            const currentValue = [...(values.teachers || []), searchQuery];
            const teachers = res.map((teacher) => teacher.name);
            const teacherOptions = teachers
              .map((teacher) => createOption(teacher))
              .filter((option) => !currentValue.includes(option.value)); // 現在の値と同じものはoptionが重複するので除外
            setOptions((options) => ({
              ...options,
              searched: {
                ...options.searched,
                [name]: teacherOptions,
              },
            }));
          } catch (err) {
            console.error(err);
          } finally {
            setLoading("teachers", false);
          }
        }, 1000); // 1秒間入力がなければAPIを叩く
    }
  };

  const handleAddButtonClick = () => {
    setLoading("submit", true);
    // バリデーション
    const errors = {};
    if (!values.course_name) {
      errors.course_name = "講義名を入力してください";
    }
    if (!values.teachers || values.teachers.length === 0) {
      errors.teachers = "教員名を入力してください";
    }
    if (!values.course_group) {
      errors.course_group = "科目区分を選択してください";
    }
    if (!values.semester) {
      errors.semester = "学期を選択してください";
    }
    if (!values.credit || values.credit < 0) {
      errors.credit = "単位数が不正です";
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      setLoading("submit", false);
      return;
    }

    const { course_name, teachers, course_group, semester, credit } = values;
    onAddClick({
      credit: credit,
      courseName: course_name,
      courseGroup: course_group,
      semester: semester,
      teachers: teachers,
    }).then(() => {
      setLoading("submit", false);
    });
  };

  return (
    <div
      className={styles.header}
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        flexGrow: 1,
      }}
    >
      <div
        style={{
          maxWidth: 400,
          width: "100%",
          margin: "4px auto 0",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <h3>授業を追加</h3>
        <Form style={{ height: "100%", maxWidth: 999 }}>
          <div>
            <FormField>
              <label className={styles.sans_serif}>講義名</label>
              <Dropdown
                value={values.course_name}
                options={[
                  ...options.selected.course_name,
                  ...options.searched.course_name,
                ]}
                placeholder="講義名を入力"
                loading={loadings.course_name}
                fluid
                search
                selection
                clearable
                scrolling
                closeOnChange
                allowAdditions
                additionLabel="検索条件に追加: "
                noResultsMessage="検索キーワードを入力してください"
                {...register("course_name")}
                onChange={handleFormChange}
                onSearchChange={handleSearchChange}
              />
              {errors.course_name && (
                <span className={styles.error}>
                  <Icon name="exclamation circle" />
                  {errors.course_name}
                </span>
              )}
            </FormField>
            <FormField>
              <label className={styles.sans_serif}>教員名</label>
              <Dropdown
                value={values.teachers || []}
                options={[
                  ...options.selected.teachers,
                  ...options.searched.teachers,
                ]}
                placeholder="教員名を入力"
                loading={loadings.teachers}
                fluid
                multiple
                search
                selection
                clearable
                scrolling
                closeOnChange
                allowAdditions
                additionLabel="検索条件に追加: "
                noResultsMessage="検索キーワードを入力してください"
                {...register("teachers")}
                onChange={handleFormChange}
                onSearchChange={handleSearchChange}
              />
              {errors.teachers && (
                <span className={styles.error}>
                  <Icon name="exclamation circle" />
                  {errors.teachers}
                </span>
              )}
            </FormField>
            <FormField>
              <label className={styles.sans_serif}>科目区分</label>
              <Select
                value={values.course_group}
                options={UNIV[univ_id].course_group.map((group) =>
                  createOption(group)
                )}
                placeholder="選択してください"
                fluid
                {...register("course_group")}
              />
              {errors.course_group && (
                <span className={styles.error}>
                  <Icon name="exclamation circle" />
                  {errors.course_group}
                </span>
              )}
            </FormField>
            <FormField>
              <label className={styles.sans_serif}>学期</label>
              <Select
                value={values.semester}
                options={UNIV[univ_id].periods.map((period) =>
                  createOption(period)
                )}
                placeholder="選択してください"
                fluid
                {...register("semester")}
              />
            </FormField>
            <FormField>
              <label className={styles.sans_serif}>単位数</label>
              <LabelGroup basic>
                <Counter
                  value={values.credit}
                  {...register("credit")}
                  onChange={handleFormChange}
                />
              </LabelGroup>
            </FormField>
          </div>
        </Form>
        <Grid columns={2} style={{ marginBottom: 6 }}>
          <GridColumn>
            <Button
              content="キャンセル"
              fluid
              className={styles.sans_serif}
              onClick={onCancelClick}
            />
          </GridColumn>
          <GridColumn>
            <Button
              content="授業を追加"
              color="blue"
              loading={loadings.submit}
              fluid
              className={styles.sans_serif}
              onClick={handleAddButtonClick}
            />
          </GridColumn>
        </Grid>
      </div>
    </div>
  );
};
export default CellAddContent;
