import React from "react";
import { Label } from "semantic-ui-react";

const SpeechBubble = (props) => {
  const { children, style } = props;
  return (
    <Label basic pointing color="green" style={{ borderRadius: 8, ...style }}>
      <p style={{ lineHeight: "1.2em", fontWeight: "normal" }}>{children}</p>
    </Label>
  );
};
export default SpeechBubble;
