import React, { useCallback } from "react";
import { Icon, List } from "semantic-ui-react";

import CellListItem from "./CellListItem";
import SearchFigure from "../../../../../../../static/figures/search.png";

const CellList = (props) => {
  const { cells = [], loading = false, onSelectClick } = props;
  const handleSelectClick = useCallback(
    (cell) => {
      onSelectClick(cell);
    },
    [onSelectClick]
  );
  return (
    <List
      divided
      relaxed
      celled
      style={{ overflow: "scroll", margin: "4px 0 6px", flexGrow: 1 }}
    >
      {loading ? (
        <div style={{ textAlign: "center", margin: "12px auto" }}>
          <Icon name="circle notch" loading size="big" color="grey" />
        </div>
      ) : (
        <>
          {cells.map((cell) => (
            <CellListItem
              key={cell.cell_id}
              cell={cell}
              onSelectClick={() => handleSelectClick(cell)}
            />
          ))}
          {cells.length === 0 && (
            <div style={{ textAlign: "center", margin: "24px auto" }}>
              <img
                src={SearchFigure}
                alt="search"
                style={{ width: "100px", height: "100px" }}
              />
              <p style={{ fontSize: "1.2em" }}>授業が見当たりません</p>
            </div>
          )}
        </>
      )}
    </List>
  );
};
export default CellList;
