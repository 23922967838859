// export const API_HOSTNAME = process.env.REACT_APP_BACKEND_API_URL + "/api/v1/<univ_id>";
export const API_HOSTNAME = (process.env.REACT_APP_BACKEND_API_URL)
  ? process.env.REACT_APP_BACKEND_API_URL+ "/api/v1"
  : "/api/v1"

export const USER_COUNT = API_HOSTNAME + "/user_count"
export const REVIEW_COUNT = API_HOSTNAME + "/<univ_id>/review_count"


export const REVIEW = {
  GET: API_HOSTNAME + "/<univ_id>/review",
  POST: API_HOSTNAME + "/<univ_id>/review",
  DELETE: API_HOSTNAME + "/<univ_id>/review/<nid>",
  LIST: {
    LATEST: {
      GET: API_HOSTNAME + "/<univ_id>/review",
    },
    QUERY: {
      GET: API_HOSTNAME + "/<univ_id>/node/<query>/review",
    },
    SEARCH: {
      GET: API_HOSTNAME + "/<univ_id>/search/<query>",
    },
  },
}



export const SEARCH = {
  TEACHER: {
    GET: API_HOSTNAME + "/<univ_id>/search/teacher/<query>",
  },
  COURSE: {
    GET: API_HOSTNAME + "/<univ_id>/search/course/<query>",
  },
}
