import * as CRITERION from './criterion'
import UNIV from './univ'

export const Example = {
  review: {
    data: {
      user_id: "abcdef",
      year: "2000",
      semester: "前期",
      timestamp: 100000000,

      comment: "コメント",

      attendance_freq: CRITERION.ATTENDANCE_FREQ.ITEM.ALWAYS.key,
      scoring_method:  CRITERION.SCORING_METHOD.ITEM.ONLY_REPORT.key,
      content_quality: CRITERION.CREDIT_EASINESS.ITEM.VERY_DIFFICULT.key,
      credit_easiness: CRITERION.CONTENT_QUALITY.ITEM.GOOD.key,
    },
    metadata: {
      id: 2,
    }
  },
  course: {
    data: {
      name: "講義名",
      group: "工学部",
    },
    metadata: {
      id: 0,
    }
  },
  teacher: {
    data: {
      name: "教員名",
    },
    metadata: {
      id: 1,
    }
  },
}

export const RandomExampleList = (n=5) => {
  return(
    [...Array(n).keys()].map(item => RandomExample())
  )
}

const RandomString = (l=10) => {
  var c = "隴西の李徴は博学才穎、天宝の末年、若くして名を虎榜に連ね、ついで江南尉に補せられたが、性、狷介、自ら恃むところ頗る厚く、賤吏に甘んずるを潔しとしなかった。いくばくもなく官を退いた後は、故山、かく略に帰臥し、人と交を絶って、ひたすら詩作に耽った。下吏となって長く膝を俗悪な大官の前に屈するよりは、詩家としての名を死後百年に遺そうとしたのである。しかし、文名は容易に揚らず、生活は日を逐うて苦しくなる。李徴は漸く焦躁に駆られて来た。この頃からその容貌も峭刻となり、肉落ち骨秀で、眼光のみ徒らに炯々として、曾て進士に登第した頃の豊頬の美少年の俤は、何処に求めようもない。数年の後、貧窮に堪えず、妻子の衣食のために遂に節を屈して、再び東へ赴き、一地方官吏の職を奉ずることになった。一方、これは、己の詩業に半ば絶望したためでもある。曾ての同輩は既に遥か高位に進み、彼が昔、鈍物として歯牙にもかけなかったその連中の下命を拝さねばならぬことが、往年の儁才李徴の自尊心を如何に傷けたかは、想像に難くない。彼は怏々として楽しまず、狂悖の性は愈々抑え難くなった。一年の後、公用で旅に出、汝水のほとりに宿った時、遂に発狂した。或夜半、急に顔色を変えて寝床から起上ると、何か訳の分らぬことを叫びつつそのまま下にとび下りて、闇の中へ駈出した。彼は二度と戻って来なかった。附近の山野を捜索しても、何の手掛りもない。その後李徴がどうなったかを知る者は、誰もなかった。"
  var cl = c.length;
  var r = "";
  for(var i = 0; i < l; i++){
    r += c[Math.floor(Math.random()*cl)];
  }
return(r)
}

export const RandomExample = (univ_id="nagoya_u") => {
  return(
    {
      review: {
        data: {
          user_id: Math.random().toString(36).substr(2,8),
          year: 2015 + Math.floor(Math.random() * 5),
          semester: UNIV[univ_id].periods[Math.floor(Math.random() * UNIV[univ_id].periods.length)],
          timestamp: 100000000,

          comment: RandomString(Math.floor(Math.random() * 500)),

          attendance_freq: Object.values(CRITERION.ATTENDANCE_FREQ.ITEM)[Math.floor(Math.random() * Object.keys(CRITERION.ATTENDANCE_FREQ.ITEM).length)].key
          ,
          scoring_method:  Object.values(CRITERION.SCORING_METHOD.ITEM)[Math.floor(Math.random() * Object.keys(CRITERION.SCORING_METHOD.ITEM).length)].key,
          content_quality: Object.values(CRITERION.CONTENT_QUALITY.ITEM)[Math.floor(Math.random() * Object.keys(CRITERION.CONTENT_QUALITY.ITEM).length)].key,
          credit_easiness: Object.values(CRITERION.CREDIT_EASINESS.ITEM)[Math.floor(Math.random() * Object.keys(CRITERION.CREDIT_EASINESS.ITEM).length)].key,
        },
        metadata: {
          id: 2,
        }
      },
      course: {
        data: {
          name: RandomString(Math.floor(Math.random() * 20)),
          group: UNIV[univ_id].course_group[Math.floor(Math.random() * UNIV[univ_id].course_group.length)],

        },
        metadata: {
          id: Math.floor(Math.random() * 10000),
        }
      },
      teacher: {
        data: {
          name: RandomString(Math.floor(Math.random() * 20)),
        },
        metadata: {
          id: Math.floor(Math.random() * 10000),
        }
      },
    }
  )
}
