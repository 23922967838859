import React from "react";
import { Dropdown, DropdownMenu, DropdownItem } from "semantic-ui-react";

import TermDropdown from "../TermDropdown/index";
import { EllipsisButton } from "../../Buttons";
import { DownloadButton } from "../../Buttons/Download";

const TimeTableChartHeader = (props) => {
  const {
    currentTerm,
    terms,
    loading,
    handleItemClick,
    deletable,
    onDelete,
    onCreateAveTimeTable,
    onCapture,
  } = props;
  const [grade, semester] = currentTerm;
  const currentTermLabel = grade && semester ? currentTerm.join("年") : " - ";
  return (
    <div style={{ margin: "16px 0" }}>
      <div style={{ width: "100%", position: "relative" }}>
        <div style={{ textAlign: "center" }}>
          <TermDropdown
            label={loading ? " - " : currentTermLabel}
            terms={terms}
            onClick={handleItemClick}
            loading={loading}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "-4px",
            right: "0px",
          }}
        >
          <DownloadButton onClick={onCapture} disabled={!deletable} />
          <Dropdown
            icon={<EllipsisButton />}
            direction="left"
            disabled={loading}
          >
            <DropdownMenu>
              <DropdownItem
                text="時間割を自動作成"
                icon="magic"
                onClick={onCreateAveTimeTable}
              />
              <DropdownItem
                text="全てのコマを削除"
                icon="trash alternate outline"
                onClick={onDelete}
                disabled={!deletable}
              />
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default TimeTableChartHeader;
