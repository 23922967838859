import React, { useState, useEffect } from "react";
import { Button, Grid, Icon } from "semantic-ui-react";

import CellSelectControl from "../CellSelectControl";
import CellList from "../CellList";
import styles from "./styles.module.scss";
import {
  CUSTOMIZED_ATTENDANCE_FREQ,
  CUSTOMIZED_SCORING_METHOD,
} from "./constants";

const CellSelectContent = (props) => {
  const {
    cells,
    cellPos: [row, _],
    loading,
    onAddClick,
    onSelectCell,
    onSelectPreCell,
  } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [attendanceFreq, setAttendanceFreq] = useState(
    CUSTOMIZED_ATTENDANCE_FREQ
  );
  const [scoringMethod, setScoringMethod] = useState(CUSTOMIZED_SCORING_METHOD);
  const [sortMethod, setSortMethod] = useState("student_count");

  const controlledCells = cells
    .filter((cell) => {
      const regex = new RegExp(searchQuery);
      const course_name = cell.lecture.course.name;
      const AFCons = Object.keys(attendanceFreq).filter(
        (key) => attendanceFreq[key].active
      );
      const SMCons = Object.keys(scoringMethod).filter(
        (key) => scoringMethod[key].active
      );
      // 除外条件
      if (searchQuery.length > 0 && !regex.test(course_name)) return false;
      if (AFCons.length > 0 && !AFCons.includes(cell.attendance_freq))
        return false;
      if (SMCons.length > 0 && !SMCons.includes(cell.scoring_method))
        return false;
      return true;
    })
    .toSorted((c1, c2) => c2[sortMethod] - c1[sortMethod]);
  const isFirstRow = row <= 1;
  return (
    <div
      className={styles.header}
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3>授業を選ぶ</h3>
      <>
        <CellSelectControl
          searchQuery={searchQuery}
          attendanceFreq={attendanceFreq}
          scoringMethod={scoringMethod}
          sortMethod={sortMethod}
          setSearchQuery={setSearchQuery}
          setAttendanceFreq={setAttendanceFreq}
          setScoringMethod={setScoringMethod}
          setSortMethod={setSortMethod}
          style={{ flexGrow: 0 }}
        />
        <CellList
          cells={controlledCells}
          loading={loading}
          onSelectClick={onSelectCell}
        />
        <Grid style={{ flexGrow: 0, flexShrink: 0, fontFamily: "sans-serif" }}>
          {!isFirstRow && (
            <Grid.Column width={8}>
              <Button
                content={
                  <span>
                    {row - 1}限と同じ授業
                    <Icon name="history" style={{ marginLeft: 2 }} />
                  </span>
                }
                color="grey"
                fluid
                disabled={onSelectPreCell ? false : true}
                style={{ fontFamily: "sans-serif" }}
                onClick={onSelectPreCell}
              />
            </Grid.Column>
          )}
          <Grid.Column width={isFirstRow ? 16 : 8}>
            <Button
              content="新しく授業を追加＋"
              color="blue"
              fluid
              style={{ fontFamily: "sans-serif" }}
              onClick={onAddClick}
            />
          </Grid.Column>
        </Grid>
      </>
    </div>
  );
};
export default CellSelectContent;
