import React from "react";

import { Grid } from "semantic-ui-react";
import {
  BarChart,
  Bar,
  XAxis,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

import * as CRITERION from "../../constants/criterion";

export const getRandCriterionArray = (criterion_item) => {
  var ret = Object.values(criterion_item).map((item) => {
    return { name: item.label, value: Math.floor(Math.random() * 16) };
  });
  return ret;
};

export default class Stat extends React.Component {
  render() {
    const {
      fill_color = "green",
      data = [],
      loading = false,
      limited = false,
      ...otherProps
    } = this.props;

    const limitedCriterions = [
      // 制限する項目
      CRITERION.ATTENDANCE_FREQ,
      // CRITERION.SCORING_METHOD,
      CRITERION.CREDIT_EASINESS,
      // CRITERION.CONTENT_QUALITY,
    ];

    if (!loading) {
      return (
        <Grid {...otherProps}>
          {[
            CRITERION.ATTENDANCE_FREQ,
            CRITERION.SCORING_METHOD,
            CRITERION.CREDIT_EASINESS,
            CRITERION.CONTENT_QUALITY,
          ].map((item, key) => (
            <Grid.Column
              key={key}
              mobile={8}
              tablet={4}
              computer={4}
              style={{
                height: "116px",
              }}
            >
              <CustomBarChart
                data={data}
                item={item}
                fill_color={fill_color}
                limited={
                  limited && limitedCriterions.includes(item) ? true : false
                }
              />
            </Grid.Column>
          ))}
        </Grid>
      );
    } else {
      return <div {...otherProps}>{/* Loading... */}</div>;
    }
  }
}

const CustomBarChart = (props) => {
  const { data, item, fill_color, limited = false } = props;
  const dummyData = {
    [CRITERION.ATTENDANCE_FREQ.key]: [
      { name: CRITERION.ATTENDANCE_FREQ.ITEM.NONE.label, value: 2 },
      { name: CRITERION.ATTENDANCE_FREQ.ITEM.SOMETIME.label, value: 1 },
      { name: CRITERION.ATTENDANCE_FREQ.ITEM.ALWAYS.label, value: 2 },
      { name: CRITERION.ATTENDANCE_FREQ.ITEM.REPORT.label, value: 3 },
      { name: CRITERION.ATTENDANCE_FREQ.ITEM.OTHER.label, value: 4 },
      { name: CRITERION.ATTENDANCE_FREQ.ITEM.UNKNOWN.label, value: 5 },
    ],
    [CRITERION.SCORING_METHOD.key]: [
      { name: CRITERION.SCORING_METHOD.ITEM.ONLY_EXAM.label, value: 2 },
      { name: CRITERION.SCORING_METHOD.ITEM.ONLY_REPORT.label, value: 1 },
      { name: CRITERION.SCORING_METHOD.ITEM.EXAM_REPORT.label, value: 2 },
      { name: CRITERION.SCORING_METHOD.ITEM.OTHER.label, value: 3 },
      { name: CRITERION.SCORING_METHOD.ITEM.UNKNOWN.label, value: 4 },
    ],
    [CRITERION.CREDIT_EASINESS.key]: [
      { name: CRITERION.CREDIT_EASINESS.ITEM.EASY.label, value: 2 },
      { name: CRITERION.CREDIT_EASINESS.ITEM.NORMAL.label, value: 1 },
      { name: CRITERION.CREDIT_EASINESS.ITEM.DIFFICULT.label, value: 2 },
      { name: CRITERION.CREDIT_EASINESS.ITEM.VERY_DIFFICULT.label, value: 3 },
      { name: CRITERION.CREDIT_EASINESS.ITEM.VERY_EASY.label, value: 4 },
    ],
    [CRITERION.CONTENT_QUALITY.key]: [
      { name: CRITERION.CONTENT_QUALITY.ITEM.AWFUL.label, value: 2 },
      { name: CRITERION.CONTENT_QUALITY.ITEM.BAD.label, value: 1 },
      { name: CRITERION.CONTENT_QUALITY.ITEM.AVERAGE.label, value: 2 },
      { name: CRITERION.CONTENT_QUALITY.ITEM.GOOD.label, value: 3 },
      { name: CRITERION.CONTENT_QUALITY.ITEM.EXCELLENT.label, value: 4 },
    ],
  };
  return (
    <>
      <ResponsiveContainer>
        <BarChart
          data={limited ? dummyData[item.key] : data[item.key]}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis
            style={{ fontSize: "0.58em" }}
            dataKey="name"
            tickLine={false}
            angle={-30}
            interval={0}
            textAnchor="end"
          />
          <Bar
            dataKey={"value"}
            name={item.label}
            fill={limited ? "lightgray" : fill_color}
          >
            <LabelList
              dataKey="value"
              style={{ fontSize: "0.5em" }}
              position="top"
            />
          </Bar>
          <Legend offset={-1} iconSize={0} verticalAlign="top" height={32} />
        </BarChart>
      </ResponsiveContainer>
      {limited && (
        <span
          style={{
            width: "40%",
            padding: "2px 10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(250, 250, 250, 0.4)",
            textAlign: "center",
            backdropFilter: "blur(3px)",
            fontSize: "0.9em",
            color: "gray",
          }}
        >
          会員限定
        </span>
      )}
    </>
  );
};
