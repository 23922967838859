import ApiClient from "./ApiClient";
import { SURVEYS } from "./ApiEndpoints";

export default class SurveyApi {
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  async getSurveys() {
    try {
      const res = await this.apiClient.get(SURVEYS.GET);
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async postAnswer(surveyId, answer) {
    try {
      const res = await this.apiClient.post(
        SURVEYS.SINGLE.ANSWERS.POST.replace("<survey_id>", surveyId),
        answer
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }
}
