import React from "react";

const SegmentArea = (props) => {
  const { children, style } = props;
  return (
    <div
      style={{
        backgroundColor: "#F4F5F6",
        borderRadius: "6px",
        padding: 12,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default SegmentArea;
