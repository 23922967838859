import { microCmsClient } from "./MicroCmsClient";

class SystemApi {
  async getSystemSettings() {
    try {
      const data = await microCmsClient.get({
        endpoint: "system",
      });
      return data;
    } catch (err) {
      throw err;
    }
  }
}

const systemApi = new SystemApi();
export default systemApi;
