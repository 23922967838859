import React from "react";

export default class Footer extends React.Component {
  render() {
    const { children, to = "", ...otherProps } = this.props;
    return (
      <>
        <a href={to} {...otherProps}>
          {children}
        </a>
      </>
    );
  }
}
