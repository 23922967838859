export const getLabel = (criterion_key, value_key, ret_key) => {
  var ret_obj = Object.values(
    Object.fromEntries(
      Object.entries(criterion_key.ITEM).filter(([k, v]) => v.key === value_key)
    )
  )[0];
  try {
    if (!ret_obj) throw TypeError;
    if (!ret_obj[ret_key]) throw TypeError;
    return ret_obj[ret_key];
  } catch (err) {
    return "その他";
  }
};

export const ATTENDANCE_FREQ = {
  key: "attendance_freq",
  text: "出欠確認の頻度",
  label: "出欠",
  ITEM: {
    NONE: {
      key: "no_check",
      text: "出欠確認なし",
      label: "なし",
    },
    SOMETIME: {
      key: "sometime",
      text: "時々出欠をとる",
      label: "時々",
    },
    ALWAYS: {
      key: "always",
      text: "いつも出欠をとる",
      label: "いつも",
    },
    REPORT: {
      key: "report",
      text: "レポート提出が出欠確認",
      label: "レポ",
    },
    OTHER: {
      key: "other",
      text: "その他",
      label: "その他",
    },
    UNKNOWN: {
      key: "unknown",
      text: "不明",
      label: "不明",
    },
  },
};

export const SCORING_METHOD = {
  key: "scoring_method",
  text: "採点方法",
  label: "採点",
  ITEM: {
    ONLY_EXAM: {
      key: "only_exam",
      text: "テストのみ",
      label: "テス",
    },
    ONLY_REPORT: {
      key: "only_report",
      text: "レポートのみ",
      label: "レポ",
    },
    EXAM_REPORT: {
      key: "exam_report",
      text: "テストとレポート両方",
      label: "テス&レポ",
    },
    OTHER: {
      key: "other",
      text: "その他",
      label: "その他",
    },
    UNKNOWN: {
      key: "unknown",
      text: "不明",
      label: "不明",
    },
  },
};

export const CREDIT_EASINESS = {
  key: "credit_easiness",
  text: "単位の取りやすさ",
  label: "単位",
  ITEM: {
    VERY_DIFFICULT: {
      key: "very_difficult",
      text: "激難",
      label: "激難",
    },
    DIFFICULT: {
      key: "difficult",
      text: "難",
      label: "難",
    },
    NORMAL: {
      key: "normal",
      text: "普通",
      label: "普通",
    },
    EASY: {
      key: "easy",
      text: "楽",
      label: "楽",
    },
    VERY_EASY: {
      key: "very_easy",
      text: "超楽",
      label: "超楽",
    },
  },
};

export const CONTENT_QUALITY = {
  key: "content_quality",
  text: "内容よさ",
  label: "内容",
  ITEM: {
    AWFUL: {
      key: "awful",
      text: "最悪",
      label: "最悪",
    },
    BAD: {
      key: "bad",
      text: "悪い",
      label: "悪",
    },
    AVERAGE: {
      key: "average",
      text: "普通",
      label: "普通",
    },
    GOOD: {
      key: "good",
      text: "良い",
      label: "良",
    },
    EXCELLENT: {
      key: "excellent",
      text: "最良",
      label: "最良",
    },
  },
};
