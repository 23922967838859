import React from "react";

import { Auth } from "aws-amplify";

import {
  Button,
  Container,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  TextArea,
  Modal,
} from "semantic-ui-react";

import { shareReviewOnTwitter } from "@/api/Sharing";

import A from "@/components/A/index";
import Navbar from "@/components/Navbar/index";
import Footer from "@/components/Footer/index";

import * as ROUTES from "@/constants/routes";
import UNIV from "@/constants/univ";
import * as CRITERION from "@/constants/criterion";

import { updateInput, verifyInput } from "./utils/inputHandlers";
import { createCourseGroupItem, createSemesterItem } from "./utils/univ";
import { onSearchChange } from "./utils/searchHandlers";
import { onSelectChange } from "./utils/selectChangeHandlers";
import { postReview } from "./utils/reviewHandlers";

import {
  courseChangeAutoComplete,
  teacherAutoComplete,
} from "./utils/AutoComplete";

export default class PostReview extends React.Component {
  pushHistory = (uri) => {
    if (this.props.history) {
      this.props.history.push(uri);
    }
  };
  state = {
    user: null,
    is_loading: false,
    is_form_changed: false,
    is_course_selected: false,
    is_teacher_selected: false,
    is_modal_opened: false,

    input_select: {
      course: {
        key: "course_name",
        title: "講義名",
        value: "",
        required: true,
      },
      teacher: {
        key: "teachers",
        title: "教員名",
        value: [],
        required: true,
      },
      course_group: {
        key: "course_group",
        text: "科目区分",
        value: "その他",
        required: true,
      },
      year: {
        key: "year",
        text: "年度",
        value: new Date().getFullYear(),
        required: true,
        items: [...Array(new Date().getFullYear() + 1).keys()]
          .slice(new Date().getFullYear() - 5)
          .map((item) => {
            return { key: item, value: item, text: item };
          })
          .reverse(),
      },
      semester: {
        key: "semester",
        text: "学期",
        value: "",
        required: true,
        items: ["前期", "後期"].map((item) => {
          return { key: item, value: item, text: item };
        }),
      },
      ATTENDANCE_FREQ: {
        key: CRITERION.ATTENDANCE_FREQ.key,
        value: CRITERION.ATTENDANCE_FREQ.ITEM.SOMETIME.key,
        required: true,
      },
      SCORING_METHOD: {
        key: CRITERION.SCORING_METHOD.key,
        value: CRITERION.SCORING_METHOD.ITEM.ONLY_EXAM.key,
        required: true,
      },
      CREDIT_EASINESS: {
        key: CRITERION.CREDIT_EASINESS.key,
        value: CRITERION.CREDIT_EASINESS.ITEM.NORMAL.key,
        required: true,
      },
      CONTENT_QUALITY: {
        key: CRITERION.CONTENT_QUALITY.key,
        value: CRITERION.CONTENT_QUALITY.ITEM.AVERAGE.key,
        required: true,
      },
      comment: {
        key: "comment",
        text: "comment",
        value: "",
        required: true,
      },
      anonymous: {
        key: "anonymous",
        value: false,
        required: false,
      },
    },
    options: {
      selected: {
        course: [],
        teacher: [],
      },
      results: {
        course: [],
        teacher: [],
      },
    },
    loading: {
      course: false,
      teacher: false,
    },
    teachers_of_course: [],
  };

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({ user: user });
      })
      .catch(() => null);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.is_open === false) {
      this.setState({ is_form_changed: false });
    }
  }

  handleUpdateInput = (obj) => {
    updateInput(this.state, this.setState.bind(this), obj);
  };

  handleVerifyInput = (obj) => {
    verifyInput(this.state, this.setState.bind(this), obj);
  };

  handlePostReview = () => {
    postReview(this.state, this.setState.bind(this), this.props);
  };

  // 講義名が変化した際の処理: 講義名のオートコンプリート, 教員名のレコメンド
  handleCourseChange = (univId, name, searchQuery) => {
    courseChangeAutoComplete(
      this.state,
      this.setState.bind(this),
      univId,
      name,
      searchQuery
    );
  };

  // 教員名のオートコンプリート
  handleTeacherChange = (univId, name, searchQuery) => {
    teacherAutoComplete(
      this.state,
      this.setState.bind(this),
      univId,
      name,
      searchQuery
    );
  };

  handleSelectChange = (e, obj) => {
    onSelectChange(
      e,
      obj,
      this.state,
      this.handleUpdateInput.bind(this),
      this.setState.bind(this)
    );
  };

  // 入力値が変化したときの処理
  handleSearchChange = (e, obj) => {
    onSearchChange(
      e,
      obj,
      this.props,
      this.state,
      this.setState.bind(this),
      this.handleCourseChange,
      this.handleTeacherChange
    );
  };

  // 教員名のドロップダウンのオプションを整形
  teacherDropdownOptions = () => {
    let options = [];
    if (this.state.options.selected.teacher) {
      options = options.concat(this.state.options.selected.teacher);
    }
    if (this.state.options.results.teacher) {
      options = options.concat(this.state.options.results.teacher);
    }
    if (this.state.teachers_of_course) {
      options = options.concat(
        this.state.teachers_of_course.map((teacher) => {
          return {
            key: teacher,
            value: teacher,
            text: teacher,
            content: (
              <div>
                <span style={{ color: "gray" }}>もしかして...　</span>
                {teacher}
              </div>
            ),
          };
        })
      );
    }
    // key が同じものを除外
    options = options.filter(
      (option, index, self) =>
        self.findIndex((t) => t.key === option.key) === index
    );
    return options;
  };

  render() {
    const { univ_id = "" } = this.props.match.params;
    const {
      user,
      input_select,
      loading,
      options: { selected, results },
    } = this.state;
    const course_group_item = createCourseGroupItem(univ_id);
    const semester_item = createSemesterItem(univ_id);

    if (UNIV[univ_id].status !== "open") {
      window.location.href = "/404";
    } else {
      return (
        <>
          <Navbar
            style={{ marginBottom: "24px" }}
            univ_id={univ_id}
            subtitle={"for " + UNIV[univ_id].name}
            show_pusher={false}
            show_control={false}
            pushHistory={this.pushHistory}
          />
          <Container text>
            <Header as="h1">
              <Header.Content>
                授業を評価する
                <Header.Subheader>
                  出来る限り正確な情報を書いてください。
                </Header.Subheader>
              </Header.Content>
            </Header>
            <Form>
              <Form.Dropdown
                label={
                  <label>
                    講義名
                    {input_select.course.is_invalid && (
                      <Icon name="exclamation circle" color="red" />
                    )}
                  </label>
                }
                name="course"
                options={[...selected.course, ...results.course]}
                value={input_select.course.value}
                placeholder="例: 物理学基礎"
                loading={loading.course}
                fluid
                search
                selection
                clearable
                scrolling
                closeOnChange
                allowAdditions
                additionLabel="講義名を追加: "
                noResultsMessage="講義名を入力してください"
                icon="search"
                onSearchChange={this.handleSearchChange}
                onChange={this.handleSelectChange}
              />
              <Form.Field>
                <label>
                  教員名
                  <br />
                  {input_select.teacher.is_invalid && (
                    <Icon name="exclamation circle" color="red" />
                  )}
                </label>
                <Dropdown
                  name="teacher"
                  options={this.teacherDropdownOptions()}
                  value={input_select.teacher.value}
                  placeholder="例: 田中 太郎"
                  loading={loading.teacher}
                  fluid
                  multiple
                  search
                  selection
                  clearable
                  scrolling
                  closeOnChange
                  allowAdditions
                  additionLabel="教員名を追加: "
                  noResultsMessage="教員名を入力してください"
                  icon="search"
                  onSearchChange={this.handleSearchChange}
                  onChange={this.handleSelectChange}
                />
                <p style={{ color: "gray", marginTop: "3px" }}>
                  <small>
                    複数名可。姓と名の間には半角スペースを入れてください。
                  </small>
                </p>
              </Form.Field>
              {["course_group"].map((item, index) => (
                <Form.Field key={index}>
                  <label>
                    {this.state.input_select[item].text}
                    {this.state.input_select[item].is_invalid && (
                      <Icon name="exclamation circle" color="red" />
                    )}
                  </label>
                  <Dropdown
                    selection
                    options={course_group_item}
                    value={this.state.input_select[item].value}
                    onChange={(e, data) =>
                      // this.updateInput({ [item]: data.value })
                      this.handleUpdateInput({ [item]: data.value })
                    }
                  />
                </Form.Field>
              ))}

              <Grid columns={2}>
                {["year"].map((item, index) => (
                  <Grid.Column key={index}>
                    <Form.Field>
                      <label>
                        {this.state.input_select[item].text}
                        {this.state.input_select[item].is_invalid && (
                          <Icon name="exclamation circle" color="red" />
                        )}
                      </label>
                      <Dropdown
                        selection
                        compact
                        options={this.state.input_select[item].items}
                        value={this.state.input_select[item].value}
                        onChange={(e, data) =>
                          // this.updateInput({ [item]: data.value })
                          this.handleUpdateInput({ [item]: data.value })
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                ))}
                {["semester"].map((item, index) => (
                  <Grid.Column key={index}>
                    <Form.Field key={index}>
                      <label>
                        {this.state.input_select[item].text}
                        {this.state.input_select[item].is_invalid && (
                          <Icon name="exclamation circle" color="red" />
                        )}
                      </label>
                      <Dropdown
                        selection
                        compact
                        options={semester_item}
                        value={this.state.input_select[item].value}
                        onChange={(e, data) =>
                          // this.updateInput({ [item]: data.value })
                          this.handleUpdateInput({ [item]: data.value })
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                ))}
              </Grid>
              <div style={{ margin: "24px 0" }}>
                {[
                  "ATTENDANCE_FREQ",
                  "SCORING_METHOD",
                  "CREDIT_EASINESS",
                  "CONTENT_QUALITY",
                ].map((c_key, c_index) => (
                  <Form.Field key={c_index}>
                    <label>{CRITERION[c_key].text}</label>
                    {Object.keys(CRITERION[c_key].ITEM).map(
                      (i_key, i_index) => {
                        var is_selected =
                          this.state.input_select[c_key].value ===
                          CRITERION[c_key].ITEM[i_key].key;
                        return (
                          <Label
                            key={i_index}
                            color="grey"
                            as="a"
                            style={{ marginBottom: "4px" }}
                            basic={!is_selected}
                            onClick={() =>
                              // this.updateInput({
                              //   [c_key]: CRITERION[c_key].ITEM[i_key].key,
                              // })
                              this.handleUpdateInput({
                                [c_key]: CRITERION[c_key].ITEM[i_key].key,
                              })
                            }
                          >
                            {is_selected && <Icon name="check" />}
                            {CRITERION[c_key].ITEM[i_key].text}
                          </Label>
                        );
                      }
                    )}
                  </Form.Field>
                ))}
              </div>

              <Form.Field>
                <label>
                  コメント
                  {this.state.input_select.comment.is_invalid && (
                    <Icon name="exclamation circle" color="red" />
                  )}
                </label>
                <p style={{ color: "gray", marginBottom: "10px" }}>
                  <small>
                    名誉毀損にあたる表現や攻撃的、差別的な表現は禁止されています。
                  </small>
                </p>
                <TextArea
                  rows={5}
                  placeholder="コメントを入力"
                  onChange={(e, data) =>
                    // this.updateInput({ comment: data.value })
                    this.handleUpdateInput({ comment: data.value })
                  }
                />
              </Form.Field>

              {user && (
                <>
                  <Form.Field>
                    <label>投稿設定</label>
                    <p style={{ color: "gray" }}>
                      <small>
                        匿名で投稿する場合はチェックを入れてください。
                      </small>
                    </p>
                    <Form.Checkbox
                      label="匿名で投稿する ※ランキングには反映されません"
                      onChange={(e, data) =>
                        // this.updateInput({ anonymous: data.checked })
                        this.handleUpdateInput({ anonymous: data.checked })
                      }
                    />
                  </Form.Field>
                  <p style={{ color: "gray", lineHeight: 1.1 }}>
                    <small>
                      ※名誉毀損や侮辱罪にあたる表現、過度に暴力的な表現、差別的な表現は
                      <A target="_blank" to={ROUTES.TERMS}>
                        利用規約
                      </A>
                      第2条により禁止されています。
                    </small>
                  </p>
                </>
              )}
            </Form>
            <div style={{ marginTop: "24px" }}>
              <p>
                <small>
                  投稿により、
                  <A target="_blank" to={ROUTES.TERMS}>
                    利用規約
                  </A>
                  に同意にしたものとします。 また、
                  <A target="_blank" to={ROUTES.PRIVACY}>
                    プライバシーポリシー
                  </A>
                  に基づき、投稿者の IP アドレスは記録されます。
                </small>
              </p>

              <div style={{ textAlign: "center" }}>
                <Button
                  loading={this.state.is_loading}
                  disabled={this.state.is_loading}
                  primary
                  onClick={() => this.handlePostReview()}
                >
                  投稿する
                </Button>
              </div>
            </div>

            <Modal size="mini" open={this.state.is_modal_opened}>
              <Modal.Header>Twitter共有</Modal.Header>
              <Modal.Content>
                <p>投稿内容をTwitterで共有しますか？</p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  onClick={() => {
                    this.setState({ is_modal_opened: false });
                    this.pushHistory(
                      ROUTES.APP.HOME.replace(":univ_id", univ_id)
                    );
                  }}
                >
                  No
                </Button>
                <Button
                  positive
                  onClick={() => {
                    this.setState({ is_modal_opened: false });
                    this.pushHistory(
                      ROUTES.APP.HOME.replace(":univ_id", univ_id)
                    );
                    const course = this.state.input_select.course.value;
                    const comment = this.state.input_select.comment.value;
                    shareReviewOnTwitter(course, comment);
                  }}
                >
                  Yes
                </Button>
              </Modal.Actions>
            </Modal>
          </Container>
          <Footer univ_id={univ_id} pushHistory={this.pushHistory} />
        </>
      );
    }
  }
}
