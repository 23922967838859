import React, { useCallback, useEffect, useState } from "react";

import Modal from "../Modal";
import CellSelectContent from "./CellSelectContent";
import CellAddContent from "./CellAddContent";
import { useCells } from "../../../../hooks/timetable/useCells";

const CellSelectModal = (props) => {
  const {
    univ_id,
    currentTerm: [_, semester],
    cellPos: [row, column],
    preCell,
    open,
    setOpen,
    onSelectCell,
  } = props;
  const [page, setPage] = useState("SELECT"); // SELECT | ADD
  const { cells, isLoading, clearCells, fetchCells, createCell } = useCells();

  useEffect(() => {
    if (open) {
      clearCells();
      fetchCells({
        univId: univ_id,
        semester: semester,
        row: row,
        column: column,
      });
    }
  }, [open]);

  const handleSelectCell = useCallback(
    (cell) => {
      onSelectCell(cell);
      setOpen(false);
    },
    [onSelectCell, setOpen]
  );
  const handleAddCell = useCallback(
    async ({ credit, courseName, courseGroup, semester, teachers }) => {
      try {
        const cell = await createCell({
          univId: univ_id,
          row: row,
          column: column,
          credit,
          courseName,
          courseGroup,
          semester,
          teachers,
        });
        onSelectCell(cell);
        setPage("SELECT");
        setOpen(false);
      } catch (error) {
        console.error(error);
        window.alert(
          "授業の追加に失敗しました。時間をおいて再度お試しください。"
        );
      }
    }
  );
  const handleSelectPreCell = useCallback(() => {
    if (!preCell) return;
    handleAddCell({
      credit: preCell.credit,
      courseName: preCell.lecture.course.name,
      courseGroup: preCell.lecture.course.course_group,
      semester: preCell.lecture.course.semester,
      teachers: preCell.lecture.teacher_group.teachers.map((t) => t.name),
    });
  }, [preCell, handleAddCell]);
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      scrollable={false}
      style={{ height: "80vh" }}
    >
      {page === "SELECT" && (
        <CellSelectContent
          cells={cells}
          cellPos={[row, column]}
          loading={isLoading}
          onAddClick={() => setPage("ADD")}
          onSelectCell={handleSelectCell}
          onSelectPreCell={preCell ? handleSelectPreCell : null}
        />
      )}
      {page === "ADD" && (
        <CellAddContent
          univ_id={univ_id}
          semester={semester}
          onCancelClick={() => setPage("SELECT")}
          onAddClick={handleAddCell}
        />
      )}
    </Modal>
  );
};
export default CellSelectModal;
