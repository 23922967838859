import React, { useState } from "react";
import { Button } from "semantic-ui-react";

export const DownloadButton = (props) => {
  const { size = "medium", disabled = false, onClick } = props;
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <Button
      icon="download"
      size={size}
      basic
      disabled={disabled}
      style={{ boxShadow: "none" }}
      loading={loading}
      onClick={handleButtonClick}
    />
  );
};
