export const courseRanking = {
  nagoya_u: {
    全学教養科目: [
      {
        course_id: 1045,
        course_name: "ピア・カウンセリング",
        teacher_group_id: 1187,
        teachers: ["鈴木 健一"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 420,
        course_name: "特別講義(囲碁の世界)",
        teacher_group_id: 519,
        teachers: ["伊藤 健良"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 462,
        course_name: "学問の面白さを知る",
        teacher_group_id: 823,
        teachers: ["榊原 均"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 551,
        course_name: "特別講義(社会安全学入門)",
        teacher_group_id: 665,
        teachers: ["富田 賢吾"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1110,
        course_name: "科学技術史",
        teacher_group_id: 1290,
        teachers: ["亀山 哲也"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 749,
        course_name: "大学でどう学ぶか",
        teacher_group_id: 837,
        teachers: ["丸山 和昭"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 618,
        course_name: "名大の歴史をたどる",
        teacher_group_id: 726,
        teachers: ["吉川 卓治"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 163,
        course_name: "キャリア形成論",
        teacher_group_id: 101,
        teachers: ["森 典華", "廣明 秀一"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 550,
        course_name: "社会安全学入門(特別講義)",
        teacher_group_id: 665,
        teachers: ["富田 賢吾"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 163,
        course_name: "キャリア形成論",
        teacher_group_id: 2315,
        teachers: ["武田 穰"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 163,
        course_name: "キャリア形成論",
        teacher_group_id: 2388,
        teachers: ["牧野 正人"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 462,
        course_name: "学問の面白さを知る",
        teacher_group_id: 819,
        teachers: ["杉山 直"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 487,
        course_name: "日本語教育実践入門",
        teacher_group_id: 619,
        teachers: ["徳弘 康代"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 441,
        course_name: "現代芸術論",
        teacher_group_id: 545,
        teachers: ["江坂 恵里子"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1724,
        course_name: "表象芸術論",
        teacher_group_id: 2496,
        teachers: ["伏木 啓"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 2108,
        course_name: "宗教と人類文化",
        teacher_group_id: 1122,
        teachers: ["斎藤 滋"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 876,
        teachers: ["川瀬 晃道"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1755,
        course_name: "学問の面白さを学ぶ",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1829,
        course_name: "基礎セミナー",
        teacher_group_id: 1558,
        teachers: ["忍久保 洋"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1442,
        course_name: "物理学基礎I",
        teacher_group_id: 1691,
        teachers: ["川崎 猛史"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 620,
        course_name: "心の健康と将来展望",
        teacher_group_id: 727,
        teachers: ["松本 真理子"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 938,
        course_name: "生と死の人間学",
        teacher_group_id: 1043,
        teachers: ["吉田 純"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 2108,
        course_name: "宗教と人類文化",
        teacher_group_id: 1230,
        teachers: ["齋藤 滋"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 1745,
        course_name: "絵画論",
        teacher_group_id: 2616,
        teachers: ["小林 英樹"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 860,
        teachers: ["谷川 好男"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1110,
        course_name: "科学技術史",
        teacher_group_id: 1801,
        teachers: ["馬渕 浩一"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 326,
        course_name: "多言語習得基礎",
        teacher_group_id: 730,
        teachers: ["宇都木 昭"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1847,
        course_name: "微分積分学II",
        teacher_group_id: 1220,
        teachers: ["森本 宏"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 1799,
        course_name: "英語(上級)",
        teacher_group_id: 1816,
        teachers: ["加藤 由香子"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 898,
        course_name: "科学技術とジェンダー",
        teacher_group_id: 998,
        teachers: ["小川 眞里子"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 326,
        course_name: "多言語習得基礎",
        teacher_group_id: 1097,
        teachers: ["志波 彩子"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 326,
        course_name: "多言語習得基礎",
        teacher_group_id: 1767,
        teachers: ["宇津木 昭"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 2171,
        course_name: "学問の面白さを知る",
        teacher_group_id: 1471,
        teachers: ["奥村 隆平"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 163,
        course_name: "キャリア形成論",
        teacher_group_id: 1742,
        teachers: ["森 典華"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 1204,
        course_name: "表彰芸術論",
        teacher_group_id: 1474,
        teachers: ["上芝 智裕"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 462,
        course_name: "学問の面白さを知る",
        teacher_group_id: 868,
        teachers: ["夏目 達也"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 621,
        course_name: "特別講義(心の健康と将来展望-)",
        teacher_group_id: 727,
        teachers: ["松本 真理子"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 1403,
        course_name: "線形代数学I",
        teacher_group_id: 1812,
        teachers: ["大舘 陽太"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 779,
        course_name: "微分積分学I",
        teacher_group_id: 1783,
        teachers: ["竹内 一郎"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1039,
        course_name: "化学基礎I",
        teacher_group_id: 907,
        teachers: ["岩松 将一"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1311,
        course_name: "ジェンダーの視点から考える21世紀の日本社会",
        teacher_group_id: 1654,
        teachers: ["榊原 千鶴"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1950,
        course_name: "キャリア形成論",
        teacher_group_id: 2276,
        teachers: ["武田 穣"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 61,
        course_name: "宗教と人類文化",
        teacher_group_id: 26,
        teachers: ["本村 耐樹"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 326,
        course_name: "多言語習得基礎",
        teacher_group_id: 1257,
        teachers: ["中村 登志哉"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 680,
        course_name: "社会変動と人間生活",
        teacher_group_id: 773,
        teachers: ["河村 則行"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1181,
        teachers: ["菊田 浩一"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 973,
        course_name: "力学I",
        teacher_group_id: 1084,
        teachers: ["松本 健郎"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 426,
        course_name: "朝鮮・韓国語1",
        teacher_group_id: 730,
        teachers: ["宇都木 昭"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1950,
        course_name: "キャリア形成論",
        teacher_group_id: 539,
        teachers: ["廣明 秀一"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 775,
        course_name: "切迫する自然災害に備える",
        teacher_group_id: 857,
        teachers: ["鈴木 康弘"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1454,
        course_name: "科学・技術の倫理",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 462,
        course_name: "学問の面白さを知る",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1032,
        course_name: "特別講義(テレビ報道とメディアリテラシー)",
        teacher_group_id: 1167,
        teachers: ["河村 雅隆"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 462,
        course_name: "学問の面白さを知る",
        teacher_group_id: 627,
        teachers: ["周藤 芳幸"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 462,
        course_name: "学問の面白さを知る",
        teacher_group_id: 1965,
        teachers: ["安藤"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 522,
        course_name: "情報リテラシー文系",
        teacher_group_id: 647,
        teachers: ["鈴木 泰博"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 640,
        course_name: "化学基礎II",
        teacher_group_id: 1024,
        teachers: ["野村 信嘉"],
        course_group: "全学教養科目",
        semester: "その他",
      },
      {
        course_id: 1196,
        course_name: "科学技術社会論",
        teacher_group_id: 1463,
        teachers: ["横山 輝雄"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 1850,
        course_name: "電磁気学I",
        teacher_group_id: 750,
        teachers: ["黒澤 昌志"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 326,
        course_name: "多言語習得基礎",
        teacher_group_id: 362,
        teachers: ["SAVELIEV IGOR"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1849,
        course_name: "線形代数学II",
        teacher_group_id: 752,
        teachers: ["曽我部 知広"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 1851,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 881,
        teachers: ["上原 早苗"],
        course_group: "全学教養科目",
        semester: "後期",
      },
      {
        course_id: 2027,
        course_name: "化学基礎II",
        teacher_group_id: 817,
        teachers: ["中野 秀雄"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 462,
        course_name: "学問の面白さを知る",
        teacher_group_id: 580,
        teachers: ["香坂 玲"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 731,
        course_name: "化学基礎II",
        teacher_group_id: 742,
        teachers: ["松宮 弘明"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 749,
        course_name: "大学でどう学ぶか",
        teacher_group_id: 868,
        teachers: ["夏目 達也"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1566,
        course_name: "名大史",
        teacher_group_id: 778,
        teachers: ["池内 敏"],
        course_group: "全学教養科目",
        semester: "前期",
      },
      {
        course_id: 1443,
        course_name: "健康スポーツ講義",
        teacher_group_id: 905,
        teachers: ["石田 浩司"],
        course_group: "全学教養科目",
        semester: "前期",
      },
    ],
    基礎セミナー: [
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1205,
        teachers: ["山本 徹也"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1177,
        teachers: ["櫻井 淳平"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 944,
        teachers: ["大蔵 聡"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 883,
        teachers: ["成田 哲博"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 819,
        teachers: ["杉山 直"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1033,
        teachers: ["加藤 竜司"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1094,
        teachers: ["久本 直毅"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 847,
        teachers: ["平山 修久"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 705,
        teachers: ["堀 勝"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 69,
        teachers: ["結縁 祥冶"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 864,
        teachers: ["瀧口 金吾"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 2,
        teachers: ["加藤 哲理"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1139,
        teachers: ["高野 雅夫"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 509,
        teachers: ["坂本 將暢"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 2161,
        teachers: ["三野"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 41,
        teachers: ["荒川 宜親"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 516,
        course_name: "地震と防災",
        teacher_group_id: 644,
        teachers: ["中井 健太郎"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 838,
        teachers: ["長田 実"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 1583,
        course_name: "LED徹底検証",
        teacher_group_id: 899,
        teachers: ["天野 浩"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 968,
        teachers: ["太田 元規"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 122,
        teachers: ["長崎 正雅"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1545,
        teachers: ["藤巻 朗"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 422,
        course_name: "水平思考",
        teacher_group_id: 524,
        teachers: ["中川 優"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1611,
        teachers: ["内海 悠二"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 2,
        teachers: ["加藤 哲理"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 706,
        course_name: "英語(基礎)",
        teacher_group_id: 789,
        teachers: ["河野 哲子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1558,
        teachers: ["忍久保 洋"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1073,
        teachers: ["有田 隆也"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 2103,
        course_name: "基礎セミナー",
        teacher_group_id: 2193,
        teachers: ["金吉 敬人"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 59,
        teachers: ["岩崎 雄吾"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 720,
        teachers: ["中谷 真人"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 799,
        teachers: ["加藤 靖恵"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1102,
        teachers: ["植田 健男"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1197,
        teachers: ["人見 清隆"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 892,
        course_name: "地誌を書く",
        teacher_group_id: 993,
        teachers: ["岡本 耕平"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 131,
        teachers: ["徳重 道朗"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1051,
        course_name: "森林はどう捉えられ、どう描かれてきたか",
        teacher_group_id: 1196,
        teachers: ["田中 隆文"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 380,
        teachers: ["木山"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1709,
        teachers: ["江頭 智宏"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 188,
        course_name: "統計データにだまされないために",
        teacher_group_id: 122,
        teachers: ["長崎 正雅"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 641,
        teachers: ["山本 彰夫"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1154,
        teachers: ["中村 晋一郎"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 210,
        course_name: "動物のバイオテクノロジー",
        teacher_group_id: 144,
        teachers: ["松田 二子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1209,
        course_name: "ゲーム理論",
        teacher_group_id: 1475,
        teachers: ["酒井 正彦"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1526,
        course_name: "日経サイエンスを読もう",
        teacher_group_id: 2111,
        teachers: ["久本"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 672,
        teachers: ["中田 実"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 823,
        course_name: "民法系",
        teacher_group_id: 906,
        teachers: ["村上 正子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1672,
        teachers: ["北浦 靖之"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1252,
        teachers: ["坂野 尚美"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1724,
        teachers: ["石崎 俊子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 661,
        course_name: "増田 淳矢",
        teacher_group_id: 763,
        teachers: ["増田 淳矢"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 767,
        course_name: "魅力ある商品を「開発」してみわう",
        teacher_group_id: 850,
        teachers: ["近藤 竜彦"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 721,
        course_name: "リサイクルについて考えてみよう",
        teacher_group_id: 801,
        teachers: ["市野 良一"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 78,
        teachers: ["中東 正文"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1065,
        course_name: "ロボットの製作",
        teacher_group_id: 1210,
        teachers: ["新井 史人"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1681,
        teachers: ["篠原 秀文"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 911,
        teachers: ["鳴瀧 彩絵"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 618,
        teachers: ["河西 秀哉"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 792,
        teachers: ["黒田 健介"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 509,
        course_name: "エセ科学を科学する",
        teacher_group_id: 641,
        teachers: ["山本 彰夫"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1699,
        teachers: ["久場 博司"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 746,
        teachers: ["竹延 大志"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1291,
        teachers: ["阿部 秀樹"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1626,
        teachers: ["稗田 順子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1259,
        course_name: "基礎セミナーA",
        teacher_group_id: 1556,
        teachers: ["居波 賢二"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 596,
        teachers: ["アラニ"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 16,
        teachers: ["隠岐 さや香"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 874,
        teachers: ["原田 裕貴"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 582,
        teachers: ["安立 昌篤"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 295,
        teachers: ["日栄 一真"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1117,
        teachers: ["荒井 政大"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 930,
        teachers: ["結縁 祥治"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1329,
        course_name: "今後の作物の育種について考える",
        teacher_group_id: 1688,
        teachers: ["高橋 宏和"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 75,
        course_name: "哲学",
        teacher_group_id: 508,
        teachers: ["和田 壽弘"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 2034,
        course_name: "基礎セミナーA",
        teacher_group_id: 837,
        teachers: ["丸山 和昭"],
        course_group: "基礎セミナー",
        semester: "その他",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1529,
        teachers: ["早川 直樹"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1162,
        teachers: ["山田 珠美"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1271,
        teachers: ["松原 茂樹"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 267,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1548,
        teachers: ["中西 須美"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1117,
        teachers: ["荒井 政大"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 74,
        teachers: ["興戸 正純"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 40,
        teachers: ["入山 恭寿"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1630,
        course_name: "文理融合",
        teacher_group_id: 1235,
        teachers: ["山本 章夫"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 52,
        teachers: ["金田 英宏"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 753,
        teachers: ["元廣 友美"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1037,
        teachers: ["中務 邦雄"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 2103,
        course_name: "基礎セミナー",
        teacher_group_id: 59,
        teachers: ["岩崎 雄吾"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1693,
        teachers: ["金岡 雅浩"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1609,
        teachers: ["小林 敬幸"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1188,
        teachers: ["堀尾 文彦"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1823,
        teachers: ["北川 浩之"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1023,
        course_name: "都市の川は甦るか?",
        teacher_group_id: 1154,
        teachers: ["中村 晋一郎"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 651,
        teachers: ["豊田 浩孝"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1618,
        course_name: "ヒューマンエラー",
        teacher_group_id: 1142,
        teachers: ["工藤 博章"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 2103,
        course_name: "基礎セミナー",
        teacher_group_id: 1109,
        teachers: ["宮田 正"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 1964,
        course_name: "1968/2015-現代社会を1968から考える",
        teacher_group_id: 648,
        teachers: ["黒田 光太郎"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1160,
        teachers: ["柘植 尚志"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 739,
        course_name: "身のまわりの科学・疑似科学を正しく理解する",
        teacher_group_id: 823,
        teachers: ["榊原 均"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 792,
        course_name: "音楽を考える",
        teacher_group_id: 874,
        teachers: ["原田 裕貴"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 873,
        teachers: ["平澤 政廣"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1196,
        teachers: ["田中 隆文"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 772,
        teachers: ["立原 研悟"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1235,
        teachers: ["山本 章夫"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1652,
        course_name: "カタストロフィ理論",
        teacher_group_id: 2241,
        teachers: ["冨松 彰"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1170,
        teachers: ["中里 和郎"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 238,
        teachers: ["稲葉 伸道"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1298,
        teachers: ["石川 佳治"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1760,
        teachers: ["松本 睦"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 318,
        teachers: ["MATURANA"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 927,
        course_name: "身近な生物学",
        teacher_group_id: 1026,
        teachers: ["飯島 信司"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 542,
        teachers: ["日比 正彦"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 875,
        course_name: "テクノロジーは人間をどう変えたのか?どう変えるのか?",
        teacher_group_id: 976,
        teachers: ["大谷 尚"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1786,
        teachers: ["福井 康貴"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1603,
        teachers: ["伊藤 カンナ"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1589,
        course_name: "グーグルの活用法",
        teacher_group_id: 930,
        teachers: ["結縁 祥治"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1817,
        teachers: ["田中 晴佳"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1090,
        course_name: "ファクター5",
        teacher_group_id: 1260,
        teachers: ["林 良嗣"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1623,
        teachers: ["佐藤 康紀"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1287,
        teachers: ["山篠 貴史"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 685,
        teachers: ["立石 寛"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 68,
        course_name: "放射線治療の現状",
        teacher_group_id: 32,
        teachers: ["吉橋 幸子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1044,
        teachers: ["井藤 雄一"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 483,
        course_name: "比較教育論",
        teacher_group_id: 614,
        teachers: ["服部 美奈"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1542,
        teachers: ["吉武 純夫"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 716,
        course_name: "日本古代史料学",
        teacher_group_id: 798,
        teachers: ["古尾谷 知浩"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1026,
        teachers: ["飯島 信司"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 952,
        teachers: ["藤田 耕史"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 973,
        teachers: ["井料 美帆"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1136,
        course_name: "社会科学の「読み書きソロバン」",
        teacher_group_id: 1325,
        teachers: ["宇田川 幸則"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 662,
        teachers: ["三浦 裕一"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1583,
        teachers: ["後藤 元信"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 766,
        course_name: "楽器演奏のバイオメカニクス",
        teacher_group_id: 849,
        teachers: ["松本 健朗"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 843,
        teachers: ["伊藤 正行"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1009,
        course_name: "グローバル・マニュファクチャリング・マネジメント",
        teacher_group_id: 1136,
        teachers: ["佐野 良雄"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1855,
        teachers: ["高原 照直"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 525,
        course_name: "1968/2013-現代社会を1968から考える",
        teacher_group_id: 648,
        teachers: ["黒田 光太郎"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1009,
        teachers: ["金子 一史"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 509,
        teachers: ["坂本 將暢"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1553,
        teachers: ["加藤 丈典"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1628,
        teachers: ["齊藤 誠"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 510,
        teachers: ["島本 佳寿広"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 732,
        teachers: ["石原 一彰"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 332,
        teachers: ["桒原 真人"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 9,
        teachers: ["福塚 友和"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 877,
        teachers: ["齋藤 永宏"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 732,
        teachers: ["石原 一彰"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 711,
        teachers: ["佐藤 浩太郎"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 1016,
        course_name: "仕事は楽しいかね?",
        teacher_group_id: 1143,
        teachers: ["金井 篤子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 642,
        teachers: ["大野 哲靖"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 287,
        teachers: ["TEO Kia Choong"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1604,
        teachers: ["松井 佑介"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1067,
        teachers: ["飯嶋 徹"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 2293,
        course_name: "社会と環境",
        teacher_group_id: 1284,
        teachers: ["青木 聡子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1166,
        teachers: ["松井 茂之"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1475,
        teachers: ["酒井 正彦"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 864,
        course_name: "大学入試改革を評価する",
        teacher_group_id: 957,
        teachers: ["中島 英博"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 352,
        course_name: "若者論を語る",
        teacher_group_id: 393,
        teachers: ["丸山"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 863,
        course_name: "防災について",
        teacher_group_id: 956,
        teachers: ["長江 拓也"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1692,
        course_name: "将来役に立つコミュニケーションのセミナー",
        teacher_group_id: 575,
        teachers: ["佐藤 理史"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1585,
        teachers: ["山本 真義"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 190,
        teachers: ["佐々木 重洋"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 359,
        teachers: ["杵淵 紀世志"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 942,
        teachers: ["三野 広幸"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1294,
        teachers: ["大槻 主税"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1372,
        teachers: ["中村 英樹"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1827,
        teachers: ["椿 涼太"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 968,
        teachers: ["太田 元規"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 338,
        teachers: ["松本 のぞ"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1712,
        teachers: ["松田 亮太郎"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 782,
        teachers: ["渡邉 智彦"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 324,
        teachers: ["君塚 肇"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1508,
        teachers: ["長谷川 浩"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1129,
        teachers: ["黒田 達朗"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 493,
        teachers: ["宮脇"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1346,
        teachers: ["大平 英樹"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1016,
        teachers: ["藤田 真哉"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 294,
        teachers: ["長野 方星"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 957,
        teachers: ["中島 英博"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 979,
        teachers: ["和田 光弘"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 850,
        teachers: ["近藤 竜彦"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1573,
        teachers: ["東 賢太朗"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1682,
        teachers: ["西谷 直子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1703,
        teachers: ["松山 智至"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1765,
        teachers: ["尾花 まき子"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 671,
        teachers: ["樋野 励"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 4,
        teachers: ["宮﨑 誠一"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1142,
        teachers: ["工藤 博章"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 319,
        teachers: ["番原 睦則"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 699,
        teachers: ["清水 克俊"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 414,
        teachers: ["入山"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1273,
        teachers: ["関 浩之"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1537,
        teachers: ["須藤 健悟"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 2647,
        teachers: ["ZENG Wei"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1814,
        teachers: ["足立 吉隆"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1559,
        course_name: "会計II",
        teacher_group_id: 728,
        teachers: ["木村 彰吾"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1792,
        teachers: ["杉浦 大輔"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1237,
        teachers: ["野尻 伸一"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1057,
        course_name: "バイオテクノロジーの研究実態と産業化",
        teacher_group_id: 1204,
        teachers: ["本多 裕之"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1239,
        course_name: "研究のブレイクスルー",
        teacher_group_id: 1527,
        teachers: ["小嶋 誠司"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1735,
        course_name: "人口知能",
        teacher_group_id: 2566,
        teachers: ["林 雄介"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1703,
        teachers: ["松山 智至"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 2319,
        teachers: ["服部 一三"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1710,
        teachers: ["島村 徹平"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 65,
        teachers: ["相木 秀則"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 903,
        teachers: ["宇野 洋二"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1182,
        teachers: ["上村 泰弘"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1692,
        teachers: ["矢野 勝也"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1819,
        teachers: ["小山 修司"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 620,
        teachers: ["李 澤熊"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 979,
        teachers: ["和田 光弘"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1623,
        teachers: ["佐藤 康紀"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1129,
        teachers: ["黒田 達朗"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 345,
        teachers: ["中野 俊詩"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 766,
        course_name: "楽器演奏のバイオメカニクス",
        teacher_group_id: 1084,
        teachers: ["松本 健郎"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1494,
        course_name: "物性化学I",
        teacher_group_id: 1948,
        teachers: ["松下 未知雄"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1045,
        teachers: ["熊谷 博之"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1740,
        course_name: "電子部品",
        teacher_group_id: 1574,
        teachers: ["山本 剛久"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1352,
        teachers: ["黒岩 厚"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1546,
        teachers: ["加納 修"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 561,
        teachers: ["田仲 由喜夫"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 123,
        course_name: "経営II",
        teacher_group_id: 963,
        teachers: ["宮崎 正也"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 627,
        teachers: ["周藤 芳幸"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1607,
        teachers: ["山口 景子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 858,
        teachers: ["和田 信雄"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1782,
        teachers: ["富田 孝史"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 304,
        teachers: ["巨 陽"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 1015,
        course_name: "働くとはなにか",
        teacher_group_id: 1143,
        teachers: ["金井 篤子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 516,
        teachers: ["鈴木 淳巨"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 698,
        course_name: "国際開発学",
        teacher_group_id: 783,
        teachers: ["岡田 亜弥"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1736,
        teachers: ["西島 謙一"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1692,
        teachers: ["矢野 勝也"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1588,
        teachers: ["松本 麻人"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1688,
        teachers: ["高橋 宏和"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 584,
        teachers: ["近藤 豊"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 349,
        teachers: ["髙木 新"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1156,
        teachers: ["長尾 確"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 737,
        teachers: ["加藤 昌志"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 978,
        course_name: "法と経済",
        teacher_group_id: 1092,
        teachers: ["林 秀弥"],
        course_group: "基礎セミナー",
        semester: "不明",
      },
      {
        course_id: 264,
        course_name: "フランス語1",
        teacher_group_id: 1347,
        teachers: ["飯野 和夫"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 923,
        teachers: ["大河内 美紀"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1520,
        teachers: ["吉岡 泰"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 876,
        teachers: ["川瀬 晃道"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1676,
        teachers: ["水口 将輝"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1602,
        teachers: ["西山 久雄"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 739,
        teachers: ["森 仁志"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 858,
        teachers: ["和田 信雄"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 238,
        course_name: "基礎セミナーB",
        teacher_group_id: 1281,
        teachers: ["稲葉 一将"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 1642,
        teachers: ["小澤 正邦"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 2071,
        teachers: ["河出"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1636,
        course_name: "集合と位相",
        teacher_group_id: 2212,
        teachers: ["三宅 正武"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 1034,
        teachers: ["伊東 裕"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 1565,
        course_name: "量子力学",
        teacher_group_id: 774,
        teachers: ["佐藤 憲昭"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 648,
        teachers: ["黒田 光太郎"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 31,
        course_name: "基礎セミナー",
        teacher_group_id: 2327,
        teachers: ["原田 昌幸"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 872,
        course_name: "まちの賑わいと道路空間",
        teacher_group_id: 973,
        teachers: ["井料 美帆"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 873,
        course_name: "交通と街の賑わい",
        teacher_group_id: 973,
        teachers: ["井料 美帆"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 6,
        course_name: "基礎セミナーA",
        teacher_group_id: 521,
        teachers: ["田中 実"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1058,
        teachers: ["尾上 順"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 2103,
        course_name: "基礎セミナー",
        teacher_group_id: 2075,
        teachers: ["村松"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 949,
        course_name: "電子回路論及び演習",
        teacher_group_id: 1056,
        teachers: ["岩田 聡"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 353,
        course_name: "民主主義の歴史と現在",
        teacher_group_id: 894,
        teachers: ["武田 宏子"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
      {
        course_id: 814,
        course_name: "健康・スポーツ科学実習II(太極拳)",
        teacher_group_id: 898,
        teachers: ["張 成忠"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 2103,
        course_name: "基礎セミナー",
        teacher_group_id: 2267,
        teachers: ["河出 清"],
        course_group: "基礎セミナー",
        semester: "後期",
      },
      {
        course_id: 1020,
        course_name: "特許に見るセンサ技術",
        teacher_group_id: 1151,
        teachers: ["村松 直樹"],
        course_group: "基礎セミナー",
        semester: "前期",
      },
    ],
    初修外国語: [
      {
        course_id: 1986,
        course_name: "中国語基礎1",
        teacher_group_id: 703,
        teachers: ["盧 建"],
        course_group: "初修外国語",
        semester: "その他",
      },
      {
        course_id: 846,
        course_name: "ドイツ語基礎1",
        teacher_group_id: 931,
        teachers: ["古田 香織"],
        course_group: "初修外国語",
        semester: "前期",
      },
      {
        course_id: 2021,
        course_name: "多言語習得基礎",
        teacher_group_id: 800,
        teachers: ["星野 幸代"],
        course_group: "初修外国語",
        semester: "前期",
      },
      {
        course_id: 2020,
        course_name: "中国語基礎1",
        teacher_group_id: 800,
        teachers: ["星野 幸代"],
        course_group: "初修外国語",
        semester: "後期",
      },
      {
        course_id: 333,
        course_name: "スペイン語基礎1",
        teacher_group_id: 369,
        teachers: ["岡見 友里江"],
        course_group: "初修外国語",
        semester: "後期",
      },
      {
        course_id: 994,
        course_name: "中国語初級完成",
        teacher_group_id: 1127,
        teachers: ["田中 千寿"],
        course_group: "初修外国語",
        semester: "後期",
      },
      {
        course_id: 2076,
        course_name: "中国語基礎1",
        teacher_group_id: 980,
        teachers: ["丸尾 誠"],
        course_group: "初修外国語",
        semester: "前期",
      },
      {
        course_id: 1844,
        course_name: "フランス語中級",
        teacher_group_id: 915,
        teachers: ["新井 美佐子"],
        course_group: "初修外国語",
        semester: "前期",
      },
      {
        course_id: 2328,
        course_name: "中国語中級1",
        teacher_group_id: 667,
        teachers: ["前田 光子"],
        course_group: "初修外国語",
        semester: "前期",
      },
      {
        course_id: 2021,
        course_name: "多言語習得基礎",
        teacher_group_id: 1804,
        teachers: ["小栗栖 等"],
        course_group: "初修外国語",
        semester: "前期",
      },
      {
        course_id: 1769,
        course_name: "中国語",
        teacher_group_id: 6,
        teachers: ["甘 靖超"],
        course_group: "初修外国語",
        semester: "前期",
      },
      {
        course_id: 336,
        course_name: "スペイン語初級完成",
        teacher_group_id: 372,
        teachers: ["MENDEZ GUERRA Carlos albe"],
        course_group: "初修外国語",
        semester: "後期",
      },
      {
        course_id: 1398,
        course_name: "フランス語1",
        teacher_group_id: 1804,
        teachers: ["小栗栖 等"],
        course_group: "初修外国語",
        semester: "後期",
      },
      {
        course_id: 1995,
        course_name: "中国語基礎2",
        teacher_group_id: 721,
        teachers: ["笠井 直美"],
        course_group: "初修外国語",
        semester: "前期",
      },
      {
        course_id: 552,
        course_name: "中国語基礎1",
        teacher_group_id: 667,
        teachers: ["前田 光子"],
        course_group: "初修外国語",
        semester: "後期",
      },
      {
        course_id: 1845,
        course_name: "中国語2",
        teacher_group_id: 694,
        teachers: ["坂部 晶子"],
        course_group: "初修外国語",
        semester: "後期",
      },
      {
        course_id: 2214,
        course_name: "スペイン語基礎1",
        teacher_group_id: 1861,
        teachers: ["齋藤 亮太"],
        course_group: "初修外国語",
        semester: "前期",
      },
      {
        course_id: 585,
        course_name: "中国語基礎2",
        teacher_group_id: 972,
        teachers: ["寺澤 知美"],
        course_group: "初修外国語",
        semester: "後期",
      },
      {
        course_id: 994,
        course_name: "中国語初級完成",
        teacher_group_id: 667,
        teachers: ["前田 光子"],
        course_group: "初修外国語",
        semester: "後期",
      },
      {
        course_id: 585,
        course_name: "中国語基礎2",
        teacher_group_id: 694,
        teachers: ["坂部 晶子"],
        course_group: "初修外国語",
        semester: "後期",
      },
      {
        course_id: 552,
        course_name: "中国語基礎1",
        teacher_group_id: 1408,
        teachers: ["辻 千春"],
        course_group: "初修外国語",
        semester: "後期",
      },
      {
        course_id: 574,
        course_name: "ドイツ語初級完成",
        teacher_group_id: 682,
        teachers: ["村元 麻衣"],
        course_group: "初修外国語",
        semester: "後期",
      },
    ],
    言語文化I: [
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1191,
        teachers: ["丸田 孝治"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 675,
        teachers: ["杉村 泰"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 3,
        teachers: ["S.McGuire"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 22,
        teachers: ["HAIG EDWARD"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1317,
        teachers: ["布施 哲"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 185,
        course_name: "ドイツ語",
        teacher_group_id: 120,
        teachers: ["藤井 たぎる"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 14,
        teachers: ["W.M.Jones"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 895,
        teachers: ["成田 克史"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 233,
        teachers: ["秦 明吾"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 677,
        teachers: ["尾関 修治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 57,
        teachers: ["ドライデン いづみ"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 121,
        teachers: ["I.Harnett"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 839,
        teachers: ["原田 邦彦"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 1257,
        teachers: ["中村 登志哉"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 226,
        teachers: ["八幡 耕一"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 69,
        course_name: "スペイン語",
        teacher_group_id: 1097,
        teachers: ["志波 彩子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 14,
        teachers: ["W.M.Jones"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 281,
        course_name: "英語(基礎)",
        teacher_group_id: 881,
        teachers: ["上原 早苗"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 247,
        teachers: ["寇 振鋒"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 653,
        teachers: ["木下 徹"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 6,
        teachers: ["甘 靖超"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 931,
        teachers: ["古田 香織"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 777,
        teachers: ["松井 隆幸"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 800,
        teachers: ["星野 幸代"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 721,
        teachers: ["笠井 直美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1865,
        course_name: "中国語2",
        teacher_group_id: 323,
        teachers: ["張 素芳"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1367,
        teachers: ["三友 弘孝"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 925,
        teachers: ["山口 庸子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 909,
        teachers: ["西川 智之"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 1408,
        teachers: ["辻 千春"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 980,
        teachers: ["丸尾 誠"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 851,
        teachers: ["三宅 恭子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 1295,
        teachers: ["前野 みち子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 23,
        course_name: "中国語3",
        teacher_group_id: 800,
        teachers: ["星野 幸代"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 48,
        teachers: ["Deacon Robert Joel"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 848,
        teachers: ["今井田 亜弓"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 428,
        teachers: ["杉村"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 3,
        teachers: ["S.McGuire"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 17,
        teachers: ["勝川 裕子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 688,
        teachers: ["三輪 晃司"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 725,
        teachers: ["玉岡 賀津雄"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 1668,
        teachers: ["蟹江 静夫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 69,
        course_name: "スペイン語",
        teacher_group_id: 33,
        teachers: ["加納 マルタ"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 770,
        teachers: ["土屋 洋"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 848,
        teachers: ["今井田 亜弓"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 69,
        course_name: "スペイン語",
        teacher_group_id: 995,
        teachers: ["宮下 克子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1865,
        course_name: "中国語2",
        teacher_group_id: 721,
        teachers: ["笠井 直美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 19,
        course_name: "中国語2",
        teacher_group_id: 675,
        teachers: ["杉村 泰"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 1236,
        teachers: ["林 みどり"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 435,
        teachers: ["布施"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 18,
        course_name: "スペイン語1",
        teacher_group_id: 1097,
        teachers: ["志波 彩子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 1022,
        teachers: ["大石 晴美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 895,
        teachers: ["成田 克史"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1317,
        teachers: ["布施 哲"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 950,
        teachers: ["加藤 高志"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1888,
        course_name: "ロシア語",
        teacher_group_id: 948,
        teachers: ["柳沢 民雄"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 967,
        teachers: ["松岡 光治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 1317,
        teachers: ["布施 哲"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 581,
        course_name: "ドイツ語3",
        teacher_group_id: 851,
        teachers: ["三宅 恭子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 140,
        course_name: "フランス語",
        teacher_group_id: 277,
        teachers: ["BAUMERT"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 14,
        teachers: ["W.M.Jones"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1869,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1123,
        teachers: ["福田 眞人"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 64,
        course_name: "ドイツ語2",
        teacher_group_id: 55,
        teachers: ["CONRAD Marcus"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 88,
        course_name: "ロシア語1",
        teacher_group_id: 948,
        teachers: ["柳沢 民雄"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 57,
        teachers: ["ドライデン いづみ"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1123,
        teachers: ["福田 眞人"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 800,
        teachers: ["星野 幸代"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 169,
        teachers: ["谷本 千雅子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 30,
        teachers: ["RUDE Markus"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1394,
        teachers: ["水野 由美子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 807,
        teachers: ["山下 淳子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 881,
        teachers: ["上原 早苗"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 308,
        course_name: "スペイン語2",
        teacher_group_id: 759,
        teachers: ["野内 遊"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 756,
        teachers: ["可知 れい子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 22,
        teachers: ["HAIG EDWARD"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1980,
        course_name: "ドイツ語1",
        teacher_group_id: 690,
        teachers: ["中村 修"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 64,
        course_name: "ドイツ語2",
        teacher_group_id: 30,
        teachers: ["RUDE Markus"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1905,
        course_name: "中国語4",
        teacher_group_id: 1272,
        teachers: ["劉 欣"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 469,
        teachers: ["松井"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1905,
        course_name: "中国語4",
        teacher_group_id: 323,
        teachers: ["張 素芳"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 590,
        teachers: ["平高", "藤井", "空井"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 1202,
        teachers: ["鶴田 涼子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 94,
        course_name: "ロシア語2",
        teacher_group_id: 46,
        teachers: ["ヤマザキ タチアナ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 71,
        teachers: ["T.Chambers"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 451,
        teachers: ["福田"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1979,
        course_name: "英語(基礎)",
        teacher_group_id: 1212,
        teachers: ["小川 明子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 28,
        teachers: ["M.Koch"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 194,
        teachers: ["三友"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 839,
        teachers: ["原田 邦彦"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1884,
        course_name: "スペイン語",
        teacher_group_id: 759,
        teachers: ["野内 遊"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 807,
        teachers: ["山下 淳子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 257,
        teachers: ["LONGCOPE"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 682,
        teachers: ["村元 麻衣"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 278,
        teachers: ["chad"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 703,
        teachers: ["盧 建"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 145,
        teachers: ["小出 俟子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 264,
        course_name: "フランス語1",
        teacher_group_id: 277,
        teachers: ["BAUMERT"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 132,
        teachers: ["S.Humphrey"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 572,
        teachers: ["杉村 秦"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 714,
        teachers: ["森 有礼"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 688,
        teachers: ["三輪 晃司"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1858,
        teachers: ["吉村 愛子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1887,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 824,
        teachers: ["秋田 喜美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 706,
        teachers: ["鶴巻 泉子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1905,
        course_name: "中国語4",
        teacher_group_id: 887,
        teachers: ["田村 加代子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 150,
        teachers: ["Weeks"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 676,
        teachers: ["久米 和代"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1919,
        course_name: "中国語3",
        teacher_group_id: 770,
        teachers: ["土屋 洋"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 289,
        teachers: ["Sichi"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1031,
        teachers: ["村尾 玲美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1887,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 677,
        teachers: ["尾関 修治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 1191,
        teachers: ["丸田 孝治"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 8,
        teachers: ["MORITA CHUA LIANG"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1905,
        course_name: "中国語4",
        teacher_group_id: 675,
        teachers: ["杉村 泰"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 308,
        course_name: "スペイン語2",
        teacher_group_id: 1155,
        teachers: ["渡辺 有美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 683,
        teachers: ["三上 由香"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1884,
        course_name: "スペイン語",
        teacher_group_id: 112,
        teachers: ["R.Figueroa"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1099,
        teachers: ["村主 幸一"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 676,
        teachers: ["久米 和代"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 64,
        course_name: "ドイツ語2",
        teacher_group_id: 650,
        teachers: ["伊藤 惟"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 714,
        teachers: ["森 有礼"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 49,
        course_name: "中国語1",
        teacher_group_id: 17,
        teachers: ["勝川 裕子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 689,
        teachers: ["大島 義和"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 91,
        teachers: ["E.Cabrido"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 653,
        teachers: ["木下 徹"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1872,
        course_name: "中国語1",
        teacher_group_id: 11,
        teachers: ["虞 萍"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 554,
        course_name: "ドイツ語4",
        teacher_group_id: 909,
        teachers: ["西川 智之"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 167,
        teachers: ["E.T.W.Haig"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 426,
        course_name: "朝鮮・韓国語1",
        teacher_group_id: 730,
        teachers: ["宇都木 昭"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 667,
        teachers: ["前田 光子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 656,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 756,
        teachers: ["可知 れい子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 274,
        teachers: ["李 玉節"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 1615,
        teachers: ["安川 晴基"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 460,
        teachers: ["玉岡"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 1361,
        teachers: ["中井 政喜"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 439,
        teachers: ["山口"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 141,
        course_name: "フランス語2",
        teacher_group_id: 83,
        teachers: ["DUCROS GARANCE"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 653,
        teachers: ["木下 徹"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 881,
        teachers: ["上原 早苗"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1919,
        course_name: "中国語3",
        teacher_group_id: 980,
        teachers: ["丸尾 誠"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 71,
        course_name: "スペイン語4",
        teacher_group_id: 112,
        teachers: ["R.Figueroa"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 84,
        course_name: "英語(基礎)",
        teacher_group_id: 39,
        teachers: ["Nuske Kyle David"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1373,
        teachers: ["大室 剛志"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 571,
        teachers: ["玉岡 賀津男"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1919,
        course_name: "中国語3",
        teacher_group_id: 11,
        teachers: ["虞 萍"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 1127,
        teachers: ["田中 千寿"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 653,
        teachers: ["木下 徹"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 178,
        teachers: ["D.Ramsey"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1874,
        course_name: "英語(中級)",
        teacher_group_id: 824,
        teachers: ["秋田 喜美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 25,
        teachers: ["TOMKINSON Fiona gail"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1022,
        teachers: ["大石 晴美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 667,
        teachers: ["前田 光子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1905,
        course_name: "中国語4",
        teacher_group_id: 721,
        teachers: ["笠井 直美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 581,
        course_name: "ドイツ語3",
        teacher_group_id: 895,
        teachers: ["成田 克史"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1512,
        teachers: ["池側 孝之"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 197,
        teachers: ["董 紅俊"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 789,
        teachers: ["河野 哲子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 770,
        teachers: ["土屋 洋"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 714,
        teachers: ["森 有礼"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 120,
        teachers: ["藤井 たぎる"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 677,
        teachers: ["尾関 修治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 3,
        teachers: ["S.McGuire"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 89,
        course_name: "ロシア語",
        teacher_group_id: 179,
        teachers: ["タチアナ ヤマザキ"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1354,
        teachers: ["宮北 恵子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 725,
        teachers: ["玉岡 賀津雄"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 554,
        course_name: "ドイツ語4",
        teacher_group_id: 1606,
        teachers: ["白川 茜"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 735,
        teachers: ["田中 智之"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1405,
        teachers: ["滝沢 直宏"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1865,
        course_name: "中国語2",
        teacher_group_id: 230,
        teachers: ["胡 潔"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 890,
        teachers: ["山本 幸一"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 977,
        teachers: ["松野 澄江"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 1347,
        teachers: ["飯野 和夫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 264,
        course_name: "フランス語1",
        teacher_group_id: 1347,
        teachers: ["飯野 和夫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 839,
        teachers: ["原田 邦彦"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 977,
        teachers: ["松野 澄江"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 689,
        teachers: ["大島 義和"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 265,
        teachers: ["丸田 考治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1905,
        course_name: "中国語4",
        teacher_group_id: 800,
        teachers: ["星野 幸代"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 1236,
        teachers: ["林 みどり"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 765,
        teachers: ["榎本 洋"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 1198,
        teachers: ["櫻井 龍彦"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 21,
        course_name: "中級中国語1",
        teacher_group_id: 980,
        teachers: ["丸尾 誠"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 132,
        teachers: ["S.Humphrey"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 47,
        course_name: "中国語4",
        teacher_group_id: 865,
        teachers: ["徐 春陽"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 447,
        teachers: ["サイモン"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 1538,
        teachers: ["林 久博"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 121,
        teachers: ["I.Harnett"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 389,
        teachers: ["三宅"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1664,
        teachers: ["長山 智香子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 115,
        teachers: ["朱 新健"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1733,
        teachers: ["大崎 晴美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1551,
        teachers: ["高橋 すみれ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 241,
        teachers: ["ハーネット"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 1064,
        teachers: ["中川 亜紀子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1988,
        course_name: "フランス語1",
        teacher_group_id: 1091,
        teachers: ["田所 光男"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 881,
        teachers: ["上原 早苗"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 231,
        teachers: ["Shafer"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 91,
        teachers: ["E.Cabrido"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 230,
        teachers: ["胡 潔"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 176,
        teachers: ["J.Peters"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1979,
        course_name: "英語(基礎)",
        teacher_group_id: 688,
        teachers: ["三輪 晃司"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 310,
        teachers: ["PEDDIE Francis david"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 796,
        teachers: ["北村 陽子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 1236,
        teachers: ["林 みどり"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 16,
        course_name: "英語(セミナー)",
        teacher_group_id: 3,
        teachers: ["S.McGuire"],
        course_group: "言語文化I",
        semester: "不明",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 121,
        teachers: ["I.Harnett"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 141,
        course_name: "フランス語2",
        teacher_group_id: 1057,
        teachers: ["藤村 逸子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 2692,
        teachers: ["POTTER"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 278,
        teachers: ["chad"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 186,
        teachers: ["J.Roskoski"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 706,
        course_name: "英語(基礎)",
        teacher_group_id: 789,
        teachers: ["河野 哲子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1865,
        course_name: "中国語2",
        teacher_group_id: 667,
        teachers: ["前田 光子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1932,
        course_name: "スペイン語3",
        teacher_group_id: 1097,
        teachers: ["志波 彩子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1988,
        course_name: "フランス語1",
        teacher_group_id: 915,
        teachers: ["新井 美佐子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1548,
        teachers: ["中西 須美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 2006,
        course_name: "英語(上級リーディング1)",
        teacher_group_id: 756,
        teachers: ["可知 れい子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 260,
        teachers: ["榎本"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 337,
        teachers: ["WEEKS Mark"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 13,
        teachers: ["SAYENKO Tetyana"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 249,
        teachers: ["Stavoy"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1905,
        course_name: "中国語4",
        teacher_group_id: 703,
        teachers: ["盧 建"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 15,
        teachers: ["Kyle Nuske"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 454,
        teachers: ["大庭"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1405,
        teachers: ["滝沢 直宏"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1759,
        teachers: ["木村 正子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 69,
        course_name: "スペイン語",
        teacher_group_id: 574,
        teachers: ["木下 智統"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 140,
        course_name: "フランス語",
        teacher_group_id: 252,
        teachers: ["GRAZIANI"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1884,
        course_name: "スペイン語",
        teacher_group_id: 757,
        teachers: ["志波 綾子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 554,
        course_name: "ドイツ語4",
        teacher_group_id: 668,
        teachers: ["前田 織絵"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1888,
        course_name: "ロシア語",
        teacher_group_id: 1540,
        teachers: ["安村 仁志"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 566,
        teachers: ["秦 明呉"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 89,
        teachers: ["J.Gaffney"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 271,
        teachers: ["M.Berkowitz"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 21,
        course_name: "中級中国語1",
        teacher_group_id: 17,
        teachers: ["勝川 裕子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 668,
        teachers: ["前田 織絵"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 47,
        course_name: "中国語4",
        teacher_group_id: 1408,
        teachers: ["辻 千春"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 1066,
        teachers: ["陳 朝輝"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1865,
        course_name: "中国語2",
        teacher_group_id: 286,
        teachers: ["朱 新建"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1236,
        teachers: ["林 みどり"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 23,
        course_name: "中国語3",
        teacher_group_id: 887,
        teachers: ["田村 加代子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1865,
        course_name: "中国語2",
        teacher_group_id: 867,
        teachers: ["寺澤 和美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 89,
        teachers: ["J.Gaffney"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1888,
        course_name: "ロシア語",
        teacher_group_id: 288,
        teachers: ["タチアナ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 1070,
        teachers: ["堀 えりか"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 483,
        course_name: "比較教育論",
        teacher_group_id: 614,
        teachers: ["服部 美奈"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 40,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 912,
        teachers: ["林 誠"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1108,
        teachers: ["松原 緑"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 257,
        teachers: ["LONGCOPE"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 287,
        course_name: "フランス語4",
        teacher_group_id: 1347,
        teachers: ["飯野 和夫"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1887,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 68,
        teachers: ["TOOHEY David edward"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 346,
        teachers: ["2E David"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1976,
        course_name: "英語上級",
        teacher_group_id: 1031,
        teachers: ["村尾 玲美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1851,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 881,
        teachers: ["上原 早苗"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 328,
        teachers: ["J.Shich"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1594,
        teachers: ["堀 江里香"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 193,
        teachers: ["J.Sichi"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 1668,
        teachers: ["蟹江 静夫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1976,
        course_name: "英語上級",
        teacher_group_id: 1872,
        teachers: ["城山 友孝"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 388,
        teachers: ["水野"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 39,
        teachers: ["Nuske Kyle David"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1865,
        course_name: "中国語2",
        teacher_group_id: 865,
        teachers: ["徐 春陽"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 931,
        teachers: ["古田 香織"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1888,
        course_name: "ロシア語",
        teacher_group_id: 1263,
        teachers: ["水野 晶子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 1402,
        teachers: ["内田 綾子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1869,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 137,
        teachers: ["NILEP"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1884,
        course_name: "スペイン語",
        teacher_group_id: 111,
        teachers: ["Villalobos"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 581,
        course_name: "ドイツ語3",
        teacher_group_id: 931,
        teachers: ["古田 香織"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 689,
        teachers: ["大島 義和"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 553,
        course_name: "中級中国語2",
        teacher_group_id: 667,
        teachers: ["前田 光子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 554,
        course_name: "ドイツ語4",
        teacher_group_id: 851,
        teachers: ["三宅 恭子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1869,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 8,
        teachers: ["MORITA CHUA LIANG"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 23,
        course_name: "中国語3",
        teacher_group_id: 867,
        teachers: ["寺澤 和美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 754,
        teachers: ["望月 節子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 11,
        teachers: ["虞 萍"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1594,
        teachers: ["堀 江里香"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1966,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 653,
        teachers: ["木下 徹"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1865,
        course_name: "中国語2",
        teacher_group_id: 800,
        teachers: ["星野 幸代"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 14,
        teachers: ["W.M.Jones"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 446,
        teachers: ["寇"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 475,
        teachers: ["尾関"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 69,
        course_name: "スペイン語",
        teacher_group_id: 429,
        teachers: ["木下"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 94,
        teachers: ["K.Nuske"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 721,
        teachers: ["笠井 直美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 261,
        teachers: ["DELBARRE.F"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 1090,
        teachers: ["村尾 怜美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 807,
        teachers: ["山下 淳子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 2683,
        teachers: ["POTTER"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1845,
        course_name: "中国語2",
        teacher_group_id: 694,
        teachers: ["坂部 晶子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 236,
        teachers: ["H.Edward"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 168,
        teachers: ["モンテゲイジ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 1116,
        teachers: ["杉浦 正利"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 64,
        course_name: "ドイツ語2",
        teacher_group_id: 1606,
        teachers: ["白川 茜"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 63,
        teachers: ["M.C.weeks"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 254,
        teachers: ["J.Shichi"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1905,
        course_name: "中国語4",
        teacher_group_id: 274,
        teachers: ["李 玉節"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 140,
        course_name: "フランス語",
        teacher_group_id: 256,
        teachers: ["松本 伊嵯子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 21,
        course_name: "中級中国語1",
        teacher_group_id: 800,
        teachers: ["星野 幸代"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 264,
        course_name: "フランス語1",
        teacher_group_id: 520,
        teachers: ["田所 シルヴィ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1865,
        course_name: "中国語2",
        teacher_group_id: 703,
        teachers: ["盧 建"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 568,
        teachers: ["杉村 たいぞう"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 193,
        teachers: ["J.Sichi"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 754,
        teachers: ["望月 節子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 912,
        teachers: ["林 誠"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 463,
        teachers: ["小関"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 123,
        teachers: ["謝 平"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 69,
        course_name: "スペイン語",
        teacher_group_id: 483,
        teachers: ["宮下"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 71,
        course_name: "スペイン語4",
        teacher_group_id: 33,
        teachers: ["加納 マルタ"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 280,
        teachers: ["RUDE"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1476,
        teachers: ["池側 隆之"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 374,
        teachers: ["Richard Hill"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1874,
        course_name: "英語(中級)",
        teacher_group_id: 407,
        teachers: ["平井"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1966,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 824,
        teachers: ["秋田 喜美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 406,
        teachers: ["高橋"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 1022,
        teachers: ["大石 晴美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 982,
        teachers: ["井土 愼二"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 64,
        course_name: "ドイツ語2",
        teacher_group_id: 1074,
        teachers: ["越智 和弘"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 471,
        teachers: ["今井田"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 478,
        teachers: ["村上"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 264,
        course_name: "フランス語1",
        teacher_group_id: 315,
        teachers: ["GARRABET Christophe"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1966,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 1677,
        teachers: ["中村 さやか"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 64,
        course_name: "ドイツ語2",
        teacher_group_id: 909,
        teachers: ["西川 智之"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 1085,
        teachers: ["片平 健太郎"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 90,
        course_name: "ロシア語4",
        teacher_group_id: 43,
        teachers: ["SAVELIEV"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 851,
        teachers: ["三宅 恭子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 796,
        teachers: ["北村 陽子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 23,
        course_name: "中国語3",
        teacher_group_id: 6,
        teachers: ["甘 靖超"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 176,
        course_name: "英語基礎スペイン語1",
        teacher_group_id: 112,
        teachers: ["R.Figueroa"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 488,
        teachers: ["稲垣"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1617,
        teachers: ["山崎 雄史"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 244,
        teachers: ["成田"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 47,
        teachers: ["CROSS Jeremy david"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 912,
        teachers: ["林 誠"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 420,
        teachers: ["丸田"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 259,
        teachers: ["Jones"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 1816,
        teachers: ["加藤 由香子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 989,
        course_name: "食と農の科学",
        teacher_group_id: 1119,
        teachers: ["白武 勝裕"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 29,
        teachers: ["大名 力"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 925,
        teachers: ["山口 庸子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 859,
        course_name: "現代社会と教育",
        teacher_group_id: 949,
        teachers: ["横山 悦生"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 81,
        teachers: ["DEACON Robert"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 474,
        teachers: ["笠井"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1088,
        teachers: ["芝 奈穂"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 315,
        teachers: ["GARRABET Christophe"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 203,
        course_name: "英語セミナー",
        teacher_group_id: 754,
        teachers: ["望月 節子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 448,
        teachers: ["大室"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 89,
        course_name: "ロシア語",
        teacher_group_id: 43,
        teachers: ["SAVELIEV"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 764,
        course_name: "財務会計2",
        teacher_group_id: 617,
        teachers: ["小沢 浩"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 91,
        teachers: ["E.Cabrido"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 909,
        teachers: ["西川 智之"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1212,
        teachers: ["小川 明子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 905,
        course_name: "中級スペイン語2",
        teacher_group_id: 1518,
        teachers: ["佐原 みどり"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1888,
        course_name: "ロシア語",
        teacher_group_id: 46,
        teachers: ["ヤマザキ タチアナ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 18,
        course_name: "スペイン語1",
        teacher_group_id: 5,
        teachers: ["GONZALEZ Alejandra"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 64,
        course_name: "ドイツ語2",
        teacher_group_id: 851,
        teachers: ["三宅 恭子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 467,
        teachers: ["阿藤"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 676,
        teachers: ["久米 和代"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 890,
        teachers: ["山本 幸一"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1381,
        teachers: ["田野 勲"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 2033,
        course_name: "情報リテラシー(理系)",
        teacher_group_id: 836,
        teachers: ["畔上 秀幸"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 1664,
        teachers: ["長山 智香子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 429,
        teachers: ["木下"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 48,
        teachers: ["Deacon Robert Joel"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 977,
        teachers: ["松野 澄江"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1317,
        teachers: ["布施 哲"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1883,
        course_name: "英語(上級)",
        teacher_group_id: 765,
        teachers: ["榎本 洋"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 967,
        teachers: ["松岡 光治"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 725,
        teachers: ["玉岡 賀津雄"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 1099,
        teachers: ["村主 幸一"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 558,
        course_name: "初級スペイン語",
        teacher_group_id: 670,
        teachers: ["水戸 博之"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1976,
        course_name: "英語上級",
        teacher_group_id: 738,
        teachers: ["小田 智子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 1491,
        teachers: ["小坂 光一"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 37,
        course_name: "中級中国語1",
        teacher_group_id: 721,
        teachers: ["笠井 直美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 456,
        teachers: ["岡戸"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 420,
        teachers: ["丸田"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 129,
        teachers: ["M.Marshall"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 286,
        teachers: ["朱 新建"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1733,
        teachers: ["大崎 晴美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1367,
        teachers: ["三友 弘孝"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1884,
        course_name: "スペイン語",
        teacher_group_id: 1516,
        teachers: ["糸魚川 美樹"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 1467,
        teachers: ["大橋 聰"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1437,
        teachers: ["中濱 優子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1865,
        course_name: "中国語2",
        teacher_group_id: 980,
        teachers: ["丸尾 誠"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1874,
        course_name: "英語(中級)",
        teacher_group_id: 1123,
        teachers: ["福田 眞人"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 581,
        course_name: "ドイツ語3",
        teacher_group_id: 777,
        teachers: ["松井 隆幸"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 463,
        course_name: "社会と環境",
        teacher_group_id: 1126,
        teachers: ["竹内 恒夫"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 25,
        teachers: ["TOMKINSON Fiona gail"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 89,
        teachers: ["J.Gaffney"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 982,
        teachers: ["井土 愼二"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 71,
        teachers: ["T.Chambers"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 94,
        course_name: "ロシア語2",
        teacher_group_id: 179,
        teachers: ["タチアナ ヤマザキ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 48,
        teachers: ["Deacon Robert Joel"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 227,
        course_name: "朝鮮語",
        teacher_group_id: 569,
        teachers: ["金 元榮"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 141,
        course_name: "フランス語2",
        teacher_group_id: 330,
        teachers: ["Garrabet Christophe"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 139,
        course_name: "中級スペイン語",
        teacher_group_id: 82,
        teachers: ["アパサ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 133,
        teachers: ["Dryden Laurence M"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 63,
        teachers: ["M.C.weeks"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 262,
        course_name: "フランス語3",
        teacher_group_id: 277,
        teachers: ["BAUMERT"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 1369,
        teachers: ["中島 利郎"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1874,
        course_name: "英語(中級)",
        teacher_group_id: 279,
        teachers: ["Harnett"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 811,
        teachers: ["秋田 喜美子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1874,
        course_name: "英語(中級)",
        teacher_group_id: 1022,
        teachers: ["大石 晴美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 278,
        teachers: ["chad"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 16,
        course_name: "英語(セミナー)",
        teacher_group_id: 676,
        teachers: ["久米 和代"],
        course_group: "言語文化I",
        semester: "不明",
      },
      {
        course_id: 2143,
        course_name: "英語(上級リーディング)",
        teacher_group_id: 1277,
        teachers: ["鈴木 繁夫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 281,
        course_name: "英語(基礎)",
        teacher_group_id: 982,
        teachers: ["井土 愼二"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 21,
        course_name: "中級中国語1",
        teacher_group_id: 6,
        teachers: ["甘 靖超"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 950,
        teachers: ["加藤 高志"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 51,
        teachers: ["WATSON Alex"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 18,
        course_name: "スペイン語1",
        teacher_group_id: 1004,
        teachers: ["西村 秀人"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 357,
        course_name: "経営I",
        teacher_group_id: 401,
        teachers: ["高木"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 57,
        teachers: ["ドライデン いづみ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 281,
        course_name: "英語(基礎)",
        teacher_group_id: 13,
        teachers: ["SAYENKO Tetyana"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 287,
        course_name: "フランス語4",
        teacher_group_id: 315,
        teachers: ["GARRABET Christophe"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 2172,
        course_name: "化学基礎I",
        teacher_group_id: 1472,
        teachers: ["菱川 明栄"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1932,
        course_name: "スペイン語3",
        teacher_group_id: 404,
        teachers: ["志波"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 450,
        teachers: ["丸尾"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 152,
        course_name: "コミュニケーション",
        teacher_group_id: 91,
        teachers: ["E.Cabrido"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 183,
        teachers: ["D.L.Doyle"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 738,
        teachers: ["小田 智子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1887,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1594,
        teachers: ["堀 江里香"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1547,
        teachers: ["中野 由美子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1874,
        course_name: "英語(中級)",
        teacher_group_id: 1191,
        teachers: ["丸田 孝治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1122,
        course_name: "経営組織",
        teacher_group_id: 1308,
        teachers: ["犬塚 篤"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1332,
        teachers: ["岡戸 浩子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 47,
        course_name: "中国語4",
        teacher_group_id: 980,
        teachers: ["丸尾 誠"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1976,
        course_name: "英語上級",
        teacher_group_id: 983,
        teachers: ["堀江 薫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 1022,
        teachers: ["大石 晴美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 281,
        course_name: "英語(基礎)",
        teacher_group_id: 337,
        teachers: ["WEEKS Mark"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 754,
        teachers: ["望月 節子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 675,
        teachers: ["杉村 泰"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1891,
        course_name: "会計I",
        teacher_group_id: 617,
        teachers: ["小沢 浩"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 553,
        course_name: "中級中国語2",
        teacher_group_id: 721,
        teachers: ["笠井 直美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 1257,
        teachers: ["中村 登志哉"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 3,
        teachers: ["S.McGuire"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 310,
        teachers: ["PEDDIE Francis david"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 23,
        course_name: "中国語3",
        teacher_group_id: 865,
        teachers: ["徐 春陽"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 2096,
        course_name: "ドイツ語2",
        teacher_group_id: 1061,
        teachers: ["村本 麻衣"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 581,
        course_name: "ドイツ語3",
        teacher_group_id: 1064,
        teachers: ["中川 亜紀子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 34,
        teachers: ["松下 千雅子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 321,
        teachers: ["WRIGHT David Thomas Henry"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 188,
        teachers: ["G.Rohe"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 977,
        teachers: ["松野 澄江"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 191,
        teachers: ["C.K.Shafer"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 25,
        teachers: ["TOMKINSON Fiona gail"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 305,
        teachers: ["NUSKE Kyle"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 226,
        teachers: ["八幡 耕一"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1191,
        teachers: ["丸田 孝治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 1617,
        teachers: ["山崎 雄史"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 72,
        teachers: ["Potter Simon Raymond"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 63,
        teachers: ["M.C.weeks"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 688,
        teachers: ["三輪 晃司"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 72,
        course_name: "英語(サバイバル)",
        teacher_group_id: 34,
        teachers: ["松下 千雅子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 22,
        teachers: ["HAIG EDWARD"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1887,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 405,
        teachers: ["井原"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 243,
        teachers: ["LONGCOPE Peter"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1236,
        teachers: ["林 みどり"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 470,
        teachers: ["滝沢"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1689,
        teachers: ["梶浦 眞由美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 34,
        teachers: ["松下 千雅子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 68,
        teachers: ["TOOHEY David edward"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 465,
        teachers: ["大石"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 308,
        course_name: "スペイン語2",
        teacher_group_id: 1746,
        teachers: ["関 智彦"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 169,
        teachers: ["谷本 千雅子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 890,
        teachers: ["山本 幸一"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 150,
        teachers: ["Weeks"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 682,
        teachers: ["村元 麻衣"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1888,
        course_name: "ロシア語",
        teacher_group_id: 270,
        teachers: ["Savelie"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 203,
        course_name: "英語セミナー",
        teacher_group_id: 902,
        teachers: ["涌井 隆"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 189,
        teachers: ["彩子 E ハーイ"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 203,
        course_name: "英語セミナー",
        teacher_group_id: 765,
        teachers: ["榎本 洋"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 18,
        course_name: "スペイン語1",
        teacher_group_id: 299,
        teachers: ["Mendez Guerra Carlos"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 1384,
        teachers: ["前野 みちこ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1031,
        course_name: "計量経済学II",
        teacher_group_id: 1161,
        teachers: ["為近 英恵"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1233,
        teachers: ["外池 俊幸"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 23,
        course_name: "中国語3",
        teacher_group_id: 703,
        teachers: ["盧 建"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 138,
        teachers: ["ドライデン ローレン"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1497,
        teachers: ["武田 貴子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1228,
        teachers: ["菅原 裕子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 40,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 1172,
        teachers: ["井原 伸浩"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 581,
        course_name: "ドイツ語3",
        teacher_group_id: 1625,
        teachers: ["大熊 洋行"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 193,
        teachers: ["J.Sichi"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 444,
        teachers: ["大島"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 203,
        course_name: "英語セミナー",
        teacher_group_id: 967,
        teachers: ["松岡 光治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1236,
        teachers: ["林 みどり"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 23,
        course_name: "中国語3",
        teacher_group_id: 1127,
        teachers: ["田中 千寿"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 88,
        course_name: "ロシア語1",
        teacher_group_id: 43,
        teachers: ["SAVELIEV"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 741,
        course_name: "数学入門",
        teacher_group_id: 827,
        teachers: ["西村 治道"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 64,
        course_name: "ドイツ語2",
        teacher_group_id: 1064,
        teachers: ["中川 亜紀子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 599,
        teachers: ["川本 早久良"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 2004,
        course_name: "フランス語4",
        teacher_group_id: 741,
        teachers: ["竹本 江梨"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 904,
        course_name: "スペイン語基礎2",
        teacher_group_id: 1851,
        teachers: ["齊藤 亮太"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 579,
        course_name: "英語(上級リーディング2)",
        teacher_group_id: 689,
        teachers: ["大島 義和"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1883,
        course_name: "英語(上級)",
        teacher_group_id: 677,
        teachers: ["尾関 修治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 40,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 310,
        teachers: ["PEDDIE Francis david"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 23,
        course_name: "中国語3",
        teacher_group_id: 721,
        teachers: ["笠井 直美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 132,
        teachers: ["S.Humphrey"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1945,
        course_name: "韓国語4",
        teacher_group_id: 731,
        teachers: ["黒崎 慶子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1228,
        teachers: ["菅原 裕子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 40,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 881,
        teachers: ["上原 早苗"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 90,
        course_name: "ロシア語4",
        teacher_group_id: 576,
        teachers: ["佐藤 規祥"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 123,
        teachers: ["謝 平"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 19,
        course_name: "中国語2",
        teacher_group_id: 6,
        teachers: ["甘 靖超"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 226,
        teachers: ["八幡 耕一"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 1074,
        teachers: ["越智 和弘"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 676,
        teachers: ["久米 和代"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 624,
        course_name: "韓国語3",
        teacher_group_id: 730,
        teachers: ["宇都木 昭"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 53,
        teachers: ["李 爲民"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 91,
        teachers: ["E.Cabrido"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 890,
        teachers: ["山本 幸一"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 141,
        course_name: "フランス語2",
        teacher_group_id: 277,
        teachers: ["BAUMERT"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1031,
        teachers: ["村尾 玲美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 426,
        course_name: "朝鮮・韓国語1",
        teacher_group_id: 525,
        teachers: ["李 惠敏"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 977,
        teachers: ["松野 澄江"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 807,
        teachers: ["山下 淳子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 181,
        teachers: ["松本 伊瑳子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 64,
        course_name: "ドイツ語2",
        teacher_group_id: 28,
        teachers: ["M.Koch"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 59,
        course_name: "英語(上級リーディング)",
        teacher_group_id: 902,
        teachers: ["涌井 隆"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1550,
        teachers: ["稲垣 俊史"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 689,
        teachers: ["大島 義和"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 143,
        teachers: ["ドライデン ローレンス"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 824,
        teachers: ["秋田 喜美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 2006,
        course_name: "英語(上級リーディング1)",
        teacher_group_id: 947,
        teachers: ["長畑 明利"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 262,
        course_name: "フランス語3",
        teacher_group_id: 708,
        teachers: ["奥田 智樹"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 1064,
        teachers: ["中川 亜紀子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1883,
        course_name: "英語(上級)",
        teacher_group_id: 72,
        teachers: ["Potter Simon Raymond"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 8,
        teachers: ["MORITA CHUA LIANG"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 749,
        teachers: ["水口 陽子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 264,
        course_name: "フランス語1",
        teacher_group_id: 708,
        teachers: ["奥田 智樹"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 1577,
        teachers: ["金 相美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 425,
        course_name: "朝鮮・韓国語3",
        teacher_group_id: 730,
        teachers: ["宇都木 昭"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1323,
        course_name: "基礎セミナーA",
        teacher_group_id: 1684,
        teachers: ["花房 洋"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 969,
        course_name: "文系基礎教育学",
        teacher_group_id: 581,
        teachers: ["内田 良"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1031,
        teachers: ["村尾 玲美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 81,
        teachers: ["DEACON Robert"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 652,
        course_name: "産業社会と企業",
        teacher_group_id: 751,
        teachers: ["中屋 信彦"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 1272,
        teachers: ["劉 欣"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 203,
        course_name: "英語セミナー",
        teacher_group_id: 653,
        teachers: ["木下 徹"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 81,
        teachers: ["DEACON Robert"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 40,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 13,
        teachers: ["SAYENKO Tetyana"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 141,
        course_name: "フランス語2",
        teacher_group_id: 706,
        teachers: ["鶴巻 泉子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 188,
        teachers: ["G.Rohe"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 725,
        teachers: ["玉岡 賀津雄"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 1461,
        teachers: ["須藤 勲"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1869,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 2687,
        teachers: ["POTTER"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1848,
        teachers: ["有為楠 香"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 227,
        course_name: "朝鮮語",
        teacher_group_id: 164,
        teachers: ["朴 大王"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 287,
        teachers: ["TEO Kia Choong"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 554,
        teachers: ["潘 寿君"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1799,
        course_name: "英語(上級)",
        teacher_group_id: 22,
        teachers: ["HAIG EDWARD"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 477,
        teachers: ["李"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 2161,
        course_name: "朝鮮語",
        teacher_group_id: 1359,
        teachers: ["飯田 秀敏"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 40,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 1212,
        teachers: ["小川 明子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 1091,
        teachers: ["田所 光男"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 683,
        teachers: ["三上 由香"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 1454,
        teachers: ["田所 みつを"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 305,
        teachers: ["NUSKE Kyle"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 173,
        teachers: ["M.R.Gage"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 272,
        teachers: ["李 为民"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 1594,
        teachers: ["堀 江里香"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 276,
        teachers: ["虞 平"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 2110,
        course_name: "ミクロ経済学1",
        teacher_group_id: 1130,
        teachers: ["渡邊 聡"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 68,
        teachers: ["TOOHEY David edward"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 287,
        course_name: "フランス語4",
        teacher_group_id: 706,
        teachers: ["鶴巻 泉子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1277,
        teachers: ["鈴木 繁夫"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 558,
        course_name: "初級スペイン語",
        teacher_group_id: 1004,
        teachers: ["西村 秀人"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 12,
        teachers: ["MORITA L"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 279,
        teachers: ["Harnett"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 851,
        teachers: ["三宅 恭子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 40,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 677,
        teachers: ["尾関 修治"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 559,
        course_name: "経営情報",
        teacher_group_id: 671,
        teachers: ["樋野 励"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 1366,
        teachers: ["神谷 修"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 140,
        course_name: "フランス語",
        teacher_group_id: 461,
        teachers: ["鶴巻"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1514,
        teachers: ["村上 美保子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 29,
        teachers: ["大名 力"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 2105,
        course_name: "スペイン語基礎2",
        teacher_group_id: 1097,
        teachers: ["志波 彩子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 749,
        course_name: "大学でどう学ぶか",
        teacher_group_id: 868,
        teachers: ["夏目 達也"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1476,
        teachers: ["池側 隆之"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1869,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 191,
        teachers: ["C.K.Shafer"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 37,
        teachers: ["光沢 隆"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 8,
        teachers: ["MORITA CHUA LIANG"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 471,
        teachers: ["今井田"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1838,
        course_name: "英語(中級)",
        teacher_group_id: 1402,
        teachers: ["内田 綾子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 1127,
        teachers: ["田中 千寿"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 824,
        course_name: "刑法(各論)",
        teacher_group_id: 908,
        teachers: ["古川 信彦"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 227,
        course_name: "朝鮮語",
        teacher_group_id: 562,
        teachers: ["洪 順愛"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 266,
        teachers: ["勝川"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 140,
        course_name: "フランス語",
        teacher_group_id: 455,
        teachers: ["田所"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 129,
        teachers: ["M.Marshall"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1233,
        teachers: ["外池 俊幸"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 458,
        teachers: ["谷本"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 132,
        teachers: ["S.Humphrey"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 227,
        course_name: "朝鮮語",
        teacher_group_id: 175,
        teachers: ["イ ウナ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 72,
        course_name: "英語(サバイバル)",
        teacher_group_id: 681,
        teachers: ["渡辺 美樹"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 115,
        teachers: ["朱 新健"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1408,
        teachers: ["辻 千春"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 264,
        course_name: "フランス語1",
        teacher_group_id: 1057,
        teachers: ["藤村 逸子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 1689,
        teachers: ["梶浦 眞由美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1924,
        course_name: "英語(上級リーディング)",
        teacher_group_id: 337,
        teachers: ["WEEKS Mark"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 378,
        teachers: ["辻"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 72,
        teachers: ["Potter Simon Raymond"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 14,
        teachers: ["W.M.Jones"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1464,
        teachers: ["加藤 貞道"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 1858,
        teachers: ["吉村 愛子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 904,
        course_name: "スペイン語基礎2",
        teacher_group_id: 1004,
        teachers: ["西村 秀人"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1707,
        teachers: ["古泉 隆"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 2202,
        course_name: "英語セミナー",
        teacher_group_id: 1664,
        teachers: ["長山 智香子"],
        course_group: "言語文化I",
        semester: "その他",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 729,
        teachers: ["土居 峻"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1562,
        teachers: ["岩城 奈巳"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1881,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 22,
        teachers: ["HAIG EDWARD"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 71,
        teachers: ["T.Chambers"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1116,
        teachers: ["杉浦 正利"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1377,
        teachers: ["近藤 健二"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1272,
        teachers: ["劉 欣"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 554,
        course_name: "ドイツ語4",
        teacher_group_id: 895,
        teachers: ["成田 克史"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1500,
        teachers: ["伊藤 裕子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 983,
        teachers: ["堀江 薫"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 287,
        teachers: ["TEO Kia Choong"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 250,
        teachers: ["D.S.Jarrel"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1988,
        course_name: "フランス語1",
        teacher_group_id: 706,
        teachers: ["鶴巻 泉子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 47,
        course_name: "中国語4",
        teacher_group_id: 17,
        teachers: ["勝川 裕子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 871,
        course_name: "国際会計",
        teacher_group_id: 966,
        teachers: ["柳瀬 明彦"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 1446,
        teachers: ["中嶋 忠宏"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 225,
        teachers: ["大森 ゆうじつ"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 227,
        course_name: "朝鮮語",
        teacher_group_id: 557,
        teachers: ["鄭 芝淑"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 364,
        teachers: ["David Toohey"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 290,
        teachers: ["大名"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 717,
        course_name: "文化を読む",
        teacher_group_id: 798,
        teachers: ["古尾谷 知浩"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 293,
        teachers: ["Koch"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 1561,
        teachers: ["西尾 玲美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 108,
        teachers: ["加藤"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 248,
        teachers: ["虞"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 287,
        course_name: "フランス語4",
        teacher_group_id: 708,
        teachers: ["奥田 智樹"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 88,
        course_name: "ロシア語1",
        teacher_group_id: 576,
        teachers: ["佐藤 規祥"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1932,
        course_name: "スペイン語3",
        teacher_group_id: 1510,
        teachers: ["塚原 信行"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1402,
        teachers: ["内田 綾子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1522,
        teachers: ["池田 佳子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 756,
        teachers: ["可知 れい子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 473,
        teachers: ["渡辺"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 283,
        teachers: ["Sharer"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 368,
        teachers: ["THOMAS Gareth"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 325,
        teachers: ["HAMLITSCH Nathan"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 2682,
        teachers: ["POTTER"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 839,
        teachers: ["原田 邦彦"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 1625,
        teachers: ["大熊 洋行"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 149,
        teachers: ["魯 雪娜"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 459,
        teachers: ["菅原"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 140,
        course_name: "フランス語",
        teacher_group_id: 453,
        teachers: ["越智"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 967,
        teachers: ["松岡 光治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1567,
        teachers: ["進藤 鈴子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 1116,
        teachers: ["杉浦 正利"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 18,
        course_name: "スペイン語1",
        teacher_group_id: 1155,
        teachers: ["渡辺 有美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 487,
        teachers: ["星野"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 983,
        teachers: ["堀江 薫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 59,
        course_name: "英語(上級リーディング)",
        teacher_group_id: 947,
        teachers: ["長畑 明利"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 141,
        course_name: "フランス語2",
        teacher_group_id: 1653,
        teachers: ["鳥山 定嗣"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 71,
        course_name: "スペイン語4",
        teacher_group_id: 1004,
        teachers: ["西村 秀人"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 399,
        teachers: ["金"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1560,
        course_name: "韓国語1&2",
        teacher_group_id: 730,
        teachers: ["宇都木 昭"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 71,
        course_name: "スペイン語4",
        teacher_group_id: 151,
        teachers: ["p.apaza"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 172,
        teachers: ["L.Shanahan"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1966,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 688,
        teachers: ["三輪 晃司"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1476,
        teachers: ["池側 隆之"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 350,
        course_name: "教職基礎論",
        teacher_group_id: 1322,
        teachers: ["三羽 光彦"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 68,
        teachers: ["TOOHEY David edward"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 129,
        teachers: ["M.Marshall"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 1575,
        teachers: ["福岡 麻子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 230,
        teachers: ["胡 潔"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1884,
        course_name: "スペイン語",
        teacher_group_id: 141,
        teachers: ["水戸"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 839,
        teachers: ["原田 邦彦"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 291,
        teachers: ["虞 蔽"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 140,
        course_name: "フランス語",
        teacher_group_id: 433,
        teachers: ["松本"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 263,
        teachers: ["SCHMAUS"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 55,
        teachers: ["CONRAD Marcus"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1884,
        course_name: "スペイン語",
        teacher_group_id: 1518,
        teachers: ["佐原 みどり"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 45,
        course_name: "中国語3",
        teacher_group_id: 17,
        teachers: ["勝川 裕子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 765,
        teachers: ["榎本 洋"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 40,
        course_name: "英語基礎(Pre-A)",
        teacher_group_id: 1116,
        teachers: ["杉浦 正利"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 79,
        teachers: ["姜 東局"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 137,
        teachers: ["NILEP"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 39,
        teachers: ["Nuske Kyle David"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 292,
        teachers: ["魯 雪那"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 427,
        teachers: ["吉村"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 905,
        course_name: "中級スペイン語2",
        teacher_group_id: 1004,
        teachers: ["西村 秀人"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 1597,
        teachers: ["山本 竜大"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1452,
        teachers: ["加藤 貞通"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1932,
        course_name: "スペイン語3",
        teacher_group_id: 1004,
        teachers: ["西村 秀人"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 905,
        course_name: "中級スペイン語2",
        teacher_group_id: 1344,
        teachers: ["寺澤 宏美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 18,
        course_name: "スペイン語1",
        teacher_group_id: 1190,
        teachers: ["仲町 知帆"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 581,
        course_name: "ドイツ語3",
        teacher_group_id: 690,
        teachers: ["中村 修"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 453,
        teachers: ["越智"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 19,
        teachers: ["SAYENKO"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 203,
        course_name: "英語セミナー",
        teacher_group_id: 1116,
        teachers: ["杉浦 正利"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 649,
        teachers: ["中村 靖子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1090,
        teachers: ["村尾 怜美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 1514,
        teachers: ["村上 美保子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 789,
        teachers: ["河野 哲子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 103,
        teachers: ["Joel Deacon"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 350,
        teachers: ["TROWELL Haydn Lloyd"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 191,
        teachers: ["C.K.Shafer"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1301,
        teachers: ["井上 公"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1925,
        course_name: "中級スペイン語",
        teacher_group_id: 1004,
        teachers: ["西村 秀人"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 982,
        teachers: ["井土 愼二"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 13,
        teachers: ["SAYENKO Tetyana"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 264,
        course_name: "フランス語1",
        teacher_group_id: 1653,
        teachers: ["鳥山 定嗣"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 694,
        teachers: ["坂部 晶子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 708,
        teachers: ["奥田 智樹"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1507,
        teachers: ["福田 真人"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 738,
        teachers: ["小田 智子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 275,
        teachers: ["L.Gogliotti"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 137,
        teachers: ["NILEP"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 714,
        teachers: ["森 有礼"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 321,
        teachers: ["WRIGHT David Thomas Henry"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 425,
        course_name: "朝鮮・韓国語3",
        teacher_group_id: 525,
        teachers: ["李 惠敏"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 268,
        teachers: ["gogliotti"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 445,
        teachers: ["池田"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 712,
        teachers: ["近藤 博基"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 203,
        course_name: "英語セミナー",
        teacher_group_id: 137,
        teachers: ["NILEP"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 170,
        teachers: ["大森 裕賽"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 287,
        course_name: "フランス語4",
        teacher_group_id: 1057,
        teachers: ["藤村 逸子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1099,
        teachers: ["村主 幸一"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 191,
        teachers: ["C.K.Shafer"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 281,
        teachers: ["伊里"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 18,
        teachers: ["T David"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 275,
        teachers: ["L.Gogliotti"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 1399,
        teachers: ["大庭 正春"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 34,
        teachers: ["松下 千雅子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 468,
        teachers: ["中村"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 19,
        course_name: "中国語2",
        teacher_group_id: 1408,
        teachers: ["辻 千春"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 59,
        course_name: "英語(上級リーディング)",
        teacher_group_id: 1402,
        teachers: ["内田 綾子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1905,
        course_name: "中国語4",
        teacher_group_id: 694,
        teachers: ["坂部 晶子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 1376,
        teachers: ["石井 賢治"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 1559,
        teachers: ["楊 暁史"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1505,
        teachers: ["小田原 謡子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 49,
        teachers: ["Nuske david"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 300,
        course_name: "スペイン語3",
        teacher_group_id: 333,
        teachers: ["Nelly"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 350,
        teachers: ["TROWELL Haydn Lloyd"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 1448,
        teachers: ["大橋 聡"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 106,
        course_name: "市場経済と社会",
        teacher_group_id: 56,
        teachers: ["福澤 直樹"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 449,
        teachers: ["池側"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 947,
        teachers: ["長畑 明利"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 420,
        teachers: ["丸田"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1301,
        teachers: ["井上 公"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1884,
        course_name: "スペイン語",
        teacher_group_id: 333,
        teachers: ["Nelly"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1479,
        teachers: ["宮北 惠子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 418,
        teachers: ["涌井"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 227,
        course_name: "朝鮮語",
        teacher_group_id: 555,
        teachers: ["崔 昇浩"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 69,
        course_name: "スペイン語",
        teacher_group_id: 476,
        teachers: ["西村"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 53,
        teachers: ["李 爲民"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 861,
        teachers: ["滝川 睦"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 308,
        course_name: "スペイン語2",
        teacher_group_id: 340,
        teachers: ["P.Apaza"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 221,
        teachers: ["小西 章典"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 982,
        teachers: ["井土 愼二"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 69,
        course_name: "スペイン語",
        teacher_group_id: 1510,
        teachers: ["塚原 信行"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 71,
        course_name: "スペイン語4",
        teacher_group_id: 670,
        teachers: ["水戸 博之"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 34,
        teachers: ["松下 千雅子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1976,
        course_name: "英語上級",
        teacher_group_id: 902,
        teachers: ["涌井 隆"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 1579,
        teachers: ["盧 健"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 19,
        course_name: "中国語2",
        teacher_group_id: 1066,
        teachers: ["陳 朝輝"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 807,
        teachers: ["山下 淳子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1888,
        course_name: "ロシア語",
        teacher_group_id: 479,
        teachers: ["柳沢"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1976,
        course_name: "英語上級",
        teacher_group_id: 1577,
        teachers: ["金 相美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 765,
        teachers: ["榎本 洋"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1422,
        teachers: ["加藤 忠通"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 229,
        teachers: ["High"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 282,
        teachers: ["廬 建"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 947,
        teachers: ["長畑 明利"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 106,
        course_name: "市場経済と社会",
        teacher_group_id: 803,
        teachers: ["園田 正"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1875,
        course_name: "英語(中級)",
        teacher_group_id: 1664,
        teachers: ["長山 智香子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 983,
        teachers: ["堀江 薫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1277,
        teachers: ["鈴木 繁夫"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 268,
        teachers: ["gogliotti"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1799,
        course_name: "英語(上級)",
        teacher_group_id: 1172,
        teachers: ["井原 伸浩"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 20,
        course_name: "中国語1",
        teacher_group_id: 694,
        teachers: ["坂部 晶子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 321,
        teachers: ["WRIGHT David Thomas Henry"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 24,
        course_name: "ミクロ経済学1",
        teacher_group_id: 110,
        teachers: ["荒山 祐行"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 203,
        course_name: "英語セミナー",
        teacher_group_id: 405,
        teachers: ["井原"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 29,
        teachers: ["大名 力"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1357,
        teachers: ["久野 陽一"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 193,
        teachers: ["J.Sichi"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 2326,
        course_name: "朝鮮・韓国語1",
        teacher_group_id: 731,
        teachers: ["黒崎 慶子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 34,
        teachers: ["松下 千雅子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1301,
        teachers: ["井上 公"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 47,
        teachers: ["CROSS Jeremy david"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1865,
        course_name: "中国語2",
        teacher_group_id: 698,
        teachers: ["加納 光"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 264,
        teachers: ["J"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1172,
        teachers: ["井原 伸浩"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 897,
        course_name: "スペイン語2",
        teacher_group_id: 995,
        teachers: ["宮下 克子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1799,
        course_name: "英語(上級)",
        teacher_group_id: 29,
        teachers: ["大名 力"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 59,
        course_name: "英語(上級リーディング)",
        teacher_group_id: 25,
        teachers: ["TOMKINSON Fiona gail"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 735,
        teachers: ["田中 智之"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 171,
        teachers: ["v.Heuduck"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 430,
        teachers: ["鈴木"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 902,
        teachers: ["涌井 隆"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 152,
        teachers: ["楊 暁文"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 1223,
        teachers: ["高畑 祐人"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1116,
        teachers: ["杉浦 正利"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 184,
        course_name: "ドイツ語1",
        teacher_group_id: 1223,
        teachers: ["高畑 祐人"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 2622,
        teachers: ["篋絮"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1976,
        course_name: "英語上級",
        teacher_group_id: 950,
        teachers: ["加藤 高志"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 424,
        course_name: "韓国語4",
        teacher_group_id: 525,
        teachers: ["李 惠敏"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1976,
        course_name: "英語上級",
        teacher_group_id: 1172,
        teachers: ["井原 伸浩"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1887,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 37,
        teachers: ["光沢 隆"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1116,
        teachers: ["杉浦 正利"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 947,
        teachers: ["長畑 明利"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 399,
        teachers: ["金"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1417,
        teachers: ["榎本 喜夫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 287,
        course_name: "フランス語4",
        teacher_group_id: 344,
        teachers: ["ガラべ"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 967,
        teachers: ["松岡 光治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1925,
        course_name: "中級スペイン語",
        teacher_group_id: 340,
        teachers: ["P.Apaza"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 581,
        course_name: "ドイツ語3",
        teacher_group_id: 1061,
        teachers: ["村本 麻衣"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 1061,
        teachers: ["村本 麻衣"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 37,
        teachers: ["光沢 隆"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1905,
        course_name: "中国語4",
        teacher_group_id: 152,
        teachers: ["楊 暁文"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 23,
        course_name: "中国語3",
        teacher_group_id: 152,
        teachers: ["楊 暁文"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 865,
        teachers: ["徐 春陽"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 452,
        teachers: ["宮北"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1879,
        course_name: "英語(上級)",
        teacher_group_id: 19,
        teachers: ["SAYENKO"],
        course_group: "言語文化I",
        semester: "不明",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 703,
        teachers: ["盧 建"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 573,
        course_name: "英語(上級)",
        teacher_group_id: 681,
        teachers: ["渡辺 美樹"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1873,
        course_name: "英語(セミナー)",
        teacher_group_id: 1172,
        teachers: ["井原 伸浩"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 10,
        course_name: "法と政治の思想",
        teacher_group_id: 1189,
        teachers: ["大屋 雄祐"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1549,
        teachers: ["本田 安都子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1883,
        course_name: "英語(上級)",
        teacher_group_id: 63,
        teachers: ["M.C.weeks"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 277,
        course_name: "英語(中級)",
        teacher_group_id: 29,
        teachers: ["大名 力"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 486,
        teachers: ["森"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 255,
        teachers: ["新井"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1884,
        course_name: "スペイン語",
        teacher_group_id: 670,
        teachers: ["水戸 博之"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 681,
        teachers: ["渡辺 美樹"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 690,
        teachers: ["中村 修"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1976,
        course_name: "英語上級",
        teacher_group_id: 681,
        teachers: ["渡辺 美樹"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 141,
        course_name: "フランス語2",
        teacher_group_id: 915,
        teachers: ["新井 美佐子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1899,
        course_name: "線形回路論",
        teacher_group_id: 1132,
        teachers: ["加藤 剛志"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 508,
        course_name: "情報と職業1",
        teacher_group_id: 640,
        teachers: ["山本 達大"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 187,
        teachers: ["鵜殿 倫次"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 23,
        course_name: "中国語3",
        teacher_group_id: 1066,
        teachers: ["陳 朝輝"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 592,
        course_name: "金融",
        teacher_group_id: 1147,
        teachers: ["家森 信善"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 466,
        teachers: ["長畑"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 140,
        course_name: "フランス語",
        teacher_group_id: 1057,
        teachers: ["藤村 逸子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 747,
        course_name: "微分積分学I",
        teacher_group_id: 833,
        teachers: ["小林 亮一"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1883,
        course_name: "英語(上級)",
        teacher_group_id: 1317,
        teachers: ["布施 哲"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1932,
        course_name: "スペイン語3",
        teacher_group_id: 485,
        teachers: ["寺澤"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1976,
        course_name: "英語上級",
        teacher_group_id: 1848,
        teachers: ["有為楠 香"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1402,
        teachers: ["内田 綾子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 64,
        course_name: "ドイツ語2",
        teacher_group_id: 1223,
        teachers: ["高畑 祐人"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 69,
        course_name: "スペイン語",
        teacher_group_id: 1344,
        teachers: ["寺澤 宏美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 581,
        course_name: "ドイツ語3",
        teacher_group_id: 1223,
        teachers: ["高畑 祐人"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 1074,
        teachers: ["越智 和弘"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 34,
        teachers: ["松下 千雅子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 140,
        course_name: "フランス語",
        teacher_group_id: 83,
        teachers: ["DUCROS GARANCE"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 950,
        teachers: ["加藤 高志"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 47,
        teachers: ["CROSS Jeremy david"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 432,
        teachers: ["松岡"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 59,
        course_name: "英語(上級リーディング)",
        teacher_group_id: 983,
        teachers: ["堀江 薫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 698,
        teachers: ["加納 光"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 281,
        course_name: "英語(基礎)",
        teacher_group_id: 729,
        teachers: ["土居 峻"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 223,
        teachers: ["光沢"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 1373,
        teachers: ["大室 剛志"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 22,
        teachers: ["HAIG EDWARD"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 750,
        course_name: "無機化学序論",
        teacher_group_id: 838,
        teachers: ["長田 実"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1108,
        teachers: ["松原 緑"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1933,
        course_name: "法学",
        teacher_group_id: 852,
        teachers: ["原田 綾子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 69,
        course_name: "スペイン語",
        teacher_group_id: 1004,
        teachers: ["西村 秀人"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 423,
        teachers: ["楊"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1884,
        course_name: "スペイン語",
        teacher_group_id: 1152,
        teachers: ["川田 玲子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 950,
        teachers: ["加藤 高志"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1884,
        course_name: "スペイン語",
        teacher_group_id: 1379,
        teachers: ["二村 久則"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 1212,
        teachers: ["小川 明子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 431,
        teachers: ["杉浦"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1233,
        teachers: ["外池 俊幸"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 765,
        teachers: ["榎本 洋"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 489,
        teachers: ["村主"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 457,
        teachers: ["外池"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1022,
        teachers: ["大石 晴美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1597,
        teachers: ["山本 竜大"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 902,
        teachers: ["涌井 隆"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1577,
        teachers: ["金 相美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 983,
        teachers: ["堀江 薫"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 29,
        teachers: ["大名 力"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1099,
        teachers: ["村主 幸一"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 1198,
        teachers: ["櫻井 龍彦"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 262,
        course_name: "フランス語3",
        teacher_group_id: 915,
        teachers: ["新井 美佐子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 563,
        teachers: ["大森 裕實"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 902,
        teachers: ["涌井 隆"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 235,
        teachers: ["大森 貫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 30,
        course_name: "英語(上級)",
        teacher_group_id: 902,
        teachers: ["涌井 隆"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 234,
        teachers: ["大森"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 983,
        teachers: ["堀江 薫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 902,
        teachers: ["涌井 隆"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1877,
        course_name: "中国語",
        teacher_group_id: 237,
        teachers: ["成戸"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1373,
        teachers: ["大室 剛志"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 1399,
        teachers: ["大庭 正春"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 37,
        teachers: ["光沢 隆"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 174,
        teachers: ["伊里 松俊"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1912,
        course_name: "中国語",
        teacher_group_id: 197,
        teachers: ["董 紅俊"],
        course_group: "言語文化I",
        semester: "その他",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1386,
        teachers: ["安藤 重治"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1932,
        course_name: "スペイン語3",
        teacher_group_id: 1344,
        teachers: ["寺澤 宏美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1862,
        course_name: "英語(基礎)",
        teacher_group_id: 1577,
        teachers: ["金 相美"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 1577,
        teachers: ["金 相美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1396,
        teachers: ["吉村 正和"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 15,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 681,
        teachers: ["渡辺 美樹"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1887,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 738,
        teachers: ["小田 智子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 1501,
        teachers: ["柴田 庄一"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 947,
        teachers: ["長畑 明利"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 14,
        course_name: "英語",
        teacher_group_id: 1277,
        teachers: ["鈴木 繁夫"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 947,
        teachers: ["長畑 明利"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 287,
        course_name: "フランス語4",
        teacher_group_id: 915,
        teachers: ["新井 美佐子"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 11,
        course_name: "英語(基礎)",
        teacher_group_id: 861,
        teachers: ["滝川 睦"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 37,
        teachers: ["光沢 隆"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 182,
        teachers: ["成戸 浩嗣"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 681,
        teachers: ["渡辺 美樹"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 915,
        teachers: ["新井 美佐子"],
        course_group: "言語文化I",
        semester: "前期",
      },
      {
        course_id: 1870,
        course_name: "英語",
        teacher_group_id: 1577,
        teachers: ["金 相美"],
        course_group: "言語文化I",
        semester: "後期",
      },
      {
        course_id: 38,
        course_name: "中国語",
        teacher_group_id: 152,
        teachers: ["楊 暁文"],
        course_group: "言語文化I",
        semester: "後期",
      },
    ],
    言語文化II: [
      {
        course_id: 2191,
        course_name: "特別英語セミナー",
        teacher_group_id: 1562,
        teachers: ["岩城 奈巳"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 83,
        course_name: "特別英語セミナー",
        teacher_group_id: 39,
        teachers: ["Nuske Kyle David"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 49,
        course_name: "中国語1",
        teacher_group_id: 17,
        teachers: ["勝川 裕子"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 219,
        course_name: "中級スペイン語2",
        teacher_group_id: 155,
        teachers: ["C.Rojas"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 1902,
        course_name: "中国語2",
        teacher_group_id: 115,
        teachers: ["朱 新健"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 553,
        course_name: "中級中国語2",
        teacher_group_id: 667,
        teachers: ["前田 光子"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 45,
        course_name: "中国語3",
        teacher_group_id: 675,
        teachers: ["杉村 泰"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 1882,
        course_name: "ドイツ語",
        teacher_group_id: 777,
        teachers: ["松井 隆幸"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 414,
        course_name: "中級ドイツ語1",
        teacher_group_id: 1064,
        teachers: ["中川 亜紀子"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 23,
        course_name: "中国語3",
        teacher_group_id: 800,
        teachers: ["星野 幸代"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 69,
        course_name: "スペイン語",
        teacher_group_id: 33,
        teachers: ["加納 マルタ"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 65,
        course_name: "ドイツ語",
        teacher_group_id: 690,
        teachers: ["中村 修"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 121,
        course_name: "英語(セミナー)",
        teacher_group_id: 68,
        teachers: ["TOOHEY David edward"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 977,
        teachers: ["松野 澄江"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 896,
        course_name: "初級スペイン語1",
        teacher_group_id: 995,
        teachers: ["宮下 克子"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 46,
        course_name: "中級中国語2",
        teacher_group_id: 698,
        teachers: ["加納 光"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 37,
        course_name: "中級中国語1",
        teacher_group_id: 721,
        teachers: ["笠井 直美"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 12,
        course_name: "英語(中級)",
        teacher_group_id: 1317,
        teachers: ["布施 哲"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 1908,
        course_name: "フランス語",
        teacher_group_id: 181,
        teachers: ["松本 伊瑳子"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 21,
        course_name: "中級中国語1",
        teacher_group_id: 800,
        teachers: ["星野 幸代"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 37,
        course_name: "中級中国語1",
        teacher_group_id: 972,
        teachers: ["寺澤 知美"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 413,
        course_name: "ドイツ語4",
        teacher_group_id: 511,
        teachers: ["大塚 直"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 414,
        course_name: "中級ドイツ語1",
        teacher_group_id: 1202,
        teachers: ["鶴田 涼子"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 45,
        course_name: "中国語3",
        teacher_group_id: 17,
        teachers: ["勝川 裕子"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 37,
        course_name: "中級中国語1",
        teacher_group_id: 675,
        teachers: ["杉村 泰"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 46,
        course_name: "中級中国語2",
        teacher_group_id: 1127,
        teachers: ["田中 千寿"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 121,
        course_name: "英語(セミナー)",
        teacher_group_id: 1664,
        teachers: ["長山 智香子"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 46,
        course_name: "中級中国語2",
        teacher_group_id: 865,
        teachers: ["徐 春陽"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 46,
        course_name: "中級中国語2",
        teacher_group_id: 1198,
        teachers: ["櫻井 龍彦"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 414,
        course_name: "中級ドイツ語1",
        teacher_group_id: 851,
        teachers: ["三宅 恭子"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 121,
        course_name: "英語(セミナー)",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 37,
        course_name: "中級中国語1",
        teacher_group_id: 867,
        teachers: ["寺澤 和美"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 897,
        course_name: "スペイン語2",
        teacher_group_id: 995,
        teachers: ["宮下 克子"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 414,
        course_name: "中級ドイツ語1",
        teacher_group_id: 511,
        teachers: ["大塚 直"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 656,
        course_name: "英語(コミュニケーション)",
        teacher_group_id: 756,
        teachers: ["可知 れい子"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 414,
        course_name: "中級ドイツ語1",
        teacher_group_id: 1257,
        teachers: ["中村 登志哉"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 37,
        course_name: "中級中国語1",
        teacher_group_id: 11,
        teachers: ["虞 萍"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 37,
        course_name: "中級中国語1",
        teacher_group_id: 887,
        teachers: ["田村 加代子"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 98,
        course_name: "中国語2",
        teacher_group_id: 53,
        teachers: ["李 爲民"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 46,
        course_name: "中級中国語2",
        teacher_group_id: 17,
        teachers: ["勝川 裕子"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 151,
        course_name: "中級スペイン語1",
        teacher_group_id: 90,
        teachers: ["M.Ito"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 46,
        course_name: "中級中国語2",
        teacher_group_id: 703,
        teachers: ["盧 建"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 46,
        course_name: "中級中国語2",
        teacher_group_id: 53,
        teachers: ["李 爲民"],
        course_group: "言語文化II",
        semester: "後期",
      },
      {
        course_id: 604,
        course_name: "中級フランス語1",
        teacher_group_id: 706,
        teachers: ["鶴巻 泉子"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 37,
        course_name: "中級中国語1",
        teacher_group_id: 694,
        teachers: ["坂部 晶子"],
        course_group: "言語文化II",
        semester: "前期",
      },
      {
        course_id: 37,
        course_name: "中級中国語1",
        teacher_group_id: 1272,
        teachers: ["劉 欣"],
        course_group: "言語文化II",
        semester: "前期",
      },
    ],
    自然系基礎科目: [
      {
        course_id: 148,
        course_name: "微分積分学I",
        teacher_group_id: 1,
        teachers: ["糸 健太郎"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2194,
        course_name: "地球科学基礎I",
        teacher_group_id: 1591,
        teachers: ["日高 洋"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 32,
        course_name: "線形代数学II",
        teacher_group_id: 882,
        teachers: ["柳浦 睦憲"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 772,
        course_name: "物理学基礎II",
        teacher_group_id: 1053,
        teachers: ["中村 光廣"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 882,
        teachers: ["柳浦 睦憲"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1836,
        course_name: "芸術論B",
        teacher_group_id: 775,
        teachers: ["秋庭 史典"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 341,
        course_name: "線形代数学I",
        teacher_group_id: 1165,
        teachers: ["永尾 太郎"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 899,
        teachers: ["天野 浩"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1111,
        course_name: "生物学基礎I",
        teacher_group_id: 2667,
        teachers: ["成田 哲博", "井原 邦夫", "荒木 聡彦"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 341,
        course_name: "線形代数学I",
        teacher_group_id: 377,
        teachers: ["LE GALL Francois Pierre Marcel"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1850,
        course_name: "電磁気学I",
        teacher_group_id: 750,
        teachers: ["黒澤 昌志"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 338,
        course_name: "微分積分学II",
        teacher_group_id: 126,
        teachers: ["金銅 誠之"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 267,
        teachers: ["張 紹良"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 341,
        course_name: "線形代数学I",
        teacher_group_id: 1826,
        teachers: ["真島 一成"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 328,
        course_name: "物理学基礎I",
        teacher_group_id: 365,
        teachers: ["川﨑 猛史"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 87,
        teachers: ["BUSCEMI Francesco"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 640,
        course_name: "化学基礎II",
        teacher_group_id: 1024,
        teachers: ["野村 信嘉"],
        course_group: "自然系基礎科目",
        semester: "その他",
      },
      {
        course_id: 1252,
        course_name: "化学基礎I",
        teacher_group_id: 1948,
        teachers: ["松下 未知雄"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1143,
        course_name: "力学II",
        teacher_group_id: 1846,
        teachers: ["判治 剛"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 338,
        course_name: "微分積分学II",
        teacher_group_id: 1752,
        teachers: ["椿野 大輔"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 148,
        course_name: "微分積分学I",
        teacher_group_id: 1745,
        teachers: ["小野 廣隆"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1794,
        course_name: "電磁気学II",
        teacher_group_id: 1708,
        teachers: ["清水 裕彦"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 860,
        teachers: ["谷川 好男"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1252,
        course_name: "化学基礎I",
        teacher_group_id: 1533,
        teachers: ["福島 和彦"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2200,
        course_name: "物理学実験",
        teacher_group_id: 1659,
        teachers: ["森 洋二郎"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 772,
        course_name: "物理学基礎II",
        teacher_group_id: 1283,
        teachers: ["谷村 省吾"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1111,
        course_name: "生物学基礎I",
        teacher_group_id: 1808,
        teachers: ["荒木 聡彦"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 148,
        course_name: "微分積分学I",
        teacher_group_id: 2666,
        teachers: ["高橋 雄也"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 406,
        course_name: "化学基礎II",
        teacher_group_id: 1842,
        teachers: ["亀山 達矢"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 338,
        course_name: "微分積分学II",
        teacher_group_id: 1845,
        teachers: ["高橋 康史"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 338,
        course_name: "微分積分学II",
        teacher_group_id: 1745,
        teachers: ["小野 廣隆"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 406,
        course_name: "化学基礎II",
        teacher_group_id: 1832,
        teachers: ["布施 新一郎"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1143,
        course_name: "力学II",
        teacher_group_id: 1338,
        teachers: ["水野 幸治"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 789,
        course_name: "力学I",
        teacher_group_id: 872,
        teachers: ["小山 剛史"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1111,
        course_name: "生物学基礎I",
        teacher_group_id: 1291,
        teachers: ["阿部 秀樹"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 148,
        course_name: "微分積分学I",
        teacher_group_id: 1796,
        teachers: ["小見山 尚"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1327,
        course_name: "生物学実験",
        teacher_group_id: 1688,
        teachers: ["高橋 宏和"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 233,
        course_name: "物理学実験",
        teacher_group_id: 658,
        teachers: ["中村 泰之"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1833,
        course_name: "線形代数I",
        teacher_group_id: 1738,
        teachers: ["加納 成男"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 229,
        course_name: "化学実験",
        teacher_group_id: 1758,
        teachers: ["村井 征史"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1336,
        course_name: "力学II",
        teacher_group_id: 1703,
        teachers: ["松山 智至"],
        course_group: "自然系基礎科目",
        semester: "その他",
      },
      {
        course_id: 406,
        course_name: "化学基礎II",
        teacher_group_id: 505,
        teachers: ["多喜 正泰"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1785,
        course_name: "物理学基礎",
        teacher_group_id: 1981,
        teachers: ["久野 純冶"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1738,
        teachers: ["加納 成男"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 9,
        teachers: ["福塚 友和"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 328,
        course_name: "物理学基礎I",
        teacher_group_id: 1679,
        teachers: ["時田 恵一郎"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 148,
        course_name: "微分積分学I",
        teacher_group_id: 1225,
        teachers: ["林 孝宏"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 650,
        course_name: "電磁気学I",
        teacher_group_id: 922,
        teachers: ["小林 義明"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 830,
        course_name: "化学実験",
        teacher_group_id: 917,
        teachers: ["長尾 征洋"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 338,
        course_name: "微分積分学II",
        teacher_group_id: 2679,
        teachers: ["松岡 健", "椿野 大輔"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 830,
        course_name: "化学実験",
        teacher_group_id: 1107,
        teachers: ["饗場 浩文"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1794,
        course_name: "電磁気学II",
        teacher_group_id: 578,
        teachers: ["長尾 全寛"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1271,
        course_name: "地球科学基礎I",
        teacher_group_id: 1554,
        teachers: ["渡邊 誠一郎"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 731,
        course_name: "化学基礎II",
        teacher_group_id: 742,
        teachers: ["松宮 弘明"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 406,
        course_name: "化学基礎II",
        teacher_group_id: 1865,
        teachers: ["戸谷 義明"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
      {
        course_id: 328,
        course_name: "物理学基礎I",
        teacher_group_id: 1696,
        teachers: ["山崎 真理子"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 536,
        course_name: "物理学実験",
        teacher_group_id: 23,
        teachers: ["槇 亙介"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1565,
        teachers: ["長岡 正隆"],
        course_group: "自然系基礎科目",
        semester: "前期",
      },
      {
        course_id: 637,
        course_name: "生物学基礎II",
        teacher_group_id: 748,
        teachers: ["一柳 健司"],
        course_group: "自然系基礎科目",
        semester: "後期",
      },
    ],
    理系教養科目: [
      {
        course_id: 2104,
        course_name: "物質世界の認識",
        teacher_group_id: 1096,
        teachers: ["樫田 啓"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2187,
        course_name: "図情報とコンピュータ",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 313,
        course_name: "情報リテラシー(理系)",
        teacher_group_id: 921,
        teachers: ["森崎 修司"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2118,
        course_name: "情報科学入門",
        teacher_group_id: 1156,
        teachers: ["長尾 確"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 615,
        course_name: "大気水圏環境の科学",
        teacher_group_id: 1146,
        teachers: ["角皆 潤"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 929,
        course_name: "地球惑星の科学",
        teacher_group_id: 1032,
        teachers: ["山中 佳子"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 313,
        course_name: "情報リテラシー(理系)",
        teacher_group_id: 1705,
        teachers: ["出口 大輔"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2087,
        course_name: "エネルギーと環境",
        teacher_group_id: 1018,
        teachers: ["山澤 弘実"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 715,
        course_name: "現代医療と生命科学",
        teacher_group_id: 1568,
        teachers: ["松田 直之"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 170,
        course_name: "自然環境と人間",
        teacher_group_id: 896,
        teachers: ["豊國 伸哉"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 499,
        course_name: "食と農の科学",
        teacher_group_id: 636,
        teachers: ["土井 一行", "村井 篤嗣"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 715,
        course_name: "現代医療と生命科学",
        teacher_group_id: 797,
        teachers: ["曾根 三千彦"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 615,
        course_name: "大気水圏環境の科学",
        teacher_group_id: 722,
        teachers: ["長田 和雄"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 191,
        course_name: "物質と材料の科学",
        teacher_group_id: 632,
        teachers: ["小澤 正邦", "元廣 友美"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 929,
        course_name: "地球惑星の科学",
        teacher_group_id: 1206,
        teachers: ["林 誠司"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 594,
        course_name: "現代数学の流れ",
        teacher_group_id: 856,
        teachers: ["南 和彦"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 324,
        course_name: "環境問題と人間",
        teacher_group_id: 1639,
        teachers: ["小川 一治"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 615,
        course_name: "大気水圏環境の科学",
        teacher_group_id: 1440,
        teachers: ["田上 英一郎"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 715,
        course_name: "現代医療と生命科学",
        teacher_group_id: 1120,
        teachers: ["八木 哲也"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 313,
        course_name: "情報リテラシー(理系)",
        teacher_group_id: 924,
        teachers: ["枝廣 正人"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2158,
        course_name: "電磁気学",
        teacher_group_id: 1349,
        teachers: ["宮崎 誠一"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 940,
        course_name: "システム工学",
        teacher_group_id: 1047,
        teachers: ["橋爪 進"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1949,
        course_name: "生涯健康と医学",
        teacher_group_id: 538,
        teachers: ["後藤 百万"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1949,
        course_name: "生涯健康と医学",
        teacher_group_id: 1015,
        teachers: ["小寺 泰弘"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1487,
        course_name: "複素関数論",
        teacher_group_id: 258,
        teachers: ["川平 友規"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 170,
        course_name: "自然環境と人間",
        teacher_group_id: 1674,
        teachers: ["山中 宏二"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2107,
        course_name: "物理現象の科学",
        teacher_group_id: 1117,
        teachers: ["荒井 政大"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 715,
        course_name: "現代医療と生命科学",
        teacher_group_id: 962,
        teachers: ["安藤 雄一"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 715,
        course_name: "現代医療と生命科学",
        teacher_group_id: 2544,
        teachers: ["植村 和正"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 626,
        course_name: "物質世界の認識",
        teacher_group_id: 733,
        teachers: ["関 隆広"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 715,
        course_name: "現代医療と生命科学",
        teacher_group_id: 1183,
        teachers: ["横井 香平"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 593,
        course_name: "現代数学への流れ",
        teacher_group_id: 1266,
        teachers: ["太田 啓史"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 345,
        course_name: "都市と環境",
        teacher_group_id: 1580,
        teachers: ["飯塚 悟"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 454,
        course_name: "遺伝子",
        teacher_group_id: 388,
        teachers: ["水野"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 715,
        course_name: "現代医療と生命科学",
        teacher_group_id: 1952,
        teachers: ["大磯 ユタカ"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 2209,
        course_name: "動植物の科学",
        teacher_group_id: 1761,
        teachers: ["井上 直子"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 615,
        course_name: "大気水圏環境の科学",
        teacher_group_id: 1828,
        teachers: ["坪木 和久"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 984,
        course_name: "微生物の科学",
        teacher_group_id: 1107,
        teachers: ["饗場 浩文"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 191,
        course_name: "物質と材料の科学",
        teacher_group_id: 127,
        teachers: ["宇佐美 徳隆", "松宮 弘明", "元廣 友美"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 167,
        course_name: "動植物の科学",
        teacher_group_id: 106,
        teachers: ["谷口 光隆", "本堂 栄一"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 2166,
        course_name: "都市と環境",
        teacher_group_id: 1419,
        teachers: ["小松 尚"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1949,
        course_name: "生涯健康と医学",
        teacher_group_id: 1831,
        teachers: ["勝野 雅央"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 715,
        course_name: "現代医療と生命科学",
        teacher_group_id: 1007,
        teachers: ["碓氷 章彦"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 2087,
        course_name: "エネルギーと環境",
        teacher_group_id: 1235,
        teachers: ["山本 章夫"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 280,
        course_name: "システム工学入門",
        teacher_group_id: 1177,
        teachers: ["櫻井 淳平"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 614,
        course_name: "エネルギーと環境",
        teacher_group_id: 911,
        teachers: ["鳴瀧 彩絵"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 170,
        course_name: "自然環境と人間",
        teacher_group_id: 1103,
        teachers: ["山中 章弘"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 380,
        course_name: "産業社会と企業",
        teacher_group_id: 441,
        teachers: ["涌田"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1036,
        course_name: "パターン認識及び演習",
        teacher_group_id: 1173,
        teachers: ["加藤 ジェーン"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1198,
        course_name: "現代数学",
        teacher_group_id: 583,
        teachers: ["大平 徹"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 603,
        course_name: "物性とエレクトロニクス",
        teacher_group_id: 705,
        teachers: ["堀 勝"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 715,
        course_name: "現代医療と生命科学",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 2047,
        course_name: "現代数学への流れ",
        teacher_group_id: 1063,
        teachers: ["鈴木 浩志"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 170,
        course_name: "自然環境と人間",
        teacher_group_id: 755,
        teachers: ["竹本 さやか"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1615,
        course_name: "博物館概論",
        teacher_group_id: 1124,
        teachers: ["新美 倫子"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1418,
        course_name: "原子・分子の科学",
        teacher_group_id: 1849,
        teachers: ["中谷 肇"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2047,
        course_name: "現代数学への流れ",
        teacher_group_id: 856,
        teachers: ["南 和彦"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1949,
        course_name: "生涯健康と医学",
        teacher_group_id: 1671,
        teachers: ["高橋 義行"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 280,
        course_name: "システム工学入門",
        teacher_group_id: 1333,
        teachers: ["稲垣 伸吉"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1850,
        course_name: "電磁気学I",
        teacher_group_id: 750,
        teachers: ["黒澤 昌志"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 315,
        course_name: "化学基礎I",
        teacher_group_id: 351,
        teachers: ["内山 峰人"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1252,
        course_name: "化学基礎I",
        teacher_group_id: 1533,
        teachers: ["福島 和彦"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1847,
        course_name: "微分積分学II",
        teacher_group_id: 1220,
        teachers: ["森本 宏"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2015,
        course_name: "システム工学入門",
        teacher_group_id: 788,
        teachers: ["川尻 喜章"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 324,
        course_name: "環境問題と人間",
        teacher_group_id: 358,
        teachers: ["岩永 青史"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 933,
        course_name: "情報メディアとコミュニケーション",
        teacher_group_id: 1040,
        teachers: ["安田 孝美"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 615,
        course_name: "大気水圏環境の科学",
        teacher_group_id: 1560,
        teachers: ["中塚 武"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 170,
        course_name: "自然環境と人間",
        teacher_group_id: 242,
        teachers: ["大野"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 615,
        course_name: "大気水圏環境の科学",
        teacher_group_id: 1164,
        teachers: ["檜山 哲哉"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 635,
        course_name: "自然環境の人間",
        teacher_group_id: 737,
        teachers: ["加藤 昌志"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 170,
        course_name: "自然環境と人間",
        teacher_group_id: 1560,
        teachers: ["中塚 武"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 271,
        course_name: "生涯健康と医学",
        teacher_group_id: 1980,
        teachers: ["亀井"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1577,
        course_name: "情報リテラシー",
        teacher_group_id: 2615,
        teachers: ["宮尾 克"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 591,
        teachers: ["宇澤 達"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 666,
        course_name: "地球科学基礎I",
        teacher_group_id: 764,
        teachers: ["永峰 康一郎"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 324,
        course_name: "環境問題と人間",
        teacher_group_id: 548,
        teachers: ["束村", "山田"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 108,
        course_name: "バイオテクノロジー",
        teacher_group_id: 59,
        teachers: ["岩崎 雄吾"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 626,
        course_name: "物質世界の認識",
        teacher_group_id: 1822,
        teachers: ["北 英紀"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 412,
        course_name: "健康増進科学",
        teacher_group_id: 510,
        teachers: ["島本 佳寿広"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1198,
        course_name: "現代数学",
        teacher_group_id: 1466,
        teachers: ["浪川 幸彦"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 255,
        course_name: "先端材料と物性物理",
        teacher_group_id: 240,
        teachers: ["齋藤 弥八"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 901,
        course_name: "宇宙科学",
        teacher_group_id: 1001,
        teachers: ["伊藤 好孝"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2192,
        course_name: "環境問題と人間",
        teacher_group_id: 1756,
        teachers: ["青木 弾"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 271,
        course_name: "生涯健康と医学",
        teacher_group_id: 2594,
        teachers: ["亀井 讓"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 355,
        course_name: "遺伝子の世界",
        teacher_group_id: 1268,
        teachers: ["小俣 達男"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2047,
        course_name: "現代数学への流れ",
        teacher_group_id: 1410,
        teachers: ["菅野 浩明"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 170,
        course_name: "自然環境と人間",
        teacher_group_id: 1976,
        teachers: ["高橋 隆"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1504,
        course_name: "物理現象の化学",
        teacher_group_id: 1994,
        teachers: ["杜本 英二"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 2033,
        course_name: "情報リテラシー(理系)",
        teacher_group_id: 1596,
        teachers: ["松原 豊"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 412,
        course_name: "健康増進科学",
        teacher_group_id: 1830,
        teachers: ["浅野 みどり"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1186,
        course_name: "微生物",
        teacher_group_id: 1442,
        teachers: ["藤田 祐一"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 614,
        course_name: "エネルギーと環境",
        teacher_group_id: 1305,
        teachers: ["八木 伸也"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 280,
        course_name: "システム工学入門",
        teacher_group_id: 309,
        teachers: ["髙木 賢太郎"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 989,
        course_name: "食と農の科学",
        teacher_group_id: 1119,
        teachers: ["白武 勝裕"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2015,
        course_name: "システム工学入門",
        teacher_group_id: 835,
        teachers: ["佐宗 章弘"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 474,
        course_name: "自然環境と人間社会",
        teacher_group_id: 597,
        teachers: ["山本 一清"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 405,
        course_name: "微分積分学II",
        teacher_group_id: 504,
        teachers: ["山下 太郎"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1849,
        course_name: "線形代数学II",
        teacher_group_id: 752,
        teachers: ["曽我部 知広"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2033,
        course_name: "情報リテラシー(理系)",
        teacher_group_id: 836,
        teachers: ["畔上 秀幸"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 345,
        course_name: "都市と環境",
        teacher_group_id: 666,
        teachers: ["斉藤 輝幸"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2046,
        course_name: "現代数学の流れ",
        teacher_group_id: 1039,
        teachers: ["白水 徹也"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 191,
        course_name: "物質と材料の科学",
        teacher_group_id: 753,
        teachers: ["元廣 友美"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 649,
        course_name: "電磁気学I",
        teacher_group_id: 774,
        teachers: ["佐藤 憲昭"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1949,
        course_name: "生涯健康と医学",
        teacher_group_id: 1060,
        teachers: ["清井 仁"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 271,
        course_name: "生涯健康と医学",
        teacher_group_id: 296,
        teachers: ["平田 仁"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 345,
        course_name: "都市と環境",
        teacher_group_id: 2410,
        teachers: ["片木 篤"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1508,
        course_name: "情報リテラシー",
        teacher_group_id: 836,
        teachers: ["畔上 秀幸"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 2104,
        course_name: "物質世界の認識",
        teacher_group_id: 1326,
        teachers: ["浅沼 浩之"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1696,
        course_name: "環境問題と人間(文系)",
        teacher_group_id: 2387,
        teachers: ["戸丸 信弘"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 192,
        course_name: "科学・技術の哲学",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 715,
        course_name: "現代医療と生命科学",
        teacher_group_id: 296,
        teachers: ["平田 仁"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 693,
        course_name: "線形代数学I",
        teacher_group_id: 781,
        teachers: ["須田 淳"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 839,
        course_name: "情報科学入門",
        teacher_group_id: 924,
        teachers: ["枝廣 正人"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 170,
        course_name: "自然環境と人間",
        teacher_group_id: 41,
        teachers: ["荒川 宜親"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2184,
        course_name: "自然環境と人間",
        teacher_group_id: 1511,
        teachers: ["錫村 明生"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1307,
        course_name: "現代の生命科学",
        teacher_group_id: 1648,
        teachers: ["井原 邦夫"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 170,
        course_name: "自然環境と人間",
        teacher_group_id: 1238,
        teachers: ["甲斐 憲次"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 2192,
        course_name: "環境問題と人間",
        teacher_group_id: 1563,
        teachers: ["村瀬 潤"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 499,
        course_name: "食と農の科学",
        teacher_group_id: 1020,
        teachers: ["土井 一行"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 875,
        teachers: ["辻 義之"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 2015,
        course_name: "システム工学入門",
        teacher_group_id: 1047,
        teachers: ["橋爪 進"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 313,
        course_name: "情報リテラシー(理系)",
        teacher_group_id: 348,
        teachers: ["東中 竜一郎"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1282,
        course_name: "物理現象の科学",
        teacher_group_id: 1621,
        teachers: ["松岡 健"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 1336,
        course_name: "力学II",
        teacher_group_id: 1703,
        teachers: ["松山 智至"],
        course_group: "理系教養科目",
        semester: "その他",
      },
      {
        course_id: 246,
        course_name: "物理学実験",
        teacher_group_id: 222,
        teachers: ["瓜谷 章"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1487,
        course_name: "複素関数論",
        teacher_group_id: 1225,
        teachers: ["林 孝宏"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 315,
        course_name: "化学基礎I",
        teacher_group_id: 1821,
        teachers: ["植田 研二"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 929,
        course_name: "地球惑星の科学",
        teacher_group_id: 2366,
        teachers: ["田中 剛"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 2047,
        course_name: "現代数学への流れ",
        teacher_group_id: 1065,
        teachers: ["寺澤 祐高"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 2156,
        course_name: "食と農の科学",
        teacher_group_id: 1340,
        teachers: ["村井 篤嗣"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 594,
        course_name: "現代数学の流れ",
        teacher_group_id: 1266,
        teachers: ["太田 啓史"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 1813,
        course_name: "電磁気学II",
        teacher_group_id: 746,
        teachers: ["竹延 大志"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 929,
        course_name: "地球惑星の科学",
        teacher_group_id: 1360,
        teachers: ["平原 靖大"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 730,
        course_name: "物理現象の科学",
        teacher_group_id: 814,
        teachers: ["社本 英二"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 328,
        course_name: "物理学基礎I",
        teacher_group_id: 1696,
        teachers: ["山崎 真理子"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 594,
        course_name: "現代数学の流れ",
        teacher_group_id: 2212,
        teachers: ["三宅 正武"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 929,
        course_name: "地球惑星の科学",
        teacher_group_id: 1029,
        teachers: ["三村 耕一"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 454,
        course_name: "遺伝子",
        teacher_group_id: 567,
        teachers: ["門脇", "水野", "小俣"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 929,
        course_name: "地球惑星の科学",
        teacher_group_id: 2526,
        teachers: ["川邊 岩夫"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 345,
        course_name: "都市と環境",
        teacher_group_id: 805,
        teachers: ["西澤 泰彦"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 499,
        course_name: "食と農の科学",
        teacher_group_id: 1809,
        teachers: ["石川 明"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1181,
        teachers: ["菊田 浩一"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 593,
        course_name: "現代数学への流れ",
        teacher_group_id: 165,
        teachers: ["行者 明彦"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 594,
        course_name: "現代数学の流れ",
        teacher_group_id: 701,
        teachers: ["菱田 俊明"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 345,
        course_name: "都市と環境",
        teacher_group_id: 384,
        teachers: ["西澤"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 345,
        course_name: "都市と環境",
        teacher_group_id: 758,
        teachers: ["奥宮 正哉"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 593,
        course_name: "現代数学への流れ",
        teacher_group_id: 2212,
        teachers: ["三宅 正武"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 614,
        course_name: "エネルギーと環境",
        teacher_group_id: 720,
        teachers: ["中谷 真人"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 474,
        course_name: "自然環境と人間社会",
        teacher_group_id: 815,
        teachers: ["谷川 寛樹"],
        course_group: "理系教養科目",
        semester: "前期",
      },
      {
        course_id: 599,
        course_name: "エレクトロニクスと物性科学",
        teacher_group_id: 705,
        teachers: ["堀 勝"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 191,
        course_name: "物質と材料の科学",
        teacher_group_id: 1642,
        teachers: ["小澤 正邦"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 593,
        course_name: "現代数学への流れ",
        teacher_group_id: 701,
        teachers: ["菱田 俊明"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 355,
        course_name: "遺伝子の世界",
        teacher_group_id: 396,
        teachers: ["上口"],
        course_group: "理系教養科目",
        semester: "後期",
      },
      {
        course_id: 355,
        course_name: "遺伝子の世界",
        teacher_group_id: 1068,
        teachers: ["松田 洋一"],
        course_group: "理系教養科目",
        semester: "後期",
      },
    ],
    文系教養科目: [
      {
        course_id: 938,
        course_name: "生と死の人間学",
        teacher_group_id: 1043,
        teachers: ["吉田 純"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 408,
        course_name: "グローバル化と国際教育交流",
        teacher_group_id: 506,
        teachers: ["星野 晶成"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 169,
        course_name: "金融と生活",
        teacher_group_id: 2229,
        teachers: ["多和田 眞"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 201,
        course_name: "人間と行動",
        teacher_group_id: 134,
        teachers: ["鈴木 敦命"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 411,
        course_name: "現代社会と教育",
        teacher_group_id: 976,
        teachers: ["大谷 尚"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 201,
        course_name: "人間と行動",
        teacher_group_id: 813,
        teachers: ["北神 慎司"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 634,
        course_name: "教育と発達の心理",
        teacher_group_id: 736,
        teachers: ["蔵冨 恵"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 660,
        course_name: "生と死の人間学",
        teacher_group_id: 2020,
        teachers: ["吉田"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 193,
        course_name: "科学・技術の哲学",
        teacher_group_id: 128,
        teachers: ["久木田 水生"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 686,
        course_name: "芸術と人間",
        teacher_group_id: 775,
        teachers: ["秋庭 史典"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 660,
        course_name: "生と死の人間学",
        teacher_group_id: 1613,
        teachers: ["岩崎 陽一"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 2073,
        course_name: "グローバル化時代の国際社会",
        teacher_group_id: 955,
        teachers: ["井口 治夫"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 1946,
        course_name: "民主主義の歴史と現在",
        teacher_group_id: 943,
        teachers: ["佐藤 史人"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 1946,
        course_name: "民主主義の歴史と現在",
        teacher_group_id: 1077,
        teachers: ["野村 康"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 411,
        course_name: "現代社会と教育",
        teacher_group_id: 509,
        teachers: ["坂本 將暢"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2061,
        course_name: "文化を読む",
        teacher_group_id: 1027,
        teachers: ["飯田 裕子"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 589,
        course_name: "文化を読む",
        teacher_group_id: 697,
        teachers: ["重見 晋也"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 169,
        course_name: "金融と生活",
        teacher_group_id: 1269,
        teachers: ["根本 二郎"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 2061,
        course_name: "文化を読む",
        teacher_group_id: 884,
        teachers: ["釘貫 亨"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2106,
        course_name: "芸術と人間",
        teacher_group_id: 1743,
        teachers: ["酒井 健宏"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 1999,
        course_name: "心理学II",
        teacher_group_id: 727,
        teachers: ["松本 真理子"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 2044,
        course_name: "教育と発達の心理",
        teacher_group_id: 854,
        teachers: ["溝川 藍"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2061,
        course_name: "文化を読む",
        teacher_group_id: 1042,
        teachers: ["羽賀 祥二"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2044,
        course_name: "教育と発達の心理",
        teacher_group_id: 1171,
        teachers: ["氏家 達夫"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 1038,
        course_name: "経済学",
        teacher_group_id: 1178,
        teachers: ["小川 光"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 50,
        course_name: "開発の光と影",
        teacher_group_id: 105,
        teachers: ["東江 日出郎"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 660,
        course_name: "生と死の人間学",
        teacher_group_id: 832,
        teachers: ["宮原 勇"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 411,
        course_name: "現代社会と教育",
        teacher_group_id: 1649,
        teachers: ["辻 浩"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 971,
        course_name: "ことばの不思議",
        teacher_group_id: 1083,
        teachers: ["宮地 朝子"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 1041,
        course_name: "人間と行動",
        teacher_group_id: 1185,
        teachers: ["齋藤 洋典"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 859,
        course_name: "現代社会と教育",
        teacher_group_id: 949,
        teachers: ["横山 悦生"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2136,
        course_name: "ことばの不思議",
        teacher_group_id: 1665,
        teachers: ["久保田 樹"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 50,
        course_name: "開発の光と影",
        teacher_group_id: 1215,
        teachers: ["川島 富士雄"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 2003,
        course_name: "人間と行動",
        teacher_group_id: 1150,
        teachers: ["斎藤 洋典"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 226,
        course_name: "刑法(総論)",
        teacher_group_id: 163,
        teachers: ["古川 伸彦根"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 761,
        course_name: "人間と環境",
        teacher_group_id: 1647,
        teachers: ["安田 恭子"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2003,
        course_name: "人間と行動",
        teacher_group_id: 736,
        teachers: ["蔵冨 恵"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 680,
        course_name: "社会変動と人間生活",
        teacher_group_id: 773,
        teachers: ["河村 則行"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 1729,
        course_name: "表象と文化",
        teacher_group_id: 2524,
        teachers: ["阿部 泰郎"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 634,
        course_name: "教育と発達の心理",
        teacher_group_id: 786,
        teachers: ["五十嵐 裕"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 169,
        course_name: "金融と生活",
        teacher_group_id: 107,
        teachers: ["根元 二郎"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 1946,
        course_name: "民主主義の歴史と現在",
        teacher_group_id: 531,
        teachers: ["石井 三記"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 1573,
        course_name: "心理学",
        teacher_group_id: 826,
        teachers: ["川口 潤"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 472,
        course_name: "比較文化論",
        teacher_group_id: 1101,
        teachers: ["小川 正廣"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 660,
        course_name: "生と死の人間学",
        teacher_group_id: 762,
        teachers: ["金山 弥平"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 979,
        course_name: "哲学",
        teacher_group_id: 1095,
        teachers: ["神塚 淑子"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 590,
        course_name: "分化を読む",
        teacher_group_id: 697,
        teachers: ["重見 晋也"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 2293,
        course_name: "社会と環境",
        teacher_group_id: 1284,
        teachers: ["青木 聡子"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 50,
        course_name: "開発の光と影",
        teacher_group_id: 1509,
        teachers: ["東村 岳史"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 652,
        course_name: "産業社会と企業",
        teacher_group_id: 1011,
        teachers: ["小堀 聡"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 142,
        course_name: "現代社会と法",
        teacher_group_id: 84,
        teachers: ["赤渕 芳宏"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 979,
        course_name: "哲学",
        teacher_group_id: 762,
        teachers: ["金山 弥平"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 588,
        course_name: "表象と文化",
        teacher_group_id: 1778,
        teachers: ["近本 謙介"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 2003,
        course_name: "人間と行動",
        teacher_group_id: 828,
        teachers: ["冨倉 恵"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2003,
        course_name: "人間と行動",
        teacher_group_id: 1797,
        teachers: ["井関 紗代"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2136,
        course_name: "ことばの不思議",
        teacher_group_id: 1244,
        teachers: ["畝部 俊也"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 1530,
        course_name: "言葉の不思議",
        teacher_group_id: 2697,
        teachers: ["田中"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 50,
        course_name: "開発の光と影",
        teacher_group_id: 20,
        teachers: ["島田 弦"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 634,
        course_name: "教育と発達の心理",
        teacher_group_id: 928,
        teachers: ["清河 幸子"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 761,
        course_name: "人間と環境",
        teacher_group_id: 845,
        teachers: ["平島 太郎"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 588,
        course_name: "表象と文化",
        teacher_group_id: 1423,
        teachers: ["木俣 元一"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 411,
        course_name: "現代社会と教育",
        teacher_group_id: 2500,
        teachers: ["寺田 盛紀"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2003,
        course_name: "人間と行動",
        teacher_group_id: 1678,
        teachers: ["唐沢 穣"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 1163,
        course_name: "映像とメディア・リテラシー",
        teacher_group_id: 1392,
        teachers: ["後藤 明史"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 2285,
        course_name: "心理学",
        teacher_group_id: 1150,
        teachers: ["斎藤 洋典"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 398,
        course_name: "日本国憲法",
        teacher_group_id: 499,
        teachers: ["波多江"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 134,
        course_name: "グローバル化時代の国際社会",
        teacher_group_id: 136,
        teachers: ["金 彦叔"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 369,
        course_name: "都市と環境",
        teacher_group_id: 421,
        teachers: ["奥宮"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 680,
        course_name: "社会変動と人間生活",
        teacher_group_id: 1076,
        teachers: ["黒田 由彦"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 2106,
        course_name: "芸術と人間",
        teacher_group_id: 1104,
        teachers: ["丹羽 誠次郎"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 201,
        course_name: "人間と行動",
        teacher_group_id: 826,
        teachers: ["川口 潤"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 1682,
        course_name: "社会心理学",
        teacher_group_id: 1465,
        teachers: ["唐澤 穣"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 660,
        course_name: "生と死の人間学",
        teacher_group_id: 1244,
        teachers: ["畝部 俊也"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 1726,
        course_name: "教育学",
        teacher_group_id: 2500,
        teachers: ["寺田 盛紀"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 1033,
        course_name: "比較放送・報道論",
        teacher_group_id: 1167,
        teachers: ["河村 雅隆"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 142,
        course_name: "現代社会と法",
        teacher_group_id: 1092,
        teachers: ["林 秀弥"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 463,
        course_name: "社会と環境",
        teacher_group_id: 886,
        teachers: ["中野 牧子"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 588,
        course_name: "表象と文化",
        teacher_group_id: 1027,
        teachers: ["飯田 裕子"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 135,
        course_name: "国際関係論",
        teacher_group_id: 1077,
        teachers: ["野村 康"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 463,
        course_name: "社会と環境",
        teacher_group_id: 1126,
        teachers: ["竹内 恒夫"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 589,
        course_name: "文化を読む",
        teacher_group_id: 1586,
        teachers: ["栗田 秀法"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 634,
        course_name: "教育と発達の心理",
        teacher_group_id: 901,
        teachers: ["平石 賢二"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 106,
        course_name: "市場経済と社会",
        teacher_group_id: 803,
        teachers: ["園田 正"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 1946,
        course_name: "民主主義の歴史と現在",
        teacher_group_id: 1312,
        teachers: ["川田 稔"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 484,
        course_name: "表象芸術論",
        teacher_group_id: 615,
        teachers: ["青山 太郎"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 353,
        course_name: "民主主義の歴史と現在",
        teacher_group_id: 1702,
        teachers: ["波多野 敏"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2183,
        course_name: "経済学",
        teacher_group_id: 1504,
        teachers: ["野崎 謙二"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 685,
        teachers: ["立石 寛"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 1810,
        course_name: "科学史・技術史",
        teacher_group_id: 2659,
        teachers: ["竹下 至"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 1856,
        course_name: "一般経済史II",
        teacher_group_id: 56,
        teachers: ["福澤 直樹"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 1787,
        course_name: "言語学入門",
        teacher_group_id: 620,
        teachers: ["李 澤熊"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 136,
        course_name: "グローバル化と国際社会",
        teacher_group_id: 79,
        teachers: ["姜 東局"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 588,
        course_name: "表象と文化",
        teacher_group_id: 697,
        teachers: ["重見 晋也"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 588,
        course_name: "表象と文化",
        teacher_group_id: 2121,
        teachers: ["藤木"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 2116,
        course_name: "産業社会と企業",
        teacher_group_id: 1370,
        teachers: ["吉田 猛"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2082,
        course_name: "開発の光と影",
        teacher_group_id: 1002,
        teachers: ["岡田 勇"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2116,
        course_name: "産業社会と企業",
        teacher_group_id: 1136,
        teachers: ["佐野 良雄"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2136,
        course_name: "ことばの不思議",
        teacher_group_id: 1734,
        teachers: ["長崎 郁"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 192,
        course_name: "科学・技術の哲学",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 192,
        course_name: "科学・技術の哲学",
        teacher_group_id: 2432,
        teachers: ["青木 滋之"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 795,
        course_name: "法学",
        teacher_group_id: 914,
        teachers: ["古川 伸彦"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 134,
        course_name: "グローバル化時代の国際社会",
        teacher_group_id: 79,
        teachers: ["姜 東局"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 463,
        course_name: "社会と環境",
        teacher_group_id: 580,
        teachers: ["香坂 玲"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 1907,
        course_name: "教職",
        teacher_group_id: 1289,
        teachers: ["中嶋 哲彦"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 286,
        course_name: "市場経済と社会",
        teacher_group_id: 314,
        teachers: ["斎川"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 1933,
        course_name: "法学",
        teacher_group_id: 879,
        teachers: ["國分 典子"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 142,
        course_name: "現代社会と法",
        teacher_group_id: 951,
        teachers: ["高村 ゆかり"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 201,
        course_name: "人間と行動",
        teacher_group_id: 1021,
        teachers: ["田邊 宏樹"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 50,
        course_name: "開発の光と影",
        teacher_group_id: 2070,
        teachers: ["島田"],
        course_group: "文系教養科目",
        semester: "後期",
      },
      {
        course_id: 353,
        course_name: "民主主義の歴史と現在",
        teacher_group_id: 394,
        teachers: ["武田"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 353,
        course_name: "民主主義の歴史と現在",
        teacher_group_id: 530,
        teachers: ["大屋 雄裕"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 2116,
        course_name: "産業社会と企業",
        teacher_group_id: 1534,
        teachers: ["西村 眞"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 353,
        course_name: "民主主義の歴史と現在",
        teacher_group_id: 894,
        teachers: ["武田 宏子"],
        course_group: "文系教養科目",
        semester: "前期",
      },
      {
        course_id: 652,
        course_name: "産業社会と企業",
        teacher_group_id: 751,
        teachers: ["中屋 信彦"],
        course_group: "文系教養科目",
        semester: "後期",
      },
    ],
    "理系基礎(理系)": [
      {
        course_id: 407,
        course_name: "化学基礎II",
        teacher_group_id: 1473,
        teachers: ["出口 清一"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2018,
        course_name: "微分積分学I",
        teacher_group_id: 1006,
        teachers: ["大島 和幸"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 772,
        course_name: "物理学基礎II",
        teacher_group_id: 1053,
        teachers: ["中村 光廣"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 860,
        teachers: ["谷川 好男"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 705,
        course_name: "情報リテラシー",
        teacher_group_id: 787,
        teachers: ["佐藤 弘毅"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 328,
        course_name: "物理学基礎I",
        teacher_group_id: 1679,
        teachers: ["時田 恵一郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 772,
        course_name: "物理学基礎II",
        teacher_group_id: 1732,
        teachers: ["北口 雅暁"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 860,
        teachers: ["谷川 好男"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 956,
        teachers: ["長江 拓也"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 856,
        teachers: ["南 和彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1885,
        course_name: "生物学基礎I",
        teacher_group_id: 35,
        teachers: ["五島 剛太"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1942,
        course_name: "物理学基礎I",
        teacher_group_id: 507,
        teachers: ["川村 静児"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 875,
        teachers: ["辻 義之"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 87,
        course_name: "生物学基礎II",
        teacher_group_id: 1620,
        teachers: ["多田 安臣"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 920,
        teachers: ["伊藤 由佳里"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2101,
        course_name: "電磁気学",
        teacher_group_id: 876,
        teachers: ["川瀬 晃道"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1849,
        course_name: "線形代数学II",
        teacher_group_id: 752,
        teachers: ["曽我部 知広"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 1089,
        teachers: ["稲守 孝哉"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 407,
        course_name: "化学基礎II",
        teacher_group_id: 1181,
        teachers: ["菊田 浩一"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 87,
        course_name: "生物学基礎II",
        teacher_group_id: 635,
        teachers: ["本間 道夫", "黒岩 厚"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 718,
        teachers: ["棚橋 誠治"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 700,
        teachers: ["松原 洋"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 410,
        course_name: "物理学基礎I",
        teacher_group_id: 658,
        teachers: ["中村 泰之"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 693,
        course_name: "線形代数学I",
        teacher_group_id: 781,
        teachers: ["須田 淳"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2194,
        course_name: "地球科学基礎I",
        teacher_group_id: 1591,
        teachers: ["日高 洋"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1607,
        course_name: "物理学基礎",
        teacher_group_id: 1053,
        teachers: ["中村 光廣"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1937,
        course_name: "線形代数学I",
        teacher_group_id: 984,
        teachers: ["中西 知樹"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 452,
        course_name: "力学",
        teacher_group_id: 2511,
        teachers: ["戸田 祐嗣"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 9,
        teachers: ["福塚 友和"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2017,
        course_name: "微分積分学II",
        teacher_group_id: 791,
        teachers: ["内藤 久資"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 1220,
        teachers: ["森本 宏"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 104,
        teachers: ["薩摩 篤"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 847,
        course_name: "電磁気学",
        teacher_group_id: 932,
        teachers: ["草野 完也"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 38,
        teachers: ["本山 宗主", "塚田 祐貴"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 1418,
        teachers: ["安田 啓司"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 872,
        teachers: ["小山 剛史"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 591,
        teachers: ["宇澤 達"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2067,
        course_name: "数学通論II",
        teacher_group_id: 916,
        teachers: ["粟田 英資"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 772,
        course_name: "物理学基礎II",
        teacher_group_id: 1283,
        teachers: ["谷村 省吾"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1059,
        course_name: "情報リテラシー(理系)",
        teacher_group_id: 1207,
        teachers: ["嶋田 創"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 2373,
        teachers: ["吉田 寿雄"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 893,
        teachers: ["高嶋 圭史"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 834,
        teachers: ["宇沢 達"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 916,
        teachers: ["粟田 英資"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 1,
        teachers: ["糸 健太郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 924,
        course_name: "生物学実験",
        teacher_group_id: 1020,
        teachers: ["土井 一行"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 228,
        course_name: "数学通論II",
        teacher_group_id: 165,
        teachers: ["行者 明彦"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 129,
        course_name: "力学II",
        teacher_group_id: 1701,
        teachers: ["西口 浩司"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2172,
        course_name: "化学基礎I",
        teacher_group_id: 1472,
        teachers: ["菱川 明栄"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 656,
        teachers: ["久保 仁"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2137,
        course_name: "情報リテラシー",
        teacher_group_id: 1248,
        teachers: ["川合 伸幸"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 1082,
        teachers: ["尾崎 文宣"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 91,
        course_name: "生物学基礎I",
        teacher_group_id: 540,
        teachers: ["松林 嘉克", "木下 専", "五島 剛太"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 80,
        teachers: ["Garrigue Jacques"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 1300,
        teachers: ["大沢 健夫"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 711,
        course_name: "化学入門",
        teacher_group_id: 793,
        teachers: ["古賀 伸明"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 410,
        course_name: "物理学基礎I",
        teacher_group_id: 929,
        teachers: ["戸本 誠"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 410,
        course_name: "物理学基礎I",
        teacher_group_id: 871,
        teachers: ["犬塚 修一郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 157,
        teachers: ["藤江 双葉"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 148,
        course_name: "微分積分学I",
        teacher_group_id: 1,
        teachers: ["糸 健太郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 1165,
        teachers: ["永尾 太郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 32,
        course_name: "線形代数学II",
        teacher_group_id: 882,
        teachers: ["柳浦 睦憲"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1829,
        teachers: ["小林 善司"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2080,
        course_name: "地球科学実験",
        teacher_group_id: 1000,
        teachers: ["氏原 温"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 229,
        course_name: "化学実験",
        teacher_group_id: 1336,
        teachers: ["山口 茂弘"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1935,
        course_name: "微分積分学",
        teacher_group_id: 444,
        teachers: ["大島"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1951,
        course_name: "線形代数学I",
        teacher_group_id: 464,
        teachers: ["伊藤"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 1140,
        teachers: ["草刈 圭一朗"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 882,
        teachers: ["柳浦 睦憲"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 87,
        course_name: "生物学基礎II",
        teacher_group_id: 549,
        teachers: ["高木 新", "本間 道夫", "黒岩 厚"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 1498,
        teachers: ["吉田 朋子"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 221,
        course_name: "微分積分学",
        teacher_group_id: 941,
        teachers: ["山上 滋"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 941,
        teachers: ["山上 滋"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1548,
        course_name: "生物",
        teacher_group_id: 2210,
        teachers: ["堀 寛", "小田 洋一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 410,
        course_name: "物理学基礎I",
        teacher_group_id: 808,
        teachers: ["吉田 正人"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2160,
        course_name: "線形",
        teacher_group_id: 1355,
        teachers: ["梅村 浩"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 984,
        teachers: ["中西 知樹"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 458,
        course_name: "電磁気学II",
        teacher_group_id: 1125,
        teachers: ["梅原 徳次"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2017,
        course_name: "微分積分学II",
        teacher_group_id: 856,
        teachers: ["南 和彦"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 1410,
        teachers: ["菅野 浩明"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 680,
        teachers: ["藤原 一宏"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 936,
        teachers: ["伊藤 由佳理"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 410,
        course_name: "物理学基礎I",
        teacher_group_id: 782,
        teachers: ["渡邉 智彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 229,
        course_name: "化学実験",
        teacher_group_id: 704,
        teachers: ["北島 健"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1708,
        course_name: "電情対象物理学実験",
        teacher_group_id: 2455,
        teachers: ["田中 成泰"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1140,
        teachers: ["草刈 圭一朗"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 571,
        course_name: "数学通論",
        teacher_group_id: 941,
        teachers: ["山上 滋"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 878,
        teachers: ["寺崎 一郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1871,
        course_name: "線形代数学II",
        teacher_group_id: 916,
        teachers: ["粟田 英資"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 636,
        course_name: "生物学基礎2",
        teacher_group_id: 1352,
        teachers: ["黒岩 厚"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1508,
        course_name: "情報リテラシー",
        teacher_group_id: 2346,
        teachers: ["佐藤 倫正"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 148,
        course_name: "微分積分学I",
        teacher_group_id: 1745,
        teachers: ["小野 廣隆"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 920,
        teachers: ["伊藤 由佳里"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 165,
        teachers: ["行者 明彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1638,
        course_name: "有機化学",
        teacher_group_id: 1302,
        teachers: ["大木 靖弘"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 1557,
        teachers: ["山田 陽滋"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 1220,
        teachers: ["森本 宏"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 126,
        teachers: ["金銅 誠之"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1991,
        course_name: "物理学実験",
        teacher_group_id: 743,
        teachers: ["浅野 秀文"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2258,
        course_name: "数学入門",
        teacher_group_id: 2010,
        teachers: ["新田"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 664,
        course_name: "地球科学実験",
        teacher_group_id: 764,
        teachers: ["永峰 康一郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 680,
        teachers: ["藤原 一宏"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 165,
        teachers: ["行者 明彦"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 1079,
        teachers: ["横水 康伸"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 847,
        course_name: "電磁気学",
        teacher_group_id: 718,
        teachers: ["棚橋 誠治"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 1931,
        teachers: ["南"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 395,
        teachers: ["五十嵐"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 452,
        course_name: "力学",
        teacher_group_id: 561,
        teachers: ["田仲 由喜夫"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 936,
        teachers: ["伊藤 由佳理"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 410,
        course_name: "物理学基礎I",
        teacher_group_id: 855,
        teachers: ["杉山 雄規"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 410,
        course_name: "物理学基礎I",
        teacher_group_id: 910,
        teachers: ["渡邊 智彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 663,
        course_name: "地球科学基礎I",
        teacher_group_id: 952,
        teachers: ["藤田 耕史"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2300,
        course_name: "生物学基礎",
        teacher_group_id: 1352,
        teachers: ["黒岩 厚"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1508,
        course_name: "情報リテラシー",
        teacher_group_id: 1073,
        teachers: ["有田 隆也"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 1063,
        teachers: ["鈴木 浩志"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1991,
        course_name: "物理学実験",
        teacher_group_id: 228,
        teachers: ["田畑 彰守"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 157,
        teachers: ["藤江 双葉"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 315,
        course_name: "化学基礎I",
        teacher_group_id: 351,
        teachers: ["内山 峰人"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 2578,
        teachers: ["吉田 寿夫"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 971,
        teachers: ["伊師 英之"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 571,
        course_name: "数学通論",
        teacher_group_id: 1276,
        teachers: ["津川 光太郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1518,
        course_name: "数学入門(文系)",
        teacher_group_id: 2063,
        teachers: ["貝瀬"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1036,
        teachers: ["松岡 辰郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1631,
        course_name: "医学科物理基礎",
        teacher_group_id: 1237,
        teachers: ["野尻 伸一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 1039,
        teachers: ["白水 徹也"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1508,
        course_name: "情報リテラシー",
        teacher_group_id: 410,
        teachers: ["佐藤"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1508,
        course_name: "情報リテラシー",
        teacher_group_id: 836,
        teachers: ["畔上 秀幸"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1991,
        course_name: "物理学実験",
        teacher_group_id: 715,
        teachers: ["梶田 信"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1863,
        course_name: "電磁気学I",
        teacher_group_id: 4,
        teachers: ["宮﨑 誠一"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 860,
        teachers: ["谷川 好男"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 891,
        teachers: ["加藤 淳"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 91,
        course_name: "生物学基礎I",
        teacher_group_id: 1219,
        teachers: ["松本 邦弘"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 933,
        teachers: ["中島 誠"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 252,
        course_name: "線形",
        teacher_group_id: 232,
        teachers: ["糸"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 924,
        course_name: "生物学実験",
        teacher_group_id: 1291,
        teachers: ["阿部 秀樹"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 101,
        course_name: "計算機ソフトウェア第1",
        teacher_group_id: 54,
        teachers: ["野老山 貴行"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 954,
        teachers: ["木原 貴行"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1947,
        teachers: ["HESSELHOLT"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 936,
        teachers: ["伊藤 由佳理"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 126,
        teachers: ["金銅 誠之"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 704,
        course_name: "情報リテラシー(文系)",
        teacher_group_id: 654,
        teachers: ["栗本 英和"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 1355,
        teachers: ["梅村 浩"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 847,
        course_name: "電磁気学",
        teacher_group_id: 705,
        teachers: ["堀 勝"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 87,
        course_name: "生物学基礎II",
        teacher_group_id: 42,
        teachers: ["多田", "東山", "本間"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 830,
        course_name: "化学実験",
        teacher_group_id: 917,
        teachers: ["長尾 征洋"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1847,
        course_name: "微分積分学II",
        teacher_group_id: 1220,
        teachers: ["森本 宏"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 256,
        course_name: "生物学基礎",
        teacher_group_id: 245,
        teachers: ["饗場 弘二"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 407,
        course_name: "化学基礎II",
        teacher_group_id: 1036,
        teachers: ["松岡 辰郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1825,
        course_name: "力学I",
        teacher_group_id: 684,
        teachers: ["富田 英生"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 229,
        course_name: "化学実験",
        teacher_group_id: 1197,
        teachers: ["人見 清隆"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1063,
        teachers: ["鈴木 浩志"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1108,
        course_name: "物理学基礎1",
        teacher_group_id: 1286,
        teachers: ["山本 浩之"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 570,
        course_name: "数学通論I",
        teacher_group_id: 856,
        teachers: ["南 和彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 1349,
        teachers: ["宮崎 誠一"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 1473,
        teachers: ["出口 清一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 847,
        course_name: "電磁気学",
        teacher_group_id: 1401,
        teachers: ["綱島 滋"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 313,
        teachers: ["福塚"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1721,
        course_name: "物理",
        teacher_group_id: 2486,
        teachers: ["飯尾 隆義"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 860,
        teachers: ["谷川 好男"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1063,
        teachers: ["鈴木 浩志"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 1388,
        teachers: ["松本 耕二"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 860,
        teachers: ["谷川 好男"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 129,
        course_name: "力学II",
        teacher_group_id: 1262,
        teachers: ["中塚 理"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 856,
        teachers: ["南 和彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2252,
        course_name: "線形代数学",
        teacher_group_id: 2295,
        teachers: ["吉田 健一"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2018,
        course_name: "微分積分学I",
        teacher_group_id: 1265,
        teachers: ["林 正人"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1181,
        teachers: ["菊田 浩一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 532,
        course_name: "情報リテラシー文系",
        teacher_group_id: 654,
        teachers: ["栗本 英和"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1252,
        course_name: "化学基礎I",
        teacher_group_id: 1533,
        teachers: ["福島 和彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 407,
        course_name: "化学基礎II",
        teacher_group_id: 1302,
        teachers: ["大木 靖弘"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 1838,
        teachers: ["小宮山 尚"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1662,
        teachers: ["松尾 信一郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 55,
        course_name: "物理学入門",
        teacher_group_id: 2504,
        teachers: ["森 昌弘"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 256,
        course_name: "生物学基礎",
        teacher_group_id: 1219,
        teachers: ["松本 邦弘"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1937,
        course_name: "線形代数学I",
        teacher_group_id: 492,
        teachers: ["須田"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 91,
        course_name: "生物学基礎I",
        teacher_group_id: 1174,
        teachers: ["佐藤 豊"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 128,
        course_name: "力学II",
        teacher_group_id: 76,
        teachers: ["北根 安雄"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2252,
        course_name: "線形代数学",
        teacher_group_id: 1425,
        teachers: ["山本 有作"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1140,
        teachers: ["草刈 圭一朗"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 570,
        course_name: "数学通論I",
        teacher_group_id: 591,
        teachers: ["宇澤 達"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 958,
        teachers: ["中野 正樹"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 705,
        teachers: ["堀 勝"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1975,
        course_name: "地球科学入門",
        teacher_group_id: 1000,
        teachers: ["氏原 温"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1179,
        teachers: ["清水 健一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 891,
        teachers: ["加藤 淳"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 221,
        course_name: "微分積分学",
        teacher_group_id: 1532,
        teachers: ["岩田 哲"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 591,
        teachers: ["宇澤 達"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 469,
        course_name: "線型代数学I",
        teacher_group_id: 790,
        teachers: ["増渕 雄一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 452,
        course_name: "力学",
        teacher_group_id: 1941,
        teachers: ["國枝 稔"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 1708,
        teachers: ["清水 裕彦"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1738,
        teachers: ["加納 成男"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1503,
        course_name: "健康増進科学",
        teacher_group_id: 1989,
        teachers: ["島本"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 469,
        course_name: "線型代数学I",
        teacher_group_id: 591,
        teachers: ["宇澤 達"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 1117,
        teachers: ["荒井 政大"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 889,
        teachers: ["伊山 修"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 410,
        course_name: "物理学基礎I",
        teacher_group_id: 1176,
        teachers: ["原田 正康"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 512,
        teachers: ["吉信 康夫"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 407,
        course_name: "化学基礎II",
        teacher_group_id: 2588,
        teachers: ["川口 大輔"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 847,
        course_name: "電磁気学",
        teacher_group_id: 1264,
        teachers: ["内山 剛"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 695,
        teachers: ["森吉 仁志"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1259,
        course_name: "基礎セミナーA",
        teacher_group_id: 1556,
        teachers: ["居波 賢二"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1898,
        course_name: "化学基礎",
        teacher_group_id: 1418,
        teachers: ["安田 啓司"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2064,
        course_name: "数学入門",
        teacher_group_id: 897,
        teachers: ["丹羽 伸二"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 806,
        teachers: ["齊藤 博"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1072,
        teachers: ["金森 敬文"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 129,
        course_name: "力学II",
        teacher_group_id: 1459,
        teachers: ["中村 光"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 91,
        course_name: "生物学基礎I",
        teacher_group_id: 44,
        teachers: ["門松 健治"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2017,
        course_name: "微分積分学II",
        teacher_group_id: 1265,
        teachers: ["林 正人"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 1276,
        teachers: ["津川 光太郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2180,
        course_name: "線形代数学",
        teacher_group_id: 1499,
        teachers: ["安本 雅洋"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1662,
        teachers: ["松尾 信一郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 410,
        course_name: "物理学基礎I",
        teacher_group_id: 1168,
        teachers: ["横地 秀行"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1597,
        course_name: "地球科学",
        teacher_group_id: 1000,
        teachers: ["氏原 温"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 228,
        course_name: "数学通論II",
        teacher_group_id: 856,
        teachers: ["南 和彦"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 1063,
        teachers: ["鈴木 浩志"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 452,
        course_name: "力学",
        teacher_group_id: 588,
        teachers: ["高橋 誠"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 1264,
        teachers: ["内山 剛"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 847,
        course_name: "電磁気学",
        teacher_group_id: 2455,
        teachers: ["田中 成泰"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 338,
        course_name: "微分積分学II",
        teacher_group_id: 1745,
        teachers: ["小野 廣隆"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 229,
        course_name: "化学実験",
        teacher_group_id: 1755,
        teachers: ["佐藤 ちひろ"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 702,
        course_name: "情報リテラシー(文系)",
        teacher_group_id: 787,
        teachers: ["佐藤 弘毅"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 1388,
        teachers: ["松本 耕二"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 2678,
        teachers: ["川口 由紀"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 1557,
        teachers: ["山田 陽滋"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1442,
        course_name: "物理学基礎I",
        teacher_group_id: 1691,
        teachers: ["川崎 猛史"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 149,
        course_name: "数学入門",
        teacher_group_id: 1072,
        teachers: ["金森 敬文"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1250,
        teachers: ["鈴木 秀士"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 1488,
        teachers: ["中村 豪"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 338,
        course_name: "微分積分学II",
        teacher_group_id: 1752,
        teachers: ["椿野 大輔"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1327,
        course_name: "生物学実験",
        teacher_group_id: 1688,
        teachers: ["高橋 宏和"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 891,
        teachers: ["加藤 淳"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 591,
        teachers: ["宇澤 達"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 779,
        course_name: "微分積分学I",
        teacher_group_id: 1783,
        teachers: ["竹内 一郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 1966,
        teachers: ["山田 陽磁"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 512,
        teachers: ["吉信 康夫"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1813,
        course_name: "電磁気学II",
        teacher_group_id: 746,
        teachers: ["竹延 大志"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 1424,
        teachers: ["井手 一郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 87,
        teachers: ["BUSCEMI Francesco"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 1034,
        teachers: ["伊東 裕"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 701,
        teachers: ["菱田 俊明"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 833,
        teachers: ["小林 亮一"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2012,
        course_name: "化学基礎I",
        teacher_group_id: 780,
        teachers: ["沢邊 恭一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 700,
        teachers: ["松原 洋"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 812,
        course_name: "数学入門",
        teacher_group_id: 2254,
        teachers: ["新田 貴士"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 221,
        course_name: "微分積分学",
        teacher_group_id: 1225,
        teachers: ["林 孝宏"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1898,
        course_name: "化学基礎",
        teacher_group_id: 1302,
        teachers: ["大木 靖弘"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1731,
        course_name: "熱力学と量子化学",
        teacher_group_id: 1564,
        teachers: ["安田 耕二"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 876,
        teachers: ["川瀬 晃道"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1334,
        teachers: ["岡田 聡一"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 129,
        course_name: "力学II",
        teacher_group_id: 709,
        teachers: ["山本 佳士"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 804,
        teachers: ["佐藤 潤也"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 772,
        course_name: "物理学基礎II",
        teacher_group_id: 1339,
        teachers: ["大澤 幸治"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 407,
        course_name: "化学基礎II",
        teacher_group_id: 1418,
        teachers: ["安田 啓司"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 2438,
        teachers: ["二村 俊英"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 860,
        teachers: ["谷川 好男"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 812,
        course_name: "数学入門",
        teacher_group_id: 2562,
        teachers: ["貝瀬 秀裕"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 2371,
        teachers: ["石原 卓"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 984,
        teachers: ["中西 知樹"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 2584,
        teachers: ["山口 正史"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 570,
        course_name: "数学通論I",
        teacher_group_id: 680,
        teachers: ["藤原 一宏"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 869,
        teachers: ["北浦 良"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 229,
        course_name: "化学実験",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1039,
        teachers: ["白水 徹也"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 301,
        teachers: ["本山 宗主"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 2080,
        teachers: ["稲浜"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 512,
        teachers: ["吉信 康夫"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 1179,
        teachers: ["清水 健一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 2283,
        teachers: ["小野木 克明"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 663,
        course_name: "地球科学基礎I",
        teacher_group_id: 919,
        teachers: ["西村 浩一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2015,
        course_name: "システム工学入門",
        teacher_group_id: 1047,
        teachers: ["橋爪 進"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 924,
        course_name: "生物学実験",
        teacher_group_id: 1231,
        teachers: ["松浦 能行"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 101,
        course_name: "計算機ソフトウェア第1",
        teacher_group_id: 1184,
        teachers: ["奥村 大"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 407,
        course_name: "化学基礎II",
        teacher_group_id: 1565,
        teachers: ["長岡 正隆"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 1237,
        teachers: ["野尻 伸一"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 35,
        course_name: "線型代数学II",
        teacher_group_id: 9,
        teachers: ["福塚 友和"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 2586,
        teachers: ["高橋 徹"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1266,
        teachers: ["太田 啓史"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1993,
        course_name: "電磁気学II",
        teacher_group_id: 876,
        teachers: ["川瀬 晃道"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1871,
        course_name: "線形代数学II",
        teacher_group_id: 853,
        teachers: ["斎藤 博"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1039,
        course_name: "化学基礎I",
        teacher_group_id: 907,
        teachers: ["岩松 将一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1723,
        course_name: "地球科学基礎",
        teacher_group_id: 2491,
        teachers: ["井龍 康文"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1935,
        course_name: "微分積分学",
        teacher_group_id: 806,
        teachers: ["齊藤 博"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1666,
        teachers: ["石井 亮"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2157,
        course_name: "電磁気学II",
        teacher_group_id: 1349,
        teachers: ["宮崎 誠一"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 1410,
        teachers: ["菅野 浩明"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 812,
        teachers: ["伊藤 仁一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 847,
        course_name: "電磁気学",
        teacher_group_id: 1400,
        teachers: ["大熊 繁"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1259,
        teachers: ["野水 勉"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 772,
        course_name: "物理学基礎II",
        teacher_group_id: 855,
        teachers: ["杉山 雄規"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 656,
        teachers: ["久保 仁"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 791,
        teachers: ["内藤 久資"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 924,
        course_name: "生物学実験",
        teacher_group_id: 1227,
        teachers: ["平子 善章"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1871,
        course_name: "線形代数学II",
        teacher_group_id: 834,
        teachers: ["宇沢 達"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1991,
        course_name: "物理学実験",
        teacher_group_id: 712,
        teachers: ["近藤 博基"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2167,
        course_name: "線形代数学",
        teacher_group_id: 1515,
        teachers: ["大沢 建夫"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 958,
        teachers: ["中野 正樹"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 410,
        course_name: "物理学基礎I",
        teacher_group_id: 1286,
        teachers: ["山本 浩之"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1898,
        course_name: "化学基礎",
        teacher_group_id: 104,
        teachers: ["薩摩 篤"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 1356,
        teachers: ["金井 雅彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 2560,
        teachers: ["稲濱 譲"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 258,
        teachers: ["川平 友規"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 1395,
        teachers: ["室賀 嘉夫"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2252,
        course_name: "線形代数学",
        teacher_group_id: 806,
        teachers: ["齊藤 博"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 1729,
        teachers: ["伏谷 瑞穂"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 869,
        teachers: ["北浦 良"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 267,
        teachers: ["張 紹良"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1721,
        teachers: ["谷本 祥"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2252,
        course_name: "線形代数学",
        teacher_group_id: 853,
        teachers: ["斎藤 博"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1898,
        course_name: "化学基礎",
        teacher_group_id: 2406,
        teachers: ["伊丹 健一郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2252,
        course_name: "線形代数学",
        teacher_group_id: 2020,
        teachers: ["吉田"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1072,
        course_name: "地球科学基礎II",
        teacher_group_id: 1600,
        teachers: ["道林 克禎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 99,
        teachers: ["ジンチェンコ アナトーリ"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 1018,
        teachers: ["山澤 弘実"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2252,
        course_name: "線形代数学",
        teacher_group_id: 1944,
        teachers: ["佐藤 周友"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2167,
        course_name: "線形代数学",
        teacher_group_id: 1421,
        teachers: ["寺西 鎮男"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 452,
        course_name: "力学",
        teacher_group_id: 294,
        teachers: ["長野 方星"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 2617,
        teachers: ["佐藤 昌利"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1665,
        course_name: "地学",
        teacher_group_id: 2291,
        teachers: ["神沢 博"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 1521,
        teachers: ["岩下 弘一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1113,
        course_name: "複素関数論",
        teacher_group_id: 1296,
        teachers: ["川村 友美"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 772,
        course_name: "物理学基礎II",
        teacher_group_id: 1121,
        teachers: ["鈴村 順三"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1034,
        teachers: ["伊東 裕"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 2343,
        teachers: ["橋本 光靖"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 220,
        teachers: ["Zinchenko", "小長谷", "松田"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2063,
        course_name: "力学",
        teacher_group_id: 878,
        teachers: ["寺崎 一郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 407,
        course_name: "化学基礎II",
        teacher_group_id: 938,
        teachers: ["山田 泰之"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 233,
        course_name: "物理学実験",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 780,
        teachers: ["沢邊 恭一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 523,
        course_name: "情報リテラシー文系",
        teacher_group_id: 981,
        teachers: ["内山 知実"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1993,
        course_name: "電磁気学II",
        teacher_group_id: 718,
        teachers: ["棚橋 誠治"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 1806,
        teachers: ["橋本 貴宏"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1058,
        teachers: ["尾上 順"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 981,
        course_name: "情報リテラシー",
        teacher_group_id: 1100,
        teachers: ["三輪 和久"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1676,
        course_name: "地学入門",
        teacher_group_id: 2355,
        teachers: ["中村 俊夫"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1608,
        course_name: "物理学基礎演習",
        teacher_group_id: 1055,
        teachers: ["松原 隆彦"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2056,
        course_name: "電磁気学II",
        teacher_group_id: 1401,
        teachers: ["綱島 滋"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 233,
        course_name: "物理学実験",
        teacher_group_id: 184,
        teachers: ["中村 公廣"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 221,
        course_name: "微分積分学",
        teacher_group_id: 1411,
        teachers: ["笹井 理生"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2063,
        course_name: "力学",
        teacher_group_id: 1176,
        teachers: ["原田 正康"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2063,
        course_name: "力学",
        teacher_group_id: 1404,
        teachers: ["加藤 博和"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2017,
        course_name: "微分積分学II",
        teacher_group_id: 1627,
        teachers: ["中岡 宏行"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2178,
        course_name: "電磁気学",
        teacher_group_id: 1489,
        teachers: ["田中 信夫"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 452,
        course_name: "力学",
        teacher_group_id: 1459,
        teachers: ["中村 光"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1863,
        course_name: "電磁気学I",
        teacher_group_id: 1393,
        teachers: ["菅井 秀郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2063,
        course_name: "力学",
        teacher_group_id: 684,
        teachers: ["富田 英生"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1898,
        course_name: "化学基礎",
        teacher_group_id: 1462,
        teachers: ["斉藤 徹"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 1909,
        teachers: ["寺西 鎭男"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 91,
        course_name: "生物学基礎I",
        teacher_group_id: 820,
        teachers: ["肘井 直樹"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 221,
        course_name: "微分積分学",
        teacher_group_id: 157,
        teachers: ["藤江 双葉"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 221,
        course_name: "微分積分学",
        teacher_group_id: 267,
        teachers: ["張 紹良"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 847,
        course_name: "電磁気学",
        teacher_group_id: 2086,
        teachers: ["丹司"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 2190,
        teachers: ["護 雅史"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1491,
        course_name: "生物基礎",
        teacher_group_id: 1938,
        teachers: ["黒岩 堀"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 924,
        course_name: "生物学実験",
        teacher_group_id: 1697,
        teachers: ["大川 妙子"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1508,
        course_name: "情報リテラシー",
        teacher_group_id: 1013,
        teachers: ["渡邉 崇"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 1259,
        teachers: ["野水 勉"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2018,
        course_name: "微分積分学I",
        teacher_group_id: 2560,
        teachers: ["稲濱 譲"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2217,
        course_name: "生物学実験",
        teacher_group_id: 1869,
        teachers: ["寶珠山 稔"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1850,
        course_name: "電磁気学I",
        teacher_group_id: 750,
        teachers: ["黒澤 昌志"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2306,
        course_name: "生物学入門",
        teacher_group_id: 2316,
        teachers: ["斎藤 昇"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 1224,
        teachers: ["小長谷 重次"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 840,
        teachers: ["吉田 伸生"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1712,
        course_name: "電磁気学",
        teacher_group_id: 2459,
        teachers: ["丹司 敬義"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 452,
        course_name: "力学",
        teacher_group_id: 2583,
        teachers: ["秋本 晃一"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1072,
        course_name: "地球科学基礎II",
        teacher_group_id: 1353,
        teachers: ["平野 恭弘"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 847,
        course_name: "電磁気学",
        teacher_group_id: 642,
        teachers: ["大野 哲靖"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 122,
        teachers: ["長崎 正雅"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1324,
        teachers: ["今堀 慎治"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 735,
        teachers: ["田中 智之"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2252,
        course_name: "線形代数学",
        teacher_group_id: 860,
        teachers: ["谷川 好男"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1664,
        course_name: "生物学",
        teacher_group_id: 2265,
        teachers: ["伊藤 知彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 812,
        course_name: "数学入門",
        teacher_group_id: 2089,
        teachers: ["神保"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 161,
        course_name: "生物学入門",
        teacher_group_id: 978,
        teachers: ["夏原 由博"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 1081,
        teachers: ["山田 智明"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 834,
        teachers: ["宇沢 達"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 1267,
        teachers: ["高島 圭史"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 406,
        course_name: "化学基礎II",
        teacher_group_id: 505,
        teachers: ["多喜 正泰"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 899,
        teachers: ["天野 浩"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 55,
        course_name: "物理学入門",
        teacher_group_id: 23,
        teachers: ["槇 亙介"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1111,
        course_name: "生物学基礎I",
        teacher_group_id: 1291,
        teachers: ["阿部 秀樹"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 704,
        course_name: "情報リテラシー(文系)",
        teacher_group_id: 647,
        teachers: ["鈴木 泰博"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 119,
        teachers: ["富田 生"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 911,
        teachers: ["鳴瀧 彩絵"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1272,
        course_name: "地球科学入門",
        teacher_group_id: 1591,
        teachers: ["日高 洋"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 772,
        course_name: "物理学基礎II",
        teacher_group_id: 878,
        teachers: ["寺崎 一郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 189,
        course_name: "物理学入門",
        teacher_group_id: 125,
        teachers: ["北門 新作"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1794,
        course_name: "電磁気学II",
        teacher_group_id: 578,
        teachers: ["長尾 全寛"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2018,
        course_name: "微分積分学I",
        teacher_group_id: 1165,
        teachers: ["永尾 太郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 458,
        course_name: "電磁気学II",
        teacher_group_id: 646,
        teachers: ["西澤 典彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 91,
        course_name: "生物学基礎I",
        teacher_group_id: 1648,
        teachers: ["井原 邦夫"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 847,
        course_name: "電磁気学",
        teacher_group_id: 2536,
        teachers: ["堀川 直顕"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 1468,
        teachers: ["井上 泰志"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2132,
        course_name: "化学基礎II",
        teacher_group_id: 1342,
        teachers: ["北村 雅人"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 221,
        course_name: "微分積分学",
        teacher_group_id: 80,
        teachers: ["Garrigue Jacques"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 221,
        course_name: "微分積分学",
        teacher_group_id: 1351,
        teachers: ["斉藤 博"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 308,
        teachers: ["福塚 勇大"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 87,
        course_name: "生物学基礎II",
        teacher_group_id: 939,
        teachers: ["本間 道夫"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 407,
        course_name: "化学基礎II",
        teacher_group_id: 1279,
        teachers: ["村田 静昭"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1623,
        course_name: "物理実験",
        teacher_group_id: 1199,
        teachers: ["吉川 典彦"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1991,
        course_name: "物理学実験",
        teacher_group_id: 1587,
        teachers: ["堀田 昌宏"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 2103,
        teachers: ["美宅"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 407,
        course_name: "化学基礎II",
        teacher_group_id: 892,
        teachers: ["野呂 篤士"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1424,
        teachers: ["井手 一郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 790,
        teachers: ["増渕 雄一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1794,
        course_name: "電磁気学II",
        teacher_group_id: 1708,
        teachers: ["清水 裕彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1915,
        course_name: "量子力学1",
        teacher_group_id: 273,
        teachers: ["柴田 理尋"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 1266,
        teachers: ["太田 啓史"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 571,
        course_name: "数学通論",
        teacher_group_id: 680,
        teachers: ["藤原 一宏"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1738,
        teachers: ["加納 成男"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 55,
        course_name: "物理学入門",
        teacher_group_id: 1148,
        teachers: ["松尾 進"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 640,
        course_name: "化学基礎II",
        teacher_group_id: 1024,
        teachers: ["野村 信嘉"],
        course_group: "理系基礎(理系)",
        semester: "その他",
      },
      {
        course_id: 2017,
        course_name: "微分積分学II",
        teacher_group_id: 1532,
        teachers: ["岩田 哲"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 2101,
        teachers: ["宇澤"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 229,
        course_name: "化学実験",
        teacher_group_id: 166,
        teachers: ["Zinchenko"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 233,
        course_name: "物理学実験",
        teacher_group_id: 1556,
        teachers: ["居波 賢二"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1898,
        course_name: "化学基礎",
        teacher_group_id: 2049,
        teachers: ["前島"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2294,
        course_name: "化学入門",
        teacher_group_id: 1285,
        teachers: ["佐野 充"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 129,
        course_name: "力学II",
        teacher_group_id: 1098,
        teachers: ["伊藤 孝寛"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 583,
        teachers: ["大平 徹"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1699,
        course_name: "量子力学",
        teacher_group_id: 2420,
        teachers: ["北野 新作"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 735,
        teachers: ["田中 智之"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 812,
        course_name: "数学入門",
        teacher_group_id: 2516,
        teachers: ["神保 雅一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 2074,
        teachers: ["小鹿"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2063,
        course_name: "力学",
        teacher_group_id: 893,
        teachers: ["高嶋 圭史"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1898,
        course_name: "化学基礎",
        teacher_group_id: 1224,
        teachers: ["小長谷 重次"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 1754,
        teachers: ["栗本 宗明"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 853,
        teachers: ["斎藤 博"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 829,
        teachers: ["伊藤 裕貴"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1800,
        teachers: ["片山 尚幸"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 889,
        teachers: ["伊山 修"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 354,
        course_name: "電磁気学I",
        teacher_group_id: 1080,
        teachers: ["松村 年郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2101,
        course_name: "電磁気学",
        teacher_group_id: 2437,
        teachers: ["田渕 雅夫"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 2343,
        teachers: ["橋本 光靖"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 229,
        course_name: "化学実験",
        teacher_group_id: 1961,
        teachers: ["日比野 高士"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1114,
        teachers: ["古庄 英和"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 229,
        course_name: "化学実験",
        teacher_group_id: 1695,
        teachers: ["北 将樹"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 1266,
        teachers: ["太田 啓史"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1721,
        teachers: ["谷本 祥"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 233,
        course_name: "物理学実験",
        teacher_group_id: 658,
        teachers: ["中村 泰之"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1508,
        course_name: "情報リテラシー",
        teacher_group_id: 2131,
        teachers: ["内山"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1729,
        teachers: ["伏谷 瑞穂"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 2525,
        teachers: ["波多野 学"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 1818,
        teachers: ["珠玖 良昭"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1863,
        course_name: "電磁気学I",
        teacher_group_id: 932,
        teachers: ["草野 完也"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2045,
        course_name: "微分積分学",
        teacher_group_id: 853,
        teachers: ["斎藤 博"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 442,
        teachers: ["市野"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 2046,
        teachers: ["橋本"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 33,
        course_name: "線形代数学II",
        teacher_group_id: 1112,
        teachers: ["小澤 正直"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 458,
        course_name: "電磁気学II",
        teacher_group_id: 1576,
        teachers: ["澤 博"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 40,
        teachers: ["入山 恭寿"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 452,
        course_name: "力学",
        teacher_group_id: 724,
        teachers: ["山本 和弘"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2316,
        course_name: "生物",
        teacher_group_id: 1552,
        teachers: ["涌澤 伸哉"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2252,
        course_name: "線形代数学",
        teacher_group_id: 2343,
        teachers: ["橋本 光靖"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2318,
        course_name: "地球科学基礎",
        teacher_group_id: 1555,
        teachers: ["鷺谷 威"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 1406,
        teachers: ["松田 勇"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1601,
        course_name: "科学基礎",
        teacher_group_id: 1036,
        teachers: ["松岡 辰郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1,
        course_name: "微分積分学II",
        teacher_group_id: 1796,
        teachers: ["小見山 尚"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 1114,
        teachers: ["古庄 英和"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 1300,
        teachers: ["大沢 健夫"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 636,
        course_name: "生物学基礎2",
        teacher_group_id: 739,
        teachers: ["森 仁志"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 1406,
        teachers: ["松田 勇"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 328,
        course_name: "物理学基礎I",
        teacher_group_id: 1696,
        teachers: ["山崎 真理子"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 178,
        course_name: "図学",
        teacher_group_id: 113,
        teachers: ["長坂 今夫"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1469,
        course_name: "法学",
        teacher_group_id: 136,
        teachers: ["金 彦叔"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1336,
        course_name: "力学II",
        teacher_group_id: 1703,
        teachers: ["松山 智至"],
        course_group: "理系基礎(理系)",
        semester: "その他",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 1065,
        teachers: ["寺澤 祐高"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1600,
        course_name: "化学",
        teacher_group_id: 1034,
        teachers: ["伊東 裕"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 2408,
        teachers: ["齋藤 昇"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1548,
        course_name: "生物",
        teacher_group_id: 2429,
        teachers: ["松浦 徹"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 1565,
        teachers: ["長岡 正隆"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 252,
        course_name: "線形",
        teacher_group_id: 1300,
        teachers: ["大沢 健夫"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1143,
        course_name: "力学II",
        teacher_group_id: 1338,
        teachers: ["水野 幸治"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 2063,
        course_name: "力学",
        teacher_group_id: 2240,
        teachers: ["田川 浩"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 469,
        course_name: "線型代数学I",
        teacher_group_id: 2669,
        teachers: ["森 立平"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 1114,
        teachers: ["古庄 英和"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1072,
        course_name: "地球科学基礎II",
        teacher_group_id: 1226,
        teachers: ["杉谷 健一郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 941,
        teachers: ["山上 滋"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 129,
        course_name: "力学II",
        teacher_group_id: 1084,
        teachers: ["松本 健郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 407,
        course_name: "化学基礎II",
        teacher_group_id: 1078,
        teachers: ["澤田 佳代"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 229,
        course_name: "化学実験",
        teacher_group_id: 1758,
        teachers: ["村井 征史"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 967,
        course_name: "電磁気学",
        teacher_group_id: 1079,
        teachers: ["横水 康伸"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 1486,
        teachers: ["木村 眞"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 534,
        course_name: "複素関数論",
        teacher_group_id: 1388,
        teachers: ["松本 耕二"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 1296,
        teachers: ["川村 友美"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 513,
        teachers: ["萩尾 健史", "伊藤 英人", "神谷 由紀子"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 138,
        course_name: "線形代数学I",
        teacher_group_id: 379,
        teachers: ["増渕"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 87,
        course_name: "生物学基礎II",
        teacher_group_id: 613,
        teachers: ["山篠", "森"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 633,
        teachers: ["松下 泰幸", "福島 和彦"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 816,
        teachers: ["小鹿 一"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1913,
        course_name: "化学実験",
        teacher_group_id: 2197,
        teachers: ["張", "山本", "小長谷"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1861,
        course_name: "微分積分学I",
        teacher_group_id: 933,
        teachers: ["中島 誠"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 666,
        course_name: "地球科学基礎I",
        teacher_group_id: 764,
        teachers: ["永峰 康一郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2027,
        course_name: "化学基礎II",
        teacher_group_id: 817,
        teachers: ["中野 秀雄"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 221,
        course_name: "微分積分学",
        teacher_group_id: 1296,
        teachers: ["川村 友美"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 650,
        course_name: "電磁気学I",
        teacher_group_id: 922,
        teachers: ["小林 義明"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 1597,
        course_name: "地球科学",
        teacher_group_id: 1554,
        teachers: ["渡邊 誠一郎"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 761,
        teachers: ["吉田 久美"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 40,
        teachers: ["入山 恭寿"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 2132,
        course_name: "化学基礎II",
        teacher_group_id: 1224,
        teachers: ["小長谷 重次"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 637,
        course_name: "生物学基礎II",
        teacher_group_id: 748,
        teachers: ["一柳 健司"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 926,
        teachers: ["高木 秀夫"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 410,
        course_name: "物理学基礎I",
        teacher_group_id: 795,
        teachers: ["土川 覚"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 1271,
        course_name: "地球科学基礎I",
        teacher_group_id: 1554,
        teachers: ["渡邊 誠一郎"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 87,
        course_name: "生物学基礎II",
        teacher_group_id: 739,
        teachers: ["森 仁志"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
      {
        course_id: 86,
        course_name: "化学基礎",
        teacher_group_id: 761,
        teachers: ["吉田 久美"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 731,
        course_name: "化学基礎II",
        teacher_group_id: 742,
        teachers: ["松宮 弘明"],
        course_group: "理系基礎(理系)",
        semester: "前期",
      },
      {
        course_id: 129,
        course_name: "力学II",
        teacher_group_id: 724,
        teachers: ["山本 和弘"],
        course_group: "理系基礎(理系)",
        semester: "後期",
      },
    ],
    "理系基礎(文系)": [
      {
        course_id: 1975,
        course_name: "地球科学入門",
        teacher_group_id: 674,
        teachers: ["桂木 洋光"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 1975,
        course_name: "地球科学入門",
        teacher_group_id: 1000,
        teachers: ["氏原 温"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 705,
        course_name: "情報リテラシー",
        teacher_group_id: 787,
        teachers: ["佐藤 弘毅"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 523,
        course_name: "情報リテラシー文系",
        teacher_group_id: 787,
        teachers: ["佐藤 弘毅"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 523,
        course_name: "情報リテラシー文系",
        teacher_group_id: 1013,
        teachers: ["渡邉 崇"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 362,
        course_name: "地球科学入門",
        teacher_group_id: 527,
        teachers: ["山本", "坪木"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 1975,
        course_name: "地球科学入門",
        teacher_group_id: 1675,
        teachers: ["並木 敦子"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 161,
        course_name: "生物学入門",
        teacher_group_id: 537,
        teachers: ["杉田 護", "日比 正彦", "木下 専"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 189,
        course_name: "物理学入門",
        teacher_group_id: 662,
        teachers: ["三浦 裕一"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 890,
        course_name: "物理学入門",
        teacher_group_id: 988,
        teachers: ["小川 信之"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 702,
        course_name: "情報リテラシー(文系)",
        teacher_group_id: 787,
        teachers: ["佐藤 弘毅"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 2100,
        course_name: "化学入門",
        teacher_group_id: 1564,
        teachers: ["安田 耕二"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 1975,
        course_name: "地球科学入門",
        teacher_group_id: 1353,
        teachers: ["平野 恭弘"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 189,
        course_name: "物理学入門",
        teacher_group_id: 1038,
        teachers: ["松本 浩典"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 532,
        course_name: "情報リテラシー文系",
        teacher_group_id: 654,
        teachers: ["栗本 英和"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 2064,
        course_name: "数学入門",
        teacher_group_id: 897,
        teachers: ["丹羽 伸二"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 161,
        course_name: "生物学入門",
        teacher_group_id: 1138,
        teachers: ["木下 専"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 1975,
        course_name: "地球科学入門",
        teacher_group_id: 1434,
        teachers: ["古本 宗充"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 885,
        course_name: "情報リテラシー",
        teacher_group_id: 981,
        teachers: ["内山 知実"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 149,
        course_name: "数学入門",
        teacher_group_id: 658,
        teachers: ["中村 泰之"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 702,
        course_name: "情報リテラシー(文系)",
        teacher_group_id: 1100,
        teachers: ["三輪 和久"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 189,
        course_name: "物理学入門",
        teacher_group_id: 125,
        teachers: ["北門 新作"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 159,
        course_name: "日本国憲法",
        teacher_group_id: 734,
        teachers: ["河北 洋介"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 149,
        course_name: "数学入門",
        teacher_group_id: 87,
        teachers: ["BUSCEMI Francesco"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 161,
        course_name: "生物学入門",
        teacher_group_id: 1517,
        teachers: ["依田 憲"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 522,
        course_name: "情報リテラシー文系",
        teacher_group_id: 647,
        teachers: ["鈴木 泰博"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 161,
        course_name: "生物学入門",
        teacher_group_id: 98,
        teachers: ["青木 摂之"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 1984,
        course_name: "情報リテラシー文系",
        teacher_group_id: 697,
        teachers: ["重見 晋也"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 55,
        course_name: "物理学入門",
        teacher_group_id: 1148,
        teachers: ["松尾 進"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 1975,
        course_name: "地球科学入門",
        teacher_group_id: 1019,
        teachers: ["鈴木 和博"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 987,
        course_name: "微分積分学I",
        teacher_group_id: 1112,
        teachers: ["小澤 正直"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 1959,
        course_name: "生物学入門",
        teacher_group_id: 1113,
        teachers: ["梶村 恒"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 2195,
        course_name: "物理学入門",
        teacher_group_id: 1595,
        teachers: ["渡邉 尚彦"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 711,
        course_name: "化学入門",
        teacher_group_id: 793,
        teachers: ["古賀 伸明"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 149,
        course_name: "数学入門",
        teacher_group_id: 1072,
        teachers: ["金森 敬文"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 161,
        course_name: "生物学入門",
        teacher_group_id: 864,
        teachers: ["瀧口 金吾"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 161,
        course_name: "生物学入門",
        teacher_group_id: 978,
        teachers: ["夏原 由博"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 2137,
        course_name: "情報リテラシー",
        teacher_group_id: 1248,
        teachers: ["川合 伸幸"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 362,
        course_name: "地球科学入門",
        teacher_group_id: 1655,
        teachers: ["城野 信一"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 523,
        course_name: "情報リテラシー文系",
        teacher_group_id: 844,
        teachers: ["渡辺 崇"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 362,
        course_name: "地球科学入門",
        teacher_group_id: 406,
        teachers: ["高橋"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 2100,
        course_name: "化学入門",
        teacher_group_id: 1078,
        teachers: ["澤田 佳代"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 878,
        course_name: "生物学基礎",
        teacher_group_id: 978,
        teachers: ["夏原 由博"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 1959,
        course_name: "生物学入門",
        teacher_group_id: 630,
        teachers: ["犬飼 義明", "梶村 恒"],
        course_group: "理系基礎(文系)",
        semester: "後期",
      },
      {
        course_id: 877,
        course_name: "生物基礎",
        teacher_group_id: 978,
        teachers: ["夏原 由博"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 523,
        course_name: "情報リテラシー文系",
        teacher_group_id: 981,
        teachers: ["内山 知実"],
        course_group: "理系基礎(文系)",
        semester: "前期",
      },
      {
        course_id: 473,
        course_name: "数学入門",
        teacher_group_id: 610,
        teachers: ["片岡 紀智"],
        course_group: "理系基礎(文系)",
        semester: "春学期",
      },
    ],
    文系基礎科目: [
      {
        course_id: 483,
        course_name: "比較教育論",
        teacher_group_id: 614,
        teachers: ["服部 美奈"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 968,
        course_name: "教育学",
        teacher_group_id: 581,
        teachers: ["内田 良"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 62,
        course_name: "地理学",
        teacher_group_id: 27,
        teachers: ["伊賀 聖屋"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 813,
        teachers: ["北神 慎司"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2099,
        course_name: "社会学",
        teacher_group_id: 1076,
        teachers: ["黒田 由彦"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 343,
        course_name: "統計学",
        teacher_group_id: 763,
        teachers: ["増田 淳矢"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 343,
        course_name: "統計学",
        teacher_group_id: 886,
        teachers: ["中野 牧子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 779,
        teachers: ["柴田 和久"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2010,
        course_name: "歴史学",
        teacher_group_id: 798,
        teachers: ["古尾谷 知浩"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 52,
        course_name: "国際開発学",
        teacher_group_id: 1002,
        teachers: ["岡田 勇"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 299,
        course_name: "心理学II",
        teacher_group_id: 1137,
        teachers: ["窪田 由紀"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 472,
        course_name: "比較文化論",
        teacher_group_id: 594,
        teachers: ["山本 直人"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1796,
        course_name: "地理学",
        teacher_group_id: 588,
        teachers: ["高橋 誠"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 472,
        course_name: "比較文化論",
        teacher_group_id: 645,
        teachers: ["齋藤 文俊"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 826,
        teachers: ["川口 潤"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1880,
        course_name: "国際開発学",
        teacher_group_id: 913,
        teachers: ["日下 渉"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 2127,
        course_name: "心理学",
        teacher_group_id: 1346,
        teachers: ["大平 英樹"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1999,
        course_name: "心理学II",
        teacher_group_id: 727,
        teachers: ["松本 真理子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 1677,
        teachers: ["中村 さやか"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 52,
        course_name: "国際開発学",
        teacher_group_id: 991,
        teachers: ["藤川 清史"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1955,
        course_name: "教育学",
        teacher_group_id: 570,
        teachers: ["李 正連"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2010,
        course_name: "歴史学",
        teacher_group_id: 778,
        teachers: ["池内 敏"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 52,
        course_name: "国際開発学",
        teacher_group_id: 1214,
        teachers: ["西川 由紀子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 62,
        course_name: "地理学",
        teacher_group_id: 993,
        teachers: ["岡本 耕平"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1956,
        course_name: "地理学",
        teacher_group_id: 999,
        teachers: ["奥貫 圭一"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 1542,
        teachers: ["吉武 純夫"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 799,
        teachers: ["加藤 靖恵"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 381,
        teachers: ["柴田"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1857,
        course_name: "政治学",
        teacher_group_id: 2,
        teachers: ["加藤 哲理"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 52,
        course_name: "国際開発学",
        teacher_group_id: 20,
        teachers: ["島田 弦"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 299,
        course_name: "心理学II",
        teacher_group_id: 1195,
        teachers: ["野口 裕之"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2127,
        course_name: "心理学",
        teacher_group_id: 1195,
        teachers: ["野口 裕之"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 62,
        course_name: "地理学",
        teacher_group_id: 2119,
        teachers: ["溝口"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1893,
        course_name: "経営学",
        teacher_group_id: 1275,
        teachers: ["山田 基成"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 1085,
        teachers: ["片平 健太郎"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 649,
        teachers: ["中村 靖子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2053,
        course_name: "日本国憲法",
        teacher_group_id: 1824,
        teachers: ["榎澤 幸広"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 645,
        teachers: ["齋藤 文俊"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 298,
        course_name: "心理学",
        teacher_group_id: 813,
        teachers: ["北神 慎司"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2008,
        course_name: "統計学",
        teacher_group_id: 776,
        teachers: ["加藤 尚史"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 253,
        course_name: "歴史学",
        teacher_group_id: 1245,
        teachers: ["梶原 義実"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 523,
        course_name: "情報リテラシー文系",
        teacher_group_id: 787,
        teachers: ["佐藤 弘毅"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 75,
        course_name: "哲学",
        teacher_group_id: 1043,
        teachers: ["吉田 純"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 483,
        course_name: "比較教育論",
        teacher_group_id: 1588,
        teachers: ["松本 麻人"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1700,
        course_name: "国際報道論",
        teacher_group_id: 2430,
        teachers: ["春名 幹男"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 52,
        course_name: "国際開発学",
        teacher_group_id: 794,
        teachers: ["染矢 将和"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1955,
        course_name: "教育学",
        teacher_group_id: 1398,
        teachers: ["牧野 篤"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 472,
        course_name: "比較文化論",
        teacher_group_id: 1083,
        teachers: ["宮地 朝子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 299,
        course_name: "心理学II",
        teacher_group_id: 786,
        teachers: ["五十嵐 裕"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 213,
        course_name: "法学",
        teacher_group_id: 1321,
        teachers: ["岡本 裕樹"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1880,
        course_name: "国際開発学",
        teacher_group_id: 1601,
        teachers: ["石川 知子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 472,
        course_name: "比較文化論",
        teacher_group_id: 1573,
        teachers: ["東 賢太朗"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 62,
        course_name: "地理学",
        teacher_group_id: 2243,
        teachers: ["溝口 常俊"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1525,
        course_name: "歴史学(中国史)",
        teacher_group_id: 2090,
        teachers: ["井上"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2099,
        course_name: "社会学",
        teacher_group_id: 1658,
        teachers: ["立川 雅司"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 41,
        course_name: "経済学B",
        teacher_group_id: 1011,
        teachers: ["小堀 聡"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 2169,
        course_name: "健康・スポーツ科学実習(ゴルフ)",
        teacher_group_id: 1433,
        teachers: ["高橋 義雄"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 2019,
        course_name: "文学",
        teacher_group_id: 887,
        teachers: ["田村 加代子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1880,
        course_name: "国際開発学",
        teacher_group_id: 326,
        teachers: ["Christian Otchia"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 2010,
        course_name: "歴史学",
        teacher_group_id: 1546,
        teachers: ["加納 修"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 159,
        course_name: "日本国憲法",
        teacher_group_id: 1314,
        teachers: ["菅原 真"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1596,
        course_name: "文系基礎",
        teacher_group_id: 999,
        teachers: ["奥貫 圭一"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 243,
        course_name: "教育学",
        teacher_group_id: 596,
        teachers: ["アラニ"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 2134,
        course_name: "心理学I",
        teacher_group_id: 1229,
        teachers: ["田邉 宏樹"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1525,
        course_name: "歴史学(中国史)",
        teacher_group_id: 1343,
        teachers: ["井上 進"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 1346,
        teachers: ["大平 英樹"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2127,
        course_name: "心理学",
        teacher_group_id: 1443,
        teachers: ["村瀬 聡美"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1880,
        course_name: "国際開発学",
        teacher_group_id: 1086,
        teachers: ["東村 岳"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 134,
        teachers: ["鈴木 敦命"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2127,
        course_name: "心理学",
        teacher_group_id: 1678,
        teachers: ["唐沢 穣"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 1282,
        teachers: ["大井田 はる"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 415,
        teachers: ["大井田"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 135,
        course_name: "国際関係論",
        teacher_group_id: 1939,
        teachers: ["定形"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 120,
        course_name: "経営学",
        teacher_group_id: 1249,
        teachers: ["星野 崇宏"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 974,
        teachers: ["佐野 誠子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 253,
        course_name: "歴史学",
        teacher_group_id: 979,
        teachers: ["和田 光弘"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 159,
        course_name: "日本国憲法",
        teacher_group_id: 419,
        teachers: ["川畑"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 1159,
        teachers: ["町田 健"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 111,
        course_name: "経済学A",
        teacher_group_id: 61,
        teachers: ["工藤 教孝"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 52,
        course_name: "国際開発学",
        teacher_group_id: 1005,
        teachers: ["伊東 早苗"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 62,
        course_name: "地理学",
        teacher_group_id: 2501,
        teachers: ["横山 智"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 890,
        course_name: "物理学入門",
        teacher_group_id: 988,
        teachers: ["小川 信之"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1999,
        course_name: "心理学II",
        teacher_group_id: 655,
        teachers: ["中谷 素之"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 135,
        course_name: "国際関係論",
        teacher_group_id: 1077,
        teachers: ["野村 康"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 1465,
        teachers: ["唐澤 穣"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 75,
        course_name: "哲学",
        teacher_group_id: 491,
        teachers: ["香春"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 62,
        course_name: "地理学",
        teacher_group_id: 1241,
        teachers: ["伊賀 聖也"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1933,
        course_name: "法学",
        teacher_group_id: 1270,
        teachers: ["水留 正流"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1933,
        course_name: "法学",
        teacher_group_id: 1407,
        teachers: ["中野 妙子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1619,
        course_name: "戦後政治史",
        teacher_group_id: 1153,
        teachers: ["小野 耕二"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 2229,
        teachers: ["多和田 眞"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 75,
        course_name: "哲学",
        teacher_group_id: 508,
        teachers: ["和田 壽弘"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 343,
        course_name: "統計学",
        teacher_group_id: 1919,
        teachers: ["中野"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 75,
        course_name: "哲学",
        teacher_group_id: 678,
        teachers: ["松澤 和宏"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 120,
        course_name: "経営学",
        teacher_group_id: 963,
        teachers: ["宮崎 正也"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 159,
        course_name: "日本国憲法",
        teacher_group_id: 1297,
        teachers: ["川畑 博昭"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 861,
        teachers: ["滝川 睦"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2102,
        course_name: "心理学I",
        teacher_group_id: 1592,
        teachers: ["小野 美和"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 472,
        course_name: "比較文化論",
        teacher_group_id: 284,
        teachers: ["東"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 2010,
        course_name: "歴史学",
        teacher_group_id: 2126,
        teachers: ["古尾谷"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 135,
        course_name: "国際関係論",
        teacher_group_id: 2434,
        teachers: ["西田 敏宏"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 253,
        course_name: "歴史学",
        teacher_group_id: 1042,
        teachers: ["羽賀 祥二"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 1021,
        teachers: ["田邊 宏樹"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 159,
        course_name: "日本国憲法",
        teacher_group_id: 734,
        teachers: ["河北 洋介"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 2282,
        course_name: "経済学",
        teacher_group_id: 1016,
        teachers: ["藤田 真哉"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1729,
        course_name: "表象と文化",
        teacher_group_id: 2524,
        teachers: ["阿部 泰郎"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 159,
        course_name: "日本国憲法",
        teacher_group_id: 1111,
        teachers: ["増沢 陽子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 403,
        course_name: "社会学",
        teacher_group_id: 1278,
        teachers: ["室井 研二"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1897,
        course_name: "政治学",
        teacher_group_id: 1784,
        teachers: ["近藤 康史"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 120,
        course_name: "経営学",
        teacher_group_id: 67,
        teachers: ["髙橋 秀徳"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 120,
        course_name: "経営学",
        teacher_group_id: 1368,
        teachers: ["太田 亘"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 343,
        course_name: "統計学",
        teacher_group_id: 1072,
        teachers: ["金森 敬文"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1334,
        course_name: "法学",
        teacher_group_id: 1702,
        teachers: ["波多野 敏"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1933,
        course_name: "法学",
        teacher_group_id: 908,
        teachers: ["古川 信彦"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 51,
        course_name: "国際開発論",
        teacher_group_id: 1601,
        teachers: ["石川 知子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1933,
        course_name: "法学",
        teacher_group_id: 412,
        teachers: ["和田"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1999,
        course_name: "心理学II",
        teacher_group_id: 1143,
        teachers: ["金井 篤子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1880,
        course_name: "国際開発学",
        teacher_group_id: 2421,
        teachers: ["山形 英郎"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 41,
        course_name: "経済学B",
        teacher_group_id: 1603,
        teachers: ["伊藤 カンナ"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 298,
        course_name: "心理学",
        teacher_group_id: 1143,
        teachers: ["金井 篤子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 299,
        course_name: "心理学II",
        teacher_group_id: 928,
        teachers: ["清河 幸子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 1391,
        teachers: ["清水 純夫"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 299,
        course_name: "心理学II",
        teacher_group_id: 1640,
        teachers: ["光永 悠彦"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1897,
        course_name: "政治学",
        teacher_group_id: 1363,
        teachers: ["増田 知子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 75,
        course_name: "哲学",
        teacher_group_id: 832,
        teachers: ["宮原 勇"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 52,
        course_name: "国際開発学",
        teacher_group_id: 989,
        teachers: ["伊藤 早苗"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2019,
        course_name: "文学",
        teacher_group_id: 1764,
        teachers: ["川本 悠紀子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1955,
        course_name: "教育学",
        teacher_group_id: 961,
        teachers: ["伊藤 彰浩"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 472,
        course_name: "比較文化論",
        teacher_group_id: 1315,
        teachers: ["林 謙一郎"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 2011,
        course_name: "心理学I",
        teacher_group_id: 1280,
        teachers: ["伊藤 義美"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 135,
        course_name: "国際関係論",
        teacher_group_id: 1192,
        teachers: ["木下 郁夫"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 298,
        course_name: "心理学",
        teacher_group_id: 134,
        teachers: ["鈴木 敦命"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1509,
        course_name: "歴史の研究方法を学ぶ",
        teacher_group_id: 410,
        teachers: ["佐藤"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1971,
        course_name: "国際関係論",
        teacher_group_id: 660,
        teachers: ["三浦 聡"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 2472,
        teachers: ["坪井 秀人"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1897,
        course_name: "政治学",
        teacher_group_id: 97,
        teachers: ["後 房雄"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1893,
        course_name: "経営学",
        teacher_group_id: 1308,
        teachers: ["犬塚 篤"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 124,
        teachers: ["荒山 裕行"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2127,
        course_name: "心理学",
        teacher_group_id: 2266,
        teachers: ["石井 澄"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 343,
        course_name: "統計学",
        teacher_group_id: 382,
        teachers: ["増田"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1555,
        course_name: "経済学",
        teacher_group_id: 686,
        teachers: ["片山 敬章"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 243,
        course_name: "教育学",
        teacher_group_id: 215,
        teachers: ["加藤 鉦治"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 51,
        course_name: "国際開発論",
        teacher_group_id: 20,
        teachers: ["島田 弦"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1999,
        course_name: "心理学II",
        teacher_group_id: 986,
        teachers: ["河野 荘子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 253,
        course_name: "歴史学",
        teacher_group_id: 1315,
        teachers: ["林 謙一郎"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 8,
        course_name: "政治学",
        teacher_group_id: 1153,
        teachers: ["小野 耕二"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 213,
        course_name: "法学",
        teacher_group_id: 147,
        teachers: ["Frank Bennett"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 299,
        course_name: "心理学II",
        teacher_group_id: 331,
        teachers: ["田附 紘平"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1955,
        course_name: "教育学",
        teacher_group_id: 1023,
        teachers: ["松下 晴彦"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 1218,
        teachers: ["臼井 恵美子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 298,
        course_name: "心理学",
        teacher_group_id: 331,
        teachers: ["田附 紘平"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 343,
        course_name: "統計学",
        teacher_group_id: 2516,
        teachers: ["神保 雅一"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1955,
        course_name: "教育学",
        teacher_group_id: 949,
        teachers: ["横山 悦生"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 159,
        course_name: "日本国憲法",
        teacher_group_id: 1916,
        teachers: ["愛敬"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1824,
        course_name: "哲学",
        teacher_group_id: 1613,
        teachers: ["岩崎 陽一"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1199,
        course_name: "比較教育学",
        teacher_group_id: 1469,
        teachers: ["西野 節男"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 75,
        course_name: "哲学",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 828,
        course_name: "法学入門",
        teacher_group_id: 914,
        teachers: ["古川 伸彦"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 403,
        course_name: "社会学",
        teacher_group_id: 1284,
        teachers: ["青木 聡子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 376,
        course_name: "比較教育論",
        teacher_group_id: 436,
        teachers: ["西野"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 298,
        course_name: "心理学",
        teacher_group_id: 2609,
        teachers: ["斉藤 洋典"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1897,
        course_name: "政治学",
        teacher_group_id: 2141,
        teachers: ["田村"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 1178,
        teachers: ["小川 光"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1933,
        course_name: "法学",
        teacher_group_id: 1209,
        teachers: ["本 秀紀"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 1652,
        teachers: ["石井 敬子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 159,
        course_name: "日本国憲法",
        teacher_group_id: 218,
        teachers: ["建石 真公子"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 342,
        course_name: "心理学I",
        teacher_group_id: 1678,
        teachers: ["唐沢 穣"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 795,
        course_name: "法学",
        teacher_group_id: 914,
        teachers: ["古川 伸彦"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 298,
        course_name: "心理学",
        teacher_group_id: 831,
        teachers: ["石井 秀宗"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 685,
        teachers: ["立石 寛"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 159,
        course_name: "日本国憲法",
        teacher_group_id: 96,
        teachers: ["大河内 憲法"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 846,
        teachers: ["荒渡 良"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 472,
        course_name: "比較文化論",
        teacher_group_id: 1423,
        teachers: ["木俣 元一"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 41,
        course_name: "経済学B",
        teacher_group_id: 56,
        teachers: ["福澤 直樹"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1955,
        course_name: "教育学",
        teacher_group_id: 726,
        teachers: ["吉川 卓治"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1041,
        course_name: "人間と行動",
        teacher_group_id: 1185,
        teachers: ["齋藤 洋典"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 135,
        course_name: "国際関係論",
        teacher_group_id: 79,
        teachers: ["姜 東局"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1893,
        course_name: "経営学",
        teacher_group_id: 1003,
        teachers: ["高橋 秀徳"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 253,
        course_name: "歴史学",
        teacher_group_id: 1343,
        teachers: ["井上 進"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 2053,
        course_name: "日本国憲法",
        teacher_group_id: 84,
        teachers: ["赤渕 芳宏"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 41,
        course_name: "経済学B",
        teacher_group_id: 1438,
        teachers: ["金井 雄一"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 298,
        course_name: "心理学",
        teacher_group_id: 1465,
        teachers: ["唐澤 穣"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1586,
        course_name: "憲法学",
        teacher_group_id: 923,
        teachers: ["大河内 美紀"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 2016,
        teachers: ["多和田"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 52,
        course_name: "国際開発学",
        teacher_group_id: 2272,
        teachers: ["木村 宏恒"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 2173,
        teachers: ["加藤 國安"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 2019,
        course_name: "文学",
        teacher_group_id: 1420,
        teachers: ["大井田 晴彦"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 253,
        course_name: "歴史学",
        teacher_group_id: 238,
        teachers: ["稲葉 伸道"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 1933,
        course_name: "法学",
        teacher_group_id: 830,
        teachers: ["神保 文夫"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 403,
        course_name: "社会学",
        teacher_group_id: 503,
        teachers: ["丹邉 宣彦"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 41,
        course_name: "経済学B",
        teacher_group_id: 16,
        teachers: ["隠岐 さや香"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 2053,
        course_name: "日本国憲法",
        teacher_group_id: 866,
        teachers: ["飯野 賢一"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 483,
        course_name: "比較教育論",
        teacher_group_id: 1469,
        teachers: ["西野 節男"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 8,
        course_name: "政治学",
        teacher_group_id: 31,
        teachers: ["荒見 玲子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 75,
        course_name: "哲学",
        teacher_group_id: 2383,
        teachers: ["田村 均"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 75,
        course_name: "哲学",
        teacher_group_id: 996,
        teachers: ["米山 優"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 52,
        course_name: "国際開発学",
        teacher_group_id: 2095,
        teachers: ["宇佐見"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 699,
        teachers: ["清水 克俊"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1897,
        course_name: "政治学",
        teacher_group_id: 382,
        teachers: ["増田"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 52,
        course_name: "国際開発学",
        teacher_group_id: 2508,
        teachers: ["宇佐見 晃一"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 472,
        course_name: "比較文化論",
        teacher_group_id: 1101,
        teachers: ["小川 正廣"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 366,
        course_name: "文学",
        teacher_group_id: 992,
        teachers: ["塩村 耕"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1933,
        course_name: "法学",
        teacher_group_id: 879,
        teachers: ["國分 典子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 687,
        teachers: ["安達 貴教"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 52,
        course_name: "国際開発学",
        teacher_group_id: 1115,
        teachers: ["新海 尚子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1977,
        course_name: "経済学A",
        teacher_group_id: 710,
        teachers: ["柳原 光芳"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 135,
        course_name: "国際関係論",
        teacher_group_id: 518,
        teachers: ["山田 高敬"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 403,
        course_name: "社会学",
        teacher_group_id: 1141,
        teachers: ["田中 重好"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1933,
        course_name: "法学",
        teacher_group_id: 852,
        teachers: ["原田 綾子"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 299,
        course_name: "心理学II",
        teacher_group_id: 831,
        teachers: ["石井 秀宗"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 1334,
        course_name: "法学",
        teacher_group_id: 1255,
        teachers: ["松中 学"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 135,
        course_name: "国際関係論",
        teacher_group_id: 118,
        teachers: ["水島 朋則"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
      {
        course_id: 120,
        course_name: "経営学",
        teacher_group_id: 669,
        teachers: ["中島 英喜"],
        course_group: "文系基礎科目",
        semester: "後期",
      },
      {
        course_id: 2053,
        course_name: "日本国憲法",
        teacher_group_id: 923,
        teachers: ["大河内 美紀"],
        course_group: "文系基礎科目",
        semester: "前期",
      },
    ],
    "健康・スポーツ科学": [
      {
        course_id: 1786,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 880,
        teachers: ["佐々木 康"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 686,
        teachers: ["片山 敬章"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2270,
        course_name: "健康・スポーツ科学実習(テニス)",
        teacher_group_id: 885,
        teachers: ["山本 裕二"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 885,
        teachers: ["山本 裕二"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2052,
        course_name: "健康・スポーツ科学実習(ソフトボール)",
        teacher_group_id: 863,
        teachers: ["島 典広"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1978,
        course_name: "健康・スポーツ科学実習(アルティメット)",
        teacher_group_id: 686,
        teachers: ["片山 敬章"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2057,
        course_name: "健康・スポーツ科学実習(バスケットボール)",
        teacher_group_id: 880,
        teachers: ["佐々木 康"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2112,
        course_name: "健康・スポーツ科学実習(卓球)",
        teacher_group_id: 1133,
        teachers: ["蛭田 秀一"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2026,
        course_name: "健康・スポーツ科学実習(フィットネス)",
        teacher_group_id: 810,
        teachers: ["桶野 留美"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1938,
        course_name: "健康・スポーツ科学実習(水泳)",
        teacher_group_id: 1445,
        teachers: ["布目 寛幸"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 1014,
        teachers: ["横山 慶子"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2026,
        course_name: "健康・スポーツ科学実習(フィットネス)",
        teacher_group_id: 870,
        teachers: ["野中 壽子"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 752,
        course_name: "健康・スポーツ科学実習II(サッカー)",
        teacher_group_id: 841,
        teachers: ["秋間 広"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 1938,
        course_name: "健康・スポーツ科学実習(水泳)",
        teacher_group_id: 502,
        teachers: ["水藤 弘吏"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1906,
        course_name: "健康・スポーツ科学実習(ゴルフ)",
        teacher_group_id: 2574,
        teachers: ["西田 保"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2026,
        course_name: "健康・スポーツ科学実習(フィットネス)",
        teacher_group_id: 1383,
        teachers: ["島岡 みどり"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2242,
        course_name: "健康・スポーツ科学実習(バドミントン)",
        teacher_group_id: 2403,
        teachers: ["冨樫 健二"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1938,
        course_name: "健康・スポーツ科学実習(水泳)",
        teacher_group_id: 880,
        teachers: ["佐々木 康"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2026,
        course_name: "健康・スポーツ科学実習(フィットネス)",
        teacher_group_id: 841,
        teachers: ["秋間 広"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2058,
        course_name: "健康・スポーツ科学実習(バスケットボール))",
        teacher_group_id: 880,
        teachers: ["佐々木 康"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2317,
        course_name: "健康・スポーツ科学実習(ゴルフ)",
        teacher_group_id: 2514,
        teachers: ["金尾 洋治"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2065,
        course_name: "健康・スポーツ科学実習(太極拳)",
        teacher_group_id: 2156,
        teachers: ["張"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2254,
        course_name: "健康・スポーツ科学実習(サッカー)",
        teacher_group_id: 1445,
        teachers: ["布目 寛幸"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2024,
        course_name: "健康・スポーツ科学実習(エアロビクス)",
        teacher_group_id: 810,
        teachers: ["桶野 留美"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 821,
        course_name: "健康・スポーツ科学実習I(ソフトボール)",
        teacher_group_id: 1716,
        teachers: ["山本 真史"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 915,
        course_name: "健康・スポーツ科学実習I(卓球)",
        teacher_group_id: 1186,
        teachers: ["田中 憲子"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 753,
        course_name: "健康・スポーツ科学実習I(サッカー)",
        teacher_group_id: 841,
        teachers: ["秋間 広"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2308,
        course_name: "健康・スポーツ科学実習(フットサル)",
        teacher_group_id: 1445,
        teachers: ["布目 寛幸"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 1813,
        teachers: ["坂野 僚一"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2242,
        course_name: "健康・スポーツ科学実習(バドミントン)",
        teacher_group_id: 1896,
        teachers: ["湯 海鵬"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2317,
        course_name: "健康・スポーツ科学実習(ゴルフ)",
        teacher_group_id: 2568,
        teachers: ["秦 真人"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2025,
        course_name: "健康・スポーツ科学実習(フィットネス)",
        teacher_group_id: 1186,
        teachers: ["田中 憲子"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2168,
        course_name: "健康・スポーツ科学実習(アーチェリー)",
        teacher_group_id: 1431,
        teachers: ["出原 泰明"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2052,
        course_name: "健康・スポーツ科学実習(ソフトボール)",
        teacher_group_id: 2132,
        teachers: ["秦"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 821,
        course_name: "健康・スポーツ科学実習I(ソフトボール)",
        teacher_group_id: 953,
        teachers: ["水野 貴正"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2242,
        course_name: "健康・スポーツ科学実習(バドミントン)",
        teacher_group_id: 885,
        teachers: ["山本 裕二"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2025,
        course_name: "健康・スポーツ科学実習(フィットネス)",
        teacher_group_id: 953,
        teachers: ["水野 貴正"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 805,
        course_name: "健康・スポーツ科学実習I(バドミントン)",
        teacher_group_id: 686,
        teachers: ["片山 敬章"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2268,
        course_name: "健康・スポーツ科学実習(アルティメット)",
        teacher_group_id: 880,
        teachers: ["佐々木 康"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 1982,
        course_name: "健康・スポーツ科学実習I(テニス)",
        teacher_group_id: 885,
        teachers: ["山本 裕二"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1939,
        course_name: "健康・スポーツ科学実習",
        teacher_group_id: 502,
        teachers: ["水藤 弘吏"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 861,
        course_name: "健康・スポーツ科学実習II(ソフトボール)",
        teacher_group_id: 1716,
        teachers: ["山本 真史"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 915,
        course_name: "健康・スポーツ科学実習I(卓球)",
        teacher_group_id: 1014,
        teachers: ["横山 慶子"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2254,
        course_name: "健康・スポーツ科学実習(サッカー)",
        teacher_group_id: 1968,
        teachers: ["布目"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 807,
        course_name: "健康・スポーツ科学講義(テニス)",
        teacher_group_id: 885,
        teachers: ["山本 裕二"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1910,
        course_name: "健康・スポーツ科学実習(卓球)",
        teacher_group_id: 2017,
        teachers: ["蛭田"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2270,
        course_name: "健康・スポーツ科学実習(テニス)",
        teacher_group_id: 2543,
        teachers: ["中原 かおり"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 754,
        course_name: "健康・スポーツ科学実習I(フィットネス)",
        teacher_group_id: 880,
        teachers: ["佐々木 康"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 861,
        course_name: "健康・スポーツ科学実習II(ソフトボール)",
        teacher_group_id: 953,
        teachers: ["水野 貴正"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 825,
        teachers: ["山本 祐二"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2242,
        course_name: "健康・スポーツ科学実習(バドミントン)",
        teacher_group_id: 502,
        teachers: ["水藤 弘吏"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2085,
        course_name: "健康・スポーツ科学実習I(ソフトボール)",
        teacher_group_id: 1014,
        teachers: ["横山 慶子"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 821,
        course_name: "健康・スポーツ科学実習I(ソフトボール)",
        teacher_group_id: 905,
        teachers: ["石田 浩司"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2275,
        course_name: "健康・スポーツ科学実習(テニス)",
        teacher_group_id: 905,
        teachers: ["石田 浩司"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2112,
        course_name: "健康・スポーツ科学実習(卓球)",
        teacher_group_id: 2456,
        teachers: ["吉田 和人"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2112,
        course_name: "健康・スポーツ科学実習(卓球)",
        teacher_group_id: 2582,
        teachers: ["山田 耕司"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2264,
        course_name: "健康・スポーツ科学実習(スキー)",
        teacher_group_id: 502,
        teachers: ["水藤 弘吏"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2276,
        course_name: "健康・スポーツ科学実習(サッカー)",
        teacher_group_id: 841,
        teachers: ["秋間 広"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 861,
        course_name: "健康・スポーツ科学実習II(ソフトボール)",
        teacher_group_id: 1014,
        teachers: ["横山 慶子"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2301,
        course_name: "健康・スポーツ科学実習(太極拳)",
        teacher_group_id: 1383,
        teachers: ["島岡 みどり"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2065,
        course_name: "健康・スポーツ科学実習(太極拳)",
        teacher_group_id: 1744,
        teachers: ["樊 孟"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 1910,
        course_name: "健康・スポーツ科学実習(卓球)",
        teacher_group_id: 195,
        teachers: ["湯"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2170,
        course_name: "健康・スポーツ科学実習(スキー)",
        teacher_group_id: 2555,
        teachers: ["池上 康夫"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 1983,
        course_name: "健康・スポーツ科学実習",
        teacher_group_id: 927,
        teachers: ["水藤 弘史"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 1786,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 1186,
        teachers: ["田中 憲子"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 753,
        course_name: "健康・スポーツ科学実習I(サッカー)",
        teacher_group_id: 1656,
        teachers: ["小林 洋平"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 854,
        course_name: "健康・スポーツ実習1(フィットネス)",
        teacher_group_id: 946,
        teachers: ["下村 典子"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2170,
        course_name: "健康・スポーツ科学実習(スキー)",
        teacher_group_id: 1439,
        teachers: ["島岡 清"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 754,
        course_name: "健康・スポーツ科学実習I(フィットネス)",
        teacher_group_id: 841,
        teachers: ["秋間 広"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1906,
        course_name: "健康・スポーツ科学実習(ゴルフ)",
        teacher_group_id: 406,
        teachers: ["高橋"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2275,
        course_name: "健康・スポーツ科学実習(テニス)",
        teacher_group_id: 822,
        teachers: ["竹之内 隆志"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1628,
        course_name: "心理学",
        teacher_group_id: 1222,
        teachers: ["小川 豊昭"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 777,
        course_name: "健康・スポーツ科学実習II(フィットネス)",
        teacher_group_id: 859,
        teachers: ["飯田 祥明"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 388,
        teachers: ["水野"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 953,
        teachers: ["水野 貴正"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 1222,
        teachers: ["小川 豊昭"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2242,
        course_name: "健康・スポーツ科学実習(バドミントン)",
        teacher_group_id: 686,
        teachers: ["片山 敬章"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 852,
        course_name: "健康・スポーツ科学実習II(フィットネス)",
        teacher_group_id: 946,
        teachers: ["下村 典子"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 1922,
        course_name: "健康・スポーツ科学実習(バレーボール)",
        teacher_group_id: 312,
        teachers: ["竹ノ内 隆志"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 1667,
        teachers: ["藤木 理代"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 805,
        course_name: "健康・スポーツ科学実習I(バドミントン)",
        teacher_group_id: 885,
        teachers: ["山本 裕二"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2013,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 1213,
        teachers: ["津田 均"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2059,
        course_name: "健康・スポーツ科学実習I(サッカー)",
        teacher_group_id: 880,
        teachers: ["佐々木 康"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2242,
        course_name: "健康・スポーツ科学実習(バドミントン)",
        teacher_group_id: 905,
        teachers: ["石田 浩司"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1938,
        course_name: "健康・スポーツ科学実習(水泳)",
        teacher_group_id: 927,
        teachers: ["水藤 弘史"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 805,
        course_name: "健康・スポーツ科学実習I(バドミントン)",
        teacher_group_id: 905,
        teachers: ["石田 浩司"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2265,
        course_name: "健康・スポーツ科学実習(レスリング)",
        teacher_group_id: 2175,
        teachers: ["Billy"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 785,
        teachers: ["山本 明子"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1922,
        course_name: "健康・スポーツ科学実習(バレーボール)",
        teacher_group_id: 940,
        teachers: ["内田 博昭"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2026,
        course_name: "健康・スポーツ科学実習(フィットネス)",
        teacher_group_id: 946,
        teachers: ["下村 典子"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1983,
        course_name: "健康・スポーツ科学実習",
        teacher_group_id: 692,
        teachers: ["柴田 優子"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2274,
        course_name: "健康・スポーツ科学実習(ラケット)",
        teacher_group_id: 822,
        teachers: ["竹之内 隆志"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 284,
        course_name: "健康・スポーツ科学実習1(バレーボール)",
        teacher_group_id: 312,
        teachers: ["竹ノ内 隆志"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 737,
        course_name: "健康・スポーツ科学実習II(バレーボール)",
        teacher_group_id: 1133,
        teachers: ["蛭田 秀一"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1922,
        course_name: "健康・スポーツ科学実習(バレーボール)",
        teacher_group_id: 417,
        teachers: ["竹之内"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 1906,
        course_name: "健康・スポーツ科学実習(ゴルフ)",
        teacher_group_id: 160,
        teachers: ["金謙 樹"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2242,
        course_name: "健康・スポーツ科学実習(バドミントン)",
        teacher_group_id: 927,
        teachers: ["水藤 弘史"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2026,
        course_name: "健康・スポーツ科学実習(フィットネス)",
        teacher_group_id: 2598,
        teachers: ["桶野 智美"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 736,
        course_name: "健康・スポーツ科学実習",
        teacher_group_id: 822,
        teachers: ["竹之内 隆志"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 814,
        course_name: "健康・スポーツ科学実習II(太極拳)",
        teacher_group_id: 1744,
        teachers: ["樊 孟"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 1938,
        course_name: "健康・スポーツ科学実習(水泳)",
        teacher_group_id: 1350,
        teachers: ["池上 康男"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 814,
        course_name: "健康・スポーツ科学実習II(太極拳)",
        teacher_group_id: 898,
        teachers: ["張 成忠"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2065,
        course_name: "健康・スポーツ科学実習(太極拳)",
        teacher_group_id: 898,
        teachers: ["張 成忠"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 1629,
        course_name: "精神分析",
        teacher_group_id: 1222,
        teachers: ["小川 豊昭"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 859,
        teachers: ["飯田 祥明"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2270,
        course_name: "健康・スポーツ科学実習(テニス)",
        teacher_group_id: 692,
        teachers: ["柴田 優子"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 1939,
        course_name: "健康・スポーツ科学実習",
        teacher_group_id: 841,
        teachers: ["秋間 広"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 223,
        course_name: "健康・スポーツ科学実習1(バドミントン)",
        teacher_group_id: 905,
        teachers: ["石田 浩司"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 841,
        teachers: ["秋間 広"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1712,
        course_name: "電磁気学",
        teacher_group_id: 2459,
        teachers: ["丹司 敬義"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2278,
        course_name: "健康・スポーツ科学実習(バドミントン)",
        teacher_group_id: 2596,
        teachers: ["富樫 健二"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 1625,
        course_name: "精神医学",
        teacher_group_id: 1213,
        teachers: ["津田 均"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 737,
        course_name: "健康・スポーツ科学実習II(バレーボール)",
        teacher_group_id: 822,
        teachers: ["竹之内 隆志"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1906,
        course_name: "健康・スポーツ科学実習(ゴルフ)",
        teacher_group_id: 1350,
        teachers: ["池上 康男"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 822,
        course_name: "健康・スポーツ科学実習II(バドミントン)",
        teacher_group_id: 905,
        teachers: ["石田 浩司"],
        course_group: "健康・スポーツ科学",
        semester: "後期",
      },
      {
        course_id: 2242,
        course_name: "健康・スポーツ科学実習(バドミントン)",
        teacher_group_id: 2000,
        teachers: ["湯 海"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 809,
        teachers: ["石黒 洋"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 1180,
        teachers: ["古橋 忠晃"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 223,
        course_name: "健康・スポーツ科学実習1(バドミントン)",
        teacher_group_id: 160,
        teachers: ["金謙 樹"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 1133,
        teachers: ["蛭田 秀一"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1572,
        course_name: "科学",
        teacher_group_id: 822,
        teachers: ["竹之内 隆志"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 746,
        course_name: "線形代数学",
        teacher_group_id: 1114,
        teachers: ["古庄 英和"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 2029,
        course_name: "健康・スポーツ科学実習(バレーボール)",
        teacher_group_id: 822,
        teachers: ["竹之内 隆志"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 822,
        teachers: ["竹之内 隆志"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 917,
        course_name: "健康・スポーツ科学実習II(卓球)",
        teacher_group_id: 1014,
        teachers: ["横山 慶子"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1786,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 905,
        teachers: ["石田 浩司"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 1982,
        course_name: "健康・スポーツ科学実習I(テニス)",
        teacher_group_id: 692,
        teachers: ["柴田 優子"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
      {
        course_id: 349,
        course_name: "健康・スポーツ科学講義",
        teacher_group_id: 1345,
        teachers: ["小池 晃彦"],
        course_group: "健康・スポーツ科学",
        semester: "前期",
      },
    ],
    文学部専門科目: [
      {
        course_id: 518,
        course_name: "日本文化事情",
        teacher_group_id: 645,
        teachers: ["齋藤 文俊"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1019,
        course_name: "西洋金現代美術史",
        teacher_group_id: 1149,
        teachers: ["鈴木 俊晴"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 63,
        course_name: "経済地理学",
        teacher_group_id: 27,
        teachers: ["伊賀 聖屋"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 856,
        course_name: "アメリカ文学史a",
        teacher_group_id: 947,
        teachers: ["長畑 明利"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 801,
        course_name: "イギリス小説演習a",
        teacher_group_id: 881,
        teachers: ["上原 早苗"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1348,
        course_name: "インド文化学概論a",
        teacher_group_id: 1717,
        teachers: ["平野 克典"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 96,
        course_name: "ラテン語初級",
        teacher_group_id: 50,
        teachers: ["玉垣 あゆ"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1730,
        course_name: "日本精神史",
        teacher_group_id: 2524,
        teachers: ["阿部 泰郎"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 237,
        course_name: "異文化理解",
        teacher_group_id: 1775,
        teachers: ["吉田 早悠里"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 434,
        course_name: "博物館資料保存論",
        teacher_group_id: 534,
        teachers: ["栗田"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 461,
        course_name: "美術史講義VIIa",
        teacher_group_id: 579,
        teachers: ["伊藤 大輔"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1667,
        course_name: "美術史概説",
        teacher_group_id: 1423,
        teachers: ["木俣 元一"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 944,
        course_name: "プログラミング及び演習",
        teacher_group_id: 1052,
        teachers: ["森 健策"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 937,
        course_name: "中国哲学史概論Ib",
        teacher_group_id: 1043,
        teachers: ["吉田 純"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 879,
        course_name: "西洋史講義IIa",
        teacher_group_id: 979,
        teachers: ["和田 光弘"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1381,
        course_name: "地理学概論III",
        teacher_group_id: 1773,
        teachers: ["安藤 和雄"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1256,
        course_name: "西洋史基礎演習IVb",
        teacher_group_id: 1546,
        teachers: ["加納 修"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 202,
        course_name: "日本文学講義",
        teacher_group_id: 1825,
        teachers: ["中根 千絵"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 517,
        course_name: "日本語学概論",
        teacher_group_id: 645,
        teachers: ["齋藤 文俊"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1074,
        course_name: "考古学講義",
        teacher_group_id: 1245,
        teachers: ["梶原 義実"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1720,
        course_name: "東南アジア史特殊研究",
        teacher_group_id: 1539,
        teachers: ["加藤 久美子"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 237,
        course_name: "異文化理解",
        teacher_group_id: 190,
        teachers: ["佐々木 重洋"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1378,
        course_name: "地理学講義VIII",
        teacher_group_id: 1771,
        teachers: ["福本 拓"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 740,
        course_name: "英語学概論",
        teacher_group_id: 824,
        teachers: ["秋田 喜美"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 202,
        course_name: "日本文学講義",
        teacher_group_id: 135,
        teachers: ["甘露 純規"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 881,
        course_name: "アメリカ史の諸問題I",
        teacher_group_id: 979,
        teachers: ["和田 光弘"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1641,
        course_name: "人文学講義B",
        teacher_group_id: 1315,
        teachers: ["林 謙一郎"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 527,
        course_name: "人文学講義",
        teacher_group_id: 1145,
        teachers: ["佐久間 淳一"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1382,
        course_name: "地理学演習IV",
        teacher_group_id: 1774,
        teachers: ["齋藤 仁"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 460,
        course_name: "美術史概論",
        teacher_group_id: 579,
        teachers: ["伊藤 大輔"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 659,
        course_name: "西洋哲学史購読I",
        teacher_group_id: 762,
        teachers: ["金山 弥平"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1267,
        course_name: "博物館教育論",
        teacher_group_id: 1586,
        teachers: ["栗田 秀法"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1411,
        course_name: "文化人類学講義I",
        teacher_group_id: 1836,
        teachers: ["竹ノ下 祐二"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 774,
        course_name: "地理学概論II",
        teacher_group_id: 857,
        teachers: ["鈴木 康弘"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1013,
        course_name: "災害誌社会学特殊研究",
        teacher_group_id: 1141,
        teachers: ["田中 重好"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 587,
        course_name: "人文学の学生のための情報リテラシー",
        teacher_group_id: 697,
        teachers: ["重見 晋也"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1404,
        course_name: "文化資源学演習III",
        teacher_group_id: 1815,
        teachers: ["石川 寛"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1168,
        course_name: "史学概論",
        teacher_group_id: 627,
        teachers: ["周藤 芳幸"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 494,
        course_name: "西洋史概論",
        teacher_group_id: 627,
        teachers: ["周藤 芳幸"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 527,
        course_name: "人文学講義",
        teacher_group_id: 649,
        teachers: ["中村 靖子"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 434,
        course_name: "博物館資料保存論",
        teacher_group_id: 1124,
        teachers: ["新美 倫子"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 527,
        course_name: "人文学講義",
        teacher_group_id: 1542,
        teachers: ["吉武 純夫"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1385,
        course_name: "映像批評分析論講義",
        teacher_group_id: 1777,
        teachers: ["小川 翔太"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 527,
        course_name: "人文学講義",
        teacher_group_id: 2173,
        teachers: ["加藤 國安"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1532,
        course_name: "人文学講義D",
        teacher_group_id: 503,
        teachers: ["丹邉 宣彦"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1784,
        course_name: "人文学入門I",
        teacher_group_id: 689,
        teachers: ["大島 義和"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 622,
        course_name: "音声学講義",
        teacher_group_id: 730,
        teachers: ["宇都木 昭"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1384,
        course_name: "文化人類学講義III",
        teacher_group_id: 1775,
        teachers: ["吉田 早悠里"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1405,
        course_name: "文化資源学演習II",
        teacher_group_id: 1815,
        teachers: ["石川 寛"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 316,
        course_name: "中国哲学史演習IIa",
        teacher_group_id: 352,
        teachers: ["小﨑 智則"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 527,
        course_name: "人文学講義",
        teacher_group_id: 238,
        teachers: ["稲葉 伸道"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 975,
        course_name: "学習心理学講義",
        teacher_group_id: 1085,
        teachers: ["片平 健太郎"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 855,
        course_name: "英語圏の異文化理解概論",
        teacher_group_id: 947,
        teachers: ["長畑 明利"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1421,
        course_name: "西洋古典学概論",
        teacher_group_id: 1857,
        teachers: ["西村 賀子"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1728,
        course_name: "人文学講義C",
        teacher_group_id: 2524,
        teachers: ["阿部 泰郎"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1148,
        course_name: "心理学講義I",
        teacher_group_id: 1346,
        teachers: ["大平 英樹"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2212,
        course_name: "社会学講義IIIa",
        teacher_group_id: 1776,
        teachers: ["室伏 研二"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1380,
        course_name: "イタリア語A",
        teacher_group_id: 1772,
        teachers: ["柴田 有香"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 874,
        course_name: "中国語学文学基礎演習IIa",
        teacher_group_id: 974,
        teachers: ["佐野 誠子"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1412,
        course_name: "西南アジア史講義",
        teacher_group_id: 1837,
        teachers: ["三田 昌彦"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 75,
        course_name: "哲学",
        teacher_group_id: 2383,
        teachers: ["田村 均"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 925,
        course_name: "認知神経科学講義",
        teacher_group_id: 1021,
        teachers: ["田邊 宏樹"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1102,
        course_name: "社会学講義IIIa",
        teacher_group_id: 1278,
        teachers: ["室井 研二"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1892,
        course_name: "Introduction to British Literature 1",
        teacher_group_id: 64,
        teachers: ["トムキンソン フィオーナ"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1255,
        course_name: "東南アジア史講義",
        teacher_group_id: 1539,
        teachers: ["加藤 久美子"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1130,
        course_name: "中国史講義(東洋史研究II)",
        teacher_group_id: 1315,
        teachers: ["林 謙一郎"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2050,
        course_name: "シェイクスピア研究1",
        teacher_group_id: 861,
        teachers: ["滝川 睦"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1112,
        course_name: "文化人類学",
        teacher_group_id: 1293,
        teachers: ["嶋田 義仁"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1174,
        course_name: "日本文学講義IIa",
        teacher_group_id: 1420,
        teachers: ["大井田 晴彦"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2051,
        course_name: "ソネット研究2",
        teacher_group_id: 861,
        teachers: ["滝川 睦"],
        course_group: "文学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2049,
        course_name: "ソネット研究1",
        teacher_group_id: 861,
        teachers: ["滝川 睦"],
        course_group: "文学部専門科目",
        semester: "前期",
      },
    ],
    農学部専門科目: [
      {
        course_id: 179,
        course_name: "分類・形態学",
        teacher_group_id: 116,
        teachers: ["山本 直之"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 712,
        course_name: "生命系物理工学",
        teacher_group_id: 795,
        teachers: ["土川 覚"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 172,
        course_name: "生命農学序説",
        teacher_group_id: 109,
        teachers: ["吉村 徹"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1397,
        course_name: "現代社会の食と農",
        teacher_group_id: 1799,
        teachers: ["徳田 博美"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 952,
        course_name: "昆虫科学",
        teacher_group_id: 1059,
        teachers: ["三浦 健"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 303,
        course_name: "昆虫科学1",
        teacher_group_id: 335,
        teachers: ["水口 智江可"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 320,
        course_name: "生態学",
        teacher_group_id: 354,
        teachers: ["土岐 和多瑠"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 212,
        course_name: "情報リテラシー入門(応生)",
        teacher_group_id: 146,
        teachers: ["灘野 大太"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 305,
        course_name: "情報リテラシー入門",
        teacher_group_id: 335,
        teachers: ["水口 智江可"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1431,
        course_name: "植物病理学",
        teacher_group_id: 1871,
        teachers: ["竹本 大吾"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 302,
        course_name: "農学セミナー2",
        teacher_group_id: 335,
        teachers: ["水口 智江可"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 724,
        course_name: "生物材料組織学",
        teacher_group_id: 808,
        teachers: ["吉田 正人"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1430,
        course_name: "フードシステム論",
        teacher_group_id: 1870,
        teachers: ["竹下 広宣"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 305,
        course_name: "情報リテラシー入門",
        teacher_group_id: 959,
        teachers: ["山縣 高広"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1817,
        course_name: "国際森林資源論",
        teacher_group_id: 2661,
        teachers: ["原田 一宏"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 489,
        course_name: "生命と技術の倫理",
        teacher_group_id: 622,
        teachers: ["大倉 聡"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1820,
        course_name: "生産土壌学",
        teacher_group_id: 2374,
        teachers: ["浅川 晋"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 304,
        course_name: "農学セミナー1",
        teacher_group_id: 335,
        teachers: ["水口 智江可"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1089,
        course_name: "基礎有機化学",
        teacher_group_id: 1258,
        teachers: ["今井 貴規"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1222,
        course_name: "微生物学1",
        teacher_group_id: 1840,
        teachers: ["池田 素子"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 489,
        course_name: "生命と技術の倫理",
        teacher_group_id: 944,
        teachers: ["大蔵 聡"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 596,
        course_name: "生物化学1",
        teacher_group_id: 747,
        teachers: ["松本 省吾"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 172,
        course_name: "生命農学序説",
        teacher_group_id: 795,
        teachers: ["土川 覚"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 983,
        course_name: "無機化学",
        teacher_group_id: 1106,
        teachers: ["竹中 千里"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 172,
        course_name: "生命農学序説",
        teacher_group_id: 1460,
        teachers: ["谷口 光隆"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2307,
        course_name: "植物生理学1",
        teacher_group_id: 2328,
        teachers: ["三宅 博"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 738,
        course_name: "生物化学3",
        teacher_group_id: 823,
        teachers: ["榊原 均"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1371,
        course_name: "Agricultural Sciences",
        teacher_group_id: 1761,
        teachers: ["井上 直子"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1695,
        course_name: "植物分類・植生学",
        teacher_group_id: 2387,
        teachers: ["戸丸 信弘"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1253,
        course_name: "バイオマス科学",
        teacher_group_id: 1533,
        teachers: ["福島 和彦"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1918,
        course_name: "有機化学1",
        teacher_group_id: 1756,
        teachers: ["青木 弾"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 952,
        course_name: "昆虫科学",
        teacher_group_id: 2244,
        teachers: ["田中 利治"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1819,
        course_name: "細胞工学",
        teacher_group_id: 1736,
        teachers: ["西島 謙一"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1822,
        course_name: "農業資源経済学",
        teacher_group_id: 1799,
        teachers: ["徳田 博美"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1195,
        course_name: "植物生理学1",
        teacher_group_id: 1460,
        teachers: ["谷口 光隆"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 645,
        course_name: "植物生理学2",
        teacher_group_id: 747,
        teachers: ["松本 省吾"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2079,
        course_name: "道徳教育の研究",
        teacher_group_id: 987,
        teachers: ["山田 宏"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 320,
        course_name: "生態学",
        teacher_group_id: 820,
        teachers: ["肘井 直樹"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1088,
        course_name: "有機化学2",
        teacher_group_id: 1258,
        teachers: ["今井 貴規"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1415,
        course_name: "土壌学",
        teacher_group_id: 1847,
        teachers: ["渡邊 彰"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1918,
        course_name: "有機化学1",
        teacher_group_id: 1024,
        teachers: ["野村 信嘉"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 305,
        course_name: "情報リテラシー入門",
        teacher_group_id: 1694,
        teachers: ["山縣 高宏"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 127,
        course_name: "有機化学1",
        teacher_group_id: 75,
        teachers: ["西川 北"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1753,
        course_name: "資源生物科学科2年",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1816,
        course_name: "植物育種学",
        teacher_group_id: 1787,
        teachers: ["中園 幹生"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 303,
        course_name: "昆虫科学1",
        teacher_group_id: 1059,
        teachers: ["三浦 健"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1396,
        course_name: "生命物理化学1",
        teacher_group_id: 1798,
        teachers: ["邊見 久"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1815,
        course_name: "動物生理学2",
        teacher_group_id: 116,
        teachers: ["山本 直之"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1918,
        course_name: "有機化学1",
        teacher_group_id: 1706,
        teachers: ["松下 泰幸"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 597,
        course_name: "生物化学I",
        teacher_group_id: 704,
        teachers: ["北島 健"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1818,
        course_name: "作物科学",
        teacher_group_id: 2662,
        teachers: ["近藤 始彦"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 172,
        course_name: "生命農学序説",
        teacher_group_id: 573,
        teachers: ["石黒 澄衞"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1821,
        course_name: "動物繁殖学",
        teacher_group_id: 2663,
        teachers: ["上野山 賀久"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1415,
        course_name: "土壌学",
        teacher_group_id: 1841,
        teachers: ["渡邉 彰"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1222,
        course_name: "微生物学1",
        teacher_group_id: 1486,
        teachers: ["木村 眞"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 990,
        course_name: "細胞生物学1",
        teacher_group_id: 1119,
        teachers: ["白武 勝裕"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1521,
        course_name: "有機化学I",
        teacher_group_id: 2074,
        teachers: ["小鹿"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 648,
        course_name: "遺伝学",
        teacher_group_id: 748,
        teachers: ["一柳 健司"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 648,
        course_name: "遺伝学",
        teacher_group_id: 1787,
        teachers: ["中園 幹生"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1249,
        course_name: "生物情報処理演習",
        teacher_group_id: 597,
        teachers: ["山本 一清"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1392,
        course_name: "動物生理学1",
        teacher_group_id: 1791,
        teachers: ["吉村 崇"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 596,
        course_name: "生物化学1",
        teacher_group_id: 704,
        teachers: ["北島 健"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 127,
        course_name: "有機化学1",
        teacher_group_id: 302,
        teachers: ["野村", "西川"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 360,
        course_name: "細胞生物学",
        teacher_group_id: 403,
        teachers: ["白武"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1429,
        course_name: "動物育種学",
        teacher_group_id: 1867,
        teachers: ["隅山 健太"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1420,
        course_name: "動物形態学",
        teacher_group_id: 1854,
        teachers: ["本道 栄一"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 714,
        course_name: "生物情報計測学",
        teacher_group_id: 795,
        teachers: ["土川 覚"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 983,
        course_name: "無機化学",
        teacher_group_id: 1841,
        teachers: ["渡邉 彰"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 637,
        course_name: "生物学基礎II",
        teacher_group_id: 748,
        teachers: ["一柳 健司"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1361,
        course_name: "生物化学2",
        teacher_group_id: 1736,
        teachers: ["西島 謙一"],
        course_group: "農学部専門科目",
        semester: "前期",
      },
      {
        course_id: 87,
        course_name: "生物学基礎II",
        teacher_group_id: 739,
        teachers: ["森 仁志"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 983,
        course_name: "無機化学",
        teacher_group_id: 1740,
        teachers: ["中西 洋一"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1331,
        course_name: "生物材料力学",
        teacher_group_id: 1696,
        teachers: ["山崎 真理子"],
        course_group: "農学部専門科目",
        semester: "後期",
      },
    ],
    情報学部専門科目: [
      {
        course_id: 683,
        course_name: "情報創造",
        teacher_group_id: 775,
        teachers: ["秋庭 史典"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1247,
        course_name: "PBL1",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1446,
        course_name: "情報芸術論",
        teacher_group_id: 775,
        teachers: ["秋庭 史典"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2129,
        course_name: "情報セキュリティとリテラシー1",
        teacher_group_id: 1207,
        teachers: ["嶋田 創"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 862,
        course_name: "微積分学の発展1",
        teacher_group_id: 954,
        teachers: ["木原 貴行"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 943,
        course_name: "機械学習",
        teacher_group_id: 1052,
        teachers: ["森 健策"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 74,
        course_name: "科学方法論",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1246,
        course_name: "マネジメント",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 197,
        course_name: "情報セキュリティとリテラシー2",
        teacher_group_id: 1207,
        teachers: ["嶋田 創"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1116,
        course_name: "データマイニング入門",
        teacher_group_id: 1298,
        teachers: ["石川 佳治"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1095,
        course_name: "自然言語処理",
        teacher_group_id: 1271,
        teachers: ["松原 茂樹"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 329,
        course_name: "論理学1",
        teacher_group_id: 366,
        teachers: ["中澤 巧爾"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 838,
        course_name: "インフォマティックス1",
        teacher_group_id: 924,
        teachers: ["枝廣 正人"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 678,
        course_name: "社会システム論",
        teacher_group_id: 773,
        teachers: ["河村 則行"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 196,
        course_name: "プログラミング1",
        teacher_group_id: 130,
        teachers: ["鈴木 麗璽"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1338,
        course_name: "インフォマティックス3",
        teacher_group_id: 1705,
        teachers: ["出口 大輔"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 960,
        course_name: "複雑系科学の基礎",
        teacher_group_id: 1073,
        teachers: ["有田 隆也"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 293,
        course_name: "最適化1・2",
        teacher_group_id: 319,
        teachers: ["番原 睦則"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 687,
        course_name: "感じる情報学",
        teacher_group_id: 775,
        teachers: ["秋庭 史典"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2068,
        course_name: "コンピュータ科学実験2",
        teacher_group_id: 921,
        teachers: ["森崎 修司"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2338,
        course_name: "認知科学D",
        teacher_group_id: 1100,
        teachers: ["三輪 和久"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 272,
        course_name: "PBL2",
        teacher_group_id: 297,
        teachers: ["吉田 則裕"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2331,
        course_name: "クリエイティブ・ネットワーキング",
        teacher_group_id: 130,
        teachers: ["鈴木 麗璽"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2077,
        course_name: "プログラミング2",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 729,
        course_name: "心の科学",
        teacher_group_id: 813,
        teachers: ["北神 慎司"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 851,
        course_name: "情報ネットワーク",
        teacher_group_id: 945,
        teachers: ["村瀬 勉"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 76,
        course_name: "アカデミック・ライティング",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 729,
        course_name: "心の科学",
        teacher_group_id: 1652,
        teachers: ["石井 敬子"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1206,
        course_name: "オートマトン・形式言語及び演習",
        teacher_group_id: 1475,
        teachers: ["酒井 正彦"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2335,
        course_name: "認知心理学D",
        teacher_group_id: 813,
        teachers: ["北神 慎司"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 883,
        course_name: "シミュレーション・サイエンス1",
        teacher_group_id: 981,
        teachers: ["内山 知実"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2339,
        course_name: "認知科学F",
        teacher_group_id: 1248,
        teachers: ["川合 伸幸"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1274,
        course_name: "情報と国際社会",
        teacher_group_id: 1212,
        teachers: ["小川 明子"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1116,
        course_name: "データマイニング入門",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 196,
        course_name: "プログラミング1",
        teacher_group_id: 1866,
        teachers: ["遠藤 守"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1205,
        course_name: "非手続き型言語及び演習",
        teacher_group_id: 1475,
        teachers: ["酒井 正彦"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2337,
        course_name: "脳と心A",
        teacher_group_id: 2714,
        teachers: ["平井 真洋"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1274,
        course_name: "情報と国際社会",
        teacher_group_id: 1597,
        teachers: ["山本 竜大"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1097,
        course_name: "計算理論",
        teacher_group_id: 1273,
        teachers: ["関 浩之"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2333,
        course_name: "応用社会調査",
        teacher_group_id: 1208,
        teachers: ["丸山 康司"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2334,
        course_name: "認知心理学B",
        teacher_group_id: 826,
        teachers: ["川口 潤"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 980,
        course_name: "人間の知・機械の知",
        teacher_group_id: 1100,
        teachers: ["三輪 和久"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2332,
        course_name: "情報社会メディア論",
        teacher_group_id: 1866,
        teachers: ["遠藤 守"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 759,
        course_name: "アルゴリズム1",
        teacher_group_id: 844,
        teachers: ["渡辺 崇"],
        course_group: "情報学部専門科目",
        semester: "その他",
      },
      {
        course_id: 2071,
        course_name: "アルゴリズム1",
        teacher_group_id: 1013,
        teachers: ["渡邉 崇"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 685,
        course_name: "インフォマティックス2",
        teacher_group_id: 775,
        teachers: ["秋庭 史典"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 197,
        course_name: "情報セキュリティとリテラシー2",
        teacher_group_id: 130,
        teachers: ["鈴木 麗璽"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 884,
        course_name: "計算情報学3",
        teacher_group_id: 981,
        teachers: ["内山 知実"],
        course_group: "情報学部専門科目",
        semester: "その他",
      },
      {
        course_id: 1260,
        course_name: "インフォマティックス4",
        teacher_group_id: 1565,
        teachers: ["長岡 正隆"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1024,
        course_name: "人工知能基礎2",
        teacher_group_id: 1156,
        teachers: ["長尾 確"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1242,
        course_name: "情報の挑戦者・開拓者たち",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 833,
        course_name: "ソフトウェア設計法",
        teacher_group_id: 921,
        teachers: ["森崎 修司"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2336,
        course_name: "社会心理学D",
        teacher_group_id: 1652,
        teachers: ["石井 敬子"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 521,
        course_name: "計算情報学2",
        teacher_group_id: 647,
        teachers: ["鈴木 泰博"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 197,
        course_name: "情報セキュリティとリテラシー2",
        teacher_group_id: 1811,
        teachers: ["浦田 真由"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 676,
        course_name: "社会調査",
        teacher_group_id: 773,
        teachers: ["河村 則行"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 471,
        course_name: "コンピュータ科学実験2",
        teacher_group_id: 593,
        teachers: ["西田 直樹"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2071,
        course_name: "アルゴリズム1",
        teacher_group_id: 593,
        teachers: ["西田 直樹"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1858,
        course_name: "プログラミング基礎",
        teacher_group_id: 1890,
        teachers: ["豊田 ひさき"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 505,
        course_name: "情報と職業2",
        teacher_group_id: 640,
        teachers: ["山本 達大"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 508,
        course_name: "情報と職業1",
        teacher_group_id: 640,
        teachers: ["山本 達大"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1197,
        course_name: "社会心理学演習",
        teacher_group_id: 1465,
        teachers: ["唐澤 穣"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1352,
        course_name: "確率統計及び演習",
        teacher_group_id: 1722,
        teachers: ["小田 昌宏"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1003,
        course_name: "代数的構造",
        teacher_group_id: 1135,
        teachers: ["外山 勝彦"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 292,
        course_name: "最適化1",
        teacher_group_id: 319,
        teachers: ["番原 睦則"],
        course_group: "情報学部専門科目",
        semester: "後期",
      },
      {
        course_id: 505,
        course_name: "情報と職業2",
        teacher_group_id: 1597,
        teachers: ["山本 竜大"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2114,
        course_name: "離散数学及び演習",
        teacher_group_id: 1135,
        teachers: ["外山 勝彦"],
        course_group: "情報学部専門科目",
        semester: "前期",
      },
    ],
    法学部専門科目: [
      {
        course_id: 9,
        course_name: "西洋政治思想史",
        teacher_group_id: 2,
        teachers: ["加藤 哲理"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1904,
        course_name: "国際政治史",
        teacher_group_id: 139,
        teachers: ["定形 衛"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 564,
        course_name: "現代日本の司法",
        teacher_group_id: 673,
        teachers: ["宮木 康博"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 431,
        course_name: "西洋法制史",
        teacher_group_id: 531,
        teachers: ["石井 三記"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 10,
        course_name: "法と政治の思想",
        teacher_group_id: 2,
        teachers: ["加藤 哲理"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 817,
        course_name: "民法II(物権法)",
        teacher_group_id: 900,
        teachers: ["池田 雅則"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 431,
        course_name: "西洋法制史",
        teacher_group_id: 1702,
        teachers: ["波多野 敏"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1157,
        course_name: "日本政治史",
        teacher_group_id: 1363,
        teachers: ["増田 知子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1022,
        course_name: "政治学原論",
        teacher_group_id: 1426,
        teachers: ["田村 哲樹"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 367,
        course_name: "中国法",
        teacher_group_id: 1325,
        teachers: ["宇田川 幸則"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 427,
        course_name: "法情報学I",
        teacher_group_id: 526,
        teachers: ["藤本 亮"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 160,
        course_name: "行政学",
        teacher_group_id: 97,
        teachers: ["後 房雄"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 363,
        course_name: "ロシア法",
        teacher_group_id: 943,
        teachers: ["佐藤 史人"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1318,
        course_name: "マスコミと政治",
        teacher_group_id: 1663,
        teachers: ["木田 勇輔"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 769,
        course_name: "法社会学",
        teacher_group_id: 852,
        teachers: ["原田 綾子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 541,
        course_name: "国際政治学",
        teacher_group_id: 660,
        teachers: ["三浦 聡"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 7,
        course_name: "特殊講義(比較政治思想)",
        teacher_group_id: 2,
        teachers: ["加藤 哲理"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1086,
        course_name: "特殊講義(現代メディア論)",
        teacher_group_id: 1256,
        teachers: ["宇佐美 博"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1894,
        course_name: "商法II(企業組織法)",
        teacher_group_id: 78,
        teachers: ["中東 正文"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 417,
        course_name: "法情報学II",
        teacher_group_id: 517,
        teachers: ["佐野 智也", "中村 誠"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1904,
        course_name: "国際政治史",
        teacher_group_id: 1673,
        teachers: ["大久保 明"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1367,
        course_name: "韓国法",
        teacher_group_id: 1747,
        teachers: ["岡 克彦"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 363,
        course_name: "ロシア法",
        teacher_group_id: 410,
        teachers: ["佐藤"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 365,
        course_name: "刑事訴訟法",
        teacher_group_id: 413,
        teachers: ["宮木"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1139,
        course_name: "法哲学",
        teacher_group_id: 1331,
        teachers: ["森際 康友"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2150,
        course_name: "フランス法",
        teacher_group_id: 1314,
        teachers: ["菅原 真"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1711,
        course_name: "法情報4",
        teacher_group_id: 2458,
        teachers: ["角田 篤泰"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 542,
        course_name: "国際政治学ゼミ",
        teacher_group_id: 660,
        teachers: ["三浦 聡"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2138,
        course_name: "特殊講義(損害保険の法と実務)",
        teacher_group_id: 1255,
        teachers: ["松中 学"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 564,
        course_name: "現代日本の司法",
        teacher_group_id: 1358,
        teachers: ["渡部 美由紀"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 10,
        course_name: "法と政治の思想",
        teacher_group_id: 541,
        teachers: ["田村", "大屋"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2203,
        course_name: "法哲学",
        teacher_group_id: 1680,
        teachers: ["松尾 陽"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 301,
        course_name: "東洋法制史",
        teacher_group_id: 334,
        teachers: ["髙見澤 磨"],
        course_group: "法学部専門科目",
        semester: "その他",
      },
      {
        course_id: 429,
        course_name: "民法I(総則)",
        teacher_group_id: 528,
        teachers: ["丸山 絵美子"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 205,
        course_name: "特殊講義(法曹実務)",
        teacher_group_id: 140,
        teachers: ["高橋 直紹"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 373,
        course_name: "事故法",
        teacher_group_id: 2541,
        teachers: ["千葉 恵美子"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1313,
        course_name: "民法IV(事故法)",
        teacher_group_id: 1657,
        teachers: ["村田 健介"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 428,
        course_name: "法情報学III",
        teacher_group_id: 526,
        teachers: ["藤本 亮"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1357,
        course_name: "比較政治III",
        teacher_group_id: 1730,
        teachers: ["松尾 秀哉"],
        course_group: "法学部専門科目",
        semester: "その他",
      },
      {
        course_id: 1584,
        course_name: "刑法総論",
        teacher_group_id: 914,
        teachers: ["古川 伸彦"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1470,
        course_name: "戦後外交",
        teacher_group_id: 139,
        teachers: ["定形 衛"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1534,
        course_name: "法思想史",
        teacher_group_id: 530,
        teachers: ["大屋 雄裕"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2072,
        course_name: "民法III(取引法)",
        teacher_group_id: 934,
        teachers: ["平林 美紀"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 206,
        course_name: "法曹実務",
        teacher_group_id: 140,
        teachers: ["高橋 直紹"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1085,
        course_name: "現代メディア論",
        teacher_group_id: 1256,
        teachers: ["宇佐美 博"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 222,
        course_name: "比較政治2",
        teacher_group_id: 159,
        teachers: ["後 房雄", "小野 耕二"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2204,
        course_name: "比較政治2",
        teacher_group_id: 1690,
        teachers: ["柳原 克行"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1081,
        course_name: "行政救済法",
        teacher_group_id: 1254,
        teachers: ["市橋 克哉"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 132,
        course_name: "会社法",
        teacher_group_id: 78,
        teachers: ["中東 正文"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 160,
        course_name: "行政学",
        teacher_group_id: 2651,
        teachers: ["森川 想", "深谷 健"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 209,
        course_name: "民事訴訟法",
        teacher_group_id: 142,
        teachers: ["石田"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1974,
        course_name: "刑事訴訟法",
        teacher_group_id: 673,
        teachers: ["宮木 康博"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2066,
        course_name: "民事訴訟法",
        teacher_group_id: 1781,
        teachers: ["川嶋 隆憲"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 367,
        course_name: "中国法",
        teacher_group_id: 416,
        teachers: ["宇田川"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1080,
        course_name: "行政法I(総論)",
        teacher_group_id: 1254,
        teachers: ["市橋 克哉"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 467,
        course_name: "日本法制史",
        teacher_group_id: 587,
        teachers: ["伊藤 孝夫"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1410,
        course_name: "倫理学概論I",
        teacher_group_id: 625,
        teachers: ["鈴木 真"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1273,
        course_name: "法情報学1",
        teacher_group_id: 526,
        teachers: ["藤本 亮"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1021,
        course_name: "現代日本の政治と行政",
        teacher_group_id: 1363,
        teachers: ["増田 知子"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 831,
        course_name: "国際法総論",
        teacher_group_id: 918,
        teachers: ["小畑 郁"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 475,
        course_name: "ドイツ法",
        teacher_group_id: 598,
        teachers: ["大西 楠"],
        course_group: "法学部専門科目",
        semester: "その他",
      },
      {
        course_id: 2210,
        course_name: "特殊講義(デジタル世界の近代日本の政治と法)",
        teacher_group_id: 1762,
        teachers: ["養老 真一"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1081,
        course_name: "行政救済法",
        teacher_group_id: 2082,
        teachers: ["市橋"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1390,
        course_name: "社会学概論I",
        teacher_group_id: 1786,
        teachers: ["福井 康貴"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2066,
        course_name: "民事訴訟法",
        teacher_group_id: 906,
        teachers: ["村上 正子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 10,
        course_name: "法と政治の思想",
        teacher_group_id: 943,
        teachers: ["佐藤 史人"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1575,
        course_name: "法学史",
        teacher_group_id: 830,
        teachers: ["神保 文夫"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 810,
        course_name: "ジェンダーと政治",
        teacher_group_id: 894,
        teachers: ["武田 宏子"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 826,
        course_name: "刑法II(各論)",
        teacher_group_id: 914,
        teachers: ["古川 伸彦"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1704,
        course_name: "新聞論",
        teacher_group_id: 2444,
        teachers: ["田島 暁"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1156,
        course_name: "特殊講義(デジタル世界の近代日本の政治と法)",
        teacher_group_id: 1363,
        teachers: ["増田 知子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1138,
        course_name: "社会保障法",
        teacher_group_id: 1330,
        teachers: ["中野 紗子"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 10,
        course_name: "法と政治の思想",
        teacher_group_id: 530,
        teachers: ["大屋 雄裕"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1485,
        course_name: "刑事学",
        teacher_group_id: 1925,
        teachers: ["加藤 佐千夫"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 988,
        course_name: "物権法",
        teacher_group_id: 1118,
        teachers: ["丸山 恵美子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1498,
        course_name: "民法III",
        teacher_group_id: 1960,
        teachers: ["吉政 知弘"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 417,
        course_name: "法情報学II",
        teacher_group_id: 1698,
        teachers: ["佐野 智也"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 429,
        course_name: "民法I(総則)",
        teacher_group_id: 1118,
        teachers: ["丸山 恵美子"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1678,
        course_name: "民法(総論)",
        teacher_group_id: 2357,
        teachers: ["中舎 寛樹"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 732,
        course_name: "刑法I(総論)",
        teacher_group_id: 1780,
        teachers: ["斉藤 彰子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 817,
        course_name: "民法II(物権法)",
        teacher_group_id: 1118,
        teachers: ["丸山 恵美子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1897,
        course_name: "政治学",
        teacher_group_id: 97,
        teachers: ["後 房雄"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1399,
        course_name: "立法政策論",
        teacher_group_id: 1805,
        teachers: ["松本 俊太"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 419,
        course_name: "国際環境行政論",
        teacher_group_id: 518,
        teachers: ["山田 高敬"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1568,
        course_name: "刑法",
        teacher_group_id: 818,
        teachers: ["齊藤 彰子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1677,
        course_name: "民法I",
        teacher_group_id: 2357,
        teachers: ["中舎 寛樹"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1507,
        course_name: "民法",
        teacher_group_id: 2357,
        teachers: ["中舎 寛樹"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1569,
        course_name: "刑法1(総論)",
        teacher_group_id: 818,
        teachers: ["齊藤 彰子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1680,
        course_name: "民法III",
        teacher_group_id: 2357,
        teachers: ["中舎 寛樹"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1372,
        course_name: "特殊講義(インターネット技術と法規制)",
        teacher_group_id: 1762,
        teachers: ["養老 真一"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 429,
        course_name: "民法I(総則)",
        teacher_group_id: 1216,
        teachers: ["尾島 茂樹"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 10,
        course_name: "法と政治の思想",
        teacher_group_id: 1189,
        teachers: ["大屋 雄祐"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 733,
        course_name: "刑法I",
        teacher_group_id: 818,
        teachers: ["齊藤 彰子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 836,
        course_name: "比較国制論",
        teacher_group_id: 923,
        teachers: ["大河内 美紀"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1372,
        course_name: "特殊講義(インターネット技術と法規制)",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1620,
        course_name: "政治学言論",
        teacher_group_id: 1153,
        teachers: ["小野 耕二"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1284,
        course_name: "民法1(総論)",
        teacher_group_id: 1623,
        teachers: ["佐藤 康紀"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2155,
        course_name: "環境法",
        teacher_group_id: 1329,
        teachers: ["下山 憲治"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 67,
        course_name: "地方自治論",
        teacher_group_id: 31,
        teachers: ["荒見 玲子"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1507,
        course_name: "民法",
        teacher_group_id: 393,
        teachers: ["丸山"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 986,
        course_name: "環境法",
        teacher_group_id: 1111,
        teachers: ["増沢 陽子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 10,
        course_name: "法と政治の思想",
        teacher_group_id: 79,
        teachers: ["姜 東局"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2150,
        course_name: "フランス法",
        teacher_group_id: 2532,
        teachers: ["金山 直樹"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 373,
        course_name: "事故法",
        teacher_group_id: 425,
        teachers: ["岡本"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1155,
        course_name: "日本政治史I",
        teacher_group_id: 1363,
        teachers: ["増田 知子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 832,
        course_name: "国際法(総論)",
        teacher_group_id: 918,
        teachers: ["小畑 郁"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1511,
        course_name: "民法IV",
        teacher_group_id: 425,
        teachers: ["岡本"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 887,
        course_name: "計量政治学",
        teacher_group_id: 985,
        teachers: ["森 正"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 818,
        course_name: "民法IV(事後法)",
        teacher_group_id: 900,
        teachers: ["池田 雅則"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1480,
        course_name: "民法総則",
        teacher_group_id: 1904,
        teachers: ["吉政 知広"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 562,
        course_name: "経済解析1",
        teacher_group_id: 672,
        teachers: ["中田 実"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 547,
        course_name: "民法III(取引法)",
        teacher_group_id: 663,
        teachers: ["松田 貴文"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 544,
        course_name: "商法応用1",
        teacher_group_id: 661,
        teachers: ["今井 克典"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1585,
        course_name: "憲法",
        teacher_group_id: 923,
        teachers: ["大河内 美紀"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1084,
        course_name: "商法I",
        teacher_group_id: 1255,
        teachers: ["松中 学"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1161,
        course_name: "裁判所制度",
        teacher_group_id: 1389,
        teachers: ["大澤 裕"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1679,
        course_name: "債権",
        teacher_group_id: 2357,
        teachers: ["中舎 寛樹"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 467,
        course_name: "日本法制史",
        teacher_group_id: 1803,
        teachers: ["代田 清嗣"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1837,
        course_name: "国際私法",
        teacher_group_id: 2426,
        teachers: ["横溝 大"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1134,
        course_name: "民法4(事故法)",
        teacher_group_id: 1321,
        teachers: ["岡本 裕樹"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2290,
        course_name: "憲法",
        teacher_group_id: 1209,
        teachers: ["本 秀紀"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2030,
        course_name: "日本法制史",
        teacher_group_id: 830,
        teachers: ["神保 文夫"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1156,
        course_name: "特殊講義(デジタル世界の近代日本の政治と法)",
        teacher_group_id: 1698,
        teachers: ["佐野 智也"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1021,
        course_name: "現代日本の政治と行政",
        teacher_group_id: 1153,
        teachers: ["小野 耕二"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1649,
        course_name: "合理的選択論",
        teacher_group_id: 1363,
        teachers: ["増田 知子"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 545,
        course_name: "商法応用2",
        teacher_group_id: 661,
        teachers: ["今井 克典"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 825,
        course_name: "刑法(各論)",
        teacher_group_id: 914,
        teachers: ["古川 伸彦"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2201,
        course_name: "憲法II(憲法・人権保障)",
        teacher_group_id: 923,
        teachers: ["大河内 美紀"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1062,
        course_name: "憲法I(総論・統治)",
        teacher_group_id: 1209,
        teachers: ["本 秀紀"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2256,
        course_name: "租税法",
        teacher_group_id: 406,
        teachers: ["高橋"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 181,
        course_name: "現代日本の外交・国際関係",
        teacher_group_id: 1363,
        teachers: ["増田 知子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2069,
        course_name: "憲法II(人権・憲法訴訟)",
        teacher_group_id: 923,
        teachers: ["大河内 美紀"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1337,
        course_name: "労働法",
        teacher_group_id: 1704,
        teachers: ["矢野 昌浩"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1264,
        course_name: "租税法",
        teacher_group_id: 1582,
        teachers: ["高橋 祐介"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 817,
        course_name: "民法II(物権法)",
        teacher_group_id: 1623,
        teachers: ["佐藤 康紀"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1022,
        course_name: "政治学原論",
        teacher_group_id: 1153,
        teachers: ["小野 耕二"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1080,
        course_name: "行政法I(総論)",
        teacher_group_id: 1281,
        teachers: ["稲葉 一将"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1903,
        course_name: "現代日本の外交・国際関係",
        teacher_group_id: 118,
        teachers: ["水島 朋則"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1061,
        course_name: "憲法I",
        teacher_group_id: 1209,
        teachers: ["本 秀紀"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1158,
        course_name: "近代日本の政治と外交",
        teacher_group_id: 1363,
        teachers: ["増田 知子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 732,
        course_name: "刑法I(総論)",
        teacher_group_id: 818,
        teachers: ["齊藤 彰子"],
        course_group: "法学部専門科目",
        semester: "前期",
      },
      {
        course_id: 429,
        course_name: "民法I(総則)",
        teacher_group_id: 1623,
        teachers: ["佐藤 康紀"],
        course_group: "法学部専門科目",
        semester: "後期",
      },
    ],
    工学部専門科目: [
      {
        course_id: 415,
        course_name: "構造物と技術の発展",
        teacher_group_id: 514,
        teachers: [
          "丸山 一平",
          "勅使河原 正臣",
          "福和 伸夫",
          "中野 正樹",
          "中村 光",
          "水谷 法美",
        ],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 101,
        course_name: "計算機ソフトウェア第1",
        teacher_group_id: 54,
        teachers: ["野老山 貴行"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 448,
        course_name: "有機構造化学",
        teacher_group_id: 558,
        teachers: ["佐藤", "浦口"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 180,
        course_name: "数学2及び演習B",
        teacher_group_id: 117,
        teachers: ["吉田", "吉川", "佐藤"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1970,
        course_name: "センシングシステム工学",
        teacher_group_id: 659,
        teachers: ["藤井 俊彰"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1026,
        course_name: "知識処理",
        teacher_group_id: 1156,
        teachers: ["長尾 確"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 400,
        course_name: "電磁波工学",
        teacher_group_id: 501,
        teachers: ["三好 由純"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 145,
        course_name: "解析力学及び演習",
        teacher_group_id: 332,
        teachers: ["桒原 真人"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1515,
        course_name: "プログラミング",
        teacher_group_id: 2389,
        teachers: ["河原林 順"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 118,
        course_name: "工学倫理",
        teacher_group_id: 66,
        teachers: ["比屋根 均"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 400,
        course_name: "電磁波工学",
        teacher_group_id: 1484,
        teachers: ["長濱 智生"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 529,
        course_name: "機械工学通論",
        teacher_group_id: 652,
        teachers: ["義家 亮"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1626,
        course_name: "材料科学第2",
        teacher_group_id: 2652,
        teachers: ["秦 誠一", "櫻井 淳平"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2175,
        course_name: "計算機アーキテクチャ",
        teacher_group_id: 1986,
        teachers: ["加藤 真平"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1096,
        course_name: "熱移動",
        teacher_group_id: 1272,
        teachers: ["劉 欣"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 282,
        course_name: "無機化学1及び演習",
        teacher_group_id: 311,
        teachers: ["山本 瑛祐", "中村 仁", "鳴滝 彩絵"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 432,
        course_name: "計算機プログラミング基礎及び演習",
        teacher_group_id: 719,
        teachers: ["竹内 栄二朗"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 870,
        course_name: "真空電子工学",
        teacher_group_id: 965,
        teachers: ["五十嵐 信行"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2174,
        course_name: "工学概論第1",
        teacher_group_id: 1478,
        teachers: ["道木 慎二"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1805,
        course_name: "工作機械工学",
        teacher_group_id: 2656,
        teachers: ["佐藤 隆太", "社本 英二"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 538,
        course_name: "確率論・数値解析及び演習",
        teacher_group_id: 659,
        teachers: ["藤井 俊彰"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 177,
        course_name: "図学",
        teacher_group_id: 2527,
        teachers: ["村上 好夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 820,
        course_name: "情報処理序説",
        teacher_group_id: 904,
        teachers: ["山本 俊行"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 842,
        course_name: "コンパイラ及び演習",
        teacher_group_id: 930,
        teachers: ["結縁 祥治"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1120,
        course_name: "情報システム",
        teacher_group_id: 1974,
        teachers: ["渡邉 豊英"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1180,
        course_name: "分析化学",
        teacher_group_id: 1429,
        teachers: ["馬場 嘉信"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1806,
        course_name: "加工学第1及び演習",
        teacher_group_id: 2657,
        teachers: ["社本 英二", "梅原 徳次"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1079,
        course_name: "画像処理",
        teacher_group_id: 1251,
        teachers: ["間瀬 健二"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 85,
        course_name: "化学基礎I",
        teacher_group_id: 104,
        teachers: ["薩摩 篤"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1223,
        course_name: "電気・電子・情報工学序論",
        teacher_group_id: 1487,
        teachers: ["河野 明廣"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2299,
        course_name: "プログラミング(C言語)",
        teacher_group_id: 1337,
        teachers: ["武市 昇"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2062,
        course_name: "コンピュータ・リテラシー及びプログラミング",
        teacher_group_id: 1274,
        teachers: ["小橋 眞"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 851,
        course_name: "情報ネットワーク",
        teacher_group_id: 1261,
        teachers: ["高倉 弘喜"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 375,
        course_name: "無機合成化学",
        teacher_group_id: 434,
        teachers: ["河本"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1040,
        course_name: "材料科学第1",
        teacher_group_id: 1184,
        teachers: ["奥村 大"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 432,
        course_name: "計算機プログラミング基礎及び演習",
        teacher_group_id: 532,
        teachers: ["中澤", "小川", "河口"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 851,
        course_name: "情報ネットワーク",
        teacher_group_id: 945,
        teachers: ["村瀬 勉"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 335,
        course_name: "情報基礎論",
        teacher_group_id: 371,
        teachers: ["福澤 健二"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 260,
        course_name: "数学2及び演習",
        teacher_group_id: 875,
        teachers: ["辻 義之"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 794,
        course_name: "流体力学",
        teacher_group_id: 875,
        teachers: ["辻 義之"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 146,
        course_name: "解析力学",
        teacher_group_id: 85,
        teachers: ["丸山 央峰"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1612,
        course_name: "電情序論",
        teacher_group_id: 1080,
        teachers: ["松村 年郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 384,
        course_name: "粘性流体力学",
        teacher_group_id: 1853,
        teachers: ["長田 孝二"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 845,
        course_name: "アルゴリズム及び演習",
        teacher_group_id: 593,
        teachers: ["西田 直樹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1142,
        course_name: "計算機ソフトウェア第2",
        teacher_group_id: 1337,
        teachers: ["武市 昇"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 268,
        course_name: "伝熱工学及び演習",
        teacher_group_id: 294,
        teachers: ["長野 方星"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1117,
        course_name: "データベース",
        teacher_group_id: 1298,
        teachers: ["石川 佳治"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1707,
        course_name: "移動現象論後半",
        teacher_group_id: 2454,
        teachers: ["長谷川 正"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1714,
        course_name: "構造・電気化学",
        teacher_group_id: 2462,
        teachers: ["竹岡 敬和"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1172,
        course_name: "計測基礎論",
        teacher_group_id: 2655,
        teachers: ["佐藤 隆太"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1604,
        course_name: "確率統計",
        teacher_group_id: 1041,
        teachers: ["村瀬 洋"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 639,
        course_name: "マテリアル工学概論",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1970,
        course_name: "センシングシステム工学",
        teacher_group_id: 1478,
        teachers: ["道木 慎二"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1133,
        course_name: "材料加工学",
        teacher_group_id: 1318,
        teachers: ["秦 誠一"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1181,
        course_name: "超精密工学",
        teacher_group_id: 1430,
        teachers: ["佐藤 一雄"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 254,
        course_name: "電気エネルギー伝送工学",
        teacher_group_id: 239,
        teachers: ["遠藤 奎将"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 432,
        course_name: "計算機プログラミング基礎及び演習",
        teacher_group_id: 990,
        teachers: ["河口 信夫"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1283,
        course_name: "特許及び知的財産",
        teacher_group_id: 1622,
        teachers: ["鬼頭 雅弘"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1217,
        course_name: "高電圧工学",
        teacher_group_id: 1529,
        teachers: ["早川 直樹"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 936,
        course_name: "計算機と社会",
        teacher_group_id: 1041,
        teachers: ["村瀬 洋"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 317,
        course_name: "材料力学第1及び演習",
        teacher_group_id: 304,
        teachers: ["巨 陽"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 549,
        course_name: "数学1及び演習A",
        teacher_group_id: 664,
        teachers: ["塩川 和夫"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2086,
        course_name: "工学概論1",
        teacher_group_id: 1017,
        teachers: ["生田 博志"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1035,
        course_name: "数理計画法",
        teacher_group_id: 1271,
        teachers: ["松原 茂樹"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1737,
        course_name: "原子物理学A",
        teacher_group_id: 2593,
        teachers: ["渡辺 賢一"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1120,
        course_name: "情報システム",
        teacher_group_id: 1304,
        teachers: ["山本 修一郎"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2062,
        course_name: "コンピュータ・リテラシー及びプログラミング",
        teacher_group_id: 1158,
        teachers: ["岡本 敦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1515,
        course_name: "プログラミング",
        teacher_group_id: 1444,
        teachers: ["松本 敏郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2173,
        course_name: "オートマトン・形式言語",
        teacher_group_id: 1475,
        teachers: ["酒井 正彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1210,
        course_name: "パターン認識",
        teacher_group_id: 1477,
        teachers: ["石井 健一郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1219,
        course_name: "電磁波",
        teacher_group_id: 1484,
        teachers: ["長濱 智生"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 851,
        course_name: "情報ネットワーク",
        teacher_group_id: 990,
        teachers: ["河口 信夫"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 432,
        course_name: "計算機プログラミング基礎及び演習",
        teacher_group_id: 1749,
        teachers: ["大塚 真弘"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1309,
        course_name: "センシングシステム工学",
        teacher_group_id: 1650,
        teachers: ["小川 浩平"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 510,
        course_name: "電子回路",
        teacher_group_id: 2654,
        teachers: ["伊藤 伸太郎", "浅井 徹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2289,
        course_name: "流体力学",
        teacher_group_id: 2580,
        teachers: ["山西 陽子"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 276,
        course_name: "材料科学第一",
        teacher_group_id: 303,
        teachers: ["徳 悠葵"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1762,
        course_name: "電気・電子工学特別講義第2",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1054,
        course_name: "非圧縮性流体力学",
        teacher_group_id: 1201,
        teachers: ["森 浩一"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 548,
        course_name: "数学1及び演習B",
        teacher_group_id: 664,
        teachers: ["塩川 和夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2062,
        course_name: "コンピュータ・リテラシー及びプログラミング",
        teacher_group_id: 888,
        teachers: ["塚田 祐貴"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1100,
        course_name: "PCプログラミング",
        teacher_group_id: 1274,
        teachers: ["小橋 眞"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 177,
        course_name: "図学",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 275,
        course_name: "設計基礎論",
        teacher_group_id: 303,
        teachers: ["徳 悠葵"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1757,
        course_name: "機械創造設計",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1809,
        course_name: "設計製図第2",
        teacher_group_id: 2586,
        teachers: ["高橋 徹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2060,
        course_name: "離散数学",
        teacher_group_id: 1271,
        teachers: ["松原 茂樹"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1126,
        course_name: "衛生工学",
        teacher_group_id: 1310,
        teachers: ["片山 新太"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2179,
        course_name: "電磁気学",
        teacher_group_id: 1493,
        teachers: ["保坂 将人"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 166,
        course_name: "反応速度論",
        teacher_group_id: 104,
        teachers: ["薩摩 篤"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1172,
        course_name: "計測基礎論",
        teacher_group_id: 1414,
        teachers: ["森 敏彦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 613,
        course_name: "プログラミング及び演習",
        teacher_group_id: 990,
        teachers: ["河口 信夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 146,
        course_name: "解析力学",
        teacher_group_id: 1193,
        teachers: ["齋藤 晃"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1281,
        course_name: "量子力学及び演習",
        teacher_group_id: 1616,
        teachers: ["大野 雄高"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1225,
        course_name: "数値解析",
        teacher_group_id: 1490,
        teachers: ["山本 一良"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2145,
        course_name: "センシングシステム工学",
        teacher_group_id: 1299,
        teachers: ["新美 智秀"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 100,
        course_name: "設計製図第1",
        teacher_group_id: 54,
        teachers: ["野老山 貴行"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1687,
        course_name: "非手続き型言語",
        teacher_group_id: 1475,
        teachers: ["酒井 正彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 573,
        course_name: "英語(上級)",
        teacher_group_id: 681,
        teachers: ["渡辺 美樹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1666,
        course_name: "量子力学基礎",
        teacher_group_id: 1414,
        teachers: ["森 敏彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1517,
        course_name: "機航制御",
        teacher_group_id: 2061,
        teachers: ["早川"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1686,
        course_name: "非手続き型言語(ML)",
        teacher_group_id: 1475,
        teachers: ["酒井 正彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1683,
        course_name: "計算機プログラミング",
        teacher_group_id: 2371,
        teachers: ["石原 卓"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1212,
        course_name: "パワーエレクトロニクス",
        teacher_group_id: 1585,
        teachers: ["山本 真義"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2001,
        course_name: "有機化学",
        teacher_group_id: 732,
        teachers: ["石原 一彰"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 432,
        course_name: "計算機プログラミング基礎及び演習",
        teacher_group_id: 2068,
        teachers: ["八槇"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 535,
        course_name: "情報理論",
        teacher_group_id: 930,
        teachers: ["結縁 祥治"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 803,
        course_name: "離散数学",
        teacher_group_id: 1532,
        teachers: ["岩田 哲"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 118,
        course_name: "工学倫理",
        teacher_group_id: 1983,
        teachers: ["黒田"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1225,
        course_name: "数値解析",
        teacher_group_id: 1041,
        teachers: ["村瀬 洋"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 443,
        course_name: "数学1及び演習",
        teacher_group_id: 1017,
        teachers: ["生田 博志"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1803,
        course_name: "数値解析法",
        teacher_group_id: 1783,
        teachers: ["竹内 一郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1226,
        course_name: "材力",
        teacher_group_id: 1492,
        teachers: ["上原 拓也"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1235,
        course_name: "情報通信工学第1B",
        teacher_group_id: 1508,
        teachers: ["長谷川 浩"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 502,
        course_name: "無線通信方式",
        teacher_group_id: 639,
        teachers: ["片山 正昭"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2261,
        course_name: "プログラミング",
        teacher_group_id: 2068,
        teachers: ["八槇"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 443,
        course_name: "数学1及び演習",
        teacher_group_id: 664,
        teachers: ["塩川 和夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1140,
        course_name: "流体力学基礎及び演習",
        teacher_group_id: 1335,
        teachers: ["山口 浩樹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1751,
        course_name: "電気・電子工学実験第2",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1142,
        course_name: "計算機ソフトウェア第2",
        teacher_group_id: 1748,
        teachers: ["奥田 裕之"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1037,
        course_name: "電気回路工学及び演習",
        teacher_group_id: 1177,
        teachers: ["櫻井 淳平"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 816,
        course_name: "半導体工学",
        teacher_group_id: 899,
        teachers: ["天野 浩"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 269,
        course_name: "熱力学及び演習",
        teacher_group_id: 294,
        teachers: ["長野 方星"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 965,
        course_name: "物理化学",
        teacher_group_id: 1078,
        teachers: ["澤田 佳代"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1515,
        course_name: "プログラミング",
        teacher_group_id: 1274,
        teachers: ["小橋 眞"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 962,
        course_name: "無機化学序論",
        teacher_group_id: 1075,
        teachers: ["坂本 渉"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1593,
        course_name: "プログラミング(C言語)",
        teacher_group_id: 990,
        teachers: ["河口 信夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1807,
        course_name: "機械・航空宇宙工学実験第1",
        teacher_group_id: 1313,
        teachers: ["伊藤 伸太郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 186,
        course_name: "工学概論第1",
        teacher_group_id: 122,
        teachers: ["長崎 正雅"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 183,
        course_name: "力学I",
        teacher_group_id: 1117,
        teachers: ["荒井 政大"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 803,
        course_name: "離散数学",
        teacher_group_id: 1140,
        teachers: ["草刈 圭一朗"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 936,
        course_name: "計算機と社会",
        teacher_group_id: 2246,
        teachers: ["坂部 俊樹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1747,
        course_name: "電気電子情報工学実験第2",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1191,
        course_name: "電子情報回路工学及び演習",
        teacher_group_id: 1456,
        teachers: ["山里 敬也"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 186,
        course_name: "工学概論第1",
        teacher_group_id: 361,
        teachers: ["長﨑 正雅"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1688,
        course_name: "SML",
        teacher_group_id: 1475,
        teachers: ["酒井 正彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1674,
        course_name: "電子情報回路工学",
        teacher_group_id: 1456,
        teachers: ["山里 敬也"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1741,
        course_name: "物性物理",
        teacher_group_id: 1576,
        teachers: ["澤 博"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 699,
        course_name: "離散数学及び演習",
        teacher_group_id: 784,
        teachers: ["一野 祐亮"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1808,
        course_name: "制御工学第2及び演習",
        teacher_group_id: 1752,
        teachers: ["椿野 大輔"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1675,
        course_name: "電気物性基礎論",
        teacher_group_id: 1458,
        teachers: ["井上 真澄"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 802,
        course_name: "離散数学C",
        teacher_group_id: 882,
        teachers: ["柳浦 睦憲"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1189,
        course_name: "情報基礎論第1及び演習",
        teacher_group_id: 2246,
        teachers: ["坂部 俊樹"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 450,
        course_name: "物理化学序論",
        teacher_group_id: 560,
        teachers: ["安田", "田辺"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 386,
        course_name: "電気回路論及び演習",
        teacher_group_id: 556,
        teachers: ["荻野 瀧樹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 549,
        course_name: "数学1及び演習A",
        teacher_group_id: 784,
        teachers: ["一野 祐亮"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1416,
        course_name: "機構学",
        teacher_group_id: 1843,
        teachers: ["長谷川 泰久"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 791,
        course_name: "振動と波動",
        teacher_group_id: 872,
        teachers: ["小山 剛史"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 644,
        course_name: "物性物理学第2",
        teacher_group_id: 746,
        teachers: ["竹延 大志"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1802,
        course_name: "ディジタル回路",
        teacher_group_id: 1748,
        teachers: ["奥田 裕之"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 707,
        course_name: "統計力学A",
        teacher_group_id: 790,
        teachers: ["増渕 雄一"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 548,
        course_name: "数学1及び演習B",
        teacher_group_id: 1017,
        teachers: ["生田 博志"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 318,
        course_name: "材料力学",
        teacher_group_id: 1274,
        teachers: ["小橋 眞"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1738,
        course_name: "プログラミング基礎",
        teacher_group_id: 2595,
        teachers: ["八槇 博史"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1527,
        course_name: "化学工学序論",
        teacher_group_id: 1583,
        teachers: ["後藤 元信"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1414,
        course_name: "振動工学第1及び演習",
        teacher_group_id: 1839,
        teachers: ["原 進"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 962,
        course_name: "無機化学序論",
        teacher_group_id: 1294,
        teachers: ["大槻 主税"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1389,
        course_name: "計算物理学及び演習",
        teacher_group_id: 1785,
        teachers: ["畝山 多加志"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 613,
        course_name: "プログラミング及び演習",
        teacher_group_id: 719,
        teachers: ["竹内 栄二朗"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1551,
        course_name: "数1A",
        teacher_group_id: 664,
        teachers: ["塩川 和夫"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1481,
        course_name: "熱エネルギー工学",
        teacher_group_id: 1911,
        teachers: ["松田"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 537,
        course_name: "電気電子情報工学序論",
        teacher_group_id: 659,
        teachers: ["藤井 俊彰"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1233,
        course_name: "電気エネルギー基礎論及び演習",
        teacher_group_id: 1608,
        teachers: ["小島 寛樹"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1205,
        course_name: "非手続き型言語及び演習",
        teacher_group_id: 1475,
        teachers: ["酒井 正彦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1052,
        course_name: "熱力学",
        teacher_group_id: 1010,
        teachers: ["武藤 俊介"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 537,
        course_name: "電気電子情報工学序論",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 613,
        course_name: "プログラミング及び演習",
        teacher_group_id: 1618,
        teachers: ["米澤 拓郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 432,
        course_name: "計算機プログラミング基礎及び演習",
        teacher_group_id: 1508,
        teachers: ["長谷川 浩"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 497,
        course_name: "都市と文明の歴史",
        teacher_group_id: 821,
        teachers: ["恒川 和久"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1417,
        course_name: "制御工学第1及び演習",
        teacher_group_id: 1844,
        teachers: ["青山 忠義"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1393,
        course_name: "連続体の力学",
        teacher_group_id: 1793,
        teachers: ["丹波 健"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 625,
        course_name: "有機化学",
        teacher_group_id: 2176,
        teachers: ["忍久保", "浦口"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 699,
        course_name: "離散数学及び演習",
        teacher_group_id: 1532,
        teachers: ["岩田 哲"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1648,
        course_name: "伝熱工学",
        teacher_group_id: 1355,
        teachers: ["梅村 浩"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2117,
        course_name: "離散数学Aクラス",
        teacher_group_id: 1140,
        teachers: ["草刈 圭一朗"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1056,
        course_name: "生物化学序論",
        teacher_group_id: 1326,
        teachers: ["浅沼 浩之"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1055,
        course_name: "数学演習II",
        teacher_group_id: 1203,
        teachers: ["新見 智秀"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1587,
        course_name: "論理回路",
        teacher_group_id: 924,
        teachers: ["枝廣 正人"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1552,
        course_name: "数学1",
        teacher_group_id: 2391,
        teachers: ["石井 克哉"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 378,
        course_name: "数理論理学",
        teacher_group_id: 438,
        teachers: ["坂部"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1370,
        course_name: "電磁気学III及び演習",
        teacher_group_id: 1753,
        teachers: ["斎藤 晃"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 894,
        course_name: "計算機工学",
        teacher_group_id: 994,
        teachers: ["安藤 秀樹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 384,
        course_name: "粘性流体力学",
        teacher_group_id: 1387,
        teachers: ["中村 佳朗"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 849,
        course_name: "OS及び演習",
        teacher_group_id: 935,
        teachers: ["高田 広章"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1542,
        course_name: "高分子物理化学",
        teacher_group_id: 2186,
        teachers: ["高野", "松下"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 520,
        course_name: "電磁気学2と電気磁気学基礎演習",
        teacher_group_id: 646,
        teachers: ["西澤 典彦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 319,
        course_name: "固体電子工学",
        teacher_group_id: 2148,
        teachers: ["中里"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1251,
        course_name: "確率と統計",
        teacher_group_id: 1531,
        teachers: ["森 保宏"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1515,
        course_name: "プログラミング",
        teacher_group_id: 2037,
        teachers: ["小橋"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 519,
        course_name: "光エレクトロニクス",
        teacher_group_id: 646,
        teachers: ["西澤 典彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 497,
        course_name: "都市と文明の歴史",
        teacher_group_id: 631,
        teachers: ["恒川 和久", "森川 高行"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 318,
        course_name: "材料力学",
        teacher_group_id: 1217,
        teachers: ["大野 信忠"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1617,
        course_name: "離散数学B",
        teacher_group_id: 1135,
        teachers: ["外山 勝彦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1165,
        course_name: "知能制御",
        teacher_group_id: 1400,
        teachers: ["大熊 繁"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1153,
        course_name: "法学",
        teacher_group_id: 1358,
        teachers: ["渡部 美由紀"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1066,
        course_name: "制御工学第一",
        teacher_group_id: 1211,
        teachers: ["早川 義一"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1616,
        course_name: "精密加工",
        teacher_group_id: 1125,
        teachers: ["梅原 徳次"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1052,
        course_name: "熱力学",
        teacher_group_id: 1199,
        teachers: ["吉川 典彦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 404,
        course_name: "量子力学及び演習",
        teacher_group_id: 1485,
        teachers: ["柚原 淳司"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1214,
        course_name: "情報基礎論第2",
        teacher_group_id: 575,
        teachers: ["佐藤 理史"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1178,
        course_name: "応用数学",
        teacher_group_id: 1425,
        teachers: ["山本 有作"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 699,
        course_name: "離散数学及び演習",
        teacher_group_id: 1659,
        teachers: ["森 洋二郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1017,
        course_name: "計算機ハードウェア及び演習",
        teacher_group_id: 1144,
        teachers: ["本田 晋也"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2182,
        course_name: "数学2A",
        teacher_group_id: 1503,
        teachers: ["吉田 隆"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1004,
        course_name: "離散数学Cクラス",
        teacher_group_id: 1135,
        teachers: ["外山 勝彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 639,
        course_name: "マテリアル工学概論",
        teacher_group_id: 742,
        teachers: ["松宮 弘明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1960,
        course_name: "電子回路工学及び演習B",
        teacher_group_id: 642,
        teachers: ["大野 哲靖"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1528,
        course_name: "無線方式",
        teacher_group_id: 2133,
        teachers: ["片山"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 535,
        course_name: "情報理論",
        teacher_group_id: 657,
        teachers: ["武田 一哉"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1495,
        course_name: "数学2",
        teacher_group_id: 1984,
        teachers: ["庄司 多津男"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1579,
        course_name: "物理工学",
        teacher_group_id: 872,
        teachers: ["小山 剛史"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2148,
        course_name: "解析力学または熱力学",
        teacher_group_id: 1309,
        teachers: ["山下 博史"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 970,
        course_name: "電力機器",
        teacher_group_id: 1080,
        teachers: ["松村 年郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1550,
        course_name: "情報通信工学第一",
        teacher_group_id: 657,
        teachers: ["武田 一哉"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 187,
        course_name: "物物",
        teacher_group_id: 122,
        teachers: ["長崎 正雅"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1552,
        course_name: "数学1",
        teacher_group_id: 784,
        teachers: ["一野 祐亮"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1462,
        course_name: "情報理論及び演習",
        teacher_group_id: 69,
        teachers: ["結縁 祥冶"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1671,
        course_name: "論理関数",
        teacher_group_id: 2337,
        teachers: ["高木 直史"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 949,
        course_name: "電子回路論及び演習",
        teacher_group_id: 1056,
        teachers: ["岩田 聡"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2185,
        course_name: "量子化学I",
        teacher_group_id: 1525,
        teachers: ["熊谷 純"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 319,
        course_name: "固体電子工学",
        teacher_group_id: 353,
        teachers: ["新津 葵一"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1194,
        course_name: "電気物性基礎論及び演習B",
        teacher_group_id: 1458,
        teachers: ["井上 真澄"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1552,
        course_name: "数学1",
        teacher_group_id: 994,
        teachers: ["安藤 秀樹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1166,
        course_name: "知能制御システム",
        teacher_group_id: 1400,
        teachers: ["大熊 繁"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1193,
        course_name: "電気物性基礎論及び演習",
        teacher_group_id: 1458,
        teachers: ["井上 真澄"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 378,
        course_name: "数理論理学",
        teacher_group_id: 2246,
        teachers: ["坂部 俊樹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1141,
        course_name: "流体力学基礎第1及び演習",
        teacher_group_id: 1335,
        teachers: ["山口 浩樹"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2175,
        course_name: "計算機アーキテクチャ",
        teacher_group_id: 2255,
        teachers: ["冨山 宏之"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 335,
        course_name: "情報基礎論",
        teacher_group_id: 1455,
        teachers: ["高木 一義"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1179,
        course_name: "デジタル回路",
        teacher_group_id: 1427,
        teachers: ["西堀 賢司"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 996,
        course_name: "信号処理",
        teacher_group_id: 1428,
        teachers: ["藤本 健治"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1310,
        course_name: "有機化学1及び演習",
        teacher_group_id: 1651,
        teachers: ["山下 誠"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 535,
        course_name: "情報理論",
        teacher_group_id: 735,
        teachers: ["田中 智之"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1192,
        course_name: "電子情報回路工学及び演習B",
        teacher_group_id: 1456,
        teachers: ["山里 敬也"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2175,
        course_name: "計算機アーキテクチャ",
        teacher_group_id: 1480,
        teachers: ["富山 宏之"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 548,
        course_name: "数学1及び演習B",
        teacher_group_id: 712,
        teachers: ["近藤 博基"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2060,
        course_name: "離散数学",
        teacher_group_id: 882,
        teachers: ["柳浦 睦憲"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1742,
        course_name: "微生物学",
        teacher_group_id: 2607,
        teachers: ["堀 克敏"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 164,
        course_name: "線形回路論",
        teacher_group_id: 659,
        teachers: ["藤井 俊彰"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1189,
        course_name: "情報基礎論第1及び演習",
        teacher_group_id: 1455,
        teachers: ["高木 一義"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2181,
        course_name: "数学2及び演習",
        teacher_group_id: 1571,
        teachers: ["田地 宏一"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1520,
        course_name: "計算機ソフト第二",
        teacher_group_id: 2067,
        teachers: ["武市"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1669,
        course_name: "計算機ソフトウェア1",
        teacher_group_id: 1444,
        teachers: ["松本 敏郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1693,
        course_name: "情報基礎論第2B",
        teacher_group_id: 575,
        teachers: ["佐藤 理史"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2321,
        course_name: "産業と経済",
        teacher_group_id: 2579,
        teachers: ["高橋 一正"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 962,
        course_name: "無機化学序論",
        teacher_group_id: 2548,
        teachers: ["大田 裕道"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 504,
        course_name: "電子情報回路Aクラス",
        teacher_group_id: 639,
        teachers: ["片山 正昭"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1599,
        course_name: "数1演習",
        teacher_group_id: 1017,
        teachers: ["生田 博志"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2315,
        course_name: "流体力学1",
        teacher_group_id: 2474,
        teachers: ["長谷川 豊"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2001,
        course_name: "有機化学",
        teacher_group_id: 2179,
        teachers: ["古荘", "西山"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1758,
        course_name: "機械航空科概論",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1963,
        course_name: "数学2及び演習B",
        teacher_group_id: 646,
        teachers: ["西澤 典彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 443,
        course_name: "数学1及び演習",
        teacher_group_id: 1524,
        teachers: ["向井 康人"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1733,
        course_name: "電子デバイス",
        teacher_group_id: 2559,
        teachers: ["水谷 孝"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1547,
        course_name: "真空電子",
        teacher_group_id: 2205,
        teachers: ["丹治"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1605,
        course_name: "確率統計及び演習",
        teacher_group_id: 1041,
        teachers: ["村瀬 洋"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1190,
        course_name: "情報基礎論第1及び演習B",
        teacher_group_id: 1455,
        teachers: ["高木 一義"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1170,
        course_name: "制御工学第2",
        teacher_group_id: 1412,
        teachers: ["坂本 登"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1702,
        course_name: "移動現象論",
        teacher_group_id: 2436,
        teachers: ["杉山 貴彦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 437,
        course_name: "電気磁気学及び演習B",
        teacher_group_id: 535,
        teachers: ["加藤 丈佳"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2128,
        course_name: "粘性流体力学",
        teacher_group_id: 1200,
        teachers: ["酒井 康彦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1180,
        course_name: "分析化学",
        teacher_group_id: 217,
        teachers: ["馬場 渡慶次"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1570,
        course_name: "都市と文明",
        teacher_group_id: 821,
        teachers: ["恒川 和久"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 510,
        course_name: "電子回路",
        teacher_group_id: 642,
        teachers: ["大野 哲靖"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2249,
        course_name: "無機合成化学",
        teacher_group_id: 1930,
        teachers: ["太田", "河本"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 244,
        course_name: "分析化学序論",
        teacher_group_id: 217,
        teachers: ["馬場 渡慶次"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1538,
        course_name: "反応工学概論",
        teacher_group_id: 2178,
        teachers: ["安田", "鈴木"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 257,
        course_name: "無機化学A",
        teacher_group_id: 246,
        teachers: ["余語 利信"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 992,
        course_name: "精密加工学",
        teacher_group_id: 1125,
        teachers: ["梅原 徳次"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1200,
        course_name: "電気電子応用",
        teacher_group_id: 1470,
        teachers: ["高井 吉明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1216,
        course_name: "電気エネルギー基礎論",
        teacher_group_id: 1483,
        teachers: ["大久保 仁"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 435,
        course_name: "電気磁気学及び演習",
        teacher_group_id: 651,
        teachers: ["豊田 浩孝"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1479,
        course_name: "応用計測化学",
        teacher_group_id: 217,
        teachers: ["馬場 渡慶次"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1228,
        course_name: "伝送システム工学",
        teacher_group_id: 1494,
        teachers: ["谷本 正幸"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 947,
        course_name: "磁性体工学",
        teacher_group_id: 1056,
        teachers: ["岩田 聡"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1214,
        course_name: "情報基礎論第2",
        teacher_group_id: 1482,
        teachers: ["平田 富夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1899,
        course_name: "線形回路論",
        teacher_group_id: 1470,
        teachers: ["高井 吉明"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1587,
        course_name: "論理回路",
        teacher_group_id: 2337,
        teachers: ["高木 直史"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1552,
        course_name: "数学1",
        teacher_group_id: 1435,
        teachers: ["梶田 将司"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1899,
        course_name: "線形回路論",
        teacher_group_id: 651,
        teachers: ["豊田 浩孝"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 503,
        course_name: "ディジタル回路及び演習",
        teacher_group_id: 639,
        teachers: ["片山 正昭"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2298,
        course_name: "数学1",
        teacher_group_id: 1313,
        teachers: ["伊藤 伸太郎"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1750,
        course_name: "電気電子情報工学実験",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1640,
        course_name: "ベクトルと微分方程式",
        teacher_group_id: 1313,
        teachers: ["伊藤 伸太郎"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1719,
        course_name: "電磁気学II",
        teacher_group_id: 2480,
        teachers: ["佐々木 浩一"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 318,
        course_name: "材料力学",
        teacher_group_id: 304,
        teachers: ["巨 陽"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 526,
        course_name: "工学倫理(集中講義)",
        teacher_group_id: 648,
        teachers: ["黒田 光太郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1098,
        course_name: "ソフトウェア設計法",
        teacher_group_id: 1273,
        teachers: ["関 浩之"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 260,
        course_name: "数学2及び演習",
        teacher_group_id: 646,
        teachers: ["西澤 典彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1492,
        course_name: "触媒・表面化学",
        teacher_group_id: 1943,
        teachers: ["薩摩", "鳥本", "吉田"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 694,
        course_name: "固体電子工学及び演習",
        teacher_group_id: 781,
        teachers: ["須田 淳"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 451,
        course_name: "量子力学B",
        teacher_group_id: 561,
        teachers: ["田仲 由喜夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2176,
        course_name: "パワーエレクトロニクス",
        teacher_group_id: 1481,
        teachers: ["古橋 武"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1279,
        course_name: "マテリアル量子工学",
        teacher_group_id: 1614,
        teachers: ["田川 美穂"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2314,
        course_name: "量子化学II",
        teacher_group_id: 2461,
        teachers: ["鳥本 司"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1685,
        course_name: "オートマトン",
        teacher_group_id: 1475,
        teachers: ["酒井 正彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 443,
        course_name: "数学1及び演習",
        teacher_group_id: 547,
        teachers: ["向井", "小林"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1760,
        course_name: "電気電子工学実験第1",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1171,
        course_name: "宇宙推進システム",
        teacher_group_id: 1413,
        teachers: ["曽我 丈夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 260,
        course_name: "数学2及び演習",
        teacher_group_id: 117,
        teachers: ["吉田", "吉川", "佐藤"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1169,
        course_name: "統計力学及び演習",
        teacher_group_id: 1411,
        teachers: ["笹井 理生"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1248,
        course_name: "電気エネルギー変換",
        teacher_group_id: 1529,
        teachers: ["早川 直樹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1527,
        course_name: "化学工学序論",
        teacher_group_id: 2125,
        teachers: ["堀添"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1229,
        course_name: "伝送システム",
        teacher_group_id: 1494,
        teachers: ["谷本 正幸"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 384,
        course_name: "粘性流体力学",
        teacher_group_id: 464,
        teachers: ["伊藤"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 260,
        course_name: "数学2及び演習",
        teacher_group_id: 267,
        teachers: ["張 紹良"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1743,
        course_name: "概論",
        teacher_group_id: 2608,
        teachers: ["梅原 徳治"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2140,
        course_name: "情報理論",
        teacher_group_id: 1273,
        teachers: ["関 浩之"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1552,
        course_name: "数学1",
        teacher_group_id: 664,
        teachers: ["塩川 和夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1543,
        course_name: "有機化学序論",
        teacher_group_id: 2203,
        teachers: ["西山", "上垣外"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 449,
        course_name: "機能高分子化学",
        teacher_group_id: 559,
        teachers: ["八島", "上垣外"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 319,
        course_name: "固体電子工学",
        teacher_group_id: 781,
        teachers: ["須田 淳"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1217,
        course_name: "高電圧工学",
        teacher_group_id: 1483,
        teachers: ["大久保 仁"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1035,
        course_name: "数理計画法",
        teacher_group_id: 1173,
        teachers: ["加藤 ジェーン"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1540,
        course_name: "有機化学IV",
        teacher_group_id: 2183,
        teachers: ["蒲池", "大井"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1804,
        course_name: "振動工学第2及び演習",
        teacher_group_id: 2653,
        teachers: ["井上 剛志"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1848,
        course_name: "物理工学演習",
        teacher_group_id: 2660,
        teachers: ["芳松 克則"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2312,
        course_name: "数学2",
        teacher_group_id: 575,
        teachers: ["佐藤 理史"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 966,
        course_name: "電力機器工学",
        teacher_group_id: 1079,
        teachers: ["横水 康伸"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1992,
        course_name: "数学1及び演習",
        teacher_group_id: 712,
        teachers: ["近藤 博基"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1546,
        course_name: "有機化学II",
        teacher_group_id: 2176,
        teachers: ["忍久保", "浦口"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 972,
        course_name: "材料力学第2及び演習",
        teacher_group_id: 1084,
        teachers: ["松本 健郎"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 519,
        course_name: "光エレクトロニクス",
        teacher_group_id: 384,
        teachers: ["西澤"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1495,
        course_name: "数学2",
        teacher_group_id: 267,
        teachers: ["張 紹良"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1655,
        course_name: "数理論理学及び演習",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1624,
        course_name: "化学工学基礎1",
        teacher_group_id: 1204,
        teachers: ["本多 裕之"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1052,
        course_name: "熱力学",
        teacher_group_id: 1523,
        teachers: ["岡崎 進"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 965,
        course_name: "物理化学",
        teacher_group_id: 873,
        teachers: ["平澤 政廣"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1710,
        course_name: "電気電子数学",
        teacher_group_id: 1535,
        teachers: ["西谷 望"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1191,
        course_name: "電子情報回路工学及び演習",
        teacher_group_id: 639,
        teachers: ["片山 正昭"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 641,
        course_name: "原子物理学",
        teacher_group_id: 743,
        teachers: ["浅野 秀文"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1236,
        course_name: "プラズマ理工学",
        teacher_group_id: 1513,
        teachers: ["山崎 耕造"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 908,
        course_name: "熱",
        teacher_group_id: 1010,
        teachers: ["武藤 俊介"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 444,
        course_name: "固体力学及び演習",
        teacher_group_id: 550,
        teachers: ["田中 英一"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1655,
        course_name: "数理論理学及び演習",
        teacher_group_id: 2246,
        teachers: ["坂部 俊樹"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1536,
        course_name: "化学工学概論",
        teacher_group_id: 2177,
        teachers: ["小林", "堀添", "小島"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1992,
        course_name: "数学1及び演習",
        teacher_group_id: 1313,
        teachers: ["伊藤 伸太郎"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 456,
        course_name: "アルゴリズムとデータ構造",
        teacher_group_id: 575,
        teachers: ["佐藤 理史"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 967,
        course_name: "電磁気学",
        teacher_group_id: 1079,
        teachers: ["横水 康伸"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1053,
        course_name: "流体",
        teacher_group_id: 1200,
        teachers: ["酒井 康彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1056,
        course_name: "生物化学序論",
        teacher_group_id: 1204,
        teachers: ["本多 裕之"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 295,
        course_name: "物理工学演習2b",
        teacher_group_id: 2660,
        teachers: ["芳松 克則"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2298,
        course_name: "数学1",
        teacher_group_id: 1530,
        teachers: ["水谷 法美"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2208,
        course_name: "量子力学A及び演習",
        teacher_group_id: 1750,
        teachers: ["柏谷 聡"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1748,
        course_name: "電気電子情報工学実験第一",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 909,
        course_name: "電磁気学解析",
        teacher_group_id: 1010,
        teachers: ["武藤 俊介"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1395,
        course_name: "統計力学B",
        teacher_group_id: 1795,
        teachers: ["竹中 康司"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 144,
        course_name: "創造設計製作",
        teacher_group_id: 85,
        teachers: ["丸山 央峰"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 443,
        course_name: "数学1及び演習",
        teacher_group_id: 1435,
        teachers: ["梶田 将司"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1626,
        course_name: "材料科学第2",
        teacher_group_id: 1217,
        teachers: ["大野 信忠"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1215,
        course_name: "アルゴリズム",
        teacher_group_id: 1482,
        teachers: ["平田 富夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 99,
        course_name: "計算機ソフトウェア第1",
        teacher_group_id: 1444,
        teachers: ["松本 敏郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 540,
        course_name: "確率論",
        teacher_group_id: 659,
        teachers: ["藤井 俊彰"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1056,
        course_name: "生物化学序論",
        teacher_group_id: 2138,
        teachers: ["浅沼"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 178,
        course_name: "図学",
        teacher_group_id: 113,
        teachers: ["長坂 今夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 177,
        course_name: "図学",
        teacher_group_id: 1169,
        teachers: ["村山 顕人"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2162,
        course_name: "設計基礎論",
        teacher_group_id: 1362,
        teachers: ["秋庭 義明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2095,
        course_name: "電子回路論及び演習A",
        teacher_group_id: 1056,
        teachers: ["岩田 聡"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1899,
        course_name: "線形回路論",
        teacher_group_id: 2140,
        teachers: ["北岡"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 381,
        course_name: "計算機2",
        teacher_group_id: 443,
        teachers: ["菱田"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2178,
        course_name: "電磁気学",
        teacher_group_id: 1489,
        teachers: ["田中 信夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1191,
        course_name: "電子情報回路工学及び演習",
        teacher_group_id: 1977,
        teachers: ["片山 山里"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 613,
        course_name: "プログラミング及び演習",
        teacher_group_id: 1650,
        teachers: ["小川 浩平"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1603,
        course_name: "数値解析及び演習",
        teacher_group_id: 1041,
        teachers: ["村瀬 洋"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1725,
        course_name: "電気物性基礎",
        teacher_group_id: 1545,
        teachers: ["藤巻 朗"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 598,
        course_name: "誘電体工学",
        teacher_group_id: 705,
        teachers: ["堀 勝"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 891,
        course_name: "プログラミングCクラス",
        teacher_group_id: 990,
        teachers: ["河口 信夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1642,
        course_name: "形と力",
        teacher_group_id: 2214,
        teachers: ["伊藤 義人"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1800,
        course_name: "電気磁気学基礎演習",
        teacher_group_id: 578,
        teachers: ["長尾 全寛"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1466,
        course_name: "物性物理学A",
        teacher_group_id: 122,
        teachers: ["長崎 正雅"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 966,
        course_name: "電力機器工学",
        teacher_group_id: 1080,
        teachers: ["松村 年郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1541,
        course_name: "光・放射線化学",
        teacher_group_id: 2185,
        teachers: ["熊谷", "岡崎", "関"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1549,
        course_name: "電磁気学及び演習",
        teacher_group_id: 651,
        teachers: ["豊田 浩孝"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1947,
        course_name: "電気磁気学及び演習",
        teacher_group_id: 535,
        teachers: ["加藤 丈佳"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 513,
        course_name: "電子回路工学及び演習",
        teacher_group_id: 642,
        teachers: ["大野 哲靖"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1519,
        course_name: "流体力学1",
        teacher_group_id: 2065,
        teachers: ["長谷川"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1173,
        course_name: "応用構造理論",
        teacher_group_id: 1416,
        teachers: ["池田 忠繁"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1210,
        course_name: "パターン認識",
        teacher_group_id: 1173,
        teachers: ["加藤 ジェーン"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 404,
        course_name: "量子力学及び演習",
        teacher_group_id: 504,
        teachers: ["山下 太郎"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1515,
        course_name: "プログラミング",
        teacher_group_id: 2436,
        teachers: ["杉山 貴彦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1394,
        course_name: "化学物理学",
        teacher_group_id: 1794,
        teachers: ["松永 克志"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 295,
        course_name: "物理工学演習2b",
        teacher_group_id: 322,
        teachers: ["洗平 昌晃"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 501,
        course_name: "電気電子数学及び演習",
        teacher_group_id: 638,
        teachers: ["西谷 望", "谷本 正幸"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1899,
        course_name: "線形回路論",
        teacher_group_id: 713,
        teachers: ["本田 善央"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1320,
        course_name: "物理工学実験第一",
        teacher_group_id: 2658,
        teachers: ["畑野 敬史"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1232,
        course_name: "電磁気学Aクラス",
        teacher_group_id: 1502,
        teachers: ["小川 忠彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1992,
        course_name: "数学1及び演習",
        teacher_group_id: 1530,
        teachers: ["水谷 法美"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2311,
        course_name: "知能制御システム",
        teacher_group_id: 1478,
        teachers: ["道木 慎二"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1277,
        course_name: "ディジタル信号処理",
        teacher_group_id: 1610,
        teachers: ["高橋 桂太"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 269,
        course_name: "熱力学及び演習",
        teacher_group_id: 1309,
        teachers: ["山下 博史"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 318,
        course_name: "材料力学",
        teacher_group_id: 242,
        teachers: ["大野"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 794,
        course_name: "流体力学",
        teacher_group_id: 1387,
        teachers: ["中村 佳朗"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 501,
        course_name: "電気電子数学及び演習",
        teacher_group_id: 2202,
        teachers: ["西谷", "谷本"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 549,
        course_name: "数学1及び演習A",
        teacher_group_id: 712,
        teachers: ["近藤 博基"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 608,
        course_name: "線形回路論及び演習",
        teacher_group_id: 723,
        teachers: ["牧原 克典"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1005,
        course_name: "離散数学および演習",
        teacher_group_id: 1135,
        teachers: ["外山 勝彦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2310,
        course_name: "ディジタル信号処理",
        teacher_group_id: 1478,
        teachers: ["道木 慎二"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 386,
        course_name: "電気回路論及び演習",
        teacher_group_id: 1715,
        teachers: ["梅田 隆行"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 260,
        course_name: "数学2及び演習",
        teacher_group_id: 2620,
        teachers: ["伊藤 孝至"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 907,
        course_name: "熱力学及び物理工学演習2a",
        teacher_group_id: 1010,
        teachers: ["武藤 俊介"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 946,
        course_name: "流体力学基礎第2",
        teacher_group_id: 1054,
        teachers: ["伊藤 靖仁"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 906,
        course_name: "物理工学演習2a",
        teacher_group_id: 1010,
        teachers: ["武藤 俊介"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1643,
        course_name: "生物材料化学",
        teacher_group_id: 1326,
        teachers: ["浅沼 浩之"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1233,
        course_name: "電気エネルギー基礎論及び演習",
        teacher_group_id: 1503,
        teachers: ["吉田 隆"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 513,
        course_name: "電子回路工学及び演習",
        teacher_group_id: 1132,
        teachers: ["加藤 剛志"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1543,
        course_name: "有機化学序論",
        teacher_group_id: 2188,
        teachers: ["八島", "西山"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 641,
        course_name: "原子物理学",
        teacher_group_id: 1262,
        teachers: ["中塚 理"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1409,
        course_name: "相平衡論",
        teacher_group_id: 1835,
        teachers: ["宇治原 徹"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 973,
        course_name: "力学I",
        teacher_group_id: 1084,
        teachers: ["松本 健郎"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 260,
        course_name: "数学2及び演習",
        teacher_group_id: 2452,
        teachers: ["岩井 一彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 145,
        course_name: "解析力学及び演習",
        teacher_group_id: 85,
        teachers: ["丸山 央峰"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1333,
        course_name: "物理工学演習1",
        teacher_group_id: 1700,
        teachers: ["矢田 圭司"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 803,
        course_name: "離散数学",
        teacher_group_id: 1135,
        teachers: ["外山 勝彦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 180,
        course_name: "数学2及び演習B",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1499,
        course_name: "電気磁気学講義及び演習",
        teacher_group_id: 1963,
        teachers: ["佐々木", "横水"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2267,
        course_name: "電磁気学",
        teacher_group_id: 651,
        teachers: ["豊田 浩孝"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 851,
        course_name: "情報ネットワーク",
        teacher_group_id: 1134,
        teachers: ["佐藤 健一"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 260,
        course_name: "数学2及び演習",
        teacher_group_id: 1503,
        teachers: ["吉田 隆"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1036,
        course_name: "パターン認識及び演習",
        teacher_group_id: 1173,
        teachers: ["加藤 ジェーン"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2188,
        course_name: "数値解析",
        teacher_group_id: 1535,
        teachers: ["西谷 望"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1017,
        course_name: "計算機ハードウェア及び演習",
        teacher_group_id: 935,
        teachers: ["高田 広章"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 386,
        course_name: "電気回路論及び演習",
        teacher_group_id: 472,
        teachers: ["山本"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1549,
        course_name: "電磁気学及び演習",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 841,
        course_name: "コンパイラ",
        teacher_group_id: 930,
        teachers: ["結縁 祥治"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 947,
        course_name: "磁性体工学",
        teacher_group_id: 1132,
        teachers: ["加藤 剛志"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 512,
        course_name: "電子回路論及び演習",
        teacher_group_id: 642,
        teachers: ["大野 哲靖"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 528,
        course_name: "プラズマ工学",
        teacher_group_id: 651,
        teachers: ["豊田 浩孝"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 893,
        course_name: "計算機アーキテクチャ",
        teacher_group_id: 994,
        teachers: ["安藤 秀樹"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1899,
        course_name: "線形回路論",
        teacher_group_id: 108,
        teachers: ["加藤"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 249,
        course_name: "電気工学通論第1",
        teacher_group_id: 228,
        teachers: ["田畑 彰守"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1160,
        course_name: "計算機",
        teacher_group_id: 1380,
        teachers: ["島田 俊夫"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 390,
        course_name: "有機化学1",
        teacher_group_id: 2697,
        teachers: ["田中"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 180,
        course_name: "数学2及び演習B",
        teacher_group_id: 1571,
        teachers: ["田地 宏一"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1276,
        course_name: "微分積分学",
        teacher_group_id: 1605,
        teachers: ["三河 大晟"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 503,
        course_name: "ディジタル回路及び演習",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2186,
        course_name: "誘電体工学",
        teacher_group_id: 1526,
        teachers: ["森 竜雄"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1754,
        course_name: "空間設計工学及び演習",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2289,
        course_name: "流体力学",
        teacher_group_id: 1200,
        teachers: ["酒井 康彦"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 250,
        course_name: "電気回路",
        teacher_group_id: 228,
        teachers: ["田畑 彰守"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 608,
        course_name: "線形回路論及び演習",
        teacher_group_id: 713,
        teachers: ["本田 善央"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 503,
        course_name: "ディジタル回路及び演習",
        teacher_group_id: 1456,
        teachers: ["山里 敬也"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1211,
        course_name: "制御工学",
        teacher_group_id: 1478,
        teachers: ["道木 慎二"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 455,
        course_name: "オートマトンと形式言語",
        teacher_group_id: 575,
        teachers: ["佐藤 理史"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 177,
        course_name: "図学",
        teacher_group_id: 384,
        teachers: ["西澤"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1899,
        course_name: "線形回路論",
        teacher_group_id: 1132,
        teachers: ["加藤 剛志"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 638,
        course_name: "物理化学2",
        teacher_group_id: 742,
        teachers: ["松宮 弘明"],
        course_group: "工学部専門科目",
        semester: "その他",
      },
      {
        course_id: 1014,
        course_name: "生体情報処理",
        teacher_group_id: 1142,
        teachers: ["工藤 博章"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1320,
        course_name: "物理工学実験第一",
        teacher_group_id: 1670,
        teachers: ["飯田和 昌"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 996,
        course_name: "信号処理",
        teacher_group_id: 1128,
        teachers: ["大西 昇"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1263,
        course_name: "物性物理学第1",
        teacher_group_id: 1576,
        teachers: ["澤 博"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 177,
        course_name: "図学",
        teacher_group_id: 805,
        teachers: ["西澤 泰彦"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 164,
        course_name: "線形回路論",
        teacher_group_id: 102,
        teachers: ["本田", "牧原", "加藤"],
        course_group: "工学部専門科目",
        semester: "後期",
      },
      {
        course_id: 386,
        course_name: "電気回路論及び演習",
        teacher_group_id: 535,
        teachers: ["加藤 丈佳"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
      {
        course_id: 386,
        course_name: "電気回路論及び演習",
        teacher_group_id: 1585,
        teachers: ["山本 真義"],
        course_group: "工学部専門科目",
        semester: "前期",
      },
    ],
    教育学部専門科目: [
      {
        course_id: 1231,
        course_name: "人間発達科学入門",
        teacher_group_id: 1649,
        teachers: ["辻 浩"],
        course_group: "教育学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1761,
        course_name: "人間発達科学III",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "教育学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1231,
        course_name: "人間発達科学入門",
        teacher_group_id: 1496,
        teachers: ["阿曽沼 明裕"],
        course_group: "教育学部専門科目",
        semester: "前期",
      },
      {
        course_id: 533,
        course_name: "人間発達科学IV",
        teacher_group_id: 655,
        teachers: ["中谷 素之"],
        course_group: "教育学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1502,
        course_name: "現代資本市場論",
        teacher_group_id: 2470,
        teachers: ["高松 明"],
        course_group: "教育学部専門科目",
        semester: "後期",
      },
      {
        course_id: 858,
        course_name: "技術教育学",
        teacher_group_id: 949,
        teachers: ["横山 悦生"],
        course_group: "教育学部専門科目",
        semester: "後期",
      },
    ],
    専門科目: [
      {
        course_id: 1348,
        course_name: "インド文化学概論a",
        teacher_group_id: 1717,
        teachers: ["平野 克典"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 1770,
        course_name: "心理学概論",
        teacher_group_id: 1346,
        teachers: ["大平 英樹"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 1075,
        course_name: "考古学概論",
        teacher_group_id: 1245,
        teachers: ["梶原 義実"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 327,
        course_name: "美術史講義",
        teacher_group_id: 363,
        teachers: ["見田 隆鑑"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 1772,
        course_name: "社会学概論",
        teacher_group_id: 1208,
        teachers: ["丸山 康司"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 236,
        course_name: "文化人類学入門演習",
        teacher_group_id: 190,
        teachers: ["佐々木 重洋"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 1383,
        course_name: "文化人類学講義",
        teacher_group_id: 1775,
        teachers: ["吉田 早悠里"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 543,
        course_name: "特殊講義(商法基礎)",
        teacher_group_id: 661,
        teachers: ["今井 克典"],
        course_group: "専門科目",
        semester: "後期",
      },
      {
        course_id: 1771,
        course_name: "社会学概論I",
        teacher_group_id: 773,
        teachers: ["河村 則行"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 1770,
        course_name: "心理学概論",
        teacher_group_id: 2625,
        teachers: ["磯村 朋子"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 1221,
        course_name: "物性物理A",
        teacher_group_id: 1485,
        teachers: ["柚原 淳司"],
        course_group: "専門科目",
        semester: "後期",
      },
      {
        course_id: 1764,
        course_name: "物理工学演習第一",
        teacher_group_id: 1893,
        teachers: ["不明"],
        course_group: "専門科目",
        semester: "後期",
      },
      {
        course_id: 1386,
        course_name: "知的財産法",
        teacher_group_id: 1779,
        teachers: ["西井 志織"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 1794,
        course_name: "電磁気学II",
        teacher_group_id: 1708,
        teachers: ["清水 裕彦"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 2069,
        course_name: "憲法II(人権・憲法訴訟)",
        teacher_group_id: 923,
        teachers: ["大河内 美紀"],
        course_group: "専門科目",
        semester: "前期",
      },
      {
        course_id: 1262,
        course_name: "結晶物理学",
        teacher_group_id: 1574,
        teachers: ["山本 剛久"],
        course_group: "専門科目",
        semester: "後期",
      },
    ],
    開放科目: [
      {
        course_id: 1662,
        course_name: "経営学",
        teacher_group_id: 2260,
        teachers: ["岸田 民樹"],
        course_group: "開放科目",
        semester: "後期",
      },
      {
        course_id: 1085,
        course_name: "現代メディア論",
        teacher_group_id: 1256,
        teachers: ["宇佐美 博"],
        course_group: "開放科目",
        semester: "後期",
      },
      {
        course_id: 618,
        course_name: "名大の歴史をたどる",
        teacher_group_id: 726,
        teachers: ["吉川 卓治"],
        course_group: "開放科目",
        semester: "前期",
      },
      {
        course_id: 258,
        course_name: "数学展望I",
        teacher_group_id: 258,
        teachers: ["川平 友規"],
        course_group: "開放科目",
        semester: "前期",
      },
      {
        course_id: 1744,
        course_name: "言語論",
        teacher_group_id: 2613,
        teachers: ["斉藤 文俊"],
        course_group: "開放科目",
        semester: "前期",
      },
      {
        course_id: 1663,
        course_name: "教育情報工学",
        teacher_group_id: 1392,
        teachers: ["後藤 明史"],
        course_group: "開放科目",
        semester: "前期",
      },
      {
        course_id: 1891,
        course_name: "会計I",
        teacher_group_id: 73,
        teachers: ["角ヶ谷 典幸"],
        course_group: "開放科目",
        semester: "前期",
      },
      {
        course_id: 763,
        course_name: "マクロ経済学I",
        teacher_group_id: 1012,
        teachers: ["玉井 寿樹"],
        course_group: "開放科目",
        semester: "後期",
      },
      {
        course_id: 1162,
        course_name: "独文学講義",
        teacher_group_id: 1391,
        teachers: ["清水 純夫"],
        course_group: "開放科目",
        semester: "前期",
      },
      {
        course_id: 2040,
        course_name: "マクロ経済学I",
        teacher_group_id: 846,
        teachers: ["荒渡 良"],
        course_group: "開放科目",
        semester: "後期",
      },
      {
        course_id: 1650,
        course_name: "ミクロ経済学",
        teacher_group_id: 2229,
        teachers: ["多和田 眞"],
        course_group: "開放科目",
        semester: "後期",
      },
      {
        course_id: 911,
        course_name: "マクロ経済学I",
        teacher_group_id: 1306,
        teachers: ["森田 雄一"],
        course_group: "開放科目",
        semester: "後期",
      },
      {
        course_id: 1903,
        course_name: "現代日本の外交・国際関係",
        teacher_group_id: 118,
        teachers: ["水島 朋則"],
        course_group: "開放科目",
        semester: "前期",
      },
    ],
    情報文化学部専門科目: [
      {
        course_id: 934,
        course_name: "ネットワーク社会",
        teacher_group_id: 1040,
        teachers: ["安田 孝美"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 81,
        course_name: "人類生存のための科学1",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 687,
        course_name: "感じる情報学",
        teacher_group_id: 775,
        teachers: ["秋庭 史典"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1456,
        course_name: "論理学",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 688,
        course_name: "情報の哲学",
        teacher_group_id: 775,
        teachers: ["秋庭 史典"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1243,
        course_name: "シミュレーション科学入門",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 939,
        course_name: "社会調査法1",
        teacher_group_id: 1046,
        teachers: ["青山 聡子"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1496,
        course_name: "マルチメディア",
        teacher_group_id: 1951,
        teachers: ["安田 茂登山"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 760,
        course_name: "アルゴリズム",
        teacher_group_id: 844,
        teachers: ["渡辺 崇"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1668,
        course_name: "サイバー社会論",
        teacher_group_id: 2313,
        teachers: ["横井 茂樹"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 935,
        course_name: "映像メディア論",
        teacher_group_id: 1040,
        teachers: ["安田 孝美"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1103,
        course_name: "環境物質学1",
        teacher_group_id: 1279,
        teachers: ["村田 静昭"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1068,
        course_name: "地球環境学4",
        teacher_group_id: 1220,
        teachers: ["森本 宏"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 964,
        course_name: "環境政治",
        teacher_group_id: 1077,
        teachers: ["野村 康"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1645,
        course_name: "地域調査2",
        teacher_group_id: 588,
        teachers: ["高橋 誠"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2098,
        course_name: "クリエイティブ・ネットワーキング",
        teacher_group_id: 1073,
        teachers: ["有田 隆也"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 194,
        course_name: "社会の中の科学技術",
        teacher_group_id: 2493,
        teachers: ["小林 道太郎"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 211,
        course_name: "情報文化英語セミナー1",
        teacher_group_id: 145,
        teachers: ["小出 俟子"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1127,
        course_name: "政治外交思想史",
        teacher_group_id: 1312,
        teachers: ["川田 稔"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 939,
        course_name: "社会調査法1",
        teacher_group_id: 1284,
        teachers: ["青木 聡子"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1634,
        course_name: "システム論",
        teacher_group_id: 1285,
        teachers: ["佐野 充"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1105,
        course_name: "臨床心理学",
        teacher_group_id: 1280,
        teachers: ["伊藤 義美"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2223,
        course_name: "人類生存のための科学",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 198,
        course_name: "ネットワーク基礎",
        teacher_group_id: 130,
        teachers: ["鈴木 麗璽"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2226,
        course_name: "人類",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1107,
        course_name: "システム論の基礎",
        teacher_group_id: 1285,
        teachers: ["佐野 充"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2271,
        course_name: "論理学",
        teacher_group_id: 700,
        teachers: ["松原 洋"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 418,
        course_name: "国際環境と政治・外交",
        teacher_group_id: 518,
        teachers: ["山田 高敬"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 80,
        course_name: "情報倫理と法",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 860,
        course_name: "国際法と環境",
        teacher_group_id: 951,
        teachers: ["高村 ゆかり"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2331,
        course_name: "クリエイティブ・ネットワーキング",
        teacher_group_id: 130,
        teachers: ["鈴木 麗璽"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 2313,
        course_name: "社会の中の科学技術",
        teacher_group_id: 2432,
        teachers: ["青木 滋之"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 963,
        course_name: "地域社会論",
        teacher_group_id: 1076,
        teachers: ["黒田 由彦"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1244,
        course_name: "人類生存のための科学2",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 198,
        course_name: "ネットワーク基礎",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 199,
        course_name: "プログラミング2",
        teacher_group_id: 130,
        teachers: ["鈴木 麗璽"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 79,
        course_name: "論理学(批判的思考)",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 667,
        course_name: "情報リテラシーとコンピュータ",
        teacher_group_id: 764,
        teachers: ["永峰 康一郎"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 563,
        course_name: "応用経済分析2",
        teacher_group_id: 672,
        teachers: ["中田 実"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 194,
        course_name: "社会の中の科学技術",
        teacher_group_id: 128,
        teachers: ["久木田 水生"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 939,
        course_name: "社会調査法1",
        teacher_group_id: 1208,
        teachers: ["丸山 康司"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1076,
        course_name: "経済解析2",
        teacher_group_id: 1737,
        teachers: ["大森 達也"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 982,
        course_name: "認知科学と人工知能",
        teacher_group_id: 1100,
        teachers: ["三輪 和久"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1706,
        course_name: "プログラミング",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 690,
        course_name: "統計分析",
        teacher_group_id: 776,
        teachers: ["加藤 尚史"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 986,
        course_name: "環境法",
        teacher_group_id: 1111,
        teachers: ["増沢 陽子"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 418,
        course_name: "国際環境と政治・外交",
        teacher_group_id: 955,
        teachers: ["井口 治夫"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 961,
        course_name: "ネットワーク・コミュニケーション",
        teacher_group_id: 1073,
        teachers: ["有田 隆也"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1187,
        course_name: "地域環境システム論",
        teacher_group_id: 1447,
        teachers: ["林 上"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 1886,
        course_name: "人類生存のための科学2",
        teacher_group_id: 36,
        teachers: ["戸田山 和久"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 942,
        course_name: "情報デザイン",
        teacher_group_id: 1051,
        teachers: ["茂登山 清文"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1245,
        course_name: "プログラミング1",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1561,
        course_name: "情報リテラシー",
        teacher_group_id: 764,
        teachers: ["永峰 康一郎"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 531,
        course_name: "情報システム",
        teacher_group_id: 654,
        teachers: ["栗本 英和"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 679,
        course_name: "社会学概論",
        teacher_group_id: 773,
        teachers: ["河村 則行"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 199,
        course_name: "プログラミング2",
        teacher_group_id: 981,
        teachers: ["内山 知実"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1739,
        course_name: "ネットワーク",
        teacher_group_id: 2709,
        teachers: ["鈴木", "北"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2077,
        course_name: "プログラミング2",
        teacher_group_id: 1528,
        teachers: ["北 栄輔"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 679,
        course_name: "社会学概論",
        teacher_group_id: 1278,
        teachers: ["室井 研二"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1501,
        course_name: "数理情報学1",
        teacher_group_id: 1978,
        teachers: ["小澤"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1564,
        course_name: "社会変動論",
        teacher_group_id: 773,
        teachers: ["河村 則行"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 198,
        course_name: "ネットワーク基礎",
        teacher_group_id: 161,
        teachers: ["鈴木 霊璽", "北 栄輔"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 760,
        course_name: "アルゴリズム",
        teacher_group_id: 1013,
        teachers: ["渡邉 崇"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 75,
        course_name: "哲学",
        teacher_group_id: 996,
        teachers: ["米山 優"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1682,
        course_name: "社会心理学",
        teacher_group_id: 1465,
        teachers: ["唐澤 穣"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 562,
        course_name: "経済解析1",
        teacher_group_id: 672,
        teachers: ["中田 実"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1060,
        course_name: "リスク社会論",
        teacher_group_id: 1208,
        teachers: ["丸山 康司"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 143,
        course_name: "公法",
        teacher_group_id: 84,
        teachers: ["赤渕 芳宏"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 1106,
        course_name: "物質情報学1",
        teacher_group_id: 1283,
        teachers: ["谷村 省吾"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 273,
        course_name: "情報と職業1",
        teacher_group_id: 298,
        teachers: ["山本 龍大"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 79,
        course_name: "論理学(批判的思考)",
        teacher_group_id: 1087,
        teachers: ["久木田 豊"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 2253,
        course_name: "経済解析2",
        teacher_group_id: 1954,
        teachers: ["焼田 党"],
        course_group: "情報文化学部専門科目",
        semester: "前期",
      },
      {
        course_id: 385,
        course_name: "意思決定",
        teacher_group_id: 468,
        teachers: ["中村"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
      {
        course_id: 385,
        course_name: "意思決定",
        teacher_group_id: 658,
        teachers: ["中村 泰之"],
        course_group: "情報文化学部専門科目",
        semester: "後期",
      },
    ],
  },
};

export const dummyCourses = [
  {
    course_id: 1,
    course_name: "物質世界の認識",
    teacher_group_id: 1,
    teachers: ["倉田 圭一", "佐藤 由美"],
    course_group: "理系教養科目",
    semester: "前期",
  },
  {
    course_id: 2,
    course_name: "生物学の基礎",
    teacher_group_id: 2,
    teachers: ["山北 早苗"],
    course_group: "理系教養科目",
    semester: "後期",
  },
  {
    course_id: 3,
    course_name: "化学の基本",
    teacher_group_id: 3,
    teachers: ["山田 健太"],
    course_group: "理系教養科目",
    semester: "前期",
  },
  {
    course_id: 4,
    course_name: "物質世界の認識",
    teacher_group_id: 1,
    teachers: ["谷岡 省吾"],
    course_group: "理系教養科目",
    semester: "前期",
  },
  {
    course_id: 5,
    course_name: "生物学の基礎",
    teacher_group_id: 5,
    teachers: ["森田 純一"],
    course_group: "大学での学び基礎論",
    semester: "後期",
  },
];
