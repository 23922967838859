import React from "react";

import { Menu, MenuItem, MenuMenu } from "semantic-ui-react";

const RankingListHeader = (props) => {
  const { activeItem, setActiveItem } = props;
  return (
    <Menu secondary size="tiny" style={{ margin: 0 }}>
      <div
        style={{
          textAlign: "left",
          margin: 6,
        }}
      >
        <h4
          style={{
            fontFamily: "sans-serif",
            fontSize: "17px",
            color: "#333",
            margin: 0,
          }}
        >
          投稿数ランキング
        </h4>
        <p style={{ color: "gray" }}>※匿名投稿は除く</p>
      </div>
      <MenuMenu position="right">
        <MenuItem
          name="月間"
          link
          active={activeItem === "month"}
          onClick={() => setActiveItem("month")}
        />
        <MenuItem
          name="学期"
          link
          active={activeItem === "semester"}
          onClick={() => setActiveItem("semester")}
        />
        <MenuItem
          name="年間"
          link
          active={activeItem === "year"}
          onClick={() => setActiveItem("year")}
        />
      </MenuMenu>
    </Menu>
  );
};
export default RankingListHeader;
