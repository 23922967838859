// 配列をシャッフルする関数
export const shuffleArray = (array) => {
  if (array.length === 0) return array;
  const cloneArray = [...array];

  const result = cloneArray.reduce((_, cur, idx) => {
    let rand = Math.floor(Math.random() * (idx + 1));
    cloneArray[idx] = cloneArray[rand];
    cloneArray[rand] = cur;
    return cloneArray;
  });

  return result;
};
