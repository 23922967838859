import ApiClient from "./ApiClient";
import { REVIEWS } from "./ApiEndpoints";

export default class ReviewApi {
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  async getEachUnivReviewsCount() {
    const res = await this.apiClient.get(REVIEWS.COUNT.EACH.GET);
    return res.data;
  }

  async getReview(reviewId) {
    const res = await this.apiClient.get(
      REVIEWS.SINGLE.GET.replace("<review_id>", reviewId)
    );
    return res.data;
  }

  async getUnivReviews(univId, params = {}) {
    Object.keys(params).forEach((key) => {
      if (
        params[key] === null ||
        params[key] === undefined ||
        params[key] === "" ||
        JSON.stringify(params[key]) === "[]"
      ) {
        delete params[key];
      }
    }); // 不要なパラメータを削除
    const queryParams =
      Object.keys(params).length === 0
        ? ""
        : "?" + new URLSearchParams(params).toString();
    const res = await this.apiClient.get(
      REVIEWS.UNIV.GET.replace("<univ_id>", univId) + queryParams
    );
    return res.data;
  }

  async getUnivReviewsCount(univId) {
    const res = await this.apiClient.get(
      REVIEWS.UNIV.COUNT.GET.replace("<univ_id>", univId)
    );
    return res.data.count;
  }

  async getUserReviews(userId) {
    const res = await this.apiClient.get(
      REVIEWS.USER.GET.replace("<user_id>", userId)
    );
    return res.data;
  }

  async postReview({
    univId,
    courseName,
    courseGroup,
    semester,
    teachers,
    year,
    attendanceFreq,
    scoringMethod,
    creditEasiness,
    contentQuality,
    comment,
    anonymous,
  }) {
    const postData = {
      univ_id: univId,
      course_name: courseName,
      course_group: courseGroup,
      semester: semester,
      teachers: teachers,
      year: Number(year),
      attendance_freq: attendanceFreq,
      scoring_method: scoringMethod,
      credit_easiness: creditEasiness,
      content_quality: contentQuality,
      comment: comment,
      anonymous: Boolean(anonymous),
    };
    const res = await this.apiClient.post(REVIEWS.POST, postData);
    return res.data;
  }

  async deleteReview(reviewId) {
    const res = await this.apiClient.delete(
      REVIEWS.SINGLE.DELETE.replace("<review_id>", reviewId)
    );
    return res.data;
  }

  async putReviewLgtm(reviewId) {
    const res = await this.apiClient.put(
      REVIEWS.SINGLE.LGTM.PUT.replace("<review_id>", reviewId)
    );
    return res.data;
  }

  async deleteReviewLgtm(reviewId) {
    const res = await this.apiClient.delete(
      REVIEWS.SINGLE.LGTM.DELETE.replace("<review_id>", reviewId)
    );
    return res.data;
  }
}
