import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import { Container, Header } from "semantic-ui-react";

import { ReactComponent as NotFoundImage } from "../../static/404.svg";

export function NotFound() {
  return (
    <div>
      <Container>
        <Header textAlign="center" as="h1" style={{ margin: "28px 0" }}>
          お探しのページは見つかりませんでした
        </Header>
      </Container>
      <div
        style={{
          height: "200px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <NotFoundImage
          style={{
            position: "absolute",
            height: "100%",
            top: "-9999px",
            left: "-9999px",
            right: "-9999px",
            bottom: "-9999px",
            margin: "auto",
          }}
        />
      </div>
    </div>
  );
}

export class Privacy extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const title = "プライバシーポリシー";
    const description = "楽単らくだのプライバシーポリシーです。";
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta name="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>

        <Container>
          <Header as="h1">プライバシーポリシー</Header>
          <p>
            株式会社Hashup（以下、「当社」という。）は、「楽単らくだ」（以下、「当サービス」という。）におけるユーザーの個人情報について以下のとおりプライバシーポリシーを定めます。
          </p>
          <h3>個人情報の利用目的</h3>
          <p>
            当サービスでは、アカウント登録の際に当社が取得する名前やメールアドレス等の個人情報は、以下に掲げる利用目的のために取得します。その他の場合において個人情報を取得する場合は、Webや書面で利用目的を明示し同意を得て取得します。
          </p>
          <ol>
            <li>本サービスを円滑に提供するため</li>
            <li>本サービスの運営・管理のため</li>
            <li>
              本サービスの利用状況等を調査および分析し、本サービスの改善、開発または利用者ごとの最適化を行うため
            </li>
            <li>
              当社が運営するセミナー及び各種イベント等の運営管理をするため
            </li>
            <li>本サービスに関するメールマガジン等の情報を配信するため</li>
            <li>
              アンケート、キャンペーン等の依頼、連絡、プレゼント発送等を行うため
            </li>
            <li>本サービスに関して利用者と連絡を取るため</li>
            <li>本サービスに関するご案内、お問い合わせ等への対応のため</li>
            <li>当社及び第三者の商品等の広告・宣伝、販売の勧誘</li>
            <li>当社が提供するサービスやセミナーの案内を行うため</li>
            <li>当社が提供するサービスの保守・メンテナンス業務を行うため</li>
            <li>当社サービスに関する規約等の変更などを通知するため</li>
            <li>本サービスの効果測定を行うため</li>
            <li>教育機関との共同研究のため</li>
            <li>
              当社が提供する他のサービス（コンサルティング業務、受託業務等）において匿名化した個人情報やデータ性向をエビデンスとして使用するため
            </li>
            <li>その他当社と利用者との間で同意した目的のため</li>
            <li>上記 1 から 16 に附随する目的のため</li>
          </ol>
          <h3>第三者提供について</h3>
          <p>
            当社は、以下の提供先に対して、書面または電磁的な方法により個人情報を提供します。
          </p>
          <p>
            【提供先】
            <br />
            当社が協業する見込みのある企業及び機関
          </p>
          <p>
            【提供情報】
            <br />
            当社が取得した個人情報のうち、氏名を除外し性別や年代の属性情報にする等、当該情報のみでは提供先が特定の個人を識別することができない情報に加工した上で提供します。
          </p>
          <p>
            【提供目的】
            <br />
            取得した個人情報の分析の結果導き出したデータ性向等の提供を受けた第三者が使用するため。
          </p>
          <h3>個人を特定しない属性情報・行動履歴の取得及び利用について</h3>
          <p>
            当社は、利用者のプライバシーの保護、利便性の向上、広告の配信、及び統計データの取得のため、Cookie等(*)の情報を送信しています。また、当社は、Cookie等の技術を利用して、会員登録時等にご提供いただいた情報のうち年齢や性別、職業、居住地域など個人が特定できない属性情報(組み合わせることによっても個人が特定できないものに限られます)や、サイト内における利用者の行動履歴(アクセスしたURL、コンテンツ、参照順等)を取得することがあります。ただし、Cookie等及び行動履歴には個人情報は一切含まれません。なお、Cookie等の受け取りは、ブラウザの設定によって拒否することができます。拒否した場合本サービスをご利用いただく上で、一部機能に制約が生じることがあります。
          </p>
          <p>
            (*)...
            Cookie等とは、利用者がウェブサイトにアクセスした際に利用者のデバイスに保存されるテキストファイルおよびこれに類似する技術によって保存される電子ファイルをいいます。
          </p>
          <p>
            以下、当社において、どのような情報を、誰に対し、どのような目的で送信しているかを、記載いたします。
          </p>
          <h4>Googleアナリティクス</h4>
          <ul>
            <li>
              送信される情報：UserAgent情報、リファラ、おおよその位置情報、セッションの統計情報
            </li>
            <li>サービス提供者：Google LLC</li>
            <li>当社の利用目的：アクセス解析のため</li>
            <li>送信先の利用目的：利用者による閲覧の傾向や履歴の分析のため</li>
          </ul>
          <p>
            詳細については以下のURLからご確認ください。
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://marketingplatform.google.com/about/analytics/terms/jp/"
            >
              Google アナリティクス利用規約
            </a>
          </p>
          <h3>投稿者の発信 IP アドレス記録について</h3>
          本サービスへ授業評価を投稿した際、投稿に紐づけて発信元のIPアドレスが記録されます。
          サイト上ではIPアドレスが公開されることはなく、スパムや荒らしへの対応およびユーザーが利用規約へ違反した場合以外にこのIPアドレスを使用することはありません。
          <h3>個人情報の開示・訂正・利用停止等</h3>
          開示、訂正、利用停止等のお申し出があった場合には、当社所定の方法に基づき対応致します。具体的な方法については、個別にご案内しますので、下記受付窓口までお問い合わせください。
          <br />
          連絡先: rakuda X hashup.co.jp (X → @)
          <h3>個人情報取り扱いに関する相談や苦情の連絡先</h3>
          当社の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせは下記受付窓口よりご連絡ください。
          <br />
          連絡先: rakuda X hashup.co.jp (X → @)
          <h3>プライバシーポリシーの制定日及び改定日</h3>
          <p>
            制定：2019年8月20日
            <br />
            改定：2023年8月1日
          </p>
        </Container>
      </div>
    );
  }
}

export class Guide extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/*
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta name="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>
        */}
        <Container>
          <Header as="h1">削除依頼</Header>
          <p>
            以下の内容を記入の上、連絡先へご連絡ください。内容を確認の上、
            <Link to={ROUTES.TERMS}>利用規約第2条（禁止事項）</Link>
            へ違反していると認められる場合は投稿を削除します。
          </p>
          <ol>
            <li>掲載場所</li>
            <li>問題となる部分</li>
            <li>削除依頼をする理由</li>
            <li>希望する対応（部分的な削除・投稿内容全体の削除）</li>
          </ol>
          <p>連絡先: rakuda X hashup.co.jp (X → @)</p>
        </Container>
      </div>
    );
  }
}

export class Terms extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/*
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta name="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>
        */}

        <Container>
          <Header as="h1">利用規約</Header>
          <p>
            <i>作成: 2019年8月20日</i>
            <i>改定: 2020年3月13日</i>
          </p>

          <p>
            この利用規約（以下，「本規約」といいます。）は，株式会社ハッシュアップ（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
          </p>

          <h3>第1条（適用）</h3>
          <ol>
            <li>
              本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
            </li>
            <li>
              当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
            </li>
            <li>
              本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
            </li>
          </ol>

          <h3>第2条（禁止事項）</h3>
          <p>
            ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
          </p>
          <ol>
            <li>法令または公序良俗に違反する行為</li>
            <li>犯罪行為に関連する行為</li>
            <li>
              当社，本サービスの他のユーザー，または第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
            </li>
            <li>当社のサービスの運営を妨害するおそれのある行為</li>
            <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
            <li>不正アクセスをし，またはこれを試みる行為</li>
            <li>他のユーザーに成りすます行為</li>
            <li>
              当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
            </li>
            <li>
              当社，本サービスの他のユーザーまたは第三者の知的財産権，肖像権，プライバシー，名誉その他の権利または利益を侵害する行為
            </li>
            <li>
              以下の表現を含み，または含むと当社が判断する内容を本サービス上に投稿し，または送信する行為
              <ol>
                <li>過度に暴力的な表現</li>
                <li>名誉毀損や侮辱罪にあたる表現</li>
                <li>露骨な性的表現</li>
                <li>
                  人種，国籍，信条，性別，社会的身分，門地等による差別につながる表現
                </li>
                <li>自殺，自傷行為，薬物乱用を誘引または助長する表現</li>
                <li>その他反社会的な内容を含み他人に不快感を与える表現</li>
              </ol>
            </li>
            <li>
              以下を目的とし，または目的とすると当社が判断する行為
              <ol>
                <li>
                  営業，宣伝，広告，勧誘，その他営利を目的とする行為（当社の認めたものを除きます。）
                </li>
                <li>性行為やわいせつな行為を目的とする行為</li>
                <li>面識のない異性との出会いや交際を目的とする行為</li>
                <li>他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為</li>
                <li>
                  当社，本サービスの他のユーザー，または第三者に不利益，損害または不快感を与えることを目的とする行為
                </li>
                <li>
                  その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
                </li>
              </ol>
            </li>
            <li>宗教活動または宗教団体への勧誘行為</li>
            <li>その他，当社が不適切と判断する行為</li>
          </ol>

          <h3>第3条（本サービスの提供の停止等）</h3>
          <ol>
            <li>
              当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
              <ol>
                <li>
                  本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                </li>
                <li>
                  地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
                </li>
                <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                <li>その他，当社が本サービスの提供が困難と判断した場合</li>
              </ol>
            </li>
            <li>
              当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
            </li>
          </ol>

          <h3>第4条（著作権）</h3>
          <ol>
            <li>
              ユーザーは，自ら著作権等の必要な知的財産権を有するか，または必要な権利者の許諾を得た文章，画像や映像等の情報に関してのみ，本サービスを利用し，投稿ないしアップロードすることができるものとします。
            </li>
            <li>
              ユーザーが本サービスを利用して投稿ないしアップロードした文章，画像，映像等の著作権については，当該ユーザーその他既存の権利者に留保されるものとします。ただし，当社は，本サービスを利用して投稿ないしアップロードされた文章，画像，映像等について，本サービスの改良，品質の向上，または不備の是正等ならびに本サービスの周知宣伝等に必要な範囲で利用できるものとし，ユーザーは，この利用に関して，著作者人格権を行使しないものとします。
            </li>
            <li>
              前項本文の定めるものを除き，本サービスおよび本サービスに関連する一切の情報についての著作権およびその他の知的財産権はすべて当社または当社にその利用を許諾した権利者に帰属し，ユーザーは無断で複製，譲渡，貸与，翻訳，改変，転載，公衆送信（送信可能化を含みます。），伝送，配布，出版，営業使用等をしてはならないものとします。
            </li>
          </ol>

          <h3>第5条（サービス内容の変更等）</h3>
          <p>
            当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
          </p>

          <h3>第6条（利用規約の変更）</h3>
          <p>
            当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
          </p>

          <h3>第7条（免責事項）</h3>
          <ol>
            <li>
              情報の閲覧やサービスの提供を受けるにあたっては、法律上の義務に従った上、ユーザー自身の責任において行うものとします。
            </li>
            <li>
              当社は、本サイトにおける情報やサービスまたは、その安全性・確実性・有用性・最新性・合法性・道徳性や、コンピューターウィルスに感染しない等のいかなる保証を行うものではありません。
            </li>
            <li>
              当社は、本サイトからのリンクしている他のリンク先等に含まれる情報・サービスについては、一切責任を負うものではありません。
            </li>
            <li>
              当社は、虚偽の事実又は他人の個人情報を使用された場合等、不正使用については、一切の責任を負うものではありません。
            </li>
          </ol>

          <h3>第8条（個人情報の取扱い）</h3>
          <p>
            当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。
          </p>

          <h3>第9条（権利義務の譲渡の禁止）</h3>
          <p>
            ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
          </p>

          <h3>第10条（準拠法・裁判管轄）</h3>
          <ol>
            <li>本規約の解釈にあたっては，日本法を準拠法とします。</li>
            <li>
              本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
            </li>
          </ol>

          <p>以上</p>
        </Container>
      </div>
    );
  }
}

export default class Page extends React.Component {
  static Privacy = Privacy;
  static Terms = Terms;
  static Guide = Guide;
  static NotFound = NotFound;
  render() {
    return null;
  }
}
