import React from "react";

import "./Logo.less";

const DEFAULT_BG_COLOR = "#206774";
const DEFAULT_HAT_COLOR = "#545252";

export default class RakudaIcon extends React.Component {
  render() {
    const {
      hat_color = DEFAULT_HAT_COLOR,
      bg_color = DEFAULT_BG_COLOR,
      circular = false,
      ...otherProps
    } = this.props;

    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 70 70"
        style={{
          enableBackground: "new 0 0 70 70",
          width: "100%",
          height: "auto",
        }}
        {...otherProps}
      >
        {circular ? (
          <circle
            style={{ fill: bg_color ?? DEFAULT_BG_COLOR }}
            cx="35"
            cy="35"
            r="35"
          />
        ) : (
          <rect x="0" y="0" width="70" height="70" fill={bg_color} />
        )}
        <g>
          <path
            className="st0"
            d="M51.78,39.43c0,0,2.6-6.88-0.65-11.69c0,0,1.3,0.39,2.21,1.95v-1.3c0,0,2.72,1.04,2.21,5.2
		C55.27,36.01,53.88,38.17,51.78,39.43z"
          />
          <path
            className="st1"
            d="M51.78,39.82c-0.22,0-0.39-0.17-0.39-0.39c0-0.05,0.01-0.09,0.03-0.14c0.03-0.07,2.49-6.76-0.61-11.33
		c-0.12-0.18-0.08-0.42,0.1-0.54c0.1-0.07,0.22-0.09,0.33-0.05c0.65,0.24,1.24,0.63,1.7,1.15v-0.13c0-0.22,0.17-0.39,0.39-0.39
		c0.05,0,0.1,0.01,0.14,0.03c0.12,0.05,3.01,1.2,2.46,5.61c-0.29,2.56-1.76,4.83-3.97,6.14C51.9,39.81,51.84,39.82,51.78,39.82z
		 M52.21,28.88c1.6,3.54,0.82,7.63,0.29,9.57c1.48-1.24,2.43-2.99,2.66-4.91c0.34-2.68-0.74-3.94-1.43-4.47v0.62
		c0,0.22-0.17,0.39-0.39,0.39c-0.14,0-0.27-0.07-0.34-0.19C52.79,29.51,52.52,29.17,52.21,28.88L52.21,28.88z"
          />
          <path
            className="st0"
            d="M49.24,50.5c0,0-1.65,5.24-0.96,9.46l-2.31-0.2c0,0-0.44-5.5-0.17-9.25H49.24z"
          />
          <path
            className="st1"
            d="M48.29,60.35h-0.04l-2.31-0.21c-0.19-0.02-0.34-0.17-0.35-0.36c0-0.06-0.44-5.57-0.18-9.31
		c0.02-0.2,0.18-0.36,0.39-0.36h3.45c0.22,0,0.39,0.18,0.39,0.39c0,0.04-0.01,0.08-0.02,0.12c-0.02,0.05-1.6,5.16-0.96,9.23
		c0.01,0.04,0.02,0.08,0.02,0.12C48.68,60.18,48.5,60.35,48.29,60.35L48.29,60.35z M46.34,59.4l1.49,0.13
		c-0.41-3.43,0.52-7.28,0.9-8.64h-2.56C45.99,54.02,46.25,58.21,46.34,59.4z"
          />
          <polygon
            className="st3"
            points="48.86,61.54 45.83,61.7 45.98,59.45 48.48,59.31 	"
          />
          <path
            className="st1"
            d="M45.83,62.09c-0.21,0-0.39-0.17-0.39-0.39c0-0.01,0-0.02,0-0.03l0.16-2.25c0.01-0.19,0.17-0.35,0.36-0.36
		l2.49-0.14c0.2-0.01,0.37,0.13,0.41,0.32l0.38,2.22c0.04,0.21-0.11,0.41-0.32,0.45c-0.01,0-0.03,0-0.04,0l-3.03,0.17L45.83,62.09z
		 M46.35,59.82l-0.1,1.47l2.16-0.12l-0.25-1.45L46.35,59.82z"
          />
          <path
            className="st0"
            d="M37.12,50.53v9.78l2.3,0.6l0.92-8.58C40.34,52.32,41.71,50.53,37.12,50.53z"
          />
          <path
            className="st1"
            d="M39.42,61.29c-0.03,0-0.07,0-0.1-0.01l-2.3-0.6c-0.17-0.04-0.29-0.2-0.29-0.38v-9.78
		c0-0.22,0.17-0.39,0.39-0.39l0,0c2.11,0,3.32,0.37,3.69,1.12c0.17,0.39,0.14,0.83-0.09,1.19l-0.91,8.49
		c-0.01,0.11-0.08,0.22-0.17,0.28C39.57,61.26,39.49,61.29,39.42,61.29z M37.51,60l1.57,0.41l0.87-8.13
		c0.01-0.07,0.04-0.14,0.08-0.2l0,0c0,0,0.19-0.26,0.08-0.48c-0.08-0.15-0.49-0.63-2.6-0.68L37.51,60z"
          />
          <path
            className="st0"
            d="M22.75,38.01c0,0-1.72,4.41-3.22,7.39c-1.49,2.98,1.38,10.37,12.65,5.36c0,0-5.53,4.71-5.53,7.1l1.73,0.95
		c0,0,6.67-8.05,10.12-7.81c0,0,4.59,4.17,9.77-0.24L47.76,61.2l2.59-0.6c0,0,1.26-7.09,2.07-9.48s3.1-5.48,0.8-11.09
		s-2.53-7.73-4.25-7.73s-2.3,5.34-4.59,4.75c-2.29-0.6-1.26-5.94-4.37-5.94c-3.1,0-8.62,15.12-10,13.93
		c-1.38-1.19,0.46-8.58,0.46-8.58L22.75,38.01z"
          />
          <path
            className="st1"
            d="M47.75,61.59c-0.09,0-0.18-0.03-0.25-0.09c-0.09-0.08-0.14-0.2-0.14-0.32l0.47-9.58
		c-1.45,1.05-2.98,1.58-4.56,1.58c-2.58,0-4.46-1.4-4.93-1.79l0,0c-2.72,0-7.9,5.54-9.67,7.68c-0.12,0.14-0.32,0.18-0.49,0.09
		l-1.72-0.95c-0.12-0.07-0.2-0.2-0.2-0.34c0-1.71,2.39-4.32,4.08-5.95c-1.8,0.62-3.44,0.94-4.92,0.94l0,0
		c-3.53-0.01-5.16-1.79-5.82-2.86c-0.93-1.51-1.1-3.42-0.43-4.77c1.47-2.93,3.19-7.31,3.2-7.36c0.05-0.12,0.16-0.21,0.28-0.23
		l7.7-1.55c0.21-0.04,0.42,0.09,0.46,0.3c0.01,0.06,0.01,0.12,0,0.17c-0.73,2.92-1.39,7.32-0.63,8.16c0.56-0.26,2.07-3.1,3.18-5.2
		c2.72-5.14,4.8-8.78,6.61-8.78c1.99,0,2.51,1.82,2.98,3.43c0.34,1.19,0.66,2.31,1.49,2.52c0.08,0.02,0.17,0.03,0.25,0.03l0,0
		c0.82,0,1.43-1.21,2.01-2.37c0.63-1.25,1.22-2.42,2.23-2.42c1.57,0,2.09,1.39,3.23,4.43c0.36,0.96,0.81,2.15,1.38,3.54
		c1.96,4.78,0.66,7.84-0.28,10.07c-0.19,0.46-0.38,0.89-0.51,1.29c-0.79,2.33-2.04,9.35-2.06,9.42c-0.03,0.15-0.14,0.28-0.3,0.31
		l-2.59,0.6C47.81,61.59,47.78,61.59,47.75,61.59z M48.26,50.38c0.06,0,0.12,0.01,0.17,0.04c0.14,0.07,0.23,0.21,0.22,0.37
		l-0.48,9.92L50,60.28c0.24-1.31,1.31-7.12,2.04-9.28c0.14-0.43,0.34-0.87,0.53-1.34c0.94-2.22,2.12-4.99,0.28-9.47
		c-0.57-1.4-1.02-2.6-1.39-3.56c-1.15-3.05-1.53-3.92-2.51-3.92c-0.53,0-1.07,1.06-1.53,1.99c-0.66,1.31-1.41,2.8-2.71,2.8l0,0
		c-0.15,0-0.3-0.02-0.45-0.06c-1.26-0.32-1.66-1.72-2.04-3.06c-0.51-1.78-0.92-2.86-2.23-2.86c-1.49,0-4.05,4.84-5.92,8.37
		c-2.16,4.08-3.06,5.62-3.91,5.62l0,0c-0.16,0-0.31-0.06-0.43-0.16c-1.33-1.14-0.28-6.34,0.19-8.38l-6.9,1.39
		c-0.36,0.9-1.85,4.62-3.16,7.23c-0.55,1.09-0.39,2.74,0.39,4.01c0.99,1.6,2.82,2.49,5.15,2.49l0,0c1.89,0,4.11-0.56,6.59-1.66
		c0.18-0.11,0.42-0.06,0.54,0.13c0.11,0.18,0.06,0.42-0.13,0.54c-1.44,1.23-5.09,4.71-5.37,6.58l1.23,0.68
		c1.16-1.36,6.75-7.71,10.06-7.71c0.06,0,0.12,0,0.17,0c0.09,0.01,0.17,0.04,0.23,0.1c0.02,0.02,1.9,1.68,4.52,1.68
		c1.64,0,3.24-0.65,4.74-1.93C48.08,50.41,48.17,50.37,48.26,50.38L48.26,50.38z"
          />
          <path
            className="st1"
            d="M36.7,16.32c-0.89,0-1.77-0.17-2.6-0.5c-0.2-0.08-0.3-0.31-0.22-0.51c0.08-0.2,0.31-0.3,0.51-0.22
		c0.01,0,0.01,0,0.02,0.01l0,0c0.02,0.01,2.4,1,4.39-0.05c1.3-0.68,2.16-2.09,2.58-4.19c0.04-0.21,0.25-0.35,0.46-0.31
		c0.21,0.04,0.35,0.25,0.31,0.46c-0.46,2.34-1.47,3.93-2.98,4.73C38.39,16.13,37.55,16.33,36.7,16.32z"
          />
          <path
            className="st1"
            d="M41.15,11.79H41.1c-0.21-0.03-0.37-0.22-0.34-0.43c0,0,0,0,0,0c0.01-0.09,0.23-2.13-1.93-3.66
		c-0.18-0.12-0.22-0.37-0.09-0.54c0.12-0.18,0.37-0.22,0.54-0.09l0,0c2.56,1.81,2.27,4.28,2.26,4.39
		C41.51,11.65,41.35,11.79,41.15,11.79z"
          />
          <path
            className="st1"
            d="M41.61,11.58c-0.13,0-0.25-0.06-0.32-0.17c-0.02-0.02-1.5-2.2-2.89-2.74c-0.2-0.08-0.3-0.3-0.22-0.5
		c0.08-0.2,0.3-0.3,0.5-0.22c0.92,0.36,1.82,1.25,2.44,1.98c-0.26-1.18-0.89-2.25-1.8-3.05c-0.16-0.14-0.17-0.39-0.03-0.55
		c0.14-0.16,0.39-0.17,0.55-0.03c1.43,1.24,2.22,3.05,2.15,4.94c-0.02,0.16-0.13,0.3-0.29,0.34C41.68,11.58,41.65,11.58,41.61,11.58
		z"
          />
          <path
            className="st1"
            d="M42.01,11.36h-0.02c-0.21-0.01-0.38-0.19-0.37-0.4c0-0.03,0.1-3.09-1.25-4.45c-0.15-0.15-0.15-0.4,0-0.55
		c0.15-0.15,0.4-0.15,0.55,0l0,0c1.58,1.6,1.48,4.88,1.47,5.02C42.39,11.19,42.22,11.36,42.01,11.36z"
          />
          <path
            className="st1"
            d="M42.7,11.93c-0.27,0.7-1.06,1.05-1.76,0.78s-1.05-1.06-0.78-1.76c0.27-0.7,1.06-1.05,1.76-0.78
		c0,0,0.01,0,0.01,0C42.63,10.45,42.97,11.23,42.7,11.93z"
          />
          <polygon
            className="st3"
            style={{ fill: hat_color }}
            points="38.61,16.65 27.85,18.42 22.39,9.52 31.42,10.19 	"
          />
          <path
            className="st1"
            d="M27.85,18.81c-0.14,0-0.27-0.07-0.34-0.19l-5.45-8.9c-0.11-0.18-0.06-0.42,0.13-0.54
		c0.07-0.04,0.15-0.06,0.23-0.06l9.02,0.67c0.09,0.01,0.17,0.04,0.23,0.1l7.19,6.46c0.11,0.1,0.16,0.26,0.11,0.4
		c-0.04,0.14-0.16,0.25-0.31,0.28l-10.76,1.77C27.89,18.81,27.87,18.81,27.85,18.81z M23.12,9.97L28.04,18l9.7-1.6l-6.49-5.83
		L23.12,9.97z"
          />
          <path
            className="st0"
            d="M23.24,17.08c0,0-2.56-3.22-1.97-5.13c0.59-1.91-2.55,0.07-3.13,2.52c-0.58,2.45,1.62,3.69,3.66,4.33
		L23.24,17.08z"
          />
          <path
            className="st1"
            d="M21.8,19.2c-0.04,0-0.08-0.01-0.12-0.02c-3.88-1.23-4.27-3.28-3.91-4.79c0.46-1.95,2.33-3.59,3.26-3.59
		c0.22-0.01,0.43,0.09,0.56,0.27c0.17,0.23,0.19,0.56,0.05,1c-0.44,1.4,1.23,3.91,1.9,4.77c0.12,0.14,0.12,0.35,0,0.49l-1.45,1.73
		C22.02,19.14,21.91,19.2,21.8,19.2z M20.95,11.59c-0.58,0.13-2.07,1.43-2.43,2.98c-0.39,1.67,0.66,2.95,3.15,3.79l1.08-1.29
		c-0.61-0.82-2.4-3.46-1.84-5.23C20.92,11.75,20.94,11.67,20.95,11.59L20.95,11.59z"
          />
          <path
            className="st0"
            d="M32.48,20.69c0,0,4.07-0.63,4.93-2.43c0.86-1.8,1.83,1.78,0.59,3.97s-3.69,1.62-5.63,0.7L32.48,20.69z"
          />
          <path
            className="st1"
            d="M35.38,24.14c-0.91,0-1.98-0.29-3.18-0.85c-0.14-0.07-0.23-0.21-0.22-0.37l0.12-2.25
		c0.01-0.18,0.15-0.34,0.33-0.36c1.07-0.17,4-0.89,4.63-2.21c0.23-0.47,0.51-0.71,0.84-0.71c0.19,0,0.46,0.08,0.69,0.47
		c0.49,0.85,0.64,3-0.25,4.58C37.69,23.57,36.7,24.14,35.38,24.14z M32.77,22.7c0.99,0.44,1.87,0.67,2.61,0.67
		c1.03,0,1.78-0.43,2.28-1.32c0.81-1.44,0.55-3.42,0.24-3.84c-0.05,0.07-0.1,0.14-0.13,0.22c-0.8,1.68-3.9,2.4-4.91,2.59L32.77,22.7
		z"
          />
          <path
            className="st0"
            d="M16.48,38.13c-0.52,1.72-1.31,3.35-2.34,4.82c-1.7,2.39,4.38,4.62,8.71-0.57L16.48,38.13z"
          />
          <path
            className="st1"
            d="M16.83,45.93L16.83,45.93c-1.49,0-2.75-0.56-3.2-1.44c-0.29-0.56-0.23-1.19,0.19-1.77
		c1.01-1.43,1.78-3.02,2.28-4.69c0.06-0.21,0.27-0.33,0.48-0.27c0.04,0.01,0.08,0.03,0.11,0.05l6.36,4.25
		c0.09,0.06,0.15,0.16,0.17,0.27c0.02,0.11-0.02,0.22-0.09,0.3C21.39,44.72,19.1,45.93,16.83,45.93z M16.7,38.74
		c-0.53,1.57-1.29,3.06-2.24,4.42c-0.24,0.34-0.28,0.66-0.13,0.96c0.26,0.51,1.14,1.02,2.51,1.02l0,0c0.92,0,3.23-0.27,5.43-2.69
		L16.7,38.74z"
          />
          <path
            className="st4"
            d="M17.93,38.32c0,0,0.2,2.6-1.22,3.72c-1.42,1.12-0.08,3.72,4.68-0.23L17.93,38.32z"
          />
          <path
            className="st1"
            d="M17.28,44.31L17.28,44.31c-0.71,0-1.24-0.32-1.43-0.87c-0.2-0.57,0.05-1.26,0.62-1.7
		c1.25-0.98,1.08-3.36,1.08-3.39c-0.01-0.16,0.08-0.32,0.23-0.38c0.15-0.07,0.32-0.04,0.44,0.08l3.45,3.49
		c0.15,0.15,0.15,0.4,0,0.55c-0.01,0.01-0.02,0.02-0.02,0.02C19.44,43.93,18.05,44.31,17.28,44.31z M18.31,39.25
		c-0.07,0.93-0.36,2.31-1.36,3.09c-0.3,0.24-0.46,0.58-0.37,0.84c0.07,0.22,0.33,0.34,0.7,0.34c0.51,0,1.62-0.24,3.53-1.75
		L18.31,39.25z"
          />
          <path
            className="st0"
            d="M23.7,29.51l6.82,6.23c-0.62-3.3,0.88-6.54,1.06-7c0.18-0.46,3.01-3.79,3.02-4.78
		c0.01-0.99-1.55-1.9-2.16-4.82c-0.56-2.66-2.92-3.35-3.32-3.45c-0.37-0.2-2.56-1.29-4.78,0.28c-2.43,1.72-4.19,1.34-4.86,2.07
		c-0.67,0.73-0.85,5.1-1.03,5.56c-0.18,0.46-1.28,3.86-3.97,5.87L23.7,29.51z"
          />
          <path
            className="st1"
            d="M30.52,36.14c-0.1,0-0.19-0.04-0.26-0.1l-6.71-6.13l-9.09-0.05c-0.22,0-0.39-0.18-0.39-0.39
		c0-0.12,0.06-0.24,0.16-0.31c1.1-0.82,2.65-2.44,3.79-5.54c0.02-0.06,0.04-0.11,0.05-0.15c0.04-0.12,0.11-0.75,0.17-1.31
		c0.22-2.14,0.44-3.83,0.94-4.37c0.36-0.39,0.89-0.51,1.56-0.68c1.21-0.24,2.35-0.74,3.36-1.45c0.81-0.59,1.79-0.91,2.79-0.91
		c0.82,0,1.63,0.2,2.36,0.58c0.6,0.16,2.97,0.95,3.57,3.74c0.26,1.21,0.77,2.34,1.49,3.34c0.39,0.57,0.68,1.02,0.68,1.56
		c0,0.74-0.99,2.12-2.28,3.85c-0.34,0.45-0.71,0.95-0.77,1.07l-0.06,0.14c-1.27,3.05-1.23,5.29-0.98,6.64
		c0.03,0.16-0.05,0.33-0.19,0.41C30.66,36.13,30.59,36.14,30.52,36.14z M15.53,29.08l8.18,0.04c0.1,0,0.19,0.04,0.26,0.1l6.04,5.52
		c-0.1-1.46,0.11-3.48,1.16-6.01l0.05-0.13c0.07-0.19,0.34-0.54,0.87-1.25c0.69-0.92,2.12-2.84,2.13-3.39
		c0-0.29-0.23-0.64-0.55-1.12c-0.78-1.08-1.33-2.31-1.6-3.61c-0.51-2.42-2.62-3.05-3.04-3.15c-0.03-0.01-0.06-0.02-0.1-0.04
		c-0.63-0.33-1.33-0.51-2.04-0.51c-0.84,0-1.66,0.27-2.34,0.76c-1.09,0.77-2.32,1.3-3.63,1.57c-0.56,0.14-0.97,0.23-1.17,0.45
		c-0.37,0.4-0.62,2.78-0.74,3.93c-0.1,0.91-0.14,1.32-0.21,1.51c-0.01,0.03-0.03,0.07-0.05,0.13
		C17.81,26.45,16.58,28.08,15.53,29.08z"
          />
          <path
            className="st0"
            d="M28.81,32.03c-2.19-0.85-2-1.73-2.42-3.39c-0.36-1.41-2.37-2.35-4.07-1.59c-1.56,0.7-1.89,1.7-4.08,0.85
		c-2.19-0.85-5.86,1.68-6.57,5.46s0.81,7.87,7.92,6.63c4.38,5.74,8.29,3.75,10.32,0.51S31,32.88,28.81,32.03z"
          />
          <path
            className="st1"
            d="M25.22,44.02L25.22,44.02c-1.95,0-3.9-1.22-5.78-3.61c-3.33,0.54-5.74-0.05-7.11-1.7
		c-1.08-1.3-1.45-3.23-1.03-5.42c0.66-3.51,3.71-5.95,6.03-5.95c0.37,0,0.73,0.06,1.07,0.19c0.41,0.18,0.85,0.28,1.3,0.3
		c0.54,0,0.9-0.23,1.4-0.54c0.35-0.23,0.71-0.43,1.08-0.6c0.45-0.2,0.94-0.3,1.43-0.3c1.49,0,2.86,0.92,3.17,2.15
		c0.07,0.27,0.12,0.52,0.17,0.74c0.24,1.16,0.37,1.74,2.01,2.38c0.95,0.37,1.7,1.29,2.14,2.59c0.68,2.07,0.37,4.54-0.82,6.45
		C28.92,42.84,27.13,44.02,25.22,44.02z M19.61,39.6c0.12,0,0.24,0.06,0.31,0.15c1.78,2.31,3.56,3.49,5.3,3.49l0,0
		c2.21,0,3.69-1.85,4.38-2.96c1.07-1.73,1.34-3.84,0.74-5.78c-0.34-1.06-0.96-1.83-1.68-2.11c-2.04-0.8-2.23-1.7-2.49-2.95
		c-0.05-0.23-0.1-0.46-0.16-0.71c-0.22-0.88-1.28-1.56-2.41-1.56c-0.38,0-0.75,0.08-1.1,0.23c-0.34,0.16-0.67,0.34-0.99,0.55
		c-0.54,0.34-1.05,0.66-1.81,0.66c-0.54-0.02-1.08-0.14-1.58-0.36c-0.25-0.1-0.52-0.14-0.79-0.14c-2.01,0-4.69,2.27-5.26,5.3
		c-0.37,1.96-0.06,3.66,0.87,4.78c0.89,1.07,2.33,1.62,4.28,1.62c0.78-0.01,1.56-0.08,2.33-0.22C19.56,39.59,19.59,39.59,19.61,39.6
		L19.61,39.6z"
          />
          <path
            className="st1"
            d="M22.58,30.11h-0.03c-0.16-0.02-0.28-0.16-0.26-0.32c0.07-0.68-0.09-0.83-0.14-0.87
		c-0.13-0.09-0.42,0.03-0.54,0.1c-0.14,0.08-0.32,0.04-0.4-0.1c-0.08-0.14-0.04-0.32,0.1-0.4c0.07-0.04,0.67-0.39,1.15-0.09
		c0.35,0.22,0.49,0.68,0.41,1.42C22.85,29.99,22.73,30.1,22.58,30.11z"
          />
          <path
            className="st1"
            d="M24.32,30.79c-0.06,0-0.12-0.02-0.17-0.06c-0.13-0.1-0.15-0.28-0.06-0.41c0,0,0,0,0,0
		c0.44-0.59,0.85-0.84,1.26-0.76c0.55,0.1,0.76,0.77,0.78,0.85c0.04,0.16-0.06,0.31-0.22,0.35c-0.14,0.03-0.29-0.05-0.34-0.19
		c-0.04-0.12-0.17-0.4-0.33-0.43c-0.06-0.01-0.28,0-0.69,0.54C24.5,30.75,24.42,30.79,24.32,30.79z"
          />
          <path
            className="st1"
            d="M19.64,40.29c-0.05,0-0.1-0.01-0.14-0.03c-0.2-0.08-0.3-0.3-0.22-0.5c0,0,0,0,0-0.01l3.34-8.55
		c0.08-0.2,0.31-0.3,0.51-0.22c0.2,0.08,0.3,0.31,0.22,0.51L20,40.04C19.94,40.19,19.8,40.29,19.64,40.29z"
          />
          <path
            className="st5"
            d="M22.58,23.01c-0.73,1.87-2.84,2.79-4.7,2.06c-1.87-0.73-2.79-2.84-2.06-4.7c0.73-1.87,2.84-2.79,4.7-2.06
		c0,0,0,0,0,0C22.39,19.04,23.31,21.15,22.58,23.01C22.58,23.01,22.58,23.01,22.58,23.01z"
          />
          <path
            className="st1"
            d="M19.2,25.71c-2.22,0-4.02-1.8-4.02-4.02c0-2.22,1.8-4.02,4.02-4.02c2.22,0,4.02,1.8,4.02,4.02
		c0,0.5-0.09,1-0.28,1.47C22.33,24.7,20.85,25.71,19.2,25.71z M19.2,18.45c-1.79,0-3.25,1.45-3.26,3.24c0,1.79,1.45,3.25,3.24,3.26
		c1.79,0,3.25-1.45,3.26-3.24c0-1.34-0.82-2.54-2.06-3.03C20,18.52,19.6,18.45,19.2,18.45L19.2,18.45z"
          />
          <path
            className="st1"
            d="M21.82,23.29c-0.31,0.79-1.2,1.18-1.98,0.87c-0.79-0.31-1.18-1.2-0.87-1.98s1.2-1.18,1.98-0.87c0,0,0,0,0,0
		C21.74,21.61,22.12,22.5,21.82,23.29z"
          />
          <path
            className="st0"
            d="M20.52,18.31c-1.87-0.73-3.97,0.19-4.7,2.06c0,0,0,0,0,0l6.76,2.65C23.31,21.15,22.39,19.04,20.52,18.31
		C20.53,18.31,20.52,18.31,20.52,18.31z"
          />
          <path
            className="st1"
            d="M22.58,23.4c-0.05,0-0.1-0.01-0.14-0.03l-6.76-2.65c-0.2-0.08-0.3-0.3-0.23-0.5c0,0,0-0.01,0-0.01
		c0.73-2.09,3.03-3.2,5.12-2.46c2.09,0.73,3.2,3.03,2.46,5.12c-0.03,0.09-0.07,0.18-0.1,0.27c-0.04,0.1-0.11,0.17-0.21,0.22
		C22.68,23.38,22.63,23.4,22.58,23.4z M16.34,20.15l5.99,2.35c0.46-1.73-0.57-3.5-2.31-3.96C18.58,18.16,17.05,18.83,16.34,20.15z"
          />
          <path
            className="st1"
            d="M18.14,21.26c-0.16,0-0.29-0.13-0.29-0.29l-0.02-1.31c0-0.16,0.12-0.29,0.28-0.3c0,0,0,0,0,0
		c0.16,0,0.3,0.12,0.3,0.29l0.02,1.31c0,0.16-0.13,0.29-0.29,0.3c0,0,0,0,0,0L18.14,21.26z"
          />
          <path
            className="st1"
            d="M20.46,22.16c-0.16,0-0.29-0.13-0.29-0.29c0-0.08,0.03-0.15,0.08-0.2l0.9-0.95c0.11-0.12,0.29-0.13,0.41-0.02
		c0.12,0.11,0.13,0.29,0.02,0.41c-0.01,0.01-0.01,0.01-0.02,0.02l-0.9,0.95C20.62,22.14,20.54,22.17,20.46,22.16z"
          />
          <path
            className="st1"
            d="M19.3,21.72c-0.04,0-0.07-0.01-0.11-0.02c-0.15-0.06-0.23-0.23-0.17-0.38c0,0,0,0,0,0l0.58-1.46
		c0.05-0.15,0.22-0.24,0.37-0.19c0.15,0.05,0.24,0.22,0.19,0.37c0,0.01-0.01,0.02-0.01,0.03l-0.57,1.46
		C19.53,21.64,19.42,21.72,19.3,21.72z"
          />
          <path
            className="st5"
            d="M35.7,28.14c-0.73,1.87-2.84,2.79-4.7,2.06c-1.87-0.73-2.79-2.84-2.06-4.7c0.73-1.87,2.84-2.79,4.7-2.06
		c0,0,0,0,0,0C35.51,24.17,36.43,26.28,35.7,28.14C35.7,28.14,35.7,28.14,35.7,28.14z"
          />
          <path
            className="st1"
            d="M32.32,30.84c-2.22,0-4.02-1.8-4.02-4.02s1.8-4.02,4.02-4.02c2.22,0,4.02,1.8,4.02,4.02
		c0,0.5-0.09,1-0.28,1.47C35.47,29.84,33.98,30.85,32.32,30.84z M32.32,23.58c-1.8,0-3.25,1.45-3.26,3.24c0,1.8,1.45,3.25,3.24,3.26
		s3.25-1.45,3.26-3.24c0-1.34-0.82-2.54-2.06-3.03C33.13,23.66,32.73,23.58,32.32,23.58L32.32,23.58z"
          />
          <path
            className="st1"
            d="M34.95,28.42c-0.31,0.79-1.2,1.18-1.98,0.87c-0.79-0.31-1.18-1.2-0.87-1.98c0.31-0.79,1.2-1.18,1.98-0.87
		c0,0,0,0,0,0C34.87,26.75,35.25,27.63,34.95,28.42z"
          />
          <path
            className="st0"
            d="M33.65,23.44c-1.87-0.73-3.97,0.19-4.7,2.06l6.76,2.64C36.43,26.28,35.51,24.17,33.65,23.44z"
          />
          <path
            className="st1"
            d="M35.7,28.54c-0.05,0-0.1-0.01-0.14-0.03l-6.76-2.65c-0.2-0.08-0.3-0.3-0.22-0.5c0,0,0,0,0-0.01
		c0.9-2.03,3.28-2.95,5.31-2.04c1.9,0.84,2.85,3,2.17,4.97C36,28.43,35.86,28.53,35.7,28.54z M29.47,25.29l5.99,2.34
		c0.45-1.73-0.59-3.5-2.32-3.95C31.69,23.3,30.17,23.97,29.47,25.29z"
          />
          <path
            className="st1"
            d="M31.27,26.4c-0.16,0-0.29-0.13-0.29-0.29l-0.02-1.31c0-0.16,0.13-0.29,0.29-0.3c0,0,0,0,0,0
		c0.16,0,0.29,0.13,0.3,0.29l0.02,1.31C31.56,26.26,31.44,26.39,31.27,26.4C31.27,26.4,31.27,26.4,31.27,26.4L31.27,26.4z"
          />
          <path
            className="st1"
            d="M33.59,27.3c-0.16,0-0.29-0.13-0.29-0.29c0-0.08,0.03-0.15,0.08-0.2l0.9-0.95c0.1-0.12,0.29-0.14,0.41-0.04
		c0.12,0.1,0.14,0.29,0.04,0.41c-0.01,0.01-0.02,0.02-0.03,0.03l-0.9,0.95C33.74,27.27,33.67,27.3,33.59,27.3z"
          />
          <path
            className="st1"
            d="M32.43,26.85c-0.04,0-0.07-0.01-0.11-0.02c-0.15-0.06-0.23-0.23-0.17-0.38c0,0,0,0,0,0l0.57-1.46
		c0.06-0.15,0.22-0.23,0.38-0.17c0.15,0.06,0.23,0.22,0.17,0.38c0,0,0,0.01,0,0.01l-0.57,1.46C32.65,26.77,32.55,26.85,32.43,26.85z
		"
          />
          <path
            className="st6"
            d="M23.33,16.2l-0.03-0.01C23.3,16.19,23.3,16.21,23.33,16.2z"
          />
          <path
            className="st1"
            d="M23.32,16.59c-0.22,0-0.4-0.18-0.4-0.4c0-0.02,0-0.03,0-0.05c0.02-0.12,0.09-0.22,0.19-0.29
		c0.1-0.06,0.23-0.07,0.34-0.03l0.03,0.01c0.15,0.06,0.25,0.21,0.25,0.38c-0.01,0.16-0.11,0.31-0.27,0.36
		C23.41,16.58,23.36,16.59,23.32,16.59z M23.32,16.15v0.02l0.28-0.23c-0.07-0.08-0.17-0.13-0.28-0.13L23.32,16.15z"
          />
          <path
            className="st6"
            d="M32.69,19.86c0.02,0.03,0.03,0.01,0.03,0.01L32.69,19.86z"
          />
          <path
            className="st1"
            d="M32.71,20.26L32.71,20.26c-0.15,0-0.29-0.09-0.36-0.22c-0.1-0.19-0.03-0.43,0.16-0.53
		c0.1-0.05,0.22-0.06,0.33-0.02l0.03,0.01c0.11,0.04,0.2,0.14,0.23,0.25c0.04,0.12,0.01,0.24-0.06,0.34
		C32.96,20.2,32.84,20.26,32.71,20.26z M32.71,19.48v0.36l0.32-0.17C32.96,19.56,32.84,19.49,32.71,19.48z"
          />
          <path
            className="st3"
            style={{ fill: hat_color }}
            d="M32.66,17.48c0.1-2.31-2.46-3.25-3.09-3.44c-0.59-0.28-3.11-1.33-4.6,0.43c-1.23,1.45-1.55,1.7-1.64,1.73
		l4.54,1.78l0.27,0.1l4.54,1.78C32.65,19.78,32.58,19.37,32.66,17.48z"
          />
          <path
            className="st1"
            d="M32.69,20.25c-0.05,0-0.1-0.01-0.14-0.03l-9.35-3.67c-0.2-0.08-0.3-0.3-0.22-0.5c0.04-0.1,0.11-0.17,0.21-0.22
		c0.06-0.04,0.37-0.29,1.5-1.63c0.64-0.75,1.5-1.14,2.57-1.14c0.86,0.02,1.7,0.23,2.47,0.6c1.67,0.53,3.42,1.8,3.33,3.82
		c-0.07,1.74-0.02,2.13,0,2.21c0.08,0.2-0.01,0.43-0.21,0.51C32.79,20.23,32.74,20.24,32.69,20.25L32.69,20.25z M24.07,16.07
		l8.16,3.19c0-0.37,0-0.94,0.03-1.8c0.09-2-2.13-2.84-2.81-3.05c-0.02-0.01-0.04-0.01-0.05-0.02c-0.68-0.33-1.41-0.51-2.16-0.53
		c-0.76-0.03-1.48,0.29-1.98,0.86C24.71,15.38,24.33,15.8,24.07,16.07z"
          />
          <polygon
            className="st3"
            points="27.58,60.59 25.16,58.69 26.68,57.07 28.67,58.64 	"
          />
          <path
            className="st1"
            d="M27.58,60.98c-0.09,0-0.17-0.03-0.24-0.08l-2.42-1.91c-0.09-0.07-0.14-0.17-0.15-0.28
		c-0.01-0.11,0.03-0.22,0.11-0.3l1.53-1.62c0.14-0.14,0.36-0.16,0.51-0.04l1.99,1.57c0.15,0.12,0.19,0.33,0.1,0.5l-1.09,1.95
		C27.84,60.91,27.71,60.98,27.58,60.98L27.58,60.98z M25.73,58.64L27.45,60l0.71-1.26l-1.45-1.14L25.73,58.64z"
          />
          <polygon
            className="st3"
            points="39.58,62.58 36.56,62.28 37.03,60.08 39.52,60.32 	"
          />
          <path
            className="st1"
            d="M39.58,62.97h-0.04l-3.02-0.3c-0.21-0.02-0.37-0.21-0.35-0.43c0-0.01,0-0.03,0.01-0.04l0.47-2.21
		c0.04-0.19,0.22-0.33,0.42-0.31l2.49,0.24c0.2,0.02,0.35,0.18,0.35,0.38l0.06,2.2c0,0.02,0,0.04,0,0.07
		C39.97,62.79,39.8,62.97,39.58,62.97L39.58,62.97z M37.03,61.94l2.15,0.21l-0.04-1.47l-1.8-0.17L37.03,61.94z"
          />
          <polygon
            className="st3"
            points="50.73,62.32 47.69,62.4 47.91,60.15 50.41,60.08 	"
          />
          <path
            className="st1"
            d="M47.69,62.78c-0.22,0-0.39-0.18-0.39-0.39c0-0.01,0-0.02,0-0.03l0.22-2.25c0.02-0.2,0.18-0.35,0.38-0.35
		l2.5-0.07c0.2,0,0.37,0.14,0.4,0.34l0.32,2.23c0.02,0.11-0.02,0.22-0.09,0.31c-0.07,0.09-0.18,0.14-0.29,0.14l-3.04,0.08
		L47.69,62.78z M48.27,60.53l-0.14,1.47l2.16-0.06l-0.21-1.46L48.27,60.53z"
          />
          <path
            className="st1"
            d="M38.02,51.41c-0.22,0-0.39-0.18-0.39-0.39c0-0.16,0.1-0.3,0.24-0.36c0.04-0.01,0.4-0.15,1.91-0.47
		c0.21-0.05,0.42,0.08,0.47,0.29c0.05,0.21-0.08,0.42-0.29,0.47c0,0-0.01,0-0.01,0c-0.6,0.11-1.2,0.26-1.79,0.44
		C38.11,51.4,38.07,51.41,38.02,51.41z"
          />
          <path
            className="st1"
            d="M48.27,51.16h-0.02c-0.21-0.01-0.38-0.19-0.37-0.41c-0.01-0.6-0.13-1.19-0.37-1.74
		c-0.08-0.2,0.01-0.43,0.2-0.51c0.2-0.08,0.43,0.01,0.51,0.2c0.29,0.66,0.44,1.36,0.43,2.08C48.65,50.99,48.48,51.16,48.27,51.16z"
          />
        </g>
      </svg>
    );
  }
}
