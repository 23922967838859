// NOTE: 将来的にはリッチなデータを保持するためにこのような変換関数を用意する
// export const convertReviewSchemaToReview = (data) => {
//   const author = {
//     user_id: data.review_id,
//     user_name: data.user.user_name,
//     screen_name: data.user.screen_name,
//     icon_type: data.user.icon_type,
//     introduction: data.user.introduction,
//     department: data.user.department,
//     grade: data.user.grade,
//     community: data.user.community,
//     univ_id: data.user.univ_id,
//     score: data.user.score,
//   };
//   const review = {
//     review_id: data.review_id,
//     univ_id: data.univ.univ_id,
//     course: data.course.name,
//     course_group: data.course.course_group,
//     semester: data.course.semester,
//     teacher: data.teacher_group.teachers,
//     year: data.year,
//     comment: data.comment,
//     criterion: {
//       attendance_freq: CRITERION.ATTENDANCE_FREQ.ITEM[data.attendance_freq].key,
//       scoring_method: CRITERION.SCORING_METHOD.ITEM[data.scoring_method].key,
//       credit_easiness: CRITERION.CREDIT_EASINESS.ITEM[data.credit_easiness].key,
//       content_quality: CRITERION.CONTENT_QUALITY.ITEM[data.content_quality].key,
//     },
//     lgtm: data.lgtm,
//     lgtmed: data.lgtmed,
//     timestamp: data.created_at,
//   };
//   const comments = data.comments.map((comment) => {
//     return {
//       comment_id: comment.comment_id,
//       review_id: comment.review_id,
//       comment: comment.comment,
//       author: {
//         user_id: comment.user.user_id,
//         user_name: comment.user.user_name,
//         screen_name: comment.user.screen_name,
//         icon_type: comment.user.icon_type,
//         introduction: comment.user.introduction,
//         department: comment.user.department,
//         grade: comment.user.grade,
//         community: comment.user.community,
//         univ_id: comment.user.univ_id,
//         score: comment.user.score,
//       },
//       lgtm: comment.lgtm,
//       lgtmed: comment.lgtmed,
//       timestamp: comment.created_at,
//     };
//   });
//   return {
//     author: author,
//     review: review,
//     comments: comments,
//   };
// };

// ReviewSchemaからReviewへの変換関数
export const convertReviewSchemaToReview = (data) => {
  // Author
  const author = {
    user_id: data.user.user_id,
    user_name: data.user.user_name,
    screen_name: data.user.screen_name,
    univ_id: data.user.univ.univ_id,
    icon_type: data.user.icon_type,
  };

  // Review
  const review = {
    review_id: data.review_id,
    univ_id: data.univ.univ_id,
    course: data.course.name,
    teacher: data.teacher_group.teachers,
    year: data.year,
    semester: data.course.semester,
    course_group: data.course.course_group,
    comment: data.comment,
    criterion: {
      attendance_freq: data.attendance_freq,
      scoring_method: data.scoring_method,
      credit_easiness: data.credit_easiness,
      content_quality: data.content_quality,
    },
    lgtm: data.lgtm,
    lgtmed: data.lgtmed,
    timestamp: data.created_at,
  };

  // Comments
  const comments = data.comments.map((comment) => {
    return {
      author: {
        user_id: comment.user.user_id,
        user_name: comment.user.user_name,
        screen_name: comment.user.screen_name,
        univ_id: comment.user.univ.univ_id,
        icon_type: comment.user.icon_type,
      },
      comment: comment.comment,
      review_id: comment.review_id,
      lgtm: comment.lgtm,
      timestamp: comment.created_at,
    };
  });

  return {
    author: author,
    review: review,
    comments: comments,
  };
};

export const convertPublicUserSchemaToUser = (data) => {
  const user = {
    user_id: data.user_id,
    user_name: data.user_name,
    screen_name: data.screen_name,
    univ_id: data.univ.univ_id,
    department: data.department,
    major: data.major,
    grade: data.grade,
    introduction: data.introduction,
    icon_type: data.icon_type,
    community: data.community,
    score: data.score,
  };
  return user;
};

export const convertUserPrivateSchemaToPrivateUser = (data) => {
  const privateUser = {
    user_id: data.user_id,
    user_name: data.user_name,
    screen_name: data.screen_name,
    univ_id: data.univ.univ_id,
    department: data.department,
    major: data.major,
    grade: data.grade,
    introduction: data.introduction,
    icon_type: data.icon_type,
    community: data.community,
    score: data.score,
    admit_mail: data.admit_mail,
    planning_grad_school: data.planning_grad_school,
    is_employed: data.is_employed,
  };
  return privateUser;
};

export const convertPrivateUserToUserUpdateSchema = (data) => {
  const user = {
    user_name: data.user_name,
    screen_name: data.screen_name,
    icon_type: data.icon_type,
    introduction: data.introduction,
    department: data.department,
    major: data.major,
    grade: data.grade,
    community: data.community,
    univ_id: data.univ_id,
    admit_mail: data.admit_mail,
    planning_grad_school: data.planning_grad_school,
    is_employed: data.is_employed,
  };
  return user;
};
