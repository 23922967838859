import ReactGA from "react-ga";

export const gaInitialize = () => {
  ReactGA.initialize("UA-59728178-3", {
    debug: false,
  });
};

export const gaPageView = (path) => {
  if (typeof path !== "string") {
    return false;
  }
  ReactGA.pageview(path);
};

/**
 * レビュー投稿ページへの遷移を計測します
 * @param {string} action GAで集計する際のaction e.g. "Click"
 * @param {string} label GAで集計する際のlabel e.g. "Review Link from Fixed Button"
 * @returns {null}
 */
export const gaEventReview = (action, label) => {
  if (typeof action !== "string") {
    return false;
  }
  if (typeof label !== "string") {
    return false;
  }
  ReactGA.event({
    category: "Review",
    action: action,
    label: label,
  });
};

/**
 * 広告のクリックを計測します
 * @param {string} url 広告の遷移先URL
 * @param {string} label GAで集計する際のlabel e.g. "名古屋大学陸上競技部 Logo"
 * @returns {null}
 */
export const gaEventAd = (url, label) => {
  if (typeof url !== "string") {
    return false;
  }
  if (typeof label !== "string") {
    return false;
  }
  ReactGA.outboundLink(
    {
      label: label,
    },
    () => {
      // window.open(url, "_blank"); // 画面遷移しない時があるのでaタグで遷移するように変更
    }
  );
};

/**
 * ユーザ登録画面への遷移を計測します
 * @param {string} from 遷移元の場所 e.g. "Ranking"
 */
export const gaEventRegister = (from) => {
  ReactGA.event({
    category: "Register",
    action: "Click",
    label: `Register Link from ${from}`,
  });
};

/**
 * 講義ランキング画面への遷移を計測します
 * @param {string} from 遷移元の場所 e.g. "Search"
 */
export const gaEventRanking = (from) => {
  ReactGA.event({
    category: "Ranking",
    action: "Click",
    label: `Ranking Link from ${from}`,
  });
};

/**
 * 時間割への遷移を計測します
 * @param {string} from 遷移元の場所 e.g. "Search"
 */
export const gaEventTimeTable = (from) => {
  ReactGA.event({
    category: "TimeTable",
    action: "Click",
    label: `TimeTable Link from ${from}`,
  });
};

/**
 * 検索クエリを計測します
 * @param {string} query e.g. "物理基礎 山田"
 */
export const gaEventSearch = (query) => {
  ReactGA.event({
    category: "Search",
    action: "Search",
    label: query,
  });
};
