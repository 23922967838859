import React from "react";
import { Icon } from "semantic-ui-react";

const EmptyContent = (props) => {
  const { showIcon = true } = props;
  return (
    <Icon
      name={showIcon ? "plus" : null}
      style={{
        margin: 0,
        color: "whitesmoke",
        padding: "32px 3px 52px",
        pointerEvents: "none",
      }}
    />
  );
};
export default EmptyContent;
