import React from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Label,
  Icon,
} from "semantic-ui-react";

const TermDropdown = (props) => {
  const { label, terms, loading, onClick } = props;
  return (
    <Dropdown
      text={
        <Label
          size="large"
          basic
          color="green"
          style={{
            paddingRight: "16px",
            paddingLeft: "16px",
            backgroundColor: "#F4F5F6",
            fontFamily: "sans-serif",
            color: "#444",
          }}
        >
          {label}
          <Icon name="caret down" style={{ margin: "0 0 0 6px" }} />
        </Label>
      }
      icon={null}
      disabled={loading}
      scrolling
    >
      <DropdownMenu>
        {terms.map((term) => {
          const [grade, semester] = term;
          const text = term.join("年");
          return (
            <DropdownItem
              text={text}
              onClick={() => onClick([grade, semester])}
            />
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};
export default TermDropdown;
