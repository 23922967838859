import React, { memo } from "react";
import { Menu, MenuItem } from "semantic-ui-react";

const TabMenu = memo(() => {
  return (
    <Menu pointing secondary>
      <MenuItem name="My時間割" active={true} onClick={null} />
      <MenuItem name="知恵袋" active={false} onClick={null} disabled />
    </Menu>
  );
});

export default TabMenu;
