import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Helmet } from "react-helmet";

import LandingTemplate from "../templates/LandingTemplate";
import AppHomeTemplate from "../templates/AppHomeTemplate";
import ReviewTemplate from "../templates/ReviewTemplate/index";
import PageTemplate from "../templates/PageTemplate";
import RegisterPageTemplate from "../templates/RegisterPageTemplate/index";
import AuthPageTemplate from "../templates/AuthPageTemplate/index";
import UserPageTemplate from "../templates/UserPageTemplate";
import UserEditPageTemplate from "../templates/UserEditPageTemplate/index";
import ReviewPageTemplate from "../templates/ReviewPageTemplate/index";
import CourseRankingPageTemplate from "../templates/RankingPageTemplate/Course/index";
import TimeTablePageTemplate from "../templates/TimeTablePageTemplate/index";
import PostReview from "../components/PostReview/index";
import AfterLoginAction from "./AfterLoginAction/index";
import ForceRedirect from "./ForceRedirect/index";

import "./App.less";

import { gaInitialize } from "../api/GA";

import * as ROUTES from "../constants/routes";

import { Auth, I18n } from "aws-amplify";
import { vocabularies } from "../constants/translations";

import config from "../api/aws-exports";
import AWS from "aws-sdk";

import { Authenticator, translations } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { MaintenancePageTemplate } from "../templates/MaintenancePageTemplate";
import systemApi from "../api/v2/SystemApi";

Auth.configure(config);

I18n.putVocabularies(translations);
I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");

// Identity PoolのIDを指定する
const identityPoolId = process.env.REACT_APP_COGNITO_IDPOOL_ID;
const region = process.env.REACT_APP_AWS_AUTH_REGION;
AWS.config.update({
  region: region,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: identityPoolId,
  }),
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maintenance_mode: false, // メンテナンスモードかどうか
    };
  }

  componentDidMount() {
    gaInitialize();
    this.setUserSessionId();
    this.fetchSystemSettings();
  }

  fetchSystemSettings() {
    systemApi
      .getSystemSettings()
      .then((settings) => {
        this.setState({ maintenance_mode: settings.is_maintenance_mode });
      })
      .catch((err) => console.log(err));
  }

  setUserSessionId = () => {
    var user_session_id = localStorage.getItem("user_session_id");
    if (!user_session_id) {
      user_session_id = Math.random().toString(36).substr(2, 8);
      localStorage.setItem("user_session_id", user_session_id);
    }
    this.setState({ user_session_id: user_session_id });
  };

  render() {
    const { maintenance_mode } = this.state;
    return (
      <div>
        <Helmet defaultTitle="楽単らくだ - 大学生の履修選択をラクに">
          <meta
            name="description"
            content="「楽単らくだ（らくたんらくだ）」は、履修選択に悩める大学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
          />
          <meta name="robots" content="index" />
        </Helmet>
        <Authenticator.Provider>
          <Router>
            {maintenance_mode ? (
              <MaintenancePageTemplate />
            ) : (
              <>
                <AfterLoginAction>
                  <Switch>
                    <Route
                      path={ROUTES.LANDING}
                      component={LandingTemplate}
                      exact
                    />
                    <Redirect
                      from={ROUTES.APP.ROOT}
                      to={ROUTES.LANDING}
                      exact
                    />

                    <Route path={ROUTES.TERMS} component={PageTemplate} exact />
                    <Route
                      path={ROUTES.PRIVACY}
                      component={PageTemplate}
                      exact
                    />
                    <Route path={ROUTES.GUIDE} component={PageTemplate} exact />

                    <Route
                      path={ROUTES.APP.REGISTER}
                      component={RegisterPageTemplate}
                      exact
                    />
                    <Route
                      path={ROUTES.APP.AUTH}
                      component={AuthPageTemplate}
                      exact
                    />
                    <Route
                      path={ROUTES.APP.USER.HOME}
                      component={UserPageTemplate}
                      exact
                    />
                    <Route
                      path={ROUTES.APP.USER.EDIT}
                      component={UserEditPageTemplate}
                      exact
                    />
                    <Route
                      path={ROUTES.APP.REVIEW.SINGLE}
                      component={ReviewPageTemplate}
                      exact
                    />
                    <Route
                      path={ROUTES.APP.TIMETABLE.ROOT}
                      component={TimeTablePageTemplate}
                      exact
                    />

                    <Route
                      path={ROUTES.APP.POST}
                      component={PostReview}
                      exact
                    />
                    <Route
                      path={ROUTES.APP.REVIEW.SEARCH}
                      component={ReviewTemplate}
                      exact
                    />
                    <Route
                      path={ROUTES.APP.RANKING.COURSE}
                      component={CourseRankingPageTemplate}
                      exact
                    />
                    <Route
                      path={ROUTES.APP.HOME}
                      component={AppHomeTemplate}
                      exact
                    />

                    <Route path="*" component={PageTemplate} />
                  </Switch>
                </AfterLoginAction>
              </>
            )}
          </Router>
        </Authenticator.Provider>
      </div>
    );
  }
}

export default App;
