import ApiClient from "./ApiClient";
import { COURSES } from "./ApiEndpoints";

export default class UserApi {
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  async getUnivCourses(univId, course = null) {
    try {
      const queryParams = course ? `?course=${course}` : "";
      const res = await this.apiClient.get(
        COURSES.UNIV.GET.replace("<univ_id>", univId) + queryParams
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }
}
