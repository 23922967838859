import React from "react";
import { Button } from "semantic-ui-react";

import styles from "./styles.module.scss";

const Counter = (props) => {
  const { name, value, onChange } = props;
  const handleMinus = () => {
    onChange(null, { name, value: value - 1 });
  };
  const handlePlus = () => {
    onChange(null, { name, value: value + 1 });
  };
  return (
    <>
      <Button icon="minus" basic circular size="small" onClick={handleMinus} />
      <span className={styles.sans_serif} style={{ margin: "0 8px 0 6px" }}>
        {value}
      </span>
      <Button icon="plus" basic circular size="small" onClick={handlePlus} />
    </>
  );
};
export default Counter;
