import ApiClient from "./ApiClient";
import { COMMUNITIES } from "./ApiEndpoints";

export default class CommunityApi {
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  async getUnivCommunities(univId, community = null) {
    try {
      const queryParams = community ? `?community=${community}` : "";
      const res = await this.apiClient.get(
        COMMUNITIES.UNIV.GET.replace("<univ_id>", univId) + queryParams
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }
}
