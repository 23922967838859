import React, { useCallback, useEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";

import TimeTableChart from "./TimeTable/TimeTableChart";
import TimeTableHeader from "./TimeTable/TimeTableHeader";
import CaptureTemplate from "./TimeTable/CaptureTemplate";
import SpeechBubble from "./TimeTable/SpeechBubble";
import TimeTableOverview from "./TimeTable/TimeTableOverview";
import { CellDetailModal, CellSelectModal } from "../MyTimeTable/Modals";
import { PLACEHOLDER_TIMETABLE } from "../../constants/timetable";
import TimeTableApi from "../../../../api/v2/TimeTableApi";
import UNIV from "../../../../constants/univ";
import { useCell } from "../../hooks/timetable/useCell";
import { capture } from "@/utils/capture";
import { isSmartPhone } from "@/utils/device";

const MyTimeTable = (props) => {
  const {
    univ_id,
    department,
    major,
    timetable,
    currentTerm,
    terms,
    loading,
    limited,
    onCreateTimeTable,
    onChangeTimeTable,
    onChangeTerm,
  } = props;
  const [cellPos, setCellPos] = useState([1, 0]); // [row, column]
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [showSpeechBubble, setShowSpeechBubble] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [placeholderTimeTable, setPlaceholderTimeTable] = useState(
    PLACEHOLDER_TIMETABLE
  );
  const [grade, semester] = currentTerm;
  const [row, column] = cellPos;
  const { cell, fetchCell, clearCell } = useCell();
  const cells = timetable ? timetable.cells : [];
  const captureRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const handleFilledCellClick = useCallback(
    (row, column) => {
      setCellPos([row, column]);
      setIsDetailModalOpen(true);
    },
    [setCellPos, setIsDetailModalOpen]
  );
  const handleEmptyCellClick = useCallback(
    (row, column) => {
      setCellPos([row, column]);
      setIsSelectModalOpen(true);
    },
    [setCellPos, setIsSelectModalOpen]
  );
  const handleSelectCell = useCallback(
    (cell) => {
      if (timetable) {
        const newTimetable = { ...timetable, cells: [...timetable.cells] };
        newTimetable.cells.push(cell);
        onChangeTimeTable(newTimetable);
      } else {
        const { row_size, column_size } = placeholderTimeTable;
        const newTimeTable = {
          grade,
          semester,
          row_size,
          column_size,
          cells: [cell],
        };
        onCreateTimeTable(newTimeTable);
      }
    },
    [
      timetable,
      currentTerm,
      placeholderTimeTable,
      onChangeTimeTable,
      onCreateTimeTable,
    ]
  );
  const handleCreateAverageTimeTable = useCallback(async () => {
    if (cells.length > 0) {
      const confirm = window.confirm(
        `${department}${major} ${grade}年${semester} の時間割を自動作成します。\n現在の時間割は上書きされますがよろしいですか？`
      );
      if (!confirm) return;
    }
    try {
      setGenerating(true);
      const timeTableApi = new TimeTableApi();
      const aveTimeTable = await timeTableApi.getAverageTimeTable({
        univ_id,
        department,
        major,
        grade,
        semester,
      });
      if (aveTimeTable.cells.length === 0) {
        window.alert(
          "過去のデータが存在しないため、時間割を作成できません。時間割を作成してみんなが自動作成できるようにしましょう！"
        );
        setGenerating(false);
        return;
      }
      if (timetable) {
        const newTimetable = { ...timetable, ...aveTimeTable };
        onChangeTimeTable(newTimetable);
      } else {
        const { row_size, column_size, cells } = aveTimeTable;
        const newTimeTable = {
          grade,
          semester,
          row_size,
          column_size,
          cells,
        };
        onCreateTimeTable(newTimeTable);
      }
      // 4秒後に時間割が作成されたことを通知
      setTimeout(() => {
        setGenerating(false);
        setShowSpeechBubble(true);
        window.alert("時間割が作成されました！");
      }, 4000);
    } catch (err) {
      console.error(err);
      window.alert("時間割の作成に失敗しました。");
      setGenerating(false);
    }
  }, [onCreateTimeTable, placeholderTimeTable]);
  const handleDeleteCell = useCallback(() => {
    // 同じ授業のセルが連続している場合、連続しているセルも削除
    const targetCell = cells.find(
      (cell) => cell.row === row && cell.column === column
    );
    let length = 1; // 連続しているセルの数
    for (let i = row + 1; i <= timetable.row_size; i++) {
      const nextCell = cells.find(
        (cell) =>
          cell.row === i &&
          cell.column === column &&
          cell.lecture.lecture_id === targetCell.lecture.lecture_id &&
          cell.credit === targetCell.credit
      );
      if (nextCell) {
        length++;
      } else {
        break;
      }
    }
    const newCells = cells.filter(
      (cell) =>
        cell.row < row || row + length - 1 < cell.row || cell.column !== column
    ); // 削除対象のセルを除外
    onChangeTimeTable({ ...timetable, cells: newCells });
  }, [row, column, timetable, onChangeTimeTable, cells]);
  const handleDeleteTimeTable = useCallback(() => {
    if (!window.confirm(`この時間割の全てのコマを削除しますか？`)) return;
    onChangeTimeTable({ ...timetable, cells: [] });
  }, [onChangeTimeTable]);
  const handleCapture = useCallback(async () => {
    // "display: none"の要素を一時的にblockにしてキャプチャ
    captureRef.current.style.display = "block";
    const filename = "timetable.png";
    const url = await capture(captureRef, filename);
    captureRef.current.style.display = "none";
    // デバイスによって処理を分岐
    if (isSmartPhone()) {
      // スマホ・タブレットの場合は画像を表示
      setImgSrc(url);
    } else {
      // デスクトップの場合はダウンロード
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
    }
  }, [captureRef]);

  const modalCell = cells.find(
    (cell) => cell.row === row && cell.column === column
  );
  const preCell = cells.find(
    (cell) => cell.row === row - 1 && cell.column === column
  );

  useEffect(() => {
    setShowSpeechBubble(false);
    setPlaceholderTimeTable({ ...placeholderTimeTable });
  }, [grade, semester]);

  // 時間割上に存在するセルが選択されたときセル情報を取得
  useEffect(() => {
    if (modalCell) {
      clearCell();
      fetchCell({ cellId: modalCell.cell_id });
    }
  }, [modalCell]);

  return (
    <div>
      {!limited && (
        <>
          <TimeTableHeader
            currentTerm={currentTerm}
            terms={terms}
            loading={loading}
            handleItemClick={onChangeTerm}
            deletable={timetable && cells.length > 0}
            onDelete={handleDeleteTimeTable}
            onCreateAveTimeTable={handleCreateAverageTimeTable}
            onCapture={handleCapture}
          />
          <div>
            <p style={{ fontSize: 12, marginBottom: 6 }}>
              ※現在のMy時間割はβ版です。ご意見・ご要望は
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSefN6vt8e1mXpnOrEusY6dQ4784Q3efITP2U554kWo6lz3y-w/viewform"
                target="_blank"
              >
                こちら
              </a>
              から
            </p>
          </div>
          <TimeTableChart
            timetable={timetable || placeholderTimeTable}
            loading={loading}
            generating={generating}
            showIntro
            onGenerate={handleCreateAverageTimeTable}
            onFilledCellClick={handleFilledCellClick}
            onEmptyCellClick={handleEmptyCellClick}
          />
          {modalCell && (
            <CellDetailModal
              cell={cell || modalCell} // 評価情報を含むcellが取得できるまではmodalCellを表示
              open={isDetailModalOpen}
              setOpen={setIsDetailModalOpen}
              onDelete={handleDeleteCell}
            />
          )}
          <CellSelectModal
            univ_id={univ_id}
            currentTerm={currentTerm}
            cellPos={cellPos}
            preCell={preCell}
            open={isSelectModalOpen}
            setOpen={setIsSelectModalOpen}
            onSelectCell={handleSelectCell}
          />
          {showSpeechBubble && (
            <SpeechBubble style={{ fontFamily: "sans-serif" }}>
              みんなの時間割をもとにAIが作成した
              <span style={{ fontWeight: "bold" }}>
                「{UNIV[univ_id].name} {department}
                {major}」向けの時間割
              </span>
              です。あなたが直すとAIの精度が向上します。
            </SpeechBubble>
          )}
          <TimeTableOverview cells={cells} />
        </>
      )}
      {limited && (
        <>
          <TimeTableChart
            // timetable={SAMPLE_TIMETABLE} // TODO: サンプルデータを表示
            loading={loading}
            limited={limited}
          />
          <TimeTableOverview />
        </>
      )}
      {/* 時間割キャプチャ用のコンポーネント */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          display: imgSrc ? "flex" : "none",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
        onClick={() => setImgSrc(null)}
      >
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            padding: 12,
            cursor: "pointer",
            zIndex: 1001,
          }}
          onClick={() => setImgSrc(null)}
        >
          <Icon name="cancel" size="big" style={{ color: "white" }} fitted />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "white",
              fontSize: 16,
              marginBottom: 12,
            }}
          >
            画面を長押しして
            <br />
            画像を保存してください
          </p>
          <img
            src={imgSrc}
            alt="timetable"
            style={{ maxWidth: "90%", maxHeight: "70vh" }}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </div>
      <CaptureTemplate
        loading={loading}
        grade={grade}
        semester={semester}
        timetable={timetable}
        placeholderTimeTable={placeholderTimeTable}
        captureRef={captureRef}
      />
    </div>
  );
};

export default MyTimeTable;
