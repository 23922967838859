import React from "react";
import { Helmet } from "react-helmet";
import { Auth } from "aws-amplify";

import { Container } from "semantic-ui-react";

import Navbar from "../../components/Navbar/index";
import Footer from "../../components/Footer/index";
import Review from "../../components/Review/index";
import { LoadingContent } from "../../components/LoadingContent";

import * as ROUTES from "../../constants/routes";

import ReviewApi from "../../api/v2/ReviewApi";
import { convertReviewSchemaToReview } from "../../api/v2/ApiUtils";

export default class ReviewPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      review: null,
      currentUser: null,
    };
  }

  pushHistory = (uri) => {
    if (this.props.history) {
      this.props.history.push(uri);
    }
  };

  componentDidMount() {
    const reviewId = this.props.match.params["review_id"];
    new ReviewApi()
      .getReview(reviewId)
      .then((review) => {
        this.setState({ review: convertReviewSchemaToReview(review) });
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          // もし404なら404ページにリダイレクト
          this.pushHistory(ROUTES.NOT_FOUND);
        } else {
          console.log(err);
        }
      });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({ currentUser: user });
      })
      .catch(() => null);
  }

  render() {
    const { review, currentUser } = this.state;
    const reviewId = this.props.match.params["review_id"];
    return (
      <>
        <Helmet>
          <title>楽単らくだ</title>
          <meta
            name="description"
            content={
              "「楽単らくだ」は、履修選択に悩める大学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
            }
          />
          <meta name="robots" content="index" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            property="og:url"
            content={`https://www.rakutan-rakuda.com${ROUTES.APP.REVIEW.SINGLE.replace(
              ":review_id",
              reviewId
            )}`}
          />
          <meta
            property="og:image"
            content={`https://api.rakutan-rakuda.com/api/v2/ogp/reviews/${reviewId}`}
          />
          <meta
            name="twitter:image"
            content={`https://api.rakutan-rakuda.com/api/v2/ogp/reviews/${reviewId}`}
          />
        </Helmet>
        <Navbar
          style={{ marginBottom: "24px" }}
          subtitle={"授業評価"}
          show_pusher={false}
          show_control={false}
          pushHistory={this.pushHistory}
        />
        <Container>
          {review ? (
            <Review
              author={review.author}
              review={review.review}
              comments={review.comments}
              opening
              is_posted_by_user={
                currentUser
                  ? currentUser.attributes.sub === review.author.user_id
                  : false
              }
              limited={currentUser ? false : true}
            />
          ) : (
            <LoadingContent />
          )}
        </Container>
        <Footer pushHistory={this.pushHistory} />
      </>
    );
  }
}
