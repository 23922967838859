
// Twitter
export const shareReviewOnTwitter = (course, review) => {
  let limit = 100; // 文字数上限
  limit -= course.length;
  let trimmedReview = "";
  if (review.length > limit) {
    trimmedReview = review.slice(0, limit) + "...";
  } else {
    trimmedReview = review;
  }
  const text = `【${course}】%0D%0A${trimmedReview}%0D%0A｜大学生向け授業評価サイト%20%23楽単らくだ%0D%0A`;
  const url = `https://twitter.com/intent/tweet?text=${text}&url=https://www.rakutan-rakuda.com`;
  window.open(url, '_blank');
  return;
}