import React from "react";

import Review from "../Review/index";
import { gaEventAd } from "../../api/GA";
import { Image, Segment } from "semantic-ui-react";
import ThreeOptions from "../Survey/ThreeOptions";

class Ad extends React.Component {
  click = () => {
    const { alt, href } = this.props;
    gaEventAd(href, alt);
  };
  render() {
    const { alt, src, href, maxHeight } = this.props;
    return (
      <div style={{ textAlign: "center" }}>
        <a href={href} target="_blank" onClick={() => this.click()}>
          <Image
            alt={alt}
            src={src}
            style={{
              cursor: "pointer",
              display: "inline-block",
              maxHeight: `${maxHeight}px`,
              borderRadius: "6px",
            }}
          />
        </a>
      </div>
    );
  }
}

const Survey = (props) => {
  const { survey } = props;
  switch (survey.type) {
    case "three_options":
      return (
        <Segment fluid style={{ padding: 15 }}>
          <ThreeOptions survey={survey} />
        </Segment>
      );
    default:
      return null;
  }
};

export const Feed = (props) => {
  const { type, data } = props;
  switch (type) {
    case "review":
      return <Review {...data} />;
    case "ad":
      return <Ad {...data} />;
    case "survey":
      return <Survey {...data} />;
    default:
      return null;
  }
};
