import React from "react";
import { Button } from "semantic-ui-react";

const EllipsisButton = (props) => {
  const { size = "medium", onClick } = props;
  return (
    <Button
      circular
      icon="ellipsis horizontal"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0)",
      }}
      size={size}
      onClick={onClick}
    />
  );
};

export default EllipsisButton;
