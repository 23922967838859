import React from "react";
import { Modal as Modal_, ModalContent } from "semantic-ui-react";

const Modal = (props) => {
  const {
    children,
    className,
    open = false,
    setOpen,
    scrollable = true,
    style,
  } = props;
  return (
    <div>
      <Modal_
        closeIcon
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        className={className}
        style={style}
      >
        <ModalContent
          scrolling
          style={{
            overflow: scrollable ? "scroll" : "hidden",
            height: "100%",
            maxHeight: 9999,
          }}
        >
          {children}
        </ModalContent>
      </Modal_>
    </div>
  );
};
export default Modal;
