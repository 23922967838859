import React, { useState } from "react";
import {
  Icon,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableCell,
  TableBody,
} from "semantic-ui-react";

import SegmentArea from "../../SegmentArea";

const TimeTableOverview = (props) => {
  const { cells = [] } = props;
  const [open, setOpen] = useState(true);
  const cellCount = cells.length;
  const unitCount = cells.reduce((acc, cell) => acc + cell.credit, 0);
  const cellCountByCourseGroup = cells.reduce((acc, cell) => {
    const course_group = cell.lecture.course.course_group;
    if (!acc[course_group]) {
      acc[course_group] = 0;
    }
    acc[course_group]++;
    return acc;
  }, {});
  const unitCountByCourseGroup = cells.reduce((acc, cell) => {
    const course_group = cell.lecture.course.course_group;
    if (!acc[course_group]) {
      acc[course_group] = 0;
    }
    acc[course_group] += cell.credit;
    return acc;
  }, {});
  return (
    <SegmentArea style={{ marginTop: 12 }}>
      <div>
        <div>
          <p style={{ fontSize: 20 }}>
            {cellCount}コマ / {unitCount}単位
          </p>
          <a
            onClick={() => setOpen((prev) => !prev)}
            style={{ cursor: "pointer" }}
          >
            科目区分別に見る
            <Icon name={open ? "angle up" : "angle down"} />
          </a>
          {open && (
            <Table
              basic="very"
              unstackable
              compact="very"
              style={{
                backgroundColor: "white",
                borderRadius: 6,
                padding: "16px 12px",
              }}
            >
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>科目区分</TableHeaderCell>
                  <TableHeaderCell>コマ数</TableHeaderCell>
                  <TableHeaderCell>単位数</TableHeaderCell>
                </TableRow>
              </TableHeader>

              <TableBody>
                {Object.keys(cellCountByCourseGroup).map((course_group) => {
                  return (
                    <TableRow key={course_group}>
                      <TableCell>{course_group}</TableCell>
                      <TableCell>
                        {cellCountByCourseGroup[course_group]}
                      </TableCell>
                      <TableCell>
                        {unitCountByCourseGroup[course_group]}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {Object.keys(cellCountByCourseGroup).length === 0 && (
                  <TableRow>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
        {/* <Button
          content="共有＋"
          color="blue"
          size="small"
          style={{ fontFamily: "sans-serif" }}
        /> */}
      </div>
    </SegmentArea>
  );
};
export default TimeTableOverview;
