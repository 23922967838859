import ApiClient from "./ApiClient";
import { APPLICANTS } from "./ApiEndpoints";

export default class ApplicantApi {
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  async getEachApplicantsCount() {
    const res = await this.apiClient.get(APPLICANTS.COUNT.EACH.GET);
    return res.data;
  }

  async getUnivApplicantsCount(univId) {
    const res = await this.apiClient.get(
      APPLICANTS.COUNT.UNIV.GET.replace("<univ_id>", univId)
    );
    return res.data;
  }
}
