import React from "react";

const FilledContent = (props) => {
  const { cell } = props;
  const {
    lecture: {
      course: { name },
      teacher_group: { teachers },
    },
  } = cell;
  return (
    <>
      <div
        style={{
          textAlign: "center",
          fontSize: "12px",
          lineHeight: "1.1em",
          overflowWrap: "break-word",
          wordBreak: "break-word",
          whiteSpace: "normal",
          padding: "32px 3px 48px",
        }}
      >
        <span style={{ color: "white" }}>{name}</span>
      </div>
      <div
        style={{
          height: 16,
          backgroundColor: "dimgray",
          mixBlendMode: "luminosity",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "white",
            fontSize: "9px",
            fontWeight: "bold",
          }}
        >
          {teachers[0].name}
          <span
            style={{
              fontSize: "8px",
              fontWeight: "normal",
            }}
          >
            {teachers.length > 1 && " ほか"}
          </span>
        </p>
      </div>
    </>
  );
};
export default FilledContent;
