import { microCmsClient } from "./MicroCmsClient";

export default class NewsApi {
  async getNews() {
    try {
      const data = await microCmsClient.get({
        endpoint: "news",
      });
      return data.contents;
    } catch (err) {
      throw err;
    }
  }
}
