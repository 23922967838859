import ApiClient from "./ApiClient";
import { USERS } from "./ApiEndpoints";

export default class UserApi {
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  async getUser(userId) {
    try {
      const res = await this.apiClient.get(
        USERS.SINGLE.PUBLIC.GET.replace("<user_id>", userId)
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async getPrivateUser(userId) {
    try {
      const res = await this.apiClient.get(
        USERS.SINGLE.PRIVATE.GET.replace("<user_id>", userId)
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async createUser(user) {
    try {
      const res = await this.apiClient.post(USERS.POST, user);
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async updateUser(userId, user) {
    try {
      const res = await this.apiClient.put(
        USERS.SINGLE.PUT.replace("<user_id>", userId),
        user
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async deleteUser(userId) {
    try {
      const res = await this.apiClient.delete(
        USERS.SINGLE.DELETE.replace("<user_id>", userId)
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async getRanking(univId, ranking, term) {
    try {
      const res = await this.apiClient.get(
        USERS.UNIV.GET.replace("<univ_id>", univId) +
          `?ranking=${ranking}&term=${term}`
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }
}
