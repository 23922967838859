import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
} from "semantic-ui-react";

import TimeTableCell from "./TimeTableCell";
import { WEEK } from "../../../../constants/timetable";
import { LimitedPanel } from "./LimitedPanel";
import { GeneratingLoader } from "./GeneratingLoader";
import { IntroPanel } from "./IntroPanel";

const TimeTableChart = (props) => {
  const {
    timetable,
    loading,
    generating = false,
    limited = false,
    pictureMode = true,
    showIntro = false,
    onGenerate = () => {},
    onFilledCellClick,
    onEmptyCellClick,
  } = props;
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    setDirty(false);
  }, [timetable]);

  const handleCellClick = useCallback((row, column) => {
    const cell = timetable.cells.find(
      (cell) => cell.row === row && cell.column === column
    );
    if (cell) {
      onFilledCellClick(row, column);
    } else {
      onEmptyCellClick(row, column);
    }
  });
  const handleGenerate = useCallback(() => {
    setDirty(true);
    onGenerate();
  }, [onGenerate]);

  const { row_size, column_size, cells } = timetable
    ? timetable
    : {
        row_size: 5,
        column_size: 5,
        cells: [],
      };
  const days = WEEK.slice(0, column_size);
  const activeIntroPanel =
    showIntro && !dirty && cells.length === 0 && !loading && !generating;
  return (
    <>
      <Table
        celled
        compact
        unstackable
        fixed
        style={{
          margin: 0,
          fontFamily: "sans-serif",
          fontWeight: "bold",
          overflow: "hidden",
        }}
      >
        <TableHeader>
          <TableRow>
            {days.map((item, idx) => (
              <TableHeaderCell
                key={idx}
                textAlign="center"
                style={{
                  padding: "4px",
                  fontSize: "14px",
                }}
              >
                {item}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody
          style={{
            overflow: "hidden",
            position: "relative",
          }}
        >
          {(loading || generating) &&
            [...Array(5)].map((_, idx) => {
              return (
                <TableRow key={idx}>
                  {[...Array(5)].map((_, idx_) => {
                    return <TimeTableCell key={idx_} loading />;
                  })}
                </TableRow>
              );
            })}
          {!loading &&
            !generating &&
            timetable &&
            [...Array(row_size)].map((_, row_idx) => {
              row_idx++; // 時限は1から始まる
              return (
                <TableRow>
                  {[...Array(column_size)].map((_, column_idx) => {
                    const cell = cells.find(
                      (cell) =>
                        cell.row === row_idx && cell.column === column_idx
                    );
                    // そのセルが空の場合
                    if (!cell) {
                      return (
                        <TimeTableCell
                          key={`${row_idx}-${column_idx}`}
                          cell={cell}
                          pictureMode={pictureMode}
                          disabled={activeIntroPanel}
                          onCellClick={() =>
                            handleCellClick(row_idx, column_idx)
                          }
                        />
                      );
                    }
                    // ひとつ前のセルが同じ授業だった場合はnullを返す
                    const preSameCell = cells.find(
                      (cell_) =>
                        cell_.row === row_idx - 1 &&
                        cell_.column === column_idx &&
                        cell_.lecture.lecture_id === cell.lecture.lecture_id &&
                        cell_.credit === cell.credit
                    );
                    if (preSameCell) return null;
                    // 後に同じ授業が連続する場合はその分lengthを増やす
                    let length = 1;
                    for (let i = row_idx + 1; i <= row_size; i++) {
                      const nextCell = cells.find(
                        (cell_) =>
                          cell_.row === i &&
                          cell_.column === column_idx &&
                          cell_.lecture.lecture_id ===
                            cell.lecture.lecture_id &&
                          cell_.credit === cell.credit
                      );
                      if (nextCell) {
                        length++;
                      } else {
                        break;
                      }
                    }
                    return (
                      <TimeTableCell
                        key={`${row_idx}-${column_idx}`}
                        cell={cell}
                        disabled={activeIntroPanel}
                        length={length}
                        onCellClick={() => handleCellClick(row_idx, column_idx)}
                      />
                    );
                  })}
                </TableRow>
              );
            })}
          {limited && <LimitedPanel />}
          {activeIntroPanel && (
            <IntroPanel
              onGenerateClick={handleGenerate}
              onCustomClick={() => setDirty(true)}
            />
          )}
          {generating && <GeneratingLoader />}
        </TableBody>
      </Table>
    </>
  );
};

export default TimeTableChart;
