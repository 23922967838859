import axios from "axios";
import { v4 as uuid4 } from "uuid";
import { Auth } from "aws-amplify";

const GUEST_USER_ID_KEY = "guest_user_id";

class ApiClient {
  constructor() {
    // ローカルストレージにguestUserIdがなければ発行する
    const user_id = localStorage.getItem(GUEST_USER_ID_KEY);
    if (!user_id) {
      this.createGuestUser();
    }
  }

  createGuestUser() {
    const guestUserId = uuid4().toString();
    localStorage.setItem(GUEST_USER_ID_KEY, guestUserId);
    return guestUserId;
  }

  async getToken() {
    try {
      // ログインしている場合
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      return token;
    } catch (e) {
      // ログインしていない場合
      let guestUserId = localStorage.getItem(GUEST_USER_ID_KEY);
      if (!guestUserId) {
        this.createGuestUser();
        guestUserId = localStorage.getItem(GUEST_USER_ID_KEY);
      }
      // guestUserIdを使ってJWTトークンを発行する
      const token = this.generateDummyJWTToken(guestUserId);
      return token;
    }
  }

  generateDummyJWTToken(guestUserId) {
    const header = {
      alg: "HS256",
      typ: "JWT",
    };
    const payload = {
      sub: guestUserId,
    };
    const signature = "dummy_signature";
    const token =
      btoa(JSON.stringify(header)) +
      "." +
      btoa(JSON.stringify(payload)) +
      "." +
      btoa(JSON.stringify(signature));
    return token;
  }

  async get(url) {
    const token = await this.getToken();
    return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async post(url, data) {
    const token = await this.getToken();
    return await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async put(url, data) {
    const token = await this.getToken();
    return await axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async delete(url) {
    const token = await this.getToken();
    return await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

ApiClient.instance = new ApiClient();
export default ApiClient;
