import { useState } from "react";

import TimeTableApi from "../../../../api/v2/TimeTableApi";

export const useCells = () => {
  const [cells, setCells] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const clearCells = () => {
    setCells([]);
  };

  const fetchCells = ({ univId, semester, row, column }) => {
    setIsLoading(true);
    const timeTableApi = new TimeTableApi();
    timeTableApi
      .getCells({
        univ_id: univId,
        semester,
        row,
        column,
      })
      .then((cells) => {
        setCells(cells);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createCell = async ({
    credit,
    row,
    column,
    univId,
    courseName,
    courseGroup,
    semester,
    teachers,
  }) => {
    const timeTableApi = new TimeTableApi();
    return timeTableApi
      .postCell({
        credit,
        row,
        column,
        univ_id: univId,
        course_name: courseName,
        course_group: courseGroup,
        semester,
        teachers,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        setError(err);
        throw err;
      });
  };

  return {
    cells,
    error,
    isLoading,
    clearCells,
    setCells,
    fetchCells,
    createCell,
  };
};
