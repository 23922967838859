import { useEffect, useState } from "react";

import TimeTableApi from "../../../../api/v2/TimeTableApi";

export const useMyTimeTables = (userId) => {
  const [myTimeTables, setMyTimeTables] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      const timeTableApi = new TimeTableApi();
      timeTableApi
        .getMyTimeTables({ user_id: userId })
        .then((timetables) => {
          setMyTimeTables(timetables);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userId]);

  const getMyTimeTable = async (table_id) => {
    const timetableApi = new TimeTableApi();
    return timetableApi.getTimeTable(table_id);
  };

  const createMyTimeTable = async (timetable) => {
    const { grade, semester, row_size, column_size, cells } = timetable;
    const timetableApi = new TimeTableApi();
    return timetableApi.postTimeTable({
      grade,
      semester,
      row_size,
      column_size,
      cells: cells.map((cell) => cell.cell_id),
    });
  };

  const updateMyTimeTable = async (timetable) => {
    const { my_timetable_id, grade, semester, row_size, column_size, cells } =
      timetable;
    const timetableApi = new TimeTableApi();
    return timetableApi.updateTimeTable(my_timetable_id, {
      grade,
      semester,
      row_size,
      column_size,
      cells: cells.map((cell) => cell.cell_id),
    });
  };

  return {
    myTimeTables,
    error,
    isLoading,
    setMyTimeTables,
    getMyTimeTable,
    createMyTimeTable,
    updateMyTimeTable,
  };
};
