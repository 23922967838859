import { useState } from "react";

import TimeTableApi from "@/api/v2/TimeTableApi";

export const useCell = () => {
  const [cell, setCell] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const clearCell = () => {
    setCell(null);
  };

  const fetchCell = ({ cellId }) => {
    setIsLoading(true);
    const timeTableApi = new TimeTableApi();
    timeTableApi
      .getCell({
        cell_id: cellId,
      })
      .then((cell) => {
        setCell(cell);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    cell,
    error,
    isLoading,
    clearCell,
    fetchCell,
  };
};
