import React from "react";

import { Container, Header, Grid, Segment } from "semantic-ui-react";

import Navbar from "../../components/Navbar/index";
import Footer from "../../components/Footer/index";
import Landing from "../../components/Landing/index";

import UNIV from "../../constants/univ";
import * as ROUTES from "../../constants/routes";
import ReviewApi from "../../api/v2/ReviewApi";
import ApplicantApi from "../../api/v2/ApplicantApi";

import Logo from "../../components/Logo";

import { gaPageView } from "../../api/GA";

class AppLink extends React.Component {
  state = {
    review_count: -1,
  };
  componentDidMount = () => {
    gaPageView("/");
    const { status } = this.props;
    if (status === "open") {
      this.getReviewCount(this.props.u_key);
    }
  };

  getReviewCount = (univ_id) => {
    const reviewApi = new ReviewApi();
    reviewApi.getUnivReviewsCount(univ_id).then((count) => {
      this.setState({
        review_count: parseInt(count),
      });
    });
  };

  render() {
    const { u_key, status, user_count, pushHistory, ...otherProps } =
      this.props;
    //    const user_count = (u_key in Object.keys(user_count_obj)) ? user_count_obj[u_key] : -1;
    return (
      <Segment
        onClick={() => pushHistory(ROUTES.APP.HOME.replace(":univ_id", u_key))}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        {...otherProps}
      >
        <Logo
          icon={true}
          bg_color={UNIV[u_key]?.color?.main}
          circular
          style={{ width: "42px", marginRight: "8px" }}
        />
        <Header style={{ margin: "0" }}>
          <span style={{ fontSize: "16px" }}>{UNIV[u_key].name}</span>
          <Header.Subheader>
            {status === "open" &&
              (this.state.review_count > 0 ? (
                <div>
                  <span style={{ display: "inline-block" }}>
                    {this.state.review_count + "件の"}
                  </span>
                  <span style={{ display: "inline-block" }}>評価</span>
                </div>
              ) : (
                ""
              ))}
            {status === "ready" &&
              (user_count > 0 ? (
                user_count >= 200 ? (
                  <p style={{ color: "#EE0000", fontWeight: "bold" }}>
                    近日公開予定
                  </p>
                ) : (
                  user_count + "件の評価"
                )
              ) : (
                ""
              ))}
          </Header.Subheader>
        </Header>
      </Segment>
    );
  }
}

export default class LandingTemplate extends React.Component {
  state = {
    user_count_obj: {},
  };
  componentDidMount = () => {
    this.getUserCount();
  };

  pushHistory = (uri) => {
    if (this.props.history) {
      this.props.history.push(uri);
    }
  };

  getUserCount = () => {
    const applicantApi = new ApplicantApi();
    applicantApi.getEachApplicantsCount().then((res) => {
      this.setState({ user_count_obj: res });
    });
  };

  render() {
    const { ...otherProps } = this.props;
    return (
      <div {...otherProps}>
        <Navbar
          style={{ marginBottom: "24px" }}
          univ_id={""}
          subtitle={"授業評価"}
          show_pusher={true}
          show_control={false}
          pushHistory={this.pushHistory}
        />
        <Container text style={{ marginBottom: 48 }}>
          <Header as="h3" textAlign="center">
            大学一覧
          </Header>
          <Grid>
            {Object.keys(UNIV).map((u_key, uindex) => {
              if (UNIV[u_key] && UNIV[u_key].status === "open") {
                return (
                  <Grid.Column computer={8} mobile={8}>
                    <AppLink
                      pushHistory={this.pushHistory}
                      u_key={u_key}
                      status="open"
                    />
                  </Grid.Column>
                );
              }
            })}
          </Grid>
          {/* <Header as="h3" textAlign="center">
            公開準備中
            <Header.Subheader>
              200件以上の評価が集まると公開されます。
            </Header.Subheader>
          </Header>
          <Grid>
            {Object.keys(UNIV).map((u_key, uindex) => {
              if (UNIV[u_key] && UNIV[u_key].status === "ready") {
                return (
                  <Grid.Column computer={4} mobile={8}>
                    <AppLink
                      pushHistory={this.pushHistory}
                      u_key={u_key}
                      status="ready"
                      user_count={
                        this.state.user_count_obj[u_key]
                          ? this.state.user_count_obj[u_key]
                          : -1
                      }
                    />
                  </Grid.Column>
                );
              }
            })}
          </Grid>
          <Header as="h3" textAlign="center">
            申請フォーム
            <Header.Subheader>
              ここに掲載されていない大学も、申請を受け付けています
            </Header.Subheader>
          </Header>
          <div style={{ textAlign: "center" }}>
            <Button
              style={{ margin: "12px auto" }}
              primary
              onClick={() => {
                window.location.href = "https://forms.gle/pJaWWYiMDBy3srGJ8";
              }}
            >
              申請する
            </Button>
          </div> */}
        </Container>
        <Landing />
        <Footer pushHistory={this.pushHistory} />
      </div>
    );
  }
}
