import React from "react";
import { Placeholder, PlaceholderImage, TableCell } from "semantic-ui-react";
import styles from "./styles.module.scss";
import FilledContent from "./FilledContent";
import EmptyContent from "./EmptyContent";

const TimeTableCell = (props) => {
  const {
    cell = null,
    loading,
    disabled = false,
    pictureMode = true,
    length = 1,
    onCellClick,
  } = props;
  return (
    <TableCell
      collapsing
      textAlign="center"
      style={{
        padding: 0,
        margin: 0,
        cursor: loading ? "default" : "pointer",
        backgroundColor: cell ? "#4a94a1" : "white",
        height: "100%",
        position: "relative",
      }}
      disabled={disabled}
      rowSpan={length}
      onClick={!loading && onCellClick}
      className={styles.cell}
    >
      {loading && (
        <Placeholder
          fluid
          style={{ height: 96, border: "1px solid whitesmoke" }}
        >
          <PlaceholderImage />
        </Placeholder>
      )}
      {!loading &&
        (cell ? (
          <FilledContent cell={cell} />
        ) : (
          <EmptyContent showIcon={pictureMode} />
        ))}
    </TableCell>
  );
};
export default TimeTableCell;
