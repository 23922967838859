export const DISTRICT = {
  HOKKAIDO: "北海道",
  TOHOKU: "東北",
  KANTO: "関東",
  CHUBU: "中部",
  KINKI: "近畿",
  CHUSHI: "中国・四国",
  KYUSHU: "九州",
};

export const PREF = {
  HOKKAIDO: {
    name: "北海道",
    district: DISTRICT.HOKKAIDO,
  },
  MIYAGI: {
    name: "宮城",
    district: DISTRICT.TOHOKU,
  },
  TOKYO: {
    name: "東京",
    district: DISTRICT.KANTO,
  },
  AICHI: {
    name: "愛知",
    district: DISTRICT.CHUBU,
  },
  KYOTO: {
    name: "京都",
    district: DISTRICT.KINKI,
  },
  OSAKA: {
    name: "大阪",
    district: DISTRICT.KINKI,
  },
  FUKUOKA: {
    name: "福岡",
    district: DISTRICT.KYUSHU,
  },
};

const TYPE = {
  NATIONAL: "国立大学",
  PUBLIC: "公立大学",
  PRIVATE: "私立大学",
  OTHER: "その他",
};

export const getUnivColor = (univ_id) => {
  if (UNIV[univ_id]) {
    return UNIV[univ_id].color.main;
  } else {
    return "#206774";
  }
};

const UNIV = {
  nagoya_u: {
    name: "名古屋大学",
    status: "open",
    type: TYPE.NATIONAL,
    pref: PREF.AICHI,
    color: {
      main: "#00664b",
    },
    semesters: ["前期", "後期"],
    periods: ["前期", "後期", "通年", "前期集中", "後期集中", "通年集中"],
    course_group: [
      "「大学での学び」基礎論",
      "基礎セミナー",
      "英語",
      "初修外国語",
      "日本語",
      "健康・スポーツ科学科目（講義）",
      "健康・スポーツ科学科目（実習）",
      "データ科学科目",
      "国際理解科目",
      "現代教養科目",
      "超学部セミナー",
      "人文・社会系基礎科目",
      "自然系基礎科目",
      "言語文化I",
      "言語文化II",
      "言語文化III",
      "健康・スポーツ科学",
      "文系基礎科目",
      "理系基礎（文系）",
      "理系基礎（理系）",
      "文系教養科目",
      "理系教養科目",
      "全学教養科目",
      "開放科目",
      "専門科目",
      "文学部専門科目",
      "教育学部専門科目",
      "法学部専門科目",
      "経済学部専門科目",
      "情報学部専門科目",
      "情報文化学部専門科目",
      "理学部専門科目",
      "医学部専門科目",
      "工学部専門科目",
      "農学部専門科目",
      "大学院",
    ],
    department: {
      文学部: {
        majors: ["人文学科"],
      },
      法学部: {
        majors: ["法律・政治学科"],
      },
      経済学部: {
        majors: [],
      },
      教育学部: {
        majors: ["人間発達科学科"],
      },
      情報学部: {
        majors: ["自然情報学科", "人間・社会情報学科", "コンピュータ科学科"],
      },
      理学部: {
        majors: [],
      },
      工学部: {
        majors: [
          "物理工学科",
          "環境土木・建築学科",
          "化学生命工学科",
          "マテリアル工学科",
          "電気電子情報工学科",
          "機械・航空宇宙工学科",
          "エネルギー理工学科",
        ],
      },
      医学部: {
        majors: [
          "医学科",
          "保健学科／看護学専攻",
          "保健学科／放射線技術科学専攻",
          "保健学科／検査技術科学専攻",
          "保健学科／理学療法学専攻",
          "保健学科／作業療法学専攻",
        ],
      },
      農学部: {
        majors: ["生物環境科学科", "資源生物科学科", "応用生命科学科"],
      },
      人文学研究科: {
        majors: [
          "言語文化学繋",
          "英語文化学繋",
          "文献思想学繋",
          "超域人文学繋",
          "歴史文化学繋",
        ],
      },
      教育発達科学研究科: { majors: ["教育科学専攻", "心理発達科学専攻"] },
      法学研究科: { majors: ["総合法政専攻", "実務法曹養成専攻"] },
      経済学研究科: {
        majors: ["社会経済システム専攻", "産業経営システム専攻"],
      },
      情報学研究科: {
        majors: [
          "数理情報学専攻",
          "複雑系科学専攻",
          "社会情報学専攻",
          "心理・認知科学専攻",
          "情報システム学専攻",
          "知能システム学専攻",
        ],
      },
      理学研究科: { majors: ["理学専攻", "国際理学専攻"] },
      医学系研究科: { majors: ["医科学専攻", "総合保健学専攻"] },
      工学研究科: {
        majors: [
          "有機・高分子化学専攻",
          "応用物質化学専攻",
          "生命分子工学専攻",
          "応用物理学専攻",
          "物質科学専攻",
          "材料デザイン工学専攻",
          "物質プロセス工学専攻",
          "化学システム工学専攻",
          "電気工学専攻",
          "電子工学専攻",
          "情報・通信工学専攻",
          "機械システム工学専攻",
          "マイクロ・ナノ機械理工学専攻",
          "航空宇宙工学専攻",
          "エネルギー理工学専攻",
          "総合エネルギー工学専攻",
          "土木工学専攻",
          "都市環境学専攻",
        ],
      },
      生命農学研究科: {
        majors: [
          "森林・環境資源科学専攻",
          "植物生産科学専攻",
          "動物科学専攻",
          "応用生命科学専攻",
        ],
      },
      国際開発研究科: { majors: ["国際開発協力専攻"] },
      多元数理科学研究科: { majors: ["多元数理科学専攻"] },
      環境学研究科: {
        majors: ["地球環境科学専攻", "都市環境学専攻", "社会環境学専攻"],
      },
      創薬科学研究科: { majors: ["基盤創薬学専攻"] },
    },
  },
  nanzan_u: {
    name: "南山大学",
    status: "ready",
    type: TYPE.PRIVATE,
    pref: PREF.AICHI,
    color: {
      main: "#1d2088",
      sub: "#f7ab00",
    },
    semesters: ["Q1", "Q2", "Q3", "Q4"],
    periods: ["Q1", "Q2", "Q3", "Q4", "通年"],
    course_group: [
      "共通教育科目",
      "人文学部",
      "外国語学部",
      "経済学部",
      "経営学部",
      "法学部",
      "総合政策学部",
      "理工学部",
      "情報理工学部",
      "国際教養学部",
      "短期大学部",
      "資格関連科目",
      "大学院",
    ],
    department: {
      人文学部: {
        majors: [
          "キリスト教学科",
          "人類文化学科",
          "心理人間学科",
          "日本文化学科",
        ],
      },
      外国語学部: {
        majors: [
          "英米学科",
          "スペイン・ラテンアメリカ学科",
          "フランス学科",
          "ドイツ学科",
          "アジア学科",
        ],
      },
      経済学部: { majors: ["経済学科"] },
      経営学部: { majors: ["経営学科"] },
      法学部: { majors: ["法律学科"] },
      総合政策学部: {
        majors: ["総合政策学科"],
      },
      理工学部: {
        majors: [
          "ソフトウェア工学科",
          "データサイエンス学科",
          "電子情報工学科",
          "機械システム工学科",
        ],
      },
      国際教養学部: { majors: ["国際教養学科"] },
      人間文化研究科: {
        majors: [
          "キリスト教思想専攻",
          "宗教思想専攻",
          "人類学専攻",
          "教育ファシリテーション専攻",
          "言語科学専攻",
        ],
      },
      国際地域文化研究科: { majors: ["国際地域文化専攻"] },
      社会科学研究科: {
        majors: ["経済学専攻", "経営学専攻", "総合政策学専攻"],
      },
      法学研究科: { majors: ["法律学専攻"] },
      理工学研究科: {
        majors: [
          "システム数理専攻",
          "ソフトウェア工学専攻",
          "機械電子制御工学専攻",
          "データサイエンス専攻",
        ],
      },
      法務研究科: { majors: ["法務専攻"] },
    },
  },
  hokudai: {
    name: "北海道大学",
    status: "ready",
    type: TYPE.NATIONAL,
    pref: PREF.HOKKAIDO,
    color: {
      main: "#23651c",
    },
    department: {},
  },
  tohoku: {
    name: "東北大学",
    status: "ready",
    type: TYPE.NATIONAL,
    pref: PREF.MIYAGI,
    color: {
      main: "#492c83",
    },
    department: {},
  },
  u_tokyo: {
    name: "東京大学",
    status: "ready",
    type: TYPE.NATIONAL,
    pref: PREF.TOKYO,
    color: {
      main: "#5b8dca",
      sub: "#f5cb03",
    },
    department: {},
  },

  waseda: {
    name: "早稲田大学",
    status: "ready",
    type: TYPE.PRIVATE,
    pref: PREF.TOKYO,
    color: {
      main: "#961b1f",
      sub: "#172f27",
    },
    department: {},
  },
  nihon_u: {
    name: "日本大学",
    status: "ready",
    type: TYPE.PRIVATE,
    pref: PREF.TOKYO,
    color: {
      main: "#e92626",
    },
    department: {},
  },
  keio: {
    name: "慶應義塾大学",
    status: "ready",
    type: TYPE.PRIVATE,
    pref: PREF.TOKYO,
    color: {
      main: "#22265f",
      sub: "#fcd008",
    },
    department: {},
  },
  meiji: {
    name: "明治大学",
    status: "ready",
    type: TYPE.PRIVATE,
    pref: PREF.TOKYO,
    color: {
      main: "#413c98",
    },
    department: {},
  },
  chuo_u: {
    name: "中央大学",
    status: "ready",
    type: TYPE.PRIVATE,
    pref: PREF.TOKYO,
    color: {
      main: "#e3252c",
    },
    department: {},
  },
  hosei: {
    name: "法政大学",
    status: "ready",
    type: TYPE.PRIVATE,
    pref: PREF.TOKYO,
    color: {
      main: "#f48220",
    },
    department: {},
  },
  rikkyo: {
    name: "立教大学",
    status: "ready",
    type: TYPE.PRIVATE,
    pref: PREF.TOKYO,
    color: {
      main: "#532c82",
    },
    department: {},
  },
  aoyama: {
    name: "青山学院大学",
    status: "ready",
    type: TYPE.PRIVATE,
    pref: PREF.TOKYO,
    color: {
      main: "#0e998d",
    },
    department: {},
  },

  nagoya_cu: {
    name: "名古屋市立大学",
    status: "ready",
    type: TYPE.PUBLIC,
    pref: PREF.AICHI,
    color: {
      main: "#920e14",
    },
    department: {},
  },
  nitech: {
    name: "名古屋工業大学",
    status: "ready",
    type: TYPE.PUBLIC,
    pref: PREF.AICHI,
    color: {
      main: "#232b65",
    },
    department: {},
  },
  meijo_u: {
    name: "名城大学",
    status: "ready",
    type: TYPE.PUBLIC,
    pref: PREF.AICHI,
    color: {
      main: "#b71f41",
    },
    department: {},
  },
  chukyo_u: {
    name: "中京大学",
    status: "ready",
    type: TYPE.PRIVATE,
    pref: PREF.AICHI,
    color: {
      main: "#b82025",
    },
    semesters: ["春学期", "秋学期"],
    periods: [
      "春学期",
      "秋学期",
      "通年",
      "春学期集中",
      "秋学期集中",
      "通年集中",
      "認定",
    ],
    course_group: [
      "全学共通科目",
      "ゼミ",
      "コンピュータ",
      "スポーツ・健康",
      "外国語基礎",
      "外国語演習",
      "自然の探究",
      "人間の探究",
      "社会の探究",
      "新領域",
      "テーマ",
      "文学部固有科目",
      "国際英語学部固有科目",
      "国際教養学部固有科目",
      "国際学部固有科目",
      "心理学部固有科目",
      "法学部固有科目",
      "総合政策学部固有科目",
      "経済学部固有科目",
      "経営学部固有科目",
      "現代社会学部固有科目",
      "情報理工学部固有科目",
      "工学部固有科目",
      "スポーツ科学部固有科目",
      "大学院",
    ],
    department: {
      国際学部: {
        majors: [
          "国際人間学専攻",
          "国際政治学専攻",
          "国際経済学専攻",
          "GLS専攻",
          "複言語・複文化学専攻",
          "英米学専攻",
        ],
      },
      文学部: { majors: ["歴史文化学科", "日本文学科", "言語表現学科"] },
      心理学部: { majors: ["心理学科"] },
      法学部: { majors: ["法律学科"] },
      経済学部: { majors: ["経済学科"] },
      経営学部: { majors: ["経営学科"] },
      総合政策学部: { majors: ["総合政策学科"] },
      現代社会学部: {
        majors: [
          "社会学専攻",
          "コミュニティ学専攻",
          "社会福祉学専攻",
          "国際文化専攻",
        ],
      },
      工学部: {
        majors: [
          "機械システム工学科",
          "電気電子工学科",
          "情報工学科",
          "メディア工学科",
        ],
      },
      スポーツ科学部: {
        majors: [
          "スポーツマネジメント学科",
          "スポーツ健康科学科",
          "トレーナー学科",
          "スポーツ教育学科",
          "競技スポーツ科学科",
        ],
      },
      国際英語学部: { majors: ["国際英語学科", "英米文化学科"] },
      国際教養学部: { majors: ["国際教養学科"] },
      人文社会科学研究科: {
        majors: [
          "日本語日本文学専攻",
          "言語文化専攻",
          "歴史文化専攻",
          "法・政治学専攻",
          "経済・経営学専攻",
        ],
      },
      文学研究科: { majors: ["日本文学・日本語文化専攻", "歴史文化専攻"] },
      国際英語学研究科: { majors: ["国際英語学専攻", "英米文化学専攻"] },
      心理学研究科: {
        majors: ["実験・応用心理学専攻", "臨床・発達心理学専攻"],
      },
      社会学研究科: { majors: ["社会学専攻"] },
      法学研究科: { majors: ["法律学専攻"] },
      経済学研究科: { majors: ["経済学専攻", "総合政策学専攻"] },
      経営学研究科: { majors: ["経営学専攻"] },
      工学研究科: {
        majors: [
          "機械システム工学専攻",
          "電気電子工学専攻",
          "情報工学専攻",
          "工学専攻",
        ],
      },
      スポーツ科学研究科: { majors: ["スポーツ科学専攻"] },
    },
  },
  agu: {
    name: "愛知学院大学",
    status: "ready",
    type: TYPE.PUBLIC,
    pref: PREF.AICHI,
    color: {
      main: "#9a1c24",
    },
    department: {},
  },
  aichi_u: {
    name: "愛知大学",
    status: "ready",
    type: TYPE.PUBLIC,
    pref: PREF.AICHI,
    color: {
      main: "#20416f",
    },
    semesters: ["春学期", "秋学期"],
    periods: ["春学期", "秋学期", "通年"],
    course_group: [
      "共通教育科目",
      "文学部",
      "地域政策学部",
      "短期大学部",
      "司書・司書教諭・学芸員・社会教育主事課程",
      "法学部",
      "経済学部",
      "経営学部",
      "現代中国学部",
      "国際コミュニケーション学部",
      "教職課程",
      "大学院（文学研究科）",
      "大学院（経済学研究科）",
      "大学院（経営学研究科）",
      "大学院（中国研究科）",
      "大学院（国際コミュニケーション研究科）",
      "大学院（法学研究科）",
      "協定留学生（日本語コース）",
      "専門職大学院（法務研究科）",
    ],
    department: {
      法学部: { majors: ["法学科"] },
      経済学部: { majors: ["経済学科"] },
      経営学部: { majors: ["経営学科会計", "ファイナンス学科"] },
      現代中国学部: { majors: ["現代中国学科"] },
      国際コミュニケーション学部: { majors: ["英語学科", "国際教養学科"] },
      文学部: {
        majors: [
          "人文社会学科",
          "歴史地理学科",
          "日本語日本文学科",
          "心理学科",
        ],
      },
      地域政策学部: { majors: ["地域政策学科"] },
      短期大学部: { majors: ["ライフデザイン総合学科"] },
      法学研究科: { majors: ["公法学専攻", "私法学専攻"] },
      経済学研究科: { majors: ["経済学専攻"] },
      経営学研究科: { majors: ["経営学専攻"] },
      中国研究科: { majors: ["中国研究専攻"] },
      国際コミュニケーション研究科: { majors: ["国際コミュニケーション専攻"] },
      文学研究科: {
        majors: ["日本文化専攻", "地域社会システム専攻", "欧米文化専攻"],
      },
      法務研究科: { majors: ["法務専攻"] },
    },
  },
  kyoto_u: {
    name: "京都大学",
    status: "open",
    type: TYPE.NATIONAL,
    pref: PREF.KYOTO,
    color: {
      main: "#1d2858",
    },
    semesters: ["前期", "後期"],
    periods: ["前期", "後期", "通年", "前期集中", "後期集中", "通年集中"],
    course_group: [
      "人文・社会科学科目",
      "自然科学科目",
      "外国語科目",
      "情報学科目",
      "健康・スポーツ科目(実習)",
      "健康・スポーツ科目(科学)",
      "キャリア形成科目",
      "統合科学科目",
      "少人数教育科目",
      "大学院共通科目",
      "大学院横断教育科目",
      "文学部専門科目",
      "教育学部専門科目",
      "法学部専門科目",
      "経済学部専門科目",
      "理学部専門科目",
      "医学部(医学科)専門科目",
      "医学部(人間健康学科)専門科目",
      "医学部専門科目",
      "薬学部(薬学科)専門科目",
      "薬学部(薬科学科)専門科目",
      "薬学部専門科目",
      "工学部(物理工学科)専門科目",
      "工学部(電気電子工学科)専門科目",
      "工学部(地球工学科)専門科目",
      "工学部(情報学科)専門科目",
      "工学部(建築学科)専門科目",
      "工学部(工業化学科)専門科目",
      "工学部専門科目",
      "農学部専門科目",
      "総合人間学部専門科目",
      "大学院専門科目",
    ],
    department: {
      総合人間学部: { majors: ["総合人間学科"] },
      文学部: { majors: ["人文学科"] },
      教育学部: { majors: ["教育科学科"] },
      法学部: { majors: [] },
      経済学部: { majors: ["経済経営学科"] },
      理学部: { majors: ["理学科"] },
      医学部: { majors: ["医学科", "人間健康科学科"] },
      薬学部: { majors: ["薬科学科", "薬学科"] },
      工学部: {
        majors: [
          "地球工学科",
          "建築学科",
          "物理工学科",
          "電気電子工学科",
          "情報学科",
          "工業化学科",
        ],
      },
      農学部: {
        majors: [
          "資源生物科学科",
          "応用生命科学科",
          "地域環境工学科",
          "食料・環境経済学科",
          "森林科学科",
          "食品生物科学科",
        ],
      },
      文学研究科: {
        majors: [
          "文献文化学専攻",
          "思想文化学専攻",
          "歴史文化学専攻",
          "行動文化学専攻",
          "現代文化学専攻",
          "京都大学・ハイデルベルク大学国際連携文化越境専攻",
        ],
      },
      教育学研究科: { majors: ["教育学環専攻"] },
      法学研究科: { majors: ["法政理論専攻", "法曹養成専攻"] },
      経済学研究科: {
        majors: ["経済学専攻", "京都大学国際連携グローバル経済・地域創造専攻"],
      },
      理学研究科: {
        majors: [
          "数学・数理解析専攻",
          "物理学・宇宙物理学専攻",
          "地球惑星科学専攻",
          "化学専攻",
          "生物科学専攻",
        ],
      },
      医学研究科: {
        majors: [
          "医学専攻",
          "医科学専攻",
          "社会健康医学系専攻",
          "人間健康科学系専攻",
          "京都大学・マギル大学ゲノム医学国際連携専攻",
        ],
      },
      薬学研究科: {
        majors: [
          "薬学専攻",
          "創発医薬科学専攻",
          "薬科学専攻",
          "医薬創成情報科学専攻",
        ],
      },
      工学研究科: {
        majors: [
          "社会基盤工学専攻",
          "都市社会工学専攻",
          "都市環境工学専攻",
          "建築学専攻",
          "機械理工学専攻",
          "マイクロエンジニアリング専攻",
          "航空宇宙工学専攻",
          "原子核工学専攻",
          "材料工学専攻",
          "電気工学専攻",
          "電子工学専攻",
          "材料化学専攻",
          "物質エネルギー化学専攻",
          "分子工学専攻",
          "高分子化学専攻",
          "合成・生物化学専攻",
          "化学工学専攻",
        ],
      },
      農学研究科: {
        majors: [
          "農学専攻",
          "森林科学専攻",
          "応用生命科学専攻",
          "応用生物科学専攻",
          "地域環境科学専攻",
          "生物資源経済学専攻",
          "食品生物科学専攻",
        ],
      },
      "人間・環境学研究科": { majors: ["人間・環境学専攻"] },
      エネルギー科学研究科: {
        majors: [
          "エネルギー社会・環境科学専攻",
          "エネルギー基礎科学専攻",
          "エネルギー変換科学専攻",
          "エネルギー応用科学専攻",
        ],
      },
      "アジア・アフリカ地域研究研究科": {
        majors: [
          "東南アジア地域研究専攻",
          "アフリカ地域研究専攻",
          "グローバル地域研究専攻",
        ],
      },
      情報学研究科: { majors: ["情報学専攻"] },
      生命科学研究科: { majors: ["統合生命科学専攻", "高次生命科学専攻"] },
      総合生存学館: { majors: ["総合生存学専攻"] },
      地球環境学舎: { majors: ["地球環境学専攻", "環境マネジメント専攻"] },
      公共政策大学院: { majors: ["公共政策専攻"] },
      経営管理大学院: { majors: ["経営管理専攻", "経営科学専攻"] },
    },
  },
  osaka_u: {
    name: "大阪大学",
    status: "ready",
    type: TYPE.NATIONAL,
    pref: PREF.OSAKA,
    color: {
      main: "#2f2f7e",
    },
    department: {},
  },
  kyushu_u: {
    name: "九州大学",
    status: "ready",
    type: TYPE.NATIONAL,
    pref: PREF.FUKUOKA,
    color: {
      main: "#7b1542",
    },
    department: {},
  },
};

export default UNIV;
