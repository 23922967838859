import { domToPng } from "modern-screenshot";

export const capture = async (ref, filename) => {
  const element = ref.current;
  if (element === null) {
    console.error("ref.current is null");
    return;
  }
  try {
    // 最初の読み込みで画像が読み込まれないバグがあるため、先に一度読み込む
    await domToPng(element);
    const dataUrl = await domToPng(element, {
      quality: 1.0,
      scale: 2,
      fetch: {
        requestInit: {
          mode: "cors",
        },
      },
    });
    return dataUrl;
  } catch (err) {
    console.error(err);
  }
};
