import { useEffect, useState } from "react";
import UNIV from "../../../../constants/univ";

export const useTerm = (univId) => {
  const [grade, setGrade] = useState(null);
  const [semester, setSemester] = useState(null);
  const term = [grade, semester];

  useEffect(() => {
    if (univId) {
      const grades = [1, 2, 3, 4];
      const grade = grades[0]; // 短大など4年制以外を想定し始めたらUNIVを参照するように変更
      if (grade) {
        setGrade(grade);
      }
      const semester = UNIV[univId] ? UNIV[univId].semesters[0] : null;
      if (semester) {
        setSemester(semester);
      }
    }
  }, [univId]);

  const setTerm = ([grade, semester]) => {
    setGrade(grade);
    setSemester(semester);
  };

  return { term, setTerm };
};
