import ApiClient from "./ApiClient";
import { TIMETABLES } from "./ApiEndpoints";

export default class TimeTableApi {
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  async getMyTimeTables({ user_id }) {
    try {
      const res = await this.apiClient.get(
        TIMETABLES.GET + `?user_id=${user_id}`
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async postTimeTable(timetableCreate) {
    try {
      const res = await this.apiClient.post(TIMETABLES.POST, timetableCreate);
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async getTimeTable(table_id) {
    try {
      const res = await this.apiClient.get(
        TIMETABLES.SINGLE.GET.replace("<table_id>", table_id)
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async updateTimeTable(table_id, timetableUpdate) {
    try {
      const res = await this.apiClient.put(
        TIMETABLES.SINGLE.PUT.replace("<table_id>", table_id),
        timetableUpdate
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async deleteTimeTable(table_id) {
    try {
      await this.apiClient.delete(
        TIMETABLES.SINGLE.DELETE.replace("<table_id>", table_id)
      );
    } catch (err) {
      throw err;
    }
  }

  async getAverageTimeTable({ univ_id, department, major, grade, semester }) {
    const params = {
      univ_id,
      department,
      major,
      grade,
      semester,
    };
    const queryParams = new URLSearchParams(params).toString();
    try {
      const res = await this.apiClient.get(
        TIMETABLES.AVERAGE.GET + `?${queryParams}`
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async getSharedTimeTables({ univ_id, department, major, grade, semester }) {
    try {
      const res = await this.apiClient.get(TIMETABLES.SHARES.GET, {
        univ_id,
        department,
        major,
        grade,
        semester,
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async postSharedTimeTable(sharedTimeTableCreate) {
    try {
      const res = await this.apiClient.post(
        TIMETABLES.SHARES.POST,
        sharedTimeTableCreate
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async deleteSharedTimeTable(table_id) {
    try {
      await this.apiClient.delete(
        TIMETABLES.SHARES.SINGLE.DELETE.replace("<table_id>", table_id)
      );
    } catch (err) {
      throw err;
    }
  }

  async postSharedTimeTableComment(table_id, commentCreate) {
    try {
      const res = await this.apiClient.post(
        TIMETABLES.SHARES.SINGLE.COMMENTS.POST.replace("<table_id>", table_id),
        commentCreate
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async countUpImportCount(table_id) {
    try {
      await this.apiClient.post(
        TIMETABLES.SHARES.SINGLE.IMPORT_COUNTS.POST.replace(
          "<table_id>",
          table_id
        )
      );
    } catch (err) {
      throw err;
    }
  }

  async getCells({ univ_id, semester, row, column }) {
    try {
      const params = {
        univ_id,
        semester,
        row,
        column,
      };
      const queryParams = new URLSearchParams(params).toString();
      const res = await this.apiClient.get(
        TIMETABLES.CELLS.GET + `?${queryParams}`
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async getCell({ cell_id }) {
    try {
      const res = await this.apiClient.get(
        TIMETABLES.CELLS.SINGLE.GET.replace("<cell_id>", cell_id)
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async postCell(cellCreate) {
    try {
      const res = await this.apiClient.post(TIMETABLES.CELLS.POST, cellCreate);
      return res.data;
    } catch (err) {
      throw err;
    }
  }
}
