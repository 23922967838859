import ApiClient from "./ApiClient";
import { TEACHERS } from "./ApiEndpoints";

export default class TeacherApi {
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  async getUnivTeachers(univId, teacher = null) {
    try {
      const queryParams = teacher ? `?teacher=${teacher}` : "";
      const res = await this.apiClient.get(
        TEACHERS.UNIV.GET.replace("<univ_id>", univId) + queryParams
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async getUnivCourseTeachers(univId, course_name = null) {
    try {
      const queryParams = course_name ? `?course_name=${course_name}` : "";
      const res = await this.apiClient.get(
        TEACHERS.UNIV.GET.replace("<univ_id>", univId) + queryParams
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async getUnivTeacherGroups(univId, teachers = null) {
    try {
      const queryParams =
        teachers && teachers.length !== 0
          ? `?teachers=${teachers.join(",")}`
          : "";
      const res = await this.apiClient.get(
        TEACHERS.UNIV.GROUPS.GET.replace("<univ_id>", univId) + queryParams
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  }
}
