import { useEffect, useState } from "react";
import UserApi from "../../api/v2/UserApi";

export const useRakudaUser = (userId) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      const userApi = new UserApi();
      userApi
        .getUser(userId)
        .then((user) => {
          setUser(user);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userId]);
  return { user, error, isLoading };
};
