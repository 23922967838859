import React from "react";

import { Segment, Menu, Container, Button } from "semantic-ui-react";
import { gaEventReview } from "../../api/GA";

import Ad from "../Ad.js";
import AdApi from "../../api/v2/AdApi";
import * as ROUTES from "../../constants/routes";
import { ReactComponent as CoverImage } from "../../static/desert.svg";

export default class Footer extends React.Component {
  state = {
    fixedAd: null,
  };
  componentDidMount() {
    const adApi = new AdApi();
    adApi
      .getAds()
      .then((ads) => {
        const { univ_id } = this.props;
        this.setState({
          fixedAd:
            ads
              .filter(
                (ad) =>
                  ad.limited_to.length === 0 || ad.limited_to.includes(univ_id)
              )
              .find((ad) => ad.is_fixed) ?? null,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    const {
      univ_id = "",
      pushHistory = (uri) => {
        console.log("<Footer> pushHistory: " + uri);
      },
      show_sitemap = true,
      hide_desert = false,
      ...otherProps
    } = this.props;
    const { fixedAd = null } = this.state;
    return (
      <div {...otherProps}>
        {!hide_desert && (
          <div
            style={{
              height: "240px",
              marginTop: "48px",
              marginBottom: "-180px",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <CoverImage
              style={{
                position: "absolute",
                height: "100%",
                top: "-9999px",
                left: "-9999px",
                right: "-9999px",
                bottom: "-9999px",
                margin: "auto",
              }}
            />
          </div>
        )}

        <Segment
          vertical
          inverted
          style={{ backgroundColor: "#e8e8e8", marginTop: "2em" }}
        >
          <Container style={{ paddingBottom: "100px" }}>
            <Menu text stackable>
              {show_sitemap && (
                <>
                  <Menu.Item
                    name="トップ"
                    as="a"
                    onClick={() => pushHistory(ROUTES.LANDING)}
                  />
                  <Menu.Item
                    name="利用規約"
                    as="a"
                    onClick={() => pushHistory(ROUTES.TERMS)}
                  />
                  <Menu.Item
                    name="削除依頼"
                    as="a"
                    onClick={() => pushHistory(ROUTES.GUIDE)}
                  />
                  <Menu.Item
                    name="ご意見箱"
                    as="a"
                    onClick={() => {
                      window.open(
                        "https://docs.google.com/forms/d/e/1FAIpQLSefN6vt8e1mXpnOrEusY6dQ4784Q3efITP2U554kWo6lz3y-w/viewform?usp=sf_link",
                        "_blank"
                      );
                    }}
                  />
                  <Menu.Item
                    name="プライバシーポリシー"
                    as="a"
                    onClick={() => pushHistory(ROUTES.PRIVACY)}
                  />
                </>
              )}
              <Menu.Item position="right">&copy; Hashup Inc.</Menu.Item>
            </Menu>
          </Container>
          <Button
            icon="pencil"
            size="massive"
            circular
            inverted
            style={{
              position: "fixed",
              bottom: "80px",
              right: "8px",
              backgroundColor: "#00664b",
              display: univ_id == "" ? "none" : "",
            }}
            onClick={() => {
              gaEventReview("Click", "Review Link from Fixed Button");
              pushHistory(ROUTES.APP.POST.replace(":univ_id", univ_id));
            }}
          />
          {/* 三項演算子だと表示が更新されない */}
          {fixedAd && (
            <Ad
              url={fixedAd.href}
              label={fixedAd.event_label}
              src={fixedAd.image_url.url}
            />
          )}
          {fixedAd === null && (
            <Ad
              url={
                univ_id == ""
                  ? null
                  : `https://www.rakutan-rakuda.com/app/${univ_id}/post`
              }
              label="楽単らくだ 投稿呼びかけ Footer"
              src="/ad/20221108_review.png"
            />
          )}
        </Segment>
      </div>
    );
  }
}
