import { useState } from "react";

export const useAddCellForm = (config) => {
  const { defaultValues = {}, defaultOptions = { selected: {}, results: {} } } =
    config;
  const [values, setValues] = useState(defaultValues);
  const [options, setOptions] = useState(defaultOptions);
  const [errors, setErrors] = useState({});

  const register = (name) => {
    values[name] = values[name] ?? null;
    options[name] = options[name] ?? null;
    return {
      name,
      onChange: (e, { name, value }) => {
        setValues((values) => {
          return { ...values, [name]: value };
        });
      },
    };
  };

  return {
    values,
    options,
    errors,
    register,
    setValues,
    setOptions,
    setErrors,
  };
};
